import axios from "axios";
axios.defaults.withCredentials = true;
 
const API_URL = window.location.protocol + "//" + window.location.host;

export const serviceListeNoteDEbit = async (idProfil) => {
    try {
        return await axios.get(`${API_URL}/api/note/liste/${idProfil}`);
    } catch (err) {
        return {
            error: true,
            response: err.response
        };
    }
}

export const serviceValidNoteDEbit = async (idDEclaration, statut, commentaire) => {
    try {
        return await axios.post(`${API_URL}/api/note/valider`, {idDEclaration, statut, commentaire});
    } catch (err) {
        return {
            error: true,
            response: err.response
        };
    }
};