/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { listeUtilisateursAsync } from "ActionsAsync/ActionsUtilisateurAsync";
import { setPageU, setSelectedRowU } from "Simplificateurs/SimplificateursTable";
import { ouvrirFDialogueModifU, ouvrirFDialogueSupprU } from "Simplificateurs/SimplificateursUtilisateur";

// Composants du @mui material
import LinearProgress from '@mui/material/LinearProgress';
import Icon from "@mui/material/Icon";
import Tooltip from '@mui/material/Tooltip';
import { styled } from "@mui/material/styles";

// Table
import { 
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport, 
} from "@mui/x-data-grid";
import { enUS } from '@mui/material/locale';

// Composants du Material Dashboard 2 React
import MDBox from "Composants/MDBox";
import MDButton from "Composants/MDButton";

// Contexte Material Dashboard 2 PRO React
import { useMaterialUIController } from "Contexte";

import { 
  frFR, 
  globalStyles,
  CustomNoRowsOverlay, 
  formatDate,
  formatHeure,
  HtmlTooltip,
  CustomPagination
} from "Vues/Utilitaires";

var h = window.innerHeight - 340;

const TableUtilisateurs = () => {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, lang, socket } = controller;

  const envoi = useDispatch();

  const tableObj = useSelector((state) => state.table);
  const {
    chargementListeUtilisateurs, 
    listeUtilisateurs,
    pageU,
    selectedRowU
  } = tableObj;

  useEffect(() => {
    const minuteurObtenirListeUtilisateurs = setTimeout(() => {
      envoi(listeUtilisateursAsync(dispatch, socket));
    }, 1000);
    return () => {
      clearTimeout(minuteurObtenirListeUtilisateurs);
    };
  }, []);


  const traiterOuvrir2 = (
    idProfil, 
    email, 
    phone, 
    no,
    avenue,
    quartier,
    commune,
    ville,
    province,
    activation, 
    admin, 
    estStaff, 
    fonction,
    chefLieu 
  ) => () => {
    envoi(ouvrirFDialogueModifU({
      idProfil, 
      email, 
      phone, 
      no,
      avenue,
      quartier,
      commune,
      ville,
      province,
      activation, 
      admin, 
      estStaff, 
      fonction,
      chefLieu
    }));
  };

  const traiterOuvrir3 = (idProfil, email) => () => {
    envoi(ouvrirFDialogueSupprU({idProfil, email}));
  };

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    border: 0,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    WebkitFontSmoothing: "auto",
    letterSpacing: "normal",
    fontSize: "13px",
    backgroundColor: darkMode ? "#202940" : "#fff",
    color: darkMode ? "#7b809a" : "rgba(0,0,0,.85)",
    "& .MuiDataGrid-main": {
      color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
    },
    "& .MuiDataGrid-columnSeparator": {
      color: "#7b809a"
    },
    "& .MuiDataGrid-columnHeaders, & .MuiDataGrid-cell": {
      borderBottom: darkMode ? "1px solid #344767" : "1px solid #f0f2f5"
    },
    "& .MuiDataGrid-footerContainer": {
      borderTop: darkMode ? "1px solid #344767" : "1px solid #f0f2f5"
    },
    "& .MuiIconButton-root": {
      color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
    },
    "& .MuiPaginationItem-previousNext": {
      color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
    },
    "& .MuiPaginationItem-textInfo": {
      color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
    },
    "& .Mui-selected": {
      color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)"
    },
    "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
      outline: "none"
    }
  }));
  
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport 
          printOptions={{ disableToolbarButton: true }} 
          csvOptions={{
            fileName: lang === "fr" ? `${"Liste des Utilisateurs (" + moment(new Date()).format('DD-MM-YYYY HHmmss') + ")"}` : `${"Users list (" + moment(new Date()).format('DD-MM-YYYY HHmmss') + ")"}`,
            delimiter: ',',
            utf8WithBom: true,
          }}
        />
      </GridToolbarContainer>
    );
  }

  var PNF = require("google-libphonenumber").PhoneNumberFormat;
  var utilPhone = require("google-libphonenumber").PhoneNumberUtil.getInstance();

  const colonnes = [
    {
      field: "Options",
      width: 95,
      renderCell: (params) => {
        return (
          <MDBox>
            <Tooltip title={lang === "fr" ? "Modifier" : "Edit"}>
              <MDButton variant="gradient" color="warning" size="small" iconOnly onClick={traiterOuvrir2(
                params.row.ProfilId,
                params.row.Email,
                params.row.Phone,
                params.row.No,
                params.row.Avenue,
                params.row.Quartier, 
                params.row.Commune, 
                params.row.Ville, 
                params.row.Province,
                params.row.Statut,
                params.row.Administrateur, 
                params.row.Assujetti === null && params.row.Service === null ? true : false,
                params.row.Service === null ? null : params.row.Service.Fonction,
                params.row.Service === null ? null : params.row.Service.ChefLieu
              )}>
                <Icon>edit</Icon>
              </MDButton>
            </Tooltip>
            &nbsp;&nbsp;&nbsp;&nbsp;
            {
              params.row.Type === "ASSUJETTI" && params.row.DEclaration ? null :
              <Tooltip title={lang === "fr" ? "Supprimer" : "Delete"}>
                <MDButton variant="gradient" color="error" size="small" iconOnly onClick={traiterOuvrir3(
                  params.row.ProfilId,
                  params.row.Email
                )}>
                  <Icon>delete_forever</Icon>
                </MDButton>
              </Tooltip>
            }
          </MDBox>
        );
      }, 
      sortable: false, 
      filterable: false,
      disableColumnMenu: true,
      disableExport: true
    },
    { 
      field: "Email", 
      headerName: "Email", 
      width: 200,
      disableColumnMenu: true
    },
    {
      field: "Type",
      width: 185,
      type: "singleSelect", 
      valueOptions: [
        { value: "ASSUJETTI", label: lang === "fr" ? "ASSUJETTI" : "SUBJECT"},
        { value: "SERVICE D'ASSIETTE", label: lang === "fr" ? "SERVICE D'ASSIETTE" : "TAX BASE SERVICE" },
        { value: "DIVISION PROVINCIALE", label: lang === "fr" ? "DIVISION PROVINCIALE" : "PROVINCIAL DIVISION" },
        { value: "AUTRE", label: lang === "fr" ? "AUTRE" : "OTHER" },
        { value: "FOMIN", label: "FOMIN" }
      ],
      renderCell: (params) => {
        return (
          <>
            {
              params.row.Assujetti ?
              <HtmlTooltip title={
                <Fragment>
                  {
                    lang === "fr" ? "Exportateur : " + listeTypeIds.filter(obj => obj.NomFr === params.row.Assujetti.TypeId)[0].NomFr : "Exporter: " + listeTypeIds.filter(obj => obj.NomFr === params.row.Assujetti.TypeId)[0].NomAng
                  }
                  {`${params.row.Assujetti.IdNat === null ? "" : lang === "fr" ? "\nIdentité Nationale : " + params.row.Assujetti.IdNat : "\nNational Identity: " + params.row.Assujetti.IdNat}`}
                  {lang === "fr" ? "\nNuméro d'arrêté de l'agrément : " + params.row.Assujetti.NoAgr : "\nApproval order number: " + params.row.Assujetti.NoAgr}
                  {lang === "fr" ? "\nDate de début : " + moment(params.row.Assujetti.DateD).format("DD/MM/YYYY") : "\nStart date: " + moment(params.row.Assujetti.DateD).format("DD/MM/YYYY")}
                  {lang === "fr" ? "\nDate d'expiration : " + moment(params.row.Assujetti.DateF).format("DD/MM/YYYY") : "\nEnd date: " + moment(params.row.Assujetti.DateF).format("DD/MM/YYYY")}
                </Fragment>
              }>
                <div><Icon color="success">info</Icon> {lang === "fr" ? "ASSUJETTI" : "SUBJECT"}</div>
              </HtmlTooltip> : 
              params.row.Service ? 
              <HtmlTooltip title={
                <Fragment>
                  {params.row.Service?.Type === 1 && lang === "fr" ? "Fonction : " + params.row.Service.Fonction : "Role: " + params.row.Service.Fonction}
                  {lang === "fr" ? "\nProvince : " + params.row.Province : "\nProvince: " + params.row.Province}
                  {lang === "fr" ? "\nChef-lieu : " + params.row.Service.ChefLieu : "\nChief town: " + params.row.Service.ChefLieu}
                </Fragment>
              }>
                <div>
                  <Icon color="success">info</Icon>
                  {params.row.Service?.Type === 1 && lang === "fr" && (" DIVISION PROVINCIALE")}
                  {params.row.Service?.Type === 1 && lang === "eng" && (" PROVINCIAL DIVISION")}
                  {params.row.Service?.Type === 3 && lang === "fr" && (" SERVICE D'ASSIETTE")}
                  {params.row.Service?.Type === 3 && lang === "eng" && (" TAX BASE SERVICE")}
                  {params.row.Service?.Type === 4 && lang === "fr" && (" AUTRE")}
                  {params.row.Service?.Type === 4 && lang === "eng" && (" OTHER")}
                </div>
              </HtmlTooltip> : 
              <div>FOMIN</div>
            }
          </>
        );
      }, 
      disableColumnMenu: true
    },
    { 
      field: "Administrateur", 
      headerName: "Admin", 
      type: "singleSelect", 
      valueOptions: [
        { value: true, label: lang === "fr" ? "Oui" : "Yes"},
        { value: false, label: lang === "fr" ? "Non" : "No" }
      ],
      width: 85, 
      renderCell: (params) => (
        <MDBox ml={-1}>
          {params.value === true && <MDButton variant="outlined" color="success" size="small" iconOnly disabled><Icon>check</Icon></MDButton>}
          {params.value === false && <MDButton variant="outlined" color="error" size="small" iconOnly disabled><Icon>close</Icon></MDButton>}
        </MDBox>
      ),
      disableColumnMenu: true
    },
    { 
      field: "Statut", 
      headerName: lang === "fr" ? "Statut" : "Status", 
      type: "singleSelect", 
      valueOptions: [
        { value: true, label: lang === "fr" ? "Oui" : "Yes"},
        { value: false, label: lang === "fr" ? "Non" : "No" }
      ],
      width: 85, 
      renderCell: (params) => (
        <MDBox ml={-1}>
          {params.value === true && <MDButton variant="outlined" color="success" size="small" iconOnly disabled><Icon>check</Icon></MDButton>}
          {params.value === false && <MDButton variant="outlined" color="error" size="small" iconOnly disabled><Icon>close</Icon></MDButton>}
        </MDBox>
      ),
      disableColumnMenu: true
    },
    { 
      field: "createdAt", 
      headerName: lang === "fr" ? "Création" : "Creation", 
      type: "dateTime", 
      width: 160, 
      renderCell: (params) => `${formatDate(new Date(params.value))}, ${formatHeure(new Date(params.value))}`,
      valueGetter: (value) => new Date(value),
      disableColumnMenu: true
    },
    { 
      field: "updatedAt", 
      headerName: lang === "fr" ? "Der. Modification" : "Last update", 
      type: "dateTime", 
      width: 160, 
      renderCell: (params) => `${formatDate(new Date(params.value))}, ${formatHeure(new Date(params.value))}`,
      valueGetter: (value) => new Date(value),
      disableColumnMenu: true
    },
    { 
      field: "DerniEreDEsapprobation", 
      headerName: lang === "fr" ? "Der. Désactivation" : "Last deactivation", 
      type: "dateTime", 
      width: 160, 
      renderCell: (params) => `${formatDate(new Date(params.value))}, ${formatHeure(new Date(params.value))}`,
      valueGetter: (value) => new Date(value),
      disableColumnMenu: true
    },
    { 
      field: "DEnomination", 
      headerName: lang === "fr" ? "Dénomination" : "Company name", 
      width: 195, 
      disableColumnMenu: true 
    },
    { 
      field: "Nom", 
      headerName: lang === "fr" ? "Nom" : "Last name",
      width: 160, 
      disableColumnMenu: true 
    },
    { 
      field: "PostNom", 
      headerName: lang === "fr" ? "Post-nom" : "Middle name", 
      width: 160, 
      disableColumnMenu: true 
    },
    { 
      field: "PrEnom", 
      headerName: lang === "fr" ? "Prénom" : "First name", 
      width: 160, 
      disableColumnMenu: true 
    },
    { 
      field: "Phone", 
      headerName: lang === "fr" ? "Téléphone" : "Phone No.", 
      width: 150,
      renderCell: (params) => utilPhone.format(utilPhone.parse("+" + params.value, ""), PNF.INTERNATIONAL),  
      valueGetter: (value) => `+${value}`, 
      disableColumnMenu: true 
    },
    { 
      field: "No", 
      headerName: "No.",
      width: 150,
      disableColumnMenu: true 
    },
    { 
      field: "Avenue", 
      headerName: "Avenue",
      width: 150,
      disableColumnMenu: true 
    },
    { 
      field: "Quartier", 
      headerName: lang === "fr" ? "Quartier" : "Area",
      width: 150,
      disableColumnMenu: true 
    },
    { 
      field: "Commune", 
      headerName: lang === "fr" ? "Commune/Secteur" : "Municipality/Sector",
      width: 150,
      disableColumnMenu: true 
    },
    { 
      field: "Ville", 
      headerName: lang === "fr" ? "Ville/Territoire" : "City/Territory",
      width: 150,
      disableColumnMenu: true 
    },
    { 
      field: "Province", 
      headerName: "Province",
      width: 150,
      disableColumnMenu: true 
    }
  ]; 

  const [paginationModel, setPaginationModel] = useState(pageU);
  useEffect(() => {
    localStorage.setItem("pageU", JSON.stringify(paginationModel));
    envoi(setPageU());
  },[paginationModel])

  let listeTypeIds = require('../../../listeTypeIds.json');

  return (
    <div style={{ height: h, width: "100%" }}>
      {globalStyles()}
      <StyledDataGrid
        checkboxSelection
        disableRowSelectionOnClick
        density="compact"
        rows={listeUtilisateurs} 
        getRowId={(row) => row.ProfilId}
        columns={colonnes} 
        slots= {{
          loadingOverlay: LinearProgress, 
          noRowsOverlay: CustomNoRowsOverlay, 
          noResultsOverlay: CustomNoRowsOverlay,
          toolbar: CustomToolbar, 
          pagination: CustomPagination
        }}
        loading={chargementListeUtilisateurs}
        componentsProps={{
          panel: {
            sx: {
              "& .MuiDataGrid-panelWrapper": {
                backgroundColor: darkMode ? "#202940" : "#fff",
              },
              "& .MuiNativeSelect-select": {
                color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
              },
              "& .MuiSvgIcon-root": {
                color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
              },
              "& .MuiFormControl-root": {
                color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
              }
            }
          }
        }}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 50},
          },
          columns: {
            columnVisibilityModel: !JSON.parse(localStorage.getItem("columnVisibilityModelU")) ? {} : JSON.parse(localStorage.getItem("columnVisibilityModelU"))
          },
          sorting: {
            sortModel: !JSON.parse(localStorage.getItem("sortModelU")) ? [] : JSON.parse(localStorage.getItem("sortModelU"))
          },
          filter: {
            filterModel: !JSON.parse(localStorage.getItem("filterModelU")) ? {items: []} : JSON.parse(localStorage.getItem("filterModelU"))
          }
        }}
        paginationModel={pageU}
        onPaginationModelChange={setPaginationModel}
        onColumnVisibilityModelChange={(model) => localStorage.setItem("columnVisibilityModelU", JSON.stringify(model))}
        rowSelectionModel={selectedRowU}
        onRowSelectionModelChange={
          (model) => {
            envoi(setSelectedRowU(model));
          }
        }
        onSortModelChange={(model) => localStorage.setItem("sortModelU", JSON.stringify(model))}
        onFilterModelChange={(model)=> localStorage.setItem("filterModelU", JSON.stringify(model))}
        localeText={lang === "fr" ? frFR : enUS}
      />
    </div>
  );
};

export default TableUtilisateurs;
