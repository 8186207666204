import axios from "axios";
axios.defaults.withCredentials = true;

const API_URL = window.location.protocol + "//" + window.location.host;

export const serviceConnexion = async (identifiant, passe) => {
    try {
        return await axios.post(`${API_URL}/api/authentification/connexion`, { identifiant, passe });
    } catch (err) {
        return {
            error: true,
            response: err.response
        };
    }
}

export const defTokenAuth = token => {
    if (token) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
    else {
        delete axios.defaults.headers.common["Authorization"];
    }
}
 
export const serviceConfirmationToken = async () => {
    try {
        return await axios.post(`${API_URL}/api/authentification/session`);
    } catch (err) {
        return {
            error: true,
            response: err.response
        };
    }
}
 
export const serviceDisjonction = async () => {
    try {
        return await axios.post(`${API_URL}/api/utilisateur/disjonction`);
    } catch (err) {
        return {
            error: true,
            response: err.response
        };
    }
}