import React from "react";
import { createRoot } from 'react-dom/client';
const container = document.getElementById("root");
const root = createRoot(container);
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import App from "App";
import store from "Store";

// Fournisseur de contexte d"interface utilisateur
import { MaterialUIControllerProvider } from "Contexte";

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <MaterialUIControllerProvider>
        <App />
      </MaterialUIControllerProvider>
    </BrowserRouter>
  </Provider>
);
