import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    chargementLieux: true,
    listeLieux: [],
    chargementPays: true,
    listePays: []
}
   
export const partieUtilitaire = createSlice({
    name: "utilitaire",
    initialState,
    reducers: {
        obtentionListeLieux: (state, action) => {
            return {
                ...state,
                chargementLieux: false,
                listeLieux: action.payload
            }
        },
        obtentionListePays: (state, action) => {
            return {
                ...state,
                chargementPays: false,
                listePays: action.payload
            }
        }
    }
});

export const {obtentionListeLieux, obtentionListePays} = partieUtilitaire.actions;
   
export default partieUtilitaire.reducer;