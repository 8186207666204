/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import QRCode from 'qrcode';
import { Document, Page, View, Text, StyleSheet, Image, BlobProvider } from "@react-pdf/renderer";
import { listeDEclarationsAsync } from "ActionsAsync/ActionsDEclarationAsync";
import { ouvrirFDialogueValidDcl, ouvrirFSurvolCamions } from "Simplificateurs/SimplificateursDEclaration";
import { setPageD, setSelectedRowD } from "Simplificateurs/SimplificateursTable";

// Composants du @mui material
import LinearProgress from '@mui/material/LinearProgress';
import Icon from "@mui/material/Icon";
import Tooltip from '@mui/material/Tooltip';
import { styled } from "@mui/material/styles";

// Table
import { 
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport, 
} from "@mui/x-data-grid";
import { enUS } from '@mui/material/locale';

// Composants du Material Dashboard 2 React
import MDBox from "Composants/MDBox";
import MDButton from "Composants/MDButton";
import Menu from "@mui/material/Menu";
import NotificationItem from "Composants/Items/NotificationItem";

// Contexte Material Dashboard 2 PRO React
import { useMaterialUIController } from "Contexte";

import { 
  frFR, 
  globalStyles,
  CustomNoRowsOverlay, 
  formatDate,
  formatHeure,
  HtmlTooltip,
  CustomPagination
} from "Vues/Utilitaires";

const armoiries = require("../../../Outils/images/armoiries.png");
const watermark1 = require("../../../Outils/images/watermark1.png");
const watermark3 = require("../../../Outils/images/watermark3.png");
const watermark4 = require("../../../Outils/images/watermark4.png");
const watermark5 = require("../../../Outils/images/watermark5.png");

var h = window.innerHeight - 340;

function TableDEclarations() {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, lang, socket } = controller;

  const envoi = useDispatch();

  const authObj = useSelector((state) => state.authentification);

  const tableObj = useSelector((state) => state.table);
  const {
    changeColD,
    chargementListeDEclarations, 
    listeDEclarations,
    pageD,
    selectedRowD,
  } = tableObj;

  useEffect(() => {
    const minuteurObtenirlistes = setTimeout(() => {
      envoi(listeDEclarationsAsync(dispatch, socket));
    }, 1000);
    return () => {
      clearTimeout(minuteurObtenirlistes);
    };
  }, []);

 const traiterOuvrir1 = () => () => {
    /*envoi(ouvrirFDialogueModifP({}));*/
  };

  const traiterOuvrir2 = () => () => {
    
  };

  const traiterOuvrir3 = (idDEclaration) => () => {
    envoi(ouvrirFDialogueValidDcl({idDEclaration}));
  };

  const traiterOuvrir4 = (listeCamionsParD, no) => () => {
    envoi(ouvrirFSurvolCamions({listeCamionsParD, no}));
  };

  const pdfStyles = StyleSheet.create({
    watermark: {
      position: 'absolute',
      top: 30,
      left: 35,
      width: '100%',
      height: '100%',
    },
    body: {
      backgroundColor: '#fff',
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    image: {
      width: "auto", 
      height: "65px",
      paddingLeft: "17%",
      marginRight: "68%",
      marginBottom: "5px"
    },
    title1: {
      fontFamily: "Times-Bold",
      fontSize: 15,
      margin: 10,
      paddingTop: 5,
      paddingLeft: 3,
      textAlign: "center",
      border: "3px solid #B22222",
    },
    title2: {
      fontFamily: "Times-Bold",
      fontSize: 12,
      paddingRight: "50%",
      textAlign: "center"
    },
    title3: {
      fontFamily: "Times-Roman",
      fontSize: 12,
      paddingRight: "50%",
      textAlign: "center"
    },
    title4: {
      fontFamily: "Times-Bold",
      fontSize: 12,
      textDecoration: "underline",
      paddingRight: "50%",
      paddingBottom: 3,
      textAlign: "center"
    },
    subtitle: {
      fontFamily: "Times-Bold",
      fontSize: 12,
      margin: 3, 
      paddingLeft: 70,
      textAlign: "left"
    },
    text1: {
      fontFamily: "Times-Roman",
      lineHeight: 1.5,
      fontSize: 12,
      paddingLeft: 20,
      textAlign: "left"
    },
    text1a: {
      fontFamily: "Times-Bold",
      lineHeight: 1.5,
      fontSize: 12,
      paddingLeft: 20,
      textAlign: "left"
    },
    text2: {
      lineHeight: 1.5,
      fontSize: 12, 
      maxWidth: 350,
      textAlign: "left"
    },
    text2a: {
      lineHeight: 1.5,
      fontSize: 12, 
      maxWidth: 175,
      textAlign: "left"
    },
    text2b: {
      lineHeight: 1.5,
      fontSize: 12, 
      paddingLeft: 345,
      textAlign: "left"
    },
    text3: {
      fontFamily: "Times-Roman",
      lineHeight: 1.5,
      fontSize: 12,
      paddingLeft: 95,
      textAlign: "left"
    },
    text4: {
      fontFamily: "Times-Roman",
      lineHeight: 1.5,
      fontSize: 12, 
      maxWidth: 350,
      textAlign: "left"
    },
    text4a: {
      fontFamily: "Times-Roman",
      lineHeight: 1.5,
      fontSize: 12, 
      maxWidth: 350,
      paddingLeft: 110,
      textAlign: "left"
    },
    text4b: {
      fontFamily: "Times-Roman",
      lineHeight: 1.5,
      fontSize: 12, 
      maxWidth: 175,
      textAlign: "left"
    },
    text5: {
      fontFamily: "Times-Roman",
      lineHeight: 1.5,
      fontSize: 12,
      paddingLeft: 45,
      textAlign: "left"
    },
    text6: {
      fontFamily: "Times-Roman",
      lineHeight: 1.5,
      fontSize: 12, 
      maxWidth: 350,
      textAlign: "left"
    },
    text6a: {
      fontFamily: "Times-Roman",
      lineHeight: 1.5,
      fontSize: 12, 
      maxWidth: 450,
      textAlign: "left"
    },
    text7: {
      fontFamily: "Times-Roman",
      lineHeight: 1.5,
      fontSize: 12,
      paddingLeft: 65,
      textAlign: "left"
    },
    text7a: {
      fontFamily: "Times-Roman",
      lineHeight: 1.5,
      fontSize: 12,
      paddingTop: -5,
      paddingLeft: 75,
      textAlign: "left"
    },
    text7b: {
      fontFamily: "Times-Bold",
      lineHeight: 1.5,
      fontSize: 12,
      paddingLeft: 57,
      textAlign: "left"
    },
    text8: {
      fontFamily: "Times-Roman",
      lineHeight: 1.5,
      fontSize: 12, 
      maxWidth: 350,
      textAlign: "left"
    },
    text9: {
      fontFamily: "Times-Roman",
      lineHeight: 1.5,
      fontSize: 12, 
      margin: 3,
      paddingRight: 85,
      textAlign: "right"
    },
    text10: {
      fontFamily: "Times-Roman",
      lineHeight: 1.5,
      fontSize: 12, 
      margin: 3,
      paddingTop: 10,
      paddingLeft: 135,
      textAlign: "left"
    },
    table: { 
      display: "table", 
      width: "auto", 
      borderStyle: "solid", 
      borderWidth: 1, 
      borderRightWidth: 0, 
      borderBottomWidth: 0 
    }, 
    tableRow: { 
      margin: "auto", 
      flexDirection: "row" 
    }, 
    tableCol: { 
      borderStyle: "solid", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0 
    }, 
    tableCell1: { 
      margin: "auto", 
      fontFamily: "Times-Bold",
      fontSize: 11 
    },
    tableCell2: { 
      margin: "auto", 
      fontFamily: "Times-Roman",
      fontSize: 11
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
    qrcode1: {
      width: "100%", 
      height: "75px"
    },
    qrcode2: {
      width: "60%", 
      height: "75px"
    },
  });

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    border: 0,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    WebkitFontSmoothing: "auto",
    letterSpacing: "normal",
    fontSize: "13px",
    backgroundColor: darkMode ? "#202940" : "#fff",
    color: darkMode ? "#7b809a" : "rgba(0,0,0,.85)",
    "& .MuiDataGrid-main": {
      color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
    },
    "& .MuiDataGrid-columnSeparator": {
      color: "#7b809a"
    },
    "& .MuiDataGrid-columnHeaders, & .MuiDataGrid-cell": {
      borderBottom: darkMode ? "1px solid #344767" : "1px solid #f0f2f5"
    },
    "& .MuiDataGrid-footerContainer": {
      borderTop: darkMode ? "1px solid #344767" : "1px solid #f0f2f5"
    },
    "& .MuiIconButton-root": {
      color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
    },
    "& .MuiPaginationItem-previousNext": {
      color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
    },
    "& .MuiPaginationItem-textInfo": {
      color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
    },
    "& .Mui-selected": {
      color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)"
    },
    "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
      outline: "none"
    }
  }));

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport 
          printOptions={{ disableToolbarButton: true }} 
          csvOptions={{
            fileName: lang === "fr" ? `${"Liste des Déclarations (" + moment(new Date()).format('DD-MM-YYYY HHmmss') + ")"}` : `${"Declarations list (" + moment(new Date()).format('DD-MM-YYYY HHmmss') + ")"}`,
            delimiter: ',',
            utf8WithBom: true,
          }}
        />
      </GridToolbarContainer>
    );
  }

  const colonnesO = [
    {
      field: "Options",
      width: authObj.info.AssujettiId !== null ? 205 : authObj.info.TypeService === 1 ? 240 : 115,
      renderCell: (params) => {
        const [ouvrirMenu, assignerOuvrirMenu] = useState(false);
        const traiterOuvrirMenu = (event) => assignerOuvrirMenu(event.currentTarget);
        const traiterFermerMenu = () => assignerOuvrirMenu(false);
        const redevancePourc = params.row.RedevancePourc;
        const prixTotalUSDRedevance = params.row.PrixTotalUSD * redevancePourc / 100;
        const redevanceUSD50 = prixTotalUSDRedevance * 50 / 100;
        const redevanceUSD25 = prixTotalUSDRedevance * 25 / 100;
        const redevanceUSD15 = prixTotalUSDRedevance * 15 / 100;
        const redevanceUSD10 = prixTotalUSDRedevance * 10 / 100;
        const [qrCodeData1, assignerQRCodeData1] = useState("");
        const [qrCodeData2, assignerQRCodeData2] = useState("");
        useEffect(() => {
          QRCode.toDataURL(`https://localhost:3000/authentication/dcl/lecture/${params.row.Lecture}`, (error, url) => {
            if (params.row.Lecture && error) {
              console.error("Erreur lors de la génération du code QR (DCL):", error);
              return;
            }
            assignerQRCodeData1(url);
          });
          QRCode.toDataURL(`https://localhost:3000/authentication/nd/lecture/${params.row.Lecture}`, (error, url) => {
            if (params.row.Lecture && error) {
              console.error("Erreur lors de la génération du code QR (NP):", error);
              return;
            }
            assignerQRCodeData2(url);
          });
        }, [params.row.Lecture]);
        return (
          <>
            {
              authObj.info.AssujettiId !== null && params.row.EstEnElaborationD === "Oui" || authObj.info.TypeService === 1 && params.row.EstValidED === "Non" ?
              <>
                <Tooltip title={lang === "fr" ? "Modifier" : "Edit"}>
                  <MDButton variant="gradient" color="warning" size="small" iconOnly onClick={traiterOuvrir1(
                    params.row.Id,
                    params.row.NatureProduit,
                    params.row.ChantierExtraction,
                    params.row.ProvinceExtraction,
                    params.row.EmplacementSite,
                    params.row.ZoneExploitation,
                    params.row.LocalitEGroupement,
                    params.row.Secteur,
                    params.row.Territoire,
                    params.row.QtENet,	
                    params.row.QtEHumide,	
                    params.row.QtESec,	
                    params.row.QualitESecPourc,
                    params.row.QualitEHumidePourc,
                    params.row.PrixUnitEUSD,	
                    params.row.PrixTotalUSD,	
                    params.row.IdentitEAcheteurs,
                    params.row.RueAcheteur,	
                    params.row.PaysAcheteur,	
                    params.row.EmailAcheteur,	
                    params.row.REglement,	
                    params.row.DateExportation,
                    params.row.FraisTransport,
                    params.row.FraisAnalyse,
                    params.row.FraisAssurance,
                    params.row.FraisCommercialisation,
                    params.row.VenteEntitETransformation,
                    params.row.Camions
                  )}>
                    <Icon>edit</Icon>
                  </MDButton>
                </Tooltip>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Tooltip title={lang === "fr" ? "Supprimer" : "Delete"}>
                  <MDButton variant="gradient" color="error" size="small" iconOnly onClick={traiterOuvrir2(params.row.Id, params.row.No)}>
                    <Icon>delete_forever</Icon>
                  </MDButton>
                </Tooltip>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </> : 
              authObj.info.AssujettiId === null && authObj.info.TypeService === null ? null :
              <>
                <MDButton variant="gradient" color="secondary" size="small" disabled iconOnly>
                  <Icon>edit</Icon>
                </MDButton>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <MDButton variant="gradient" color="secondary" size="small" disabled iconOnly>
                  <Icon>delete_forever</Icon>
                </MDButton>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </>
            }
            {
              authObj.info.TypeService === 1 ?
                params.row.EstEnAttenteDeValidationD === "Oui" ?
                <>
                  <Tooltip title={lang === "fr" ? "Valider" : "Validate"}>
                    <MDButton variant="gradient" color="primary" size="small" iconOnly onClick={traiterOuvrir3(params.row.Id)}>
                      <Icon>thumb_up_alt</Icon>
                    </MDButton>
                  </Tooltip>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </> : 
                <>
                  <MDButton variant="gradient" color="secondary" size="small" disabled iconOnly>
                    <Icon>thumb_up_alt</Icon>
                  </MDButton>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </>
              : null
            }
            <>
              <Tooltip title={lang === "fr" ? "Télécharger/Imprimer" : "Download/Print"}>
                <MDButton variant="gradient" color="success" size="small" iconOnly onClick={traiterOuvrirMenu}>
                  <Icon>download</Icon>
                </MDButton>
              </Tooltip>
              &nbsp;&nbsp;&nbsp;&nbsp;
            </>
            <Tooltip title={lang === "fr" ? "Liste des camions" : "Trucks list"}>
                <MDButton variant="gradient" color="info" size="small" iconOnly onClick={traiterOuvrir4(params.row.Camions, params.row.No)}>
                  <Icon>directions_car</Icon>
                </MDButton>
            </Tooltip>

            <Menu
              anchorEl={ouvrirMenu}
              anchorReference={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              open={Boolean(ouvrirMenu)}
              onClose={traiterFermerMenu}
              sx={{ mt: 2 }}
            >
              <BlobProvider document={(
                <Document>
                  <Page size="A4" style={pdfStyles.body}>
                    <View style={pdfStyles.watermark} fixed>
                      <Image src={params.row.EstValidED === "Oui" ? watermark1 : watermark3} style={{ width: '100%', height: '100%' }} />
                    </View>
                    <Text style={pdfStyles.title2}>REPUBLIQUE DEMOCRATIQUE DU CONGO</Text>
                    <Text style={pdfStyles.title2}>PROVINCE DU {params.row.ProvinceExtraction}</Text>
                    <Image style={pdfStyles.image} src={armoiries}/>
                    <Text style={pdfStyles.title3}>DIVISION PROVINCIALE DES MINES</Text>
                    {params.row.EstValidED === "Oui" && <Text style={pdfStyles.title4}>{"VILLE DE " + params.row.LieuEmissionD}</Text>}
                    <Text style={pdfStyles.title3}>LE CHEF DE DIVISION</Text>
                    <Text style={pdfStyles.title1}>DECLARATION DE L'ORIGINE ET DE VENTE DES PRODUITS MINIERS MARCHANDS {params.row.EstValidED === "Oui" ? `N° ${params.row.No}` : ""}</Text>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text1}>1. Destinataire de la déclaration :</Text>
                      <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman", paddingLeft: 5}]}>Direction des mines de la province du {params.row.ProvinceExtraction} ou services des mines de {params.row.Territoire}</Text>
                    </View>
                    <Text style={[pdfStyles.text1, {margin: 3}]}>2. Exportateur :</Text>
                    <Text style={pdfStyles.subtitle}>
                      {params.row.TypeId === "TITULAIRE DES DROITS MINIERS" && "Titulaire des droits miniers"}
                      {params.row.TypeId === "ENTITE DE TRAITEMENT OU DE TRANSFORMATION" && "Entité de traitement ou de transformation"}
                      {params.row.TypeId === "COMPTOIR D'ACHAT AGREE" && "Comptoir d'Achat agréé"}
                    </Text>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text3}>1.&nbsp;</Text>
                      <Text style={pdfStyles.text4}>
                        {params.row.TypeId === "TITULAIRE DES DROITS MINIERS" && "Dénomination ou raison social/Nom, Post nom, Prénom : " + params.row.DEnomination + "/" + params.row.Nom + " "  + params.row.PostNom + " "  + params.row.PrEnom}
                        {params.row.TypeId === "ENTITE DE TRAITEMENT OU DE TRANSFORMATION" && "Dénomination ou raison social : " + params.row.DEnomination}
                        {params.row.TypeId === "COMPTOIR D'ACHAT AGREE" && "Dénomination ou raison social : " + params.row.DEnomination}
                      </Text>
                    </View>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text3}>2.&nbsp;</Text>
                      <Text style={pdfStyles.text4}>
                        {params.row.TypeId === "TITULAIRE DES DROITS MINIERS" && "N° d'identification Nationale : " + params.row.IdNat}
                        {params.row.TypeId === "ENTITE DE TRAITEMENT OU DE TRANSFORMATION" && "Siège social - siège d'exploitation : " + params.row.No + ", " + params.row.Avenue + ", Q/" + params.row.Quartier + ", C/" + params.row.Commune + ", V/" + params.row.Ville + ", P/" + params.row.Province}
                        {params.row.TypeId === "COMPTOIR D'ACHAT AGREE" && "Siège social - siège d'exploitation : " + params.row.No + ", " + params.row.Avenue + ", Q/" + params.row.Quartier + ", C/" + params.row.Commune + ", V/" + params.row.Ville + ", P/" + params.row.Province}
                      </Text>
                    </View>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text3}>3.&nbsp;</Text>
                      <Text style={pdfStyles.text4}>
                        {params.row.TypeId === "TITULAIRE DES DROITS MINIERS" && "Siège social - siège d'exploitation : " + params.row.No + ", " + params.row.Avenue + ", Q/" + params.row.Quartier + ", C/" + params.row.Commune + ", V/" + params.row.Ville + ", P/" + params.row.Province}
                        {params.row.TypeId === "ENTITE DE TRAITEMENT OU DE TRANSFORMATION" && "N° de l'Arrêté d'agrément : " + params.row.NoAgr}
                        {params.row.TypeId === "COMPTOIR D'ACHAT AGREE" && "N° de l'Arrêté d'agrément : " + params.row.NoAgr}
                      </Text>
                    </View>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text3}>4.&nbsp;</Text>
                      <Text style={pdfStyles.text4}>
                        {params.row.TypeId === "TITULAIRE DES DROITS MINIERS" && "N° de l'Arrêté d'agrément : " + params.row.NoAgr}
                        {params.row.TypeId === "ENTITE DE TRAITEMENT OU DE TRANSFORMATION" && "Période de validité : Du " + formatDate(new Date(params.row.DateD))+ " au " + formatDate(new Date(params.row.DateF))}
                        {params.row.TypeId === "COMPTOIR D'ACHAT AGREE" && "Période de validité : Du " + formatDate(new Date(params.row.DateD)) + " au " + formatDate(new Date(params.row.DateF))}
                      </Text>
                    </View>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text3}>{params.row.TypeId == "TITULAIRE DES DROITS MINIERS" && "5. "}</Text>
                      <Text style={pdfStyles.text4}>
                        {params.row.TypeId === "TITULAIRE DES DROITS MINIERS" && "Période de validité : Du " + formatDate(new Date(params.row.DateD)) + " au " + formatDate(new Date(params.row.DateF))}
                      </Text>
                    </View>
                    <Text style={[pdfStyles.text1, {margin: 3}]}>3. Nature et origine des produits miniers marchands concerné</Text>
                    <Text style={[pdfStyles.text5, {margin: 3}]}>1. Nature des produits : {params.row.NatureProduit}</Text>
                    <Text style={[pdfStyles.text5, {margin: 3}]}>2. Origine :</Text>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text7}>a. Chantiers d'extraction : </Text>
                      <Text style={pdfStyles.text8}>{params.row.ChantierExtraction}</Text>
                    </View>
                    <Text style={[pdfStyles.text7, {margin: 3}]}>b. Province d'extraction : {params.row.ProvinceExtraction}</Text>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text3}>i. Emplacement du site : </Text>
                      <Text style={pdfStyles.text4}>{params.row.EmplacementSite}</Text>
                    </View>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text3}>ii. Nom de la zone d'exploitation industrielle/artisanale : </Text>
                      <Text style={pdfStyles.text4}>{params.row.ZoneExploitation}</Text>
                    </View>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text3}>iii. Localité/Groupement : </Text>
                      <Text style={pdfStyles.text4}>{params.row.LocalitEGroupement}</Text>
                    </View>
                    <Text style={[pdfStyles.text3, {margin: 3}]}>iv. Secteur : {params.row.Secteur}</Text>
                    <Text style={[pdfStyles.text3, {margin: 3}]}>v. Territoire : {params.row.Territoire}</Text>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text1}>4. Quantité net : </Text>
                      <Text style={[pdfStyles.text2, {fontFamily: "Times-Bold"}]}>{params.row.QtENet.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} {params.row.EstKg? "Kg(s)": "Tonne(s)" }</Text>
                    </View>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text1}>5. Quantité humide : </Text>
                      <Text style={[pdfStyles.text2, {fontFamily: "Times-Bold"}]}>{params.row.QtEHumide.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} {params.row.EstKg? "Kg(s)": "Tonne(s)" }</Text>
                    </View>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text1}>6. Quantité sec : </Text>
                      <Text style={[pdfStyles.text2, {fontFamily: "Times-Bold"}]}>{params.row.QtESec.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} {params.row.EstKg? "Kg(s)": "Tonne(s)" }</Text>
                    </View>
                    <Text style={[pdfStyles.text1, {margin: 3}]}>7. Qualité : {params.row.QualitESecPourc.replace(".", ",")} %&nbsp;&nbsp;&nbsp;H2O {params.row.QualitEHumidePourc.replace(".", ",")} %</Text>
                    <Text style={[pdfStyles.text1, {margin: 3}]}>8. Prix/Unité : {params.row.PrixUnitEUSD.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD</Text>
                    <Text style={[pdfStyles.text1, {margin: 3}]}>9. Prix total : {params.row.PrixTotalUSD.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD</Text>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text5}>1. Identité des acheteurs : </Text>
                      <Text style={pdfStyles.text6}>{params.row.IdentitEAcheteurs}</Text>
                    </View>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text5}>2. Nom ou Raison social : </Text>
                      <Text style={pdfStyles.text6}>{params.row.RaisonSocial} ; Adresse :</Text>
                    </View>
                    <Text style={[pdfStyles.text3, {margin: 3}]}>i. Rue : {params.row.RueAcheteur}</Text>
                    <Text style={[pdfStyles.text3, {margin: 3}]}>ii. Pays : {params.row.PaysAcheteur}</Text>
                    <Text style={[pdfStyles.text3, {margin: 3}]}>iii. Email : {params.row.EmailAcheteur}</Text>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text1}>10. Modalité du Règlement du prix de vente : </Text>
                      <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman"}]}>{params.row.REglement}</Text>
                    </View>
                    <Text style={[pdfStyles.text1, {margin: 3}]}>11. Date de l'exportation : {formatDate(new Date(params.row.DateExportation))}</Text>
                    <Text style={[pdfStyles.text3, {margin: 3}]}>i. Frais de transport : {params.row.FraisTransport.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD</Text>
                    <Text style={[pdfStyles.text3, {margin: 3}]}>ii. Frais d'analyse se rapportant au contrôle de qualité des produits marchands : {params.row.FraisAnalyse.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD</Text>
                    <Text style={[pdfStyles.text3, {margin: 3}]}>iii. Frais d'assurance : {params.row.FraisAssurance.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD</Text>
                    <Text style={[pdfStyles.text3, {margin: 3}]}>iv. Frais de commercialisation : {params.row.FraisCommercialisation.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD</Text>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text3}>v. Vente à une Entité de transformation établie sur le territoire national. le cas échéant : </Text>
                      <Text style={pdfStyles.text4}>{params.row.VenteEntitETransformation === "" && " -"}</Text>
                    </View>
                    <Text style={pdfStyles.text4a}>{params.row.VenteEntitETransformation !== "" && params.row.VenteEntitETransformation}</Text>
                    {
                      params.row.EstValidED === "Oui" &&
                      <>
                        <Text style={[pdfStyles.text9, {paddingTop: 15}]}>Fait à {params.row.LieuEmissionD}, le {formatDate(new Date(params.row.DateEmissionD))}</Text>
                        <Text style={pdfStyles.text9}>L'exportateur ou son mandataire</Text>
                        <View style={{flexDirection: "row", margin: 3}}>
                          <Text style={[pdfStyles.text3, {textDecoration: "underline"}]}>Visa</Text>
                          <Text style={pdfStyles.text4}> : Directeur des Mines ou Chef de Service des Mines du ressort</Text>
                        </View>
                        <Text style={pdfStyles.text10}>{params.row.NomVisaD}, {params.row.FonctionVisa}</Text>
                        <View style={{flexDirection: "row", marginLeft: 450}}>
                          <Image style={pdfStyles.qrcode1} src={qrCodeData1} alt="QR Code" />
                        </View>
                        <View style={{flexDirection: "row", margin: 3, paddingTop: 10}}>
                          <Text style={[pdfStyles.text1, {textDecoration: "underline"}]}>Ampliateurs</Text>
                          <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman", paddingLeft: 5}]}> : </Text>
                        </View>
                        <View style={{flexDirection: "row", margin: 3}}>
                          <Text style={pdfStyles.text1}>1. Gouverneur de la Province d'extraction : </Text>
                          <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman"}]}>{params.row.Gouverneur}</Text>
                        </View>
                        <View style={{flexDirection: "row", margin: 3}}>
                          <Text style={pdfStyles.text1}>2. Maire de la ville ou Administrateur du Territoire d'extraction : </Text>
                          <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman"}]}>{params.row.MaireAdm}</Text>
                        </View>
                      </>
                    }
                    <Text style={[pdfStyles.text2, {margin: 3, paddingTop: 10, paddingLeft: 20, fontFamily: "Times-Bold", textDecoration: "underline"}]}>LISTE DES CAMIONS</Text>
                    <Text style={[pdfStyles.text2, {margin: 3, paddingLeft: 20, fontFamily: "Times-Bold", textDecoration: "underline"}]}>BP/135</Text>
                    <View style={pdfStyles.table}> 
                      <View style={pdfStyles.tableRow}> 
                        <View style={[pdfStyles.tableCol, {width: 30}]}> 
                          <Text style={pdfStyles.tableCell1}>N°</Text> 
                        </View> 
                        <View style={[pdfStyles.tableCol, {width: 55}]}> 
                          <Text style={pdfStyles.tableCell1}>PLAQUE</Text> 
                        </View> 
                        <View style={[pdfStyles.tableCol, {width: 65}]}> 
                          <Text style={pdfStyles.tableCell1}>PS NET/KG</Text> 
                        </View> 
                        <View style={[pdfStyles.tableCol, {width: 75}]}> 
                          <Text style={pdfStyles.tableCell1}>HUMIDITE%</Text> 
                        </View> 
                        <View style={[pdfStyles.tableCol, {width: 85}]}> 
                          <Text style={pdfStyles.tableCell1}>PS HUMIDE/KG</Text> 
                        </View> 
                        <View style={[pdfStyles.tableCol, {width: 64}]}> 
                          <Text style={pdfStyles.tableCell1}>PS SEC/KG</Text> 
                        </View> 
                        <View style={[pdfStyles.tableCol, {width: 70}]}> 
                          <Text style={pdfStyles.tableCell1}>PRIX UNT/$</Text> 
                        </View> 
                        <View style={[pdfStyles.tableCol, {width: 80}]}> 
                          <Text style={pdfStyles.tableCell1}>PRIX TOTAL/$</Text> 
                        </View> 
                      </View>
                      {
                        params.row.Camions.map((x, index) => (
                          <View style={pdfStyles.tableRow} key={index}> 
                            <View style={[pdfStyles.tableCol, {width: 30}]}>  
                              <Text style={[pdfStyles.tableCell2, {width: 20, textAlign: "right"}]}>{index + 1}.</Text> 
                            </View> 
                            <View style={[pdfStyles.tableCol, {width: 55}]}>  
                              <Text style={[pdfStyles.tableCell2, {width: 50, textAlign: "left"}]}>{x.Plaque}</Text> 
                            </View> 
                            <View style={[pdfStyles.tableCol, {width: 65}]}> 
                              <Text style={[pdfStyles.tableCell2, {width: 50, textAlign: "right"}]}>{x.QtENet.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")}</Text> 
                            </View>
                            <View style={[pdfStyles.tableCol, {width: 75}]}>  
                              <Text style={[pdfStyles.tableCell2, {width: 70, textAlign: "right"}]}>{x.QualitEHumidePourc.replace(".", ",")}</Text> 
                            </View> 
                            <View style={[pdfStyles.tableCol, {width: 85}]}>  
                              <Text style={[pdfStyles.tableCell2, {width: 80, textAlign: "right"}]}>{x.QtEHumide.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")}</Text> 
                            </View> 
                            <View style={[pdfStyles.tableCol, {width: 64}]}>  
                              <Text style={[pdfStyles.tableCell2, {width: 59, textAlign: "right"}]}>{x.QtESec.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")}</Text> 
                            </View> 
                            <View style={[pdfStyles.tableCol, {width: 70}]}>  
                              <Text style={[pdfStyles.tableCell2, {width: 65, textAlign: "right"}]}>{x.PrixUnitEUSD.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")}</Text> 
                            </View> 
                            <View style={[pdfStyles.tableCol, {width: 80}]}>  
                              <Text style={[pdfStyles.tableCell2, {width: 75, textAlign: "right"}]}>{x.PrixTotalUSD.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")}</Text> 
                            </View> 
                          </View> 
                        ))  
                      }
                      <View style={pdfStyles.tableRow}> 
                        <View style={[pdfStyles.tableCol, {width: 85}]}>  
                          <Text style={pdfStyles.tableCell1}>TOTAL</Text> 
                        </View> 
                        <View style={[pdfStyles.tableCol, {width: 65}]}> 
                          <Text style={[pdfStyles.tableCell1, {width: 60, textAlign: "right"}]}>
                            {
                              params.row.Camions.reduce(
                                (acc, x) => acc + Math.round(Number.parseFloat(x.QtENet).toFixed(2) * 100) / 100, 0
                              ).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")
                            }
                          </Text> 
                        </View>
                        <View style={[pdfStyles.tableCol, {width: 75}]}>  
                          <Text style={[pdfStyles.tableCell1, {width: 70, textAlign: "right"}]}>
                            {
                              params.row.Camions.reduce(
                                (acc, x) => acc + Math.round(Number.parseFloat(x.QualitEHumidePourc).toFixed(2) * 100) / 100, 0
                              ).toFixed(2).replace(".", ",")
                            }
                          </Text> 
                        </View> 
                        <View style={[pdfStyles.tableCol, {width: 85}]}>  
                          <Text style={[pdfStyles.tableCell1, {width: 80, textAlign: "right"}]}>
                            {
                              params.row.Camions.reduce(
                                (acc, x) => acc + Math.round(Number.parseFloat(x.QtEHumide).toFixed(2) * 100) / 100, 0
                              ).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")
                            }
                          </Text> 
                        </View> 
                        <View style={[pdfStyles.tableCol, {width: 64}]}>  
                          <Text style={[pdfStyles.tableCell1, {width: 59, textAlign: "right"}]}>
                            {
                              params.row.Camions.reduce(
                                (acc, x) => acc + Math.round(Number.parseFloat(x.QtESec).toFixed(2) * 100) / 100, 0
                              ).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")
                            }
                          </Text> 
                        </View> 
                        <View style={[pdfStyles.tableCol, {width: 70}]}>  
                          <Text style={[pdfStyles.tableCell1, {width: 65, textAlign: "right"}]}>
                            {
                              params.row.Camions.reduce(
                                (acc, x) => acc + Math.round(Number.parseFloat(x.PrixUnitEUSD).toFixed(2) * 100) / 100, 0
                              ).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")
                            }
                          </Text> 
                        </View> 
                        <View style={[pdfStyles.tableCol, {width: 80}]}>  
                          <Text style={[pdfStyles.tableCell1, {width: 75, textAlign: "right"}]}>
                            {
                              params.row.Camions.reduce(
                                (acc, x) => acc + Math.round(Number.parseFloat(x.PrixTotalUSD).toFixed(2) * 100) / 100, 0
                              ).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")
                            }
                          </Text> 
                        </View> 
                      </View> 
                    </View>
                    <Text style={pdfStyles.pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} fixed />
                  </Page>
                </Document>
              )}>
                {({ url }) => (
                  <NotificationItem icon={<Icon>download</Icon>} href={url} target="_blank" title={lang === "fr" ? "Télécharger/Imprimer la déclaration" : "Download/Print the declaration"} />
                )}
              </BlobProvider>
              <BlobProvider document={(
                <Document>
                  <Page size="A4" style={pdfStyles.body}>
                    <View style={pdfStyles.watermark} fixed>
                      <Image src={params.row.StatutND === null ? watermark4 : params.row.StatutND === true ? watermark1 : watermark5} style={{ width: '100%', height: '100%' }} />
                    </View>
                    <Text style={pdfStyles.title2}>REPUBLIQUE DEMOCRATIQUE DU CONGO</Text>
                    <Text style={pdfStyles.title2}>PROVINCE DU {params.row.ProvinceExtraction}</Text>
                    <Image style={pdfStyles.image} src={armoiries}/>
                    <Text style={pdfStyles.title3}>DIVISION PROVINCIALE DES MINES</Text>
                    {params.row.EstValidEND === "Oui" && <Text style={pdfStyles.title4}>{"VILLE DE " + params.row.LieuEmissionND}</Text>}
                    <Text style={pdfStyles.title3}>LE CHEF DE DIVISION</Text>
                    <Text style={pdfStyles.title1}>NOTE DE DEBIT {params.row.EstValidEND === "Oui" && (<>N° DIV.MIN/354/8.3/ {(params.row.No).slice(-7)}&nbsp;&nbsp;&nbsp;&nbsp;{moment(params.row.DateEmissionND).format("YYYY")}</>)}</Text>
                    <Text style={pdfStyles.text1}>Taxation de la redevance minière Cfr Art 240 à 242 Code Minieret Art 256 du Règlement Minier</Text>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text1}>Dénomination sociale du déclarant</Text>
                      <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman", paddingLeft: 151.5}]}>: </Text>
                      <Text style={[pdfStyles.text2a, {fontFamily: "Times-Roman"}]}>{params.row.DEnomination}</Text>
                    </View>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text1}>Siège social</Text>
                      <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman", paddingLeft: 262}]}>: </Text>
                      <Text style={[pdfStyles.text2a, {fontFamily: "Times-Roman"}]}>{params.row.No}, {params.row.Avenue},</Text>
                    </View> 
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={[pdfStyles.text2b, {fontFamily: "Times-Roman"}]}>Q/{params.row.Quartier}</Text>
                    </View> 
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={[pdfStyles.text2b, {fontFamily: "Times-Roman"}]}>C/{params.row.Commune}</Text>
                    </View> 
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={[pdfStyles.text2b, {fontFamily: "Times-Roman"}]}>V/{params.row.Ville},</Text>
                    </View> 
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={[pdfStyles.text2b, {fontFamily: "Times-Roman"}]}>P/{params.row.Province}</Text>
                    </View> 
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text1}>Lieu d'exploitation</Text>
                      <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman", paddingLeft: 228}]}>: </Text>
                      <Text style={[pdfStyles.text2a, {fontFamily: "Times-Roman"}]}>{params.row.EmplacementSite}</Text>
                    </View>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text1}>Suivant la déclaration d'origine et de vente N°</Text>
                      <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman", paddingLeft: 97.5}]}>: </Text>
                      <Text style={[pdfStyles.text2a, {fontFamily: "Times-Roman"}]}>{params.row.No}</Text>
                    </View>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text1}>Des produits miniers marchands, dont spécifications suivantes</Text>
                      <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman", paddingLeft: 20.5}]}>: </Text>
                    </View>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text5}>1. Type du produit minier marchand</Text>
                      <Text style={[pdfStyles.text6, {paddingLeft: 120.5}]}>: </Text>
                      <Text style={pdfStyles.text6}>{params.row.NatureProduit}</Text>
                    </View>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text5}>2. Cours (valeur de base mensuelle)</Text>
                      <Text style={[pdfStyles.text6, {paddingLeft: 122.5}]}>: </Text>
                      <Text style={pdfStyles.text6}>{params.row.Cours?.replace(".", ",")}</Text>
                    </View>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text5}>3. Quantité</Text>
                      <Text style={[pdfStyles.text6, {paddingLeft: 240}]}>: </Text>
                    </View>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text3}>Kilogramme</Text>
                      <Text style={[pdfStyles.text4, {paddingLeft: 183}]}>: </Text>
                      <Text style={pdfStyles.text4}>
                        {params.row.QtENetConvertTKg.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")}
                      </Text>
                    </View>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text3}>Tonnes</Text>
                      <Text style={[pdfStyles.text4, {paddingLeft: 209}]}>: </Text>
                      <Text style={pdfStyles.text4}>
                        {params.row.QtENetConvertKgT.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")}
                      </Text>
                    </View>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text3}>Carat</Text>
                      <Text style={[pdfStyles.text4, {paddingLeft: 217.5}]}>: </Text>
                      <Text style={pdfStyles.text4}>
                        {params.row.QtENetConvertKgC.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")}
                      </Text>
                    </View>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text5}>4. Teneur</Text>
                      <Text style={[pdfStyles.text6, {paddingLeft: 248.5}]}>: </Text>
                      <Text style={pdfStyles.text6}>{params.row.QualitESecPourc.replace(".", ",")} %</Text>
                    </View>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text5}>5. Prix total</Text>
                      <Text style={[pdfStyles.text6, {paddingLeft: 237}]}>: </Text>
                      <Text style={pdfStyles.text6}>{params.row.PrixTotalUSD.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD</Text>
                    </View>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text5}>6. Taux de la redevance minière à appliquer</Text>
                      <Text style={[pdfStyles.text6, {paddingLeft: 84.5}]}>: </Text>
                      <Text style={pdfStyles.text6}>{params.row.RedevancePourc.replace(".", ",")} %</Text>
                    </View>
                    <Text style={pdfStyles.text7a}>(Cfr Article 241 du Code Minier)</Text>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text7b}>Montant de la redevance minière</Text>
                      <Text style={[pdfStyles.text8, {paddingLeft: 112.5}]}>: </Text>
                      <Text style={pdfStyles.text8}>{params.row.Redevance}</Text>
                    </View>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text3}>(En lettres)</Text>
                      <Text style={[pdfStyles.text4, {paddingLeft: 189.5}]}>: </Text>
                      <Text style={pdfStyles.text4b}>{params.row.RedevanceLettre}</Text>
                    </View>
                    <Text style={pdfStyles.text1a}>Répartition de la redevance minière</Text>
                    <Text style={[pdfStyles.text5, {paddingTop: -3}]}>(Cfr Art 242 du Code Minier)</Text>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text1a}>1. Pouvoir Central (DGRAD) : 50%, soit</Text>
                      <Text style={[pdfStyles.text2, {fontFamily: "Times-Bold", paddingLeft: 110}]}>: </Text>
                      <Text style={[pdfStyles.text2a, {fontFamily: "Times-Bold"}]}>{Number.parseFloat(redevanceUSD50).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD</Text>
                    </View>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text1a}>2. Province : 25%, soit</Text>
                      <Text style={[pdfStyles.text2, {fontFamily: "Times-Bold", paddingLeft: 203}]}>: </Text>
                      <Text style={[pdfStyles.text2a, {fontFamily: "Times-Bold"}]}>{Number.parseFloat(redevanceUSD25).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD</Text>
                    </View>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text1a}>3. Entité Territoriale Décentralisée : 15%, soit</Text>
                      <Text style={[pdfStyles.text2, {fontFamily: "Times-Bold", paddingLeft: 82.5}]}>: </Text>
                      <Text style={[pdfStyles.text2a, {fontFamily: "Times-Bold"}]}>{Number.parseFloat(redevanceUSD15).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD</Text>
                    </View>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text1a}>4. Fonds Minier : 10%, soit</Text>
                      <Text style={[pdfStyles.text2, {fontFamily: "Times-Bold", paddingLeft: 178.5}]}>: </Text>
                      <Text style={[pdfStyles.text2a, {fontFamily: "Times-Bold"}]}>{Number.parseFloat(redevanceUSD10).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD</Text>
                    </View>
                    <Text style={[pdfStyles.text1, {paddingTop: 10}]}>N.B :</Text>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text5}>• </Text>
                      <Text style={pdfStyles.text6a}>Le débiteur est tenu de verser sous sa propre responsabilité le montant ainsi réparti de la redevance minière dans chaque compte des bénéficiaires ;</Text>
                    </View>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text5}>• </Text>
                      <Text style={pdfStyles.text6a}>Le débiteur est tenu de déposer les preuves de versements à la Division Provinciale des Mines ou au Service des Mines du ressort endéans huit (8) jours ;</Text>
                    </View>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text5}>• </Text>
                      <Text style={pdfStyles.text6a}>En cas de paiement par chèque bancaire ou ordre de paiement, les frais y afférents sont à charge du débiteur.</Text>
                    </View>
                    {
                      params.row.EstValidEND === "Oui" &&
                      <>
                        <Text style={[pdfStyles.text9, {paddingTop: 15}]}>Fait à {params.row.LieuEmissionND}, le {formatDate(new Date(params.row.DateEmissionND))}</Text>
                        <Text style={[pdfStyles.text9, {paddingTop: 15}]}>Monsieur/Madame {params.row.NomVisaND}</Text>
                        <View style={{flexDirection: "row", marginLeft: 400, marginTop: 30}}>
                          <Image style={pdfStyles.qrcode2} src={qrCodeData2} alt="QR Code" />
                        </View>
                      </>
                    }
                    <Text style={pdfStyles.pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} fixed />
                  </Page>
                </Document>
              )}>
                {({ url }) => (
                  <NotificationItem icon={<Icon>download</Icon>} href={url} target="_blank" title={lang === "fr" ? "Télécharger/Imprimer la note de débit" : "Download/Print the debit note"} disabled={params.row.EstValidED === "Oui" ? false : true} />
                )}
              </BlobProvider>
            </Menu>
          </>
        );
      }, 
      sortable: false, 
      filterable: false,
      disableColumnMenu: true,
      disableExport: true
    }
  ]; 

  const colonnesD = [
    { 
      field: "EstEnElaborationD", 
      headerName: "Elaboration", 
      type: "singleSelect", 
      valueOptions: [
        { value: "Oui", label: lang === "fr" ? "Oui" : "Yes"},
        { value: "Non", label: lang === "fr" ? "Non" : "No" }
      ],
      width: 85, 
      renderCell: (params) => (
        <MDBox ml={-1}>
          {params.value === "Oui" && <MDButton variant="outlined" color="success" size="small" iconOnly disabled><Icon>check</Icon></MDButton>}
          {params.value === "Non" && <MDButton variant="outlined" color="error" size="small" iconOnly disabled><Icon>close</Icon></MDButton>}
        </MDBox>
      ),
      disableColumnMenu: true
    },
    { 
      field: "EstEnAttenteDeValidationD", 
      headerName: lang === "fr" ? "Attente de validation" : "Waiting for validation", 
      type: "singleSelect", 
      valueOptions: [
        { value: "Oui", label: lang === "fr" ? "Oui" : "Yes"},
        { value: "Non", label: lang === "fr" ? "Non" : "No" }
      ],
      width: 85, 
      renderCell: (params) => (
        <MDBox ml={-1}>
          {params.value === "Oui" && <MDButton variant="outlined" color="success" size="small" iconOnly disabled><Icon>check</Icon></MDButton>}
          {params.value === "Non" && <MDButton variant="outlined" color="error" size="small" iconOnly disabled><Icon>close</Icon></MDButton>}
        </MDBox>
      ),
      disableColumnMenu: true
    },
    { 
      field: "RetardTraitement", 
      headerName: lang === "fr" ? "Retard de traitement" : "Delay in processing", 
      type: "singleSelect", 
      valueOptions: [
        { value: "Oui", label: lang === "fr" ? "Oui" : "Yes"},
        { value: "Non", label: lang === "fr" ? "Non" : "No" }
      ],
      width: 85, 
      renderCell: (params) => (
        <MDBox ml={-1}>
          {params.value === "Oui" && <MDButton variant="outlined" color="success" size="small" iconOnly disabled><Icon>check</Icon></MDButton>}
          {params.value === "Non" && <MDButton variant="outlined" color="error" size="small" iconOnly disabled><Icon>close</Icon></MDButton>}
        </MDBox>
      ),
      disableColumnMenu: true
    },
    { 
      field: "EstValidED", 
      headerName: "Validation", 
      type: "singleSelect", 
      valueOptions: [
        { value: "Oui", label: lang === "fr" ? "Oui" : "Yes"},
        { value: "Non", label: lang === "fr" ? "Non" : "No" }
      ],
      width: 85, 
      renderCell: (params) => (
        <MDBox ml={-1}>
          {params.value === "Oui" && <MDButton variant="outlined" color="success" size="small" iconOnly disabled><Icon>check</Icon></MDButton>}
          {params.value === "Non" && <MDButton variant="outlined" color="error" size="small" iconOnly disabled><Icon>close</Icon></MDButton>}
        </MDBox>
      ),
      disableColumnMenu: true
    },
    { 
      field: "Identifiant", 
      headerName: lang === "fr" ? "Identifiant" : "Identifier",  
      width: 220,
      renderCell: (params) => (
        <div>
          {
            <HtmlTooltip title={
              <Fragment>
                {lang === "fr" ? "Exportateur : " + params.row.TypeId : "Exporter: " + params.row.TypeId}
                {lang === "fr" ? "\nRaison social : " + params.row.DEnomination : "\nCompany name: " + params.row.DEnomination}
                {`${params.row.IdNat === null ? "" : lang === "fr" ? "\nIdentité Nationale : " + params.row.IdNat : "\nNational Identity: " + params.row.IdNat}`}
                {lang === "fr" ? "\nAdresse : " + params.row.No : "\nAddress: " + params.row.No }
                {", Av. " + params.row.Avenue + ", Q/" + params.row.Quartier + ", C/" + params.row.Commune + ", V/" + params.row.Ville + ", P/" + params.row.Province}
                {lang === "fr" ? "\nNuméro d'arrêté de l'agrément : " + params.row.NoAgr : "\nApproval order number: " + params.row.NoAgr}
                {lang === "fr" ? "\nDate de début : " + moment(params.row.DateD).format("DD/MM/YYYY") : "\nStart date: " + moment(params.row.DateD).format("DD/MM/YYYY")}
                {lang === "fr" ? "\nDate d'expiration : " + moment(params.row.DateF).format("DD/MM/YYYY") : "\nEnd date: " + moment(params.row.DateF).format("DD/MM/YYYY")}
              </Fragment>
            }>
              <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
                <Icon color="success">info</Icon> {params.row.Identifiant}
              </div>
            </HtmlTooltip>
          }
      </div>
       
      ), 
      disableColumnMenu: true
    },
    { 
      field: "No", 
      headerName: lang === "fr" ? "N° de la déclaration" : "Declaration no.",  
      width: 160,
      disableColumnMenu: true
    },
    { 
      field: "NatureProduit", 
      headerName: lang === "fr" ? "Nature du produit" : "Type of product", 
      width: 160,
      disableColumnMenu: true
    },
    { 
      field: "ChantierExtraction", 
      headerName: lang === "fr" ? "Chantier d'extraction" : "Extraction site", 
      width: 165,
      disableColumnMenu: true
    },
    { 
      field: "ProvinceExtraction", 
      headerName: lang === "fr" ? "Province d'extraction" : "Province of extraction", 
      width: 160,
      disableColumnMenu: true
    },
    { 
      field: "EmplacementSite", 
      headerName: lang === "fr" ? "Emplacement du site" : "Site location", 
      width: 160,
      disableColumnMenu: true
    },
    { 
      field: "ZoneExploitation", 
      headerName: lang === "fr" ? "Zone d'exploitation" : "Exploitation area", 
      width: 160,
      disableColumnMenu: true
    },
    { 
      field: "LocalitEGroupement", 
      headerName: lang === "fr" ? "Localité/Groupement" : "Locality/Grouping", 
      width: 160,
      disableColumnMenu: true
    },
    { 
      field: "Secteur", 
      headerName: lang === "fr" ? "Secteur" : "Sector", 
      width: 160,
      disableColumnMenu: true
    },
    { 
      field: "Territoire", 
      headerName: lang === "fr" ? "Territoire" : "Territory", 
      width: 160,
      disableColumnMenu: true
    },
    { 
      field: "QtENet", 
      headerName: lang === "fr" ? "Quantité net" : "Net quantity", 
      width: 160,
      renderCell: (params) => lang === "fr" ? `${Number.parseFloat(params.row.QtENet).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} ${params.row.EstKg ? " Kg(s)" : " Tonne(s)"}` : `${Number.parseFloat(params.row.QtENet).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} ${params.row.EstKg ? " Kg(s)" : " Tonne(s)"}`, 
      valueGetter: (value) => lang === "fr" ? `${Number.parseFloat(value).toFixed(2).replace(".", ",")}` : `${Number.parseFloat(value).toFixed(2)}`,
      disableColumnMenu: true
    },
    { 
      field: "QtEHumide", 
      headerName: lang === "fr" ? "Quantité humide" : "Wet quantity", 
      width: 160,
      renderCell: (params) => lang === "fr" ? `${Number.parseFloat(params.row.QtEHumide).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} ${params.row.EstKg ? " Kg(s)" : " Tonne(s)"}` : `${Number.parseFloat(params.row.QtEHumide).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} ${params.row.EstKg ? " Kg(s)" : " Tonne(s)"}`, 
      valueGetter: (value) => lang === "fr" ? `${Number.parseFloat(value).toFixed(2).replace(".", ",")}` : `${Number.parseFloat(value).toFixed(2)}`,
      disableColumnMenu: true
    },
    { 
      field: "QtESec", 
      headerName: lang === "fr" ? "Quantité sec" : "Dry quantity", 
      width: 160,
      renderCell: (params) => lang === "fr" ? `${Number.parseFloat(params.row.QtESec).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} ${params.row.EstKg ? " Kg(s)" : " Tonne(s)"}` : `${Number.parseFloat(params.row.QtESec).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} ${params.row.EstKg ? " Kg(s)" : " Tonne(s)"}`, 
      valueGetter: (value) => lang === "fr" ? `${Number.parseFloat(value).toFixed(2).replace(".", ",")}` : `${Number.parseFloat(value).toFixed(2)}`,
      disableColumnMenu: true
    },
    { 
      field: "QualitESecPourc", 
      headerName: lang === "fr" ? "Qualité sec" : "Dry quality", 
      width: 160,
      renderCell: (params) => lang === "fr" ? `${Number.parseFloat(params.row.QualitESecPourc).toFixed(2).replace(".", ",")} %` : `${Number.parseFloat(params.row.QualitESecPourc).toFixed(2)} %`, 
      valueGetter: (value) => lang === "fr" ? `${Number.parseFloat(value).toFixed(2).replace(".", ",")}` : `${Number.parseFloat(value).toFixed(2)}`,
      disableColumnMenu: true
    },
    { 
      field: "QualitEHumidePourc", 
      headerName: lang === "fr" ? "Qualité humide" : "Wet quality", 
      width: 160,
      renderCell: (params) => lang === "fr" ? `${Number.parseFloat(params.row.QualitEHumidePourc).toFixed(2).replace(".", ",")} %` : `${Number.parseFloat(params.row.QualitEHumidePourc).toFixed(2)} %`, 
      valueGetter: (value) => lang === "fr" ? `${Number.parseFloat(value).toFixed(2).replace(".", ",")}` : `${Number.parseFloat(value).toFixed(2)}`,
      disableColumnMenu: true
    },
    { 
      field: "PrixUnitEUSD", 
      headerName: lang === "fr" ? "Prix/Unité" : "Unit price", 
      width: 160,
      renderCell: (params) => lang === "fr" ? `${Number.parseFloat(params.row.PrixUnitEUSD).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD` : `${Number.parseFloat(params.row.PrixUnitEUSD).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD`, 
      valueGetter: (value) => lang === "fr" ? `${Number.parseFloat(value).toFixed(2).replace(".", ",")}` : `${Number.parseFloat(value).toFixed(2)}`,
      disableColumnMenu: true
    },
    { 
      field: "PrixTotalUSD", 
      headerName: lang === "fr" ? "Prix Total" : "Total price", 
      width: 160,
      renderCell: (params) => lang === "fr" ? `${Number.parseFloat(params.row.PrixTotalUSD).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD` : `${Number.parseFloat(params.row.PrixTotalUSD).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD`, 
      valueGetter: (value) => lang === "fr" ? `${Number.parseFloat(value).toFixed(2).replace(".", ",")}` : `${Number.parseFloat(value).toFixed(2)}`,
      disableColumnMenu: true
    },
    { 
      field: "IdentitEAcheteurs", 
      headerName: lang === "fr" ? "Identité des acheteurs" : "Identity of buyers",
      width: 170,
      disableColumnMenu: true
    },
    { 
      field: "RaisonSocial", 
      headerName: lang === "fr" ? "Nom ou raison social" : "Company name",
      width: 170,
      disableColumnMenu: true
    },
    { 
      field: "RueAcheteur", 
      headerName: lang === "fr" ? "Rue" : "Street", 
      width: 160,
      disableColumnMenu: true
    },
    { 
      field: "PaysAcheteur", 
      headerName: lang === "fr" ? "Pays" : "Country", 
      width: 160,
      disableColumnMenu: true
    },
    { 
      field: "EmailAcheteur", 
      headerName: lang === "fr" ? "Email (Acheteur)" : "Email (Buyer)", 
      width: 160,
      disableColumnMenu: true
    },
    { 
      field: "REglement", 
      headerName: lang === "fr" ? "Modalité du Règlement du prix de vente" : "Payment terms", 
      width: 175,
      disableColumnMenu: true
    },
    { 
      field: "DateExportation", 
      headerName: lang === "fr" ? "Date de l'exportation" : "Date of export", 
      type: "date", 
      width: 160, 
      renderCell: (params) => formatDate(new Date(params.value)),
      valueGetter: (value) => new Date(value),
      disableColumnMenu: true
    },
    { 
      field: "FraisTransport", 
      headerName: lang === "fr" ? "Frais de transport" : "Transportation fees", 
      width: 160,
      renderCell: (params) => lang === "fr" ? `${Number.parseFloat(params.row.FraisTransport).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD` : `${Number.parseFloat(params.row.FraisTransport).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD`, 
      valueGetter: (value) => lang === "fr" ? `${Number.parseFloat(value).toFixed(2).replace(".", ",")}` : `${Number.parseFloat(value).toFixed(2)}`,
      disableColumnMenu: true
    },
    { 
      field: "FraisAnalyse", 
      headerName: lang === "fr" ? "Frais d'analyse" : "Analysis fees", 
      width: 160,
      renderCell: (params) => lang === "fr" ? `${Number.parseFloat(params.row.FraisAnalyse).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD` : `${Number.parseFloat(params.row.FraisAnalyse).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD`, 
      valueGetter: (value) => lang === "fr" ? `${Number.parseFloat(value).toFixed(2).replace(".", ",")}` : `${Number.parseFloat(value).toFixed(2)}`,
      disableColumnMenu: true
    },
    { 
      field: "FraisAssurance", 
      headerName: lang === "fr" ? "Frais d'assurance" : "Insurance fees", 
      width: 160,
      renderCell: (params) => lang === "fr" ? `${Number.parseFloat(params.row.FraisAssurance).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD` : `${Number.parseFloat(params.row.FraisAssurance).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD`, 
      valueGetter: (value) => lang === "fr" ? `${Number.parseFloat(value).toFixed(2).replace(".", ",")}` : `${Number.parseFloat(value).toFixed(2)}`,
      disableColumnMenu: true
    },
    { 
      field: "FraisCommercialisation", 
      headerName: lang === "fr" ? "Frais de commercialisation" : "Marketing fees", 
      width: 160,
      renderCell: (params) => lang === "fr" ? `${Number.parseFloat(params.row.FraisCommercialisation).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD` : `${Number.parseFloat(params.row.FraisCommercialisation).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD`, 
      valueGetter: (value) => lang === "fr" ? `${Number.parseFloat(value).toFixed(2).replace(".", ",")}` : `${Number.parseFloat(value).toFixed(2)}`,
      disableColumnMenu: true 
    },
    { 
      field: "VenteEntitETransformation", 
      headerName: lang === "fr" ? "Vente à une Entité de transformation" : "Sale to a Transformation Entity", 
      width: 165, 
      disableColumnMenu: true
    },
    { 
      field: "createdAt", 
      headerName: lang === "fr" ? "Création" : "Creation", 
      type: "dateTime", 
      width: 160, 
      renderCell: (params) => `${formatDate(new Date(params.value))}, ${formatHeure(new Date(params.value))}`,
      valueGetter: (value) => new Date(value),
      disableColumnMenu: true
    },
    { 
      field: "LieuEmissionD", 
      headerName: lang === "fr" ? "Lieu d'émission" : "Issuance place", 
      width: 160,
      disableColumnMenu: true
    },
    { 
      field: "DateEmissionD", 
      headerName: lang === "fr" ? "Date d'émission" : "Issuance date", 
      type: "dateTime", 
      width: 150, 
      renderCell: (params) => params.value ? `${formatDate(new Date(params.value))}, ${formatHeure(new Date(params.value))}` : "",
      valueGetter: (value) => value ? new Date(value) : value,
      disableColumnMenu: true
    },
    { 
      field: "NomVisaD", 
      headerName: lang === "fr" ? "Nom (Visa)" : "Name (Visa)", 
      width: 160, 
      disableColumnMenu: true
    },
    { 
      field: "FonctionVisa", 
      headerName: lang === "fr" ? "Fonction (Visa)" : "Role (Visa)", 
      width: 160, 
      disableColumnMenu: true
    },
    { 
      field: "Gouverneur", 
      headerName: lang === "fr" ? "Gouverneur" : "Governor", 
      width: 200, 
      disableColumnMenu: true
    },
    { 
      field: "MaireAdm", 
      headerName: lang === "fr" ? "Maire/Administrateur" : "Mayor/Administrator",  
      width: 160, 
      disableColumnMenu: true
    }
  ]; 

  const colonnesND = [
    { 
      field: "EstEnElaborationND", 
      headerName: "Elaboration", 
      type: "singleSelect", 
      valueOptions: [
        { value: "Oui", label: lang === "fr" ? "Oui" : "Yes"},
        { value: "Non", label: lang === "fr" ? "Non" : "No" }
      ],
      width: 85, 
      renderCell: (params) => (
        <MDBox ml={-1}>
          {params.value === "Oui" && <MDButton variant="outlined" color="success" size="small" iconOnly disabled><Icon>check</Icon></MDButton>}
          {params.value === "Non" && <MDButton variant="outlined" color="error" size="small" iconOnly disabled><Icon>close</Icon></MDButton>}
        </MDBox>
      ),
      disableColumnMenu: true
    },
    { 
      field: "EstEnAttenteDeValidationND", 
      headerName: lang === "fr" ? "Attente de validation" : "Waiting for validation", 
      type: "singleSelect", 
      valueOptions: [
        { value: "Oui", label: lang === "fr" ? "Oui" : "Yes"},
        { value: "Non", label: lang === "fr" ? "Non" : "No" }
      ],
      width: 85, 
      renderCell: (params) => (
        <MDBox ml={-1}>
          {params.value === "Oui" && <MDButton variant="outlined" color="success" size="small" iconOnly disabled><Icon>check</Icon></MDButton>}
          {params.value === "Non" && <MDButton variant="outlined" color="error" size="small" iconOnly disabled><Icon>close</Icon></MDButton>}
        </MDBox>
      ),
      disableColumnMenu: true
    },
    { 
      field: "EstValidEND", 
      headerName: "Validation", 
      type: "singleSelect", 
      valueOptions: [
        { value: "Oui", label: lang === "fr" ? "Oui" : "Yes"},
        { value: "Non", label: lang === "fr" ? "Non" : "No" }
      ],
      width: 85, 
      renderCell: (params) => (
        <MDBox ml={-1}>
          {params.value === "Oui" && <MDButton variant="outlined" color="success" size="small" iconOnly disabled><Icon>check</Icon></MDButton>}
          {params.value === "Non" && <MDButton variant="outlined" color="error" size="small" iconOnly disabled><Icon>close</Icon></MDButton>}
        </MDBox>
      ),
      disableColumnMenu: true
    },
    { 
      field: "Identifiant", 
      headerName: lang === "fr" ? "Identifiant" : "Identifier",  
      width: 220,
      renderCell: (params) => (
        <div>
          {
            <HtmlTooltip title={
              <Fragment>
                {lang === "fr" ? "Exportateur : " + params.row.TypeId : "Exporter: " + params.row.TypeId}
                {lang === "fr" ? "\nRaison social : " + params.row.DEnomination : "\nCompany name: " + params.row.DEnomination}
                {`${params.row.IdNat === null ? "" : lang === "fr" ? "\nIdentité Nationale : " + params.row.IdNat : "\nNational Identity: " + params.row.IdNat}`}
                {lang === "fr" ? "\nAdresse : " + params.row.No : "\nAddress: " + params.row.No }
                {", Av. " + params.row.Avenue + ", Q/" + params.row.Quartier + ", C/" + params.row.Commune + ", V/" + params.row.Ville + ", P/" + params.row.Province}
                {lang === "fr" ? "\nNuméro d'arrêté de l'agrément : " + params.row.NoAgr : "\nApproval order number: " + params.row.NoAgr}
                {lang === "fr" ? "\nDate de début : " + moment(params.row.DateD).format("DD/MM/YYYY") : "\nStart date: " + moment(params.row.DateD).format("DD/MM/YYYY")}
                {lang === "fr" ? "\nDate d'expiration : " + moment(params.row.DateF).format("DD/MM/YYYY") : "\nEnd date: " + moment(params.row.DateF).format("DD/MM/YYYY")}
              </Fragment>
            }>
              <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
                <Icon color="success">info</Icon> {params.row.Identifiant}
              </div>
            </HtmlTooltip>
          }
        </div>
      ), 
      disableColumnMenu: true
    },
    { 
      field: "Num", 
      headerName: lang === "fr" ? "N° de la note de débit" : "Débit note no.",  
      width: 160,
      renderCell: (params) => `DIV.MIN/354/8.3/ ${(params.row.No).slice(-7)}&nbsp;&nbsp;&nbsp;&nbsp;${moment(params.row.DateEmissionND).format("YYYY")}`,
      disableColumnMenu: true
    },
    { 
      field: "No", 
      headerName: lang === "fr" ? "N° de la déclaration" : "Declaration No.",  
      width: 160,
      disableColumnMenu: true
    },
    { 
      field: "NatureProduit", 
      headerName: lang === "fr" ? "Article budgétaire" : "Budget item",
      width: 170,
      disableColumnMenu: true
    },
    { 
      field: "Cours", 
      headerName: lang === "fr" ? "Cours (valeur de base mensuelle)" : "Price (monthly base value)", 
      width: 170,
      renderCell: (params) => params.row.Cours ? lang === "fr" ? `${Number.parseFloat(params.row.Cours).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD` : `${Number.parseFloat(params.row.Cours).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD` : "", 
      valueGetter: (value) => value ? lang === "fr" ? `${Number.parseFloat(value).toFixed(2).replace(".", ",")}` : `${Number.parseFloat(value).toFixed(2)}` : "",
      disableColumnMenu: true
    },
    { 
      field: "QtENetConvertTKg", 
      headerName: lang === "fr" ? "Quantité net 1" : "Net quantity 1", 
      width: 160,
      renderCell: (params) => lang === "fr" ? `${Number.parseFloat(params.row.QtENetConvertTKg).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} Kg(s)` : `${Number.parseFloat(params.row.QtENetConvertTKg).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} Kg(s)`, 
      valueGetter: (value) => lang === "fr" ? `${Number.parseFloat(value).toFixed(2).replace(".", ",")}` : `${Number.parseFloat(value).toFixed(2)}`,
      disableColumnMenu: true
    },
    { 
      field: "QtENetConvertKgT", 
      headerName: lang === "fr" ? "Quantité net 2" : "Net quantity 2", 
      width: 160,
      renderCell: (params) => lang === "fr" ? `${Number.parseFloat(params.row.QtENetConvertKgT).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")}  Tonne(s)` : `${Number.parseFloat(params.row.QtENetConvertKgT).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} Tonne(s)`, 
      valueGetter: (value) => lang === "fr" ? `${Number.parseFloat(value).toFixed(2).replace(".", ",")}` : `${Number.parseFloat(value).toFixed(2)}`,
      disableColumnMenu: true
    },
    { 
      field: "QtENetConvertKgc", 
      headerName: lang === "fr" ? "Quantité net 3" : "Net quantity 3", 
      width: 160,
      renderCell: (params) => lang === "fr" ? `${Number.parseFloat(params.row.QtENetConvertKgC).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} Carat(s)` : `${Number.parseFloat(params.row.QtENetConvertKgC).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} Carat(s)`, 
      valueGetter: (value) => lang === "fr" ? `${Number.parseFloat(value).toFixed(2).replace(".", ",")}` : `${Number.parseFloat(value).toFixed(2)}`,
      disableColumnMenu: true
    },
    { 
      field: "QualitESecPourc", 
      headerName: lang === "fr" ? "Qualité sec" : "Dry quality", 
      width: 160,
      renderCell: (params) => lang === "fr" ? `${Number.parseFloat(params.row.QualitESecPourc).toFixed(2).replace(".", ",")} %` : `${Number.parseFloat(params.row.QualitESecPourc).toFixed(2)} %`, 
      valueGetter: (value) => lang === "fr" ? `${Number.parseFloat(value).toFixed(2).replace(".", ",")}` : `${Number.parseFloat(value).toFixed(2)}`,
      disableColumnMenu: true
    },
    { 
      field: "PrixTotalUSD", 
      headerName: lang === "fr" ? "Prix Total" : "Total price", 
      width: 160,
      renderCell: (params) => lang === "fr" ? `${Number.parseFloat(params.row.PrixTotalUSD).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD` : `${Number.parseFloat(params.row.PrixTotalUSD).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD`, 
      valueGetter: (value) => lang === "fr" ? `${Number.parseFloat(value).toFixed(2).replace(".", ",")}` : `${Number.parseFloat(value).toFixed(2)}`,
      disableColumnMenu: true
    },
    { 
      field: "RedevancePourc", 
      headerName: lang === "fr" ? "Taux de la redevance" : "Royalty rate", 
      width: 160,
      renderCell: (params) => lang === "fr" ? `${Number.parseFloat(params.row.RedevancePourc).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} %` : `${Number.parseFloat(params.row.RedevancePourc).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} %`, 
      valueGetter: (value) => lang === "fr" ? `${Number.parseFloat(value).toFixed(2).replace(".", ",")}` : `${Number.parseFloat(value).toFixed(2)}`,
      disableColumnMenu: true
    },
    { 
      field: "Redevance", 
      headerName: lang === "fr" ? "Montant de la redevance" : "Royalty amount", 
      width: 180,
      renderCell: (params) => lang === "fr" ? `${Number.parseFloat(params.row.Redevance).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD` : `${Number.parseFloat(params.row.Redevance).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD`, 
      valueGetter: (value) => lang === "fr" ? `${Number.parseFloat(value).toFixed(2).replace(".", ",")}` : `${Number.parseFloat(value).toFixed(2)}`,
      disableColumnMenu: true
    },
    { 
      field: "RedevanceLettre", 
      headerName: lang === "fr" ? "Montant de la redevance (lettres)" : "Royalty amount (letters)",
      width: 230,
      filterable: false
    },
    { 
      field: "LieuEmissionND", 
      headerName: lang === "fr" ? "Lieu d'émission" : "Issuance place", 
      width: 160,
      disableColumnMenu: true
    },
    { 
      field: "DateEmissionND", 
      headerName: lang === "fr" ? "Date d'émission" : "Issuance date", 
      type: "dateTime", 
      width: 150, 
      renderCell: (params) => params.value ? `${formatDate(new Date(params.value))}, ${formatHeure(new Date(params.value))}` : "",
      valueGetter: (value) => value ? new Date(value) : "",
      disableColumnMenu: true
    },
    { 
      field: "NomVisaND", 
      headerName: lang === "fr" ? "Nom (Visa)" : "Name (Visa)", 
      width: 160, 
      disableColumnMenu: true
    }
  ]; 

  const colonnes = changeColD
  ? [...colonnesO, ...colonnesND]
  : [...colonnesO, ...colonnesD];

  const [paginationModel, setPaginationModel] = useState(pageD);
  useEffect(() => {
    localStorage.setItem("pageD", JSON.stringify(paginationModel));
    envoi(setPageD());
  },[paginationModel])

  return (
    <div style={{ height: h, width: "100%" }}>
      {globalStyles()}
      <StyledDataGrid
        checkboxSelection
        disableRowSelectionOnClick
        density="compact"
        rows={listeDEclarations} 
        getRowId={(row) => row.Id}
        getRowClassName={
          (params) => {
            if (params.row.RetardTraitement === "Oui") {
              return "super-app-theme"
            }
          }
        }
        columns={colonnes} 
        slots= {{
          loadingOverlay: LinearProgress, 
          noRowsOverlay: CustomNoRowsOverlay, 
          noResultsOverlay: CustomNoRowsOverlay,
          toolbar: CustomToolbar, 
          pagination: CustomPagination
        }}
        loading={chargementListeDEclarations}
        componentsProps={{
          panel: {
            sx: {
              "& .MuiDataGrid-panelWrapper": {
                backgroundColor: darkMode ? "#202940" : "#fff",
              },
              "& .MuiNativeSelect-select": {
                color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
              },
              "& .MuiSvgIcon-root": {
                color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
              },
              "& .MuiFormControl-root": {
                color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
              }
            }
          }
        }}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 50},
          },
          columns: {
            columnVisibilityModel: !JSON.parse(localStorage.getItem("columnVisibilityModelD")) ? {} : JSON.parse(localStorage.getItem("columnVisibilityModelD"))
          },
          sorting: {
            sortModel: !JSON.parse(localStorage.getItem("sortModelD")) ? [] : JSON.parse(localStorage.getItem("sortModelD"))
          },
          filter: {
            filterModel: !JSON.parse(localStorage.getItem(changeColD === false ? "filterModelD" : "filterModelND")) ? {items: []} : JSON.parse(localStorage.getItem(changeColD === false ? "filterModelD" : "filterModelND"))
          }
        }}
        paginationModel={pageD}
        onPaginationModelChange={setPaginationModel}
        onColumnVisibilityModelChange={(model) => localStorage.setItem("columnVisibilityModelD", JSON.stringify(model))}
        rowSelectionModel={selectedRowD}
        onRowSelectionModelChange={
          (model) => {
            envoi(setSelectedRowD(model));
          }
        }
        onSortModelChange={(model) => localStorage.setItem("sortModelD", JSON.stringify(model))}
        onFilterModelChange={(model)=> localStorage.setItem(changeColD === false ? "filterModelD" : "filterModelND", JSON.stringify(model))}
        localeText={lang === "fr" ? frFR : enUS}
      />
    </div>
  );
};

export default TableDEclarations;
