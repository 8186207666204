import { useEffect, useState } from "react";
// Contexte du Material Dashboard 2 React
import { useMaterialUIController } from "Contexte";

const Rebours1 = ({ temps }) => {
  const [controller, dispatch] = useMaterialUIController();
  const {lang} = controller;

  const [minutes, assignerMinutes] = useState(0)
  const [secondes, assignerSecondes] = useState(0);
  useEffect(() => {
    const intervalSec = setInterval(() => {
      const tempsActuel = Date.now();
      if (Number.isInteger(parseInt(temps))) {
        const diff = parseInt(temps) - tempsActuel;
        assignerMinutes(Math.floor((diff / 1000 / 60) % 60));
        assignerSecondes(Math.floor((diff / 1000) % 60));
      }
    }, 1000);
    return () => clearInterval(intervalSec);
  }, []);
  return (
    minutes > 0 ? 
      <span>
        {lang === "fr" && `Réessayer dans ${String(minutes).padStart(2, '0')} minutes(s) ${String(secondes).padStart(2, '0')} seconde(s)`}
        {lang === "eng" && `Retry in ${String(minutes).padStart(2, '0')} minutes(s) ${String(secondes).padStart(2, '0')} second(s)`}
      </span> 
    : secondes > 0 &&
      <span>
        {lang === "fr" && `Réessayer dans ${String(secondes).padStart(2, '0')} seconde(s)`}
        {lang === "eng" && `Retry in ${String(secondes).padStart(2, '0')} second(s)`}
      </span>
  );
};

export default Rebours1;