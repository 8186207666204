import axios from "axios";
axios.defaults.withCredentials = true;
 
const API_URL = window.location.protocol + "//" + window.location.host;

export const serviceListeComptesBancaires = async () => {
    try {
        return await axios.get(`${API_URL}/api/banque/compte/liste`);
    } catch (err) {
        return {
            error: true,
            response: err.response
        };
    }
}

export const serviceAjoutCompteBancaire = async (nom, num, banque, lieu) => {
    try {
        return await axios.post(`${API_URL}/api/banque/compte/nouveau`, {nom, num, banque, lieu});
    } catch (err) {
        return {
            error: true,
            response: err.response
        };
    }
};

export const serviceModifCompteBancaire = async (id, nom, num, banque, lieu, statut) => {
    try {
        return await axios.post(`${API_URL}/api/banque/compte/modifier`, {id, nom, num, banque, lieu, statut});
    } catch (err) {
        return {
            error: true,
            response: err.response
        };
    }
};

export const serviceSupprCompteBancaire = async (id) => {
    try {
        return await axios.post(`${API_URL}/api/banque/compte/supprimer`, {id});
    } catch (err) {
        return {
            error: true,
            response: err.response
        };
    }
};