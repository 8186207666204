/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from 'react-modal';
import { defTokenAuth } from "Services/Authentification";
import { confirmationTokenAsync } from "./ActionsAsync/ActionsAuthAsync";
import { disjonctionAsync } from "ActionsAsync/ActionsAuthAsync";
import { 
  ouvrirNotifAutoDisjonction, 
  fermerNotifAutoDisjonction, 
  fermerTousDialoguesUtilisateur,
  ouvrirFDialogueModifPasse
} from "Simplificateurs/SimplificateursUtilisateur";
import { fermerTousDialoguesNP } from "Simplificateurs/SimplificateursNatureProduit";
import { fermerTousDialoguesCB } from "Simplificateurs/SimplificateursCompteBancaire";
import { fermerTousDialoguesDEclaration } from "Simplificateurs/SimplificateursDEclaration";

import moment from "moment";

import CssBaseline from "@mui/material/CssBaseline";

// composants du @mui material
import { ThemeProvider } from "@mui/material/styles";
import Configurator from "Composants/Configurator";

// Composants du Material Dashboard 2 React
import Sidenav from "Composants/Sidenav";

// Contexte du Material Dashboard 2 React
import { setDarkMode, setMiniSidenav, setOpenConfigurator, useMaterialUIController } from "Contexte";

// Images
import brand from "Outils/images/logo.png";

// Thèmes du Material Dashboard 2 React
import theme from "Outils/theme";
import themeDark from "Outils/theme-dark";

// composants du react-router
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import routesPersonnellesAdm from "Routage/RoutagePersonnelAdm";
import routesPersonnellesSCP from "Routage/RoutagePersonnelSCP";
import routesPersonnellesCP from "Routage/RoutagePersonnelCP";
import routesPersonnellesDP from "Routage/RoutagePersonnelDP";
import routesPubliques from "Routage/RoutagePublique";

// Composants du Material Dashboard 2 React
import DashboardLayout from "Composants/LayoutContainers/DashboardLayout";
import DashboardNavbar from "Composants/Navbars/DashboardNavbar";
import Footer from "Composants/Footer";
import MDBox from "Composants/MDBox";
import MDTypography from "Composants/MDTypography";

import { styleModal430 } from "Vues/Utilitaires";

export default function App() {
  const envoi = useDispatch();
  const authObj = useSelector((state) => state.authentification);
  const {chargementAuth, chargementDijonction, estAuthentique, info, token, expiration} = authObj;
  const utilisateurObj = useSelector((state) => state.utilisateur);
  const {notifAutoDisjonction} = utilisateurObj;
  const traiterFermerNotification = () => {
    envoi(fermerNotifAutoDisjonction());
  };
  const traiterOuvrirFDialogueModifPasse = () => {
    if (info.PremiEreSession) envoi(ouvrirFDialogueModifPasse());
  }
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    layout,
    //openConfigurator,
    sidenavColor,
    //transparentSidenav,
    //whiteSidenav,
    darkMode,
    lang,
    socket
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  useEffect(() => {
    if(JSON.parse(localStorage.getItem("automaticDarkMode"))) {
      var date = new Date();
      var temps = date.getHours();
      if (temps >= 0 && temps < 6) {
        localStorage.setItem("darkMode", "true");
        setDarkMode(dispatch, true);
      };
      if (temps > 18 && temps <= 23) {
        localStorage.setItem("darkMode", "true");
        setDarkMode(dispatch, true);
      };
      if (temps > 5 && temps < 19) {
        localStorage.setItem("darkMode", "false");
        setDarkMode(dispatch, false);
      };
    };
    // Setting page scroll to 0 when changing the route
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    defTokenAuth(token);
    const minuteurConfirmationToken = setTimeout(() => {
      envoi(confirmationTokenAsync(dispatch, socket));
    }, moment(expiration).diff() - 10 * 1000);
    return () => {
      clearTimeout(minuteurConfirmationToken);
    };
  }, [expiration, token]);

  const [seconde, assignerSeconde] = useState(0);
  let horodatage;
  let alertInactifInterval = useRef();
  let commencerMinuteurInterval = useRef();

  let minuteur = () => {
    commencerMinuteurInterval.current = setTimeout(() => {
      let horodatageEnreg = sessionStorage.getItem('dernierHorodatageEvt');
      alertInactif(horodatageEnreg);
    }, 1000);
  };

  let alertInactif = (temps) => {
    clearTimeout(commencerMinuteurInterval.current);
    alertInactifInterval.current = setInterval(() => {
      const tempsMax = info ? info.AutoDisjonction : 0;
      const tempsAlert = info ? info.AutoDisjonction - 1 : 0;
      const diff = moment.duration(moment().diff(moment(temps)));
      const min = Math.max(0, diff.minutes() ? diff.minutes() : 0);
      const secondRest = 60 - diff.seconds();
      if(min === tempsAlert) {
        envoi(fermerTousDialoguesUtilisateur());
        envoi(fermerTousDialoguesNP());
        envoi(fermerTousDialoguesCB());
        envoi(fermerTousDialoguesDEclaration());
        assignerSeconde(secondRest);
        envoi(ouvrirNotifAutoDisjonction());
      }
      else if(min === tempsMax) {
        clearInterval(alertInactifInterval.current);
        sessionStorage.removeItem("dernierHorodatageEvt");
        envoi(fermerNotifAutoDisjonction());
        envoi(disjonctionAsync(dispatch, socket));
      }
      else {
        envoi(fermerNotifAutoDisjonction());
      }
    }, 1000);
  };

  let initialiserMinuteur = useCallback(() => {
    clearTimeout(commencerMinuteurInterval.current);
    clearTimeout(alertInactifInterval.current);
    assignerSeconde(0);
    if (estAuthentique) {
      horodatage = moment().format();
      sessionStorage.setItem('dernierHorodatageEvt', horodatage);
    } else {
      sessionStorage.removeItem('dernierHorodatageEvt');
    }
    minuteur();
  }, [estAuthentique]);

  useEffect(() => {
    const gestionAct = () => {
      initialiserMinuteur();
    };
    window.addEventListener('load', gestionAct, true);
    window.addEventListener('mousemove', gestionAct, true);
    window.addEventListener('mousedown', gestionAct, true);
    window.addEventListener('click', gestionAct, true);
    window.addEventListener('scroll', gestionAct, true);
    window.addEventListener('keypress', gestionAct, true);
    return () => {
      window.removeEventListener('load', gestionAct, true);
      window.removeEventListener('mousemove', gestionAct, true);
      window.removeEventListener('mousedown', gestionAct, true);
      window.removeEventListener('click', gestionAct, true);
      window.removeEventListener('scroll', gestionAct, true);
      window.removeEventListener('keypress', gestionAct, true);
      clearTimeout(commencerMinuteurInterval.current);
    };
  
  }, [initialiserMinuteur]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  if (chargementAuth) {
    return <div>
      <small>
        {lang === "fr" && "Veuillez patienter ..."}
        {lang === "eng" && "Please wait ..."}
      </small>
    </div>
  }
  else if (chargementDijonction) {
    return <div>
      <small>
        {lang === "fr" && "Arrêt de session ..."}
        {lang === "eng" && "Session termination ..."}
      </small>
    </div>
  }
  else {
    return (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {
          estAuthentique ?
            socket === null ?
            <div></div> :
            <>
              <Modal isOpen={seconde === 0 ? false : notifAutoDisjonction} onRequestClose={traiterFermerNotification} onAfterClose={traiterOuvrirFDialogueModifPasse} ariaHideApp={false} style={styleModal430}>
                <MDBox 
                  sx={{padding: 3, height: 110, textAlign: "center"}}
                  variant="gradient" 
                  bgColor={darkMode ? "dark": "#fff"}
                  borderRadius="lg"
                  coloredShadow={darkMode ? "dark": "light"}
                >
                  <MDTypography display="block" variant="button" color="text" fontWeight="medium">
                    {lang === "fr" && 
                      <>
                        <span>Si vous êtes toujours inactif,</span>
                        <br />
                        <span>votre session sera deconnectée dans</span>
                        <br />
                        <span>{seconde} seconde(s)</span>
                      </>
                    }
                    {lang === "eng" && 
                      <>
                        <span>If you are still inactive,</span>
                        <br />
                        <span>you will be logged out in</span>
                        <br />
                        <span>{seconde} second(s)</span>
                      </>
                    }
                  </MDTypography>
                </MDBox>
              </Modal>
              <DashboardLayout>
                <DashboardNavbar />
                {layout === "dashboard" && (
                  <>
                    <Sidenav
                      color={sidenavColor}
                      brandName=""
                      brand={brand}
                      routes={info.AssujettiId === null && info.TypeService === null ? routesPersonnellesSCP : info.AssujettiId === null && info.TypeService === 1 ? routesPersonnellesDP : routesPersonnellesCP}
                      onMouseEnter={handleOnMouseEnter}
                      onMouseLeave={handleOnMouseLeave}
                    />
                    <Configurator />
                  </>
                )}
                {layout === "vr" && <Configurator />}
                <Routes>
                  {info.Administrateur && getRoutes(routesPersonnellesAdm)}
                  {info.AssujettiId === null && info.TypeService === null && getRoutes(routesPersonnellesSCP)}
                  {info.AssujettiId === null && info.TypeService === 1 && getRoutes(routesPersonnellesDP)}
                  {getRoutes(routesPersonnellesCP)}
                  <Route path="*" element={<Navigate to={"/Session/TableauDeBord"} />} />
                </Routes> 
                <Footer />
              </DashboardLayout>
            </> :
          <Routes>
            {getRoutes(routesPubliques)}
            <Route path="*" element={<Navigate to="/Authentication/Connexion" />} />
          </Routes>
        }
      </ThemeProvider>
    )
  }
}
