/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
 Vous pouvez modifier ou ajouter une nouvelle largeur de bordure, une couleur de bordure ou un rayon de bordure à l'aide de ce fichier.
 */

// Styles de base du Material Dashboard 2 PRO React
import colors from "Outils/theme-dark/base/colors";

// Fonctions d'assistance du Material Dashboard 2 PRO React
import pxToRem from "Outils/theme-dark/functions/pxToRem";
import rgba from "Outils/theme-dark/functions/rgba";

const { white } = colors;

export default {
  borderColor: rgba(white.main, 0.4),

  borderWidth: {
    0: 0,
    1: pxToRem(1),
    2: pxToRem(2),
    3: pxToRem(3),
    4: pxToRem(4),
    5: pxToRem(5),
  },

  borderRadius: {
    xs: pxToRem(1.6),
    sm: pxToRem(2),
    md: pxToRem(6),
    lg: pxToRem(8),
    xl: pxToRem(12),
    xxl: pxToRem(16),
    section: pxToRem(160),
  },
};
