/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { confirmationTokenAsync } from "ActionsAsync/ActionsAuthAsync";
import { defTokenAuth } from "Services/Authentification";

// Composants du @mui material
//import Grid from "@mui/material/Grid";

// Composants du Material Dashboard 2 React
import MDBox from "Composants/MDBox";

// Contexte Material Dashboard 2 PRO React
//import { useMaterialUIController } from "Contexte";

function IllustrationsGraphiques() {
  //const [controller, dispatch] = useMaterialUIController();
  //const { lang } = controller;

  const envoi = useDispatch();

  const authObj = useSelector((state) => state.authentification);
  const {token, expiration} = authObj;
 
  useEffect(() => {
    /*defTokenAuth(token);
    const minuteurConfirmationToken = setTimeout(() => {
      envoi(confirmationTokenAsync(true));
    }, moment(expiration).diff() - 10 * 1000);
    return () => {
      clearTimeout(minuteurConfirmationToken);
    }*/
  }, [expiration, token]);

  return (
    <MDBox py={2.7}>
    
    </MDBox>
  );
};

export default IllustrationsGraphiques;
