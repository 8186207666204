/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DOMPurify from 'dompurify';
import { 
  validationProfilAsync1,
  validationProfilAsync2,
  ajoutUtilisateurAsync,
  modificationUtilisateurAsync,
  suppressionUtilisateurAsync
} from "ActionsAsync/ActionsUtilisateurAsync";
import { listeLieuxAsync } from "ActionsAsync/ActionsUtilitaireAsync";
import { 
  ouvrirFDialogueAjoutU, 
  avant1,
  avant2,
  fermerFDialogueAjoutU,
  fermerNotifAjoutU,
  fermerFDialogueModifU,
  fermerNotifModifU,
  fermerFDialogueSupprU,
  fermerNotifSupprU,
  effacerErreurValidationP,
  effacerErreurModifU
} from "Simplificateurs/SimplificateursUtilisateur";
import Modal from 'react-modal';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import fr from "react-phone-input-2/lang/fr.json";

// Composants du @mui material
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Switch from "@mui/material/Switch";

// Composants du Material Dashboard 2 React
import MDBox from "Composants/MDBox";
import MDTypography from "Composants/MDTypography";
import MDInput from "Composants/MDInput";
import MDButton from "Composants/MDButton";

// Contexte Material Dashboard 2 PRO React
import { useMaterialUIController } from "Contexte";

import { Notification, styleSelect, StyledAutocomplete, styleModal430 } from "Vues/Utilitaires";

function NotificationsEtDialoguesUtilisateur() {
    const [controller, dispatch] = useMaterialUIController();
    const { darkMode, lang, socket } = controller;
  
    const envoi = useDispatch();
  
    const authObj = useSelector((state) => state.authentification);
    const session = authObj.info.Id;
    const utilisateurObj = useSelector((state) => state.utilisateur);
    const {
      disabled,
      vertical,
      horizontal,
      ouvrir3,
      activeStep,
      erreurValidationProfil,
      erreurAjoutUtilisateur,
      notifAjoutUtilisateur,
      data,
      ouvrir4,
      erreurModificationUtilisateur,
      notifModifUtilisateur,
      ouvrir5,
      erreurSuppressionUtilisateur,
      notifSupprUtilisateur,
    } = utilisateurObj;
    const utilitaireObj = useSelector((state) => state.utilitaire);
    const { chargementLieux, listeLieux } = utilitaireObj;
  
    useEffect(() => {
      const minuteurObtenirLieux = setTimeout(() => {
        envoi(listeLieuxAsync(dispatch, socket));
      }, 1000);
      return () => {
        clearTimeout(minuteurObtenirLieux);
      };
    }, []);
   
    const traiterOuvrir1 = () => envoi(ouvrirFDialogueAjoutU());
    const traiterFermer1 = () => {
      envoi(fermerFDialogueAjoutU());
      traiterAvant1();
      envoi(effacerErreurValidationP());
      assignerNom("");
      assignerPostNom("");
      assignerPrEnom("");
      assignerEmail("");
      assignerPhone("");
      assignerProvince(null);
      assignerVille(null);
      assignerCommune(null);
      assignerQuartier("");
      assignerAvenue("");
      assignerNo("");
      assignerAdmin("");
      assignerChefLieu("");
    };
  
    const traiterFermerNotification1 = () => {
      envoi(fermerNotifAjoutU());
      traiterAvant1();
      envoi(effacerErreurValidationP());
      assignerNom("");
      assignerPostNom("");
      assignerPrEnom("");
      assignerEmail("");
      assignerPhone("");
      assignerProvince(null);
      assignerVille(null);
      assignerCommune(null);
      assignerQuartier("");
      assignerAvenue("");
      assignerNo("");
      assignerAdmin("");
      assignerChefLieu("");
    };
  
    const traiterOuvrir2 = (
      idProfilData, 
      emailData, 
      phoneData, 
      provinceData, 
      villeData, 
      communeData, 
      quartierData, 
      avenueData, 
      noData,
      statutData, 
      adminData, 
      estStaffData, 
      chefLieuData, 
      fonctionData,
      listeLieuxData
    ) => () => {
      assignerIdProfil(idProfilData);
      assignerNouvelEmail(emailData);
      assignerNouveauPhone(phoneData);
      assignerNouvelleProvince({Nom: provinceData});
      const villesArray = listeLieuxData.filter(province => province.Nom === provinceData)[0].VilleTerritoires;
      assignerListeNouvellesVilles([...villesArray].sort((a, b) => a.Nom.localeCompare(b.Nom)) || []);
      assignerNouvelleVille({Nom: villeData});
      const communesArray = villesArray.filter(ville => ville.Nom === villeData)[0].CommuneSecteurs;
      assignerListeNouvellesCommunes([...communesArray].sort((a, b) => a.Nom.localeCompare(b.Nom)) || []);
      assignerNouvelleCommune({Nom: communeData});
      assignerNouveauQuartier(quartierData);
      assignerNouvelleAvenue(avenueData);
      assignerNouveauNo(noData);
      assignerStatut(statutData ? "1" : "0");
      assignerNouvelAdmin(estStaffData ? adminData ? "1" : "0" : "0");
      assignerEstStaff(estStaffData);
      assignerNouveauChefLieu(chefLieuData);
      assignerNouvelleFonction(fonctionData);
    };

    const traiterFermer2 = () => {
      envoi(fermerFDialogueModifU());
      traiterAvant1();
      envoi(effacerErreurValidationP());
      assignerIdProfil("");
      assignerNouvelEmail("");
      assignerNouveauPhone("");
      assignerNouvelleProvince(null);
      assignerNouvelleVille(null);
      assignerNouvelleCommune(null);
      assignerNouveauQuartier("");
      assignerNouvelleAvenue("");
      assignerNouveauNo("");
      assignerStatut("");
      assignerNouvelAdmin("");
      assignerEstStaff("");
      assignerNouveauChefLieu("");
      assignerNouvelleFonction("");
    };
  
    const traiterFermerNotification2 = () => {
      envoi(fermerNotifModifU());
      traiterAvant1();
      envoi(effacerErreurValidationP());
      assignerIdProfil("");
      assignerNouvelEmail("");
      assignerNouveauPhone("");
      assignerNouvelleProvince(null);
      assignerNouvelleVille(null);
      assignerNouvelleCommune(null);
      assignerNouveauQuartier("");
      assignerNouvelleAvenue("");
      assignerNouveauNo("");
      assignerStatut("");
      assignerNouvelAdmin("");
      assignerEstStaff("");
      assignerNouveauChefLieu("");
      assignerNouvelleFonction("");
    };
  
    const traiterOuvrir3 = (idProfil, email) => () => {
      assignerIdProfil(idProfil);
      assignerNouvelEmail(email);
    };

    const traiterFermer3 = () => {
      envoi(fermerFDialogueSupprU());
      assignerIdProfil("");
      assignerNouvelEmail("");
    };
  
    const traiterFermerNotification3 = () => {
      envoi(fermerNotifSupprU());
      assignerIdProfil("");
      assignerNouvelEmail("");
    };
  
    const patternNoms = new RegExp(/^[A-Z.-\s]{3,20}$/i);
    const [nom, assignerNom] = useState("");
    const traiterNom = e => {
      let target = e.target;
      const cursor = e.target.selectionStart;
      assignerNom((e.target.value).replace(/[^a-zA-Z.-\s]/gi, "").toUpperCase());
      setTimeout(() => {
        target.setSelectionRange(cursor, cursor);
      }, 10);
    };
    const [postNom, assignerPostNom] = useState("");
    const traiterPostNom = e => {
      let target = e.target;
      const cursor = e.target.selectionStart;
      assignerPostNom((e.target.value).replace(/[^a-zA-Z.-\s]/gi, "").toUpperCase());
      setTimeout(() => {
        target.setSelectionRange(cursor, cursor);
      }, 10);
    };
    const [prEnom, assignerPrEnom] = useState("");
    const traiterPrEnom = e => {
      let target = e.target;
      const cursor = e.target.selectionStart;
      assignerPrEnom((e.target.value).replace(/[^a-zA-Z.-\s]/gi, "").toUpperCase());
      setTimeout(() => {
        target.setSelectionRange(cursor, cursor);
      }, 10);
    };
  
    const patternEmail = new RegExp(/^[A-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Z0-9](?:[A-Z0-9-]*[A-Z0-9])?\.)+[A-Z]{2,}$/i);
    const [email, assignerEmail] = useState("");
    const traiterEmail = e => {
      envoi(effacerErreurValidationP());
      assignerEmail((e.target.value).toLowerCase());
    };
    const [nouvelEmail, assignerNouvelEmail] = useState("");
    const traiterNouvelEmail = e => {
      //envoi(effacerErreurModifU());
      envoi(effacerErreurValidationP());
      assignerNouvelEmail((e.target.value).toLowerCase());
    };

    const patternPhone = new RegExp(/^(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{4,12}$/i);
    const [phone, assignerPhone] = useState("");
    const [nouveauPhone, assignerNouveauPhone] = useState("");

    const [listeVilles, assignerListeVilles] = useState([]);
    const [province, assignerProvince] = useState(null);
    const traiterProvince = (event, nouvelleValeur) => {
      assignerVille(null);
      assignerProvince(nouvelleValeur);
      const villesArray = listeLieux.filter(province => province.Nom === nouvelleValeur.Nom)[0].VilleTerritoires;
      assignerListeVilles([...villesArray].sort((a, b) => a.Nom.localeCompare(b.Nom)) || []);

      const filtreChefLieux = villesArray.filter(obj => obj.ChefLieu === true);
      filtreChefLieux.map((chefLieu) => (
        assignerChefLieu(chefLieu.Nom)
      ));   
    };
    const [listeNouvellesVilles, assignerListeNouvellesVilles] = useState([]);
    const [nouvelleProvince, assignerNouvelleProvince] = useState("");
    const traiterNouvelleProvince = (event, nouvelleValeur) => {
      assignerNouvelleVille(null);
      assignerNouvelleProvince(nouvelleValeur);
      const villesArray = listeLieux.filter(province => province.Nom === nouvelleValeur.Nom)[0].VilleTerritoires;
      assignerListeNouvellesVilles([...villesArray].sort((a, b) => a.Nom.localeCompare(b.Nom)) || []);

      envoi(effacerErreurModifU());
      const filtreChefLieux = villesArray.filter(obj => obj.ChefLieu === true);
      filtreChefLieux.map((chefLieu) => (
        assignerNouveauChefLieu(chefLieu.Nom)
      ));   
    };

    const [listeCommunes, assignerListeCommunes] = useState([]);
    const [ville, assignerVille] = useState("");
    const traiterVille = (event, nouvelleValeur) => {
      assignerCommune(null);
      assignerVille(nouvelleValeur);
      const communesArray = listeVilles.filter(ville => ville.Nom === nouvelleValeur.Nom)[0].CommuneSecteurs;
      assignerListeCommunes([...communesArray].sort((a, b) => a.Nom.localeCompare(b.Nom)) || []);
    };
    const [listeNouvellesCommunes, assignerListeNouvellesCommunes] = useState([]);
    const [nouvelleVille, assignerNouvelleVille] = useState("");
    const traiterNouvelleVille = (event, nouvelleValeur) => {
      assignerNouvelleCommune(null);
      assignerNouvelleVille(nouvelleValeur);
      const communesArray = listeNouvellesVilles.filter(ville => ville.Nom === nouvelleValeur.Nom)[0].CommuneSecteurs;
      assignerListeNouvellesCommunes([...communesArray].sort((a, b) => a.Nom.localeCompare(b.Nom)) || []);
    };
    const [commune, assignerCommune] = useState("");
    const traiterCommune = (event, nouvelleValeur) => {
      assignerCommune(nouvelleValeur);
    };
    const [nouvelleCommune, assignerNouvelleCommune] = useState("");
    const traiterNouvelleCommune = (event, nouvelleValeur) => {
      assignerNouvelleCommune(nouvelleValeur);
    };

    const patternAdresseFonction = new RegExp(/^[A-Z0-9'.-\s\/]{3,20}$/i);
    const [quartier, assignerQuartier] = useState("");
    const traiterQuartier = e => {
      let target = e.target;
      const cursor = e.target.selectionStart;
      assignerQuartier((e.target.value).replace(/[^a-zA-Z0-9'.-\s\/]/gi, "").toUpperCase());
      setTimeout(() => {
        target.setSelectionRange(cursor, cursor);
      }, 10);
    };
    const [nouveauQuartier, assignerNouveauQuartier] = useState("");
    const traiterNouveauQuartier = e => {
      let target = e.target;
      const cursor = e.target.selectionStart;
      assignerNouveauQuartier((e.target.value).replace(/[^a-zA-Z0-9'.-\s\/]/gi, "").toUpperCase());
      setTimeout(() => {
        target.setSelectionRange(cursor, cursor);
      }, 10);
    };

    const [avenue, assignerAvenue] = useState("");
    const traiterAvenue = e => {
      let target = e.target;
      const cursor = e.target.selectionStart;
      assignerAvenue((e.target.value).replace(/[^a-zA-Z0-9'.-\s\/]/gi, "").toUpperCase());
      setTimeout(() => {
        target.setSelectionRange(cursor, cursor);
      }, 10);
    };
    const [nouvelleAvenue, assignerNouvelleAvenue] = useState("");
    const traiterNouvelleAvenue = e => {
      let target = e.target;
      const cursor = e.target.selectionStart;
      assignerNouvelleAvenue((e.target.value).replace(/[^a-zA-Z0-9'.-\s\/]/gi, "").toUpperCase());
      setTimeout(() => {
        target.setSelectionRange(cursor, cursor);
      }, 10);
    };

    const patternNo = new RegExp(/^(?![A-Za-z])[0-9A-Za-z]{1,6}$/i);
    const [no, assignerNo] = useState("");
    const traiterNo = e => {
      let target = e.target;
      const cursor = e.target.selectionStart;
      assignerNo((e.target.value).replace(/[^a-zA-Z0-9]/gi, "").toUpperCase());
      setTimeout(() => {
        target.setSelectionRange(cursor, cursor);
      }, 10);
    };
    const [nouveauNo, assignerNouveauNo] = useState("");
    const traiterNouveauNo = e => {
      let target = e.target;
      const cursor = e.target.selectionStart;
      assignerNouveauNo((e.target.value).replace(/[^a-zA-Z0-9]/gi, "").toUpperCase());
      setTimeout(() => {
        target.setSelectionRange(cursor, cursor);
      }, 10);
    };

    const [idProfil, assignerIdProfil] = useState("");
   
    const [admin, assignerAdmin] = useState("");
    const traiterAdmin = e => {
      assignerAdmin(e.target.value);
    };
    const [estStaff, assignerEstStaff] = useState("");
    const [nouvelAdmin, assignerNouvelAdmin] = useState("");
    const traiterNouvelAdmin = e => {
      envoi(effacerErreurModifU());
      assignerNouvelAdmin(e.target.checked ? "1" : "0");
    };
    const [statut, assignerStatut] = useState("");
    const traiterStatut = e => {
      envoi(effacerErreurModifU());
      assignerStatut(e.target.checked ? "1" : "0");
    };

    const [fonction, assignerFonction] = useState("");
    const traiterFonction = e => {
      let target = e.target;
      const cursor = e.target.selectionStart;
      assignerFonction((e.target.value).replace(/[^a-zA-Z0-9'.-\s\/]/gi, "").toUpperCase());
      setTimeout(() => {
        target.setSelectionRange(cursor, cursor);
      }, 10);
    };
    const [nouvelleFonction, assignerNouvelleFonction] = useState("");
    const traiterNouvelleFonction = e => {
      envoi(effacerErreurModifU());
      let target = e.target;
      const cursor = e.target.selectionStart;
      assignerNouvelleFonction((e.target.value).replace(/[^a-zA-Z0-9'.-\s\/]/gi, "").toUpperCase());
      setTimeout(() => {
        target.setSelectionRange(cursor, cursor);
      }, 10);
    };

    const [chefLieu, assignerChefLieu] = useState("");
    const [nouveauChefLieu, assignerNouveauChefLieu] = useState("");
  
    const submitHandler = (e) => {
      const { id } = e.nativeEvent.submitter;
      switch (id) {
        case "submit1":
          return traiterSuivant1(e);

        case "submit2":
          return traiterSuivant2(e);
  
        case "submit3":
          return traiterAjoutUtilisateur(e);

        case "submit4":
          return traiterSuivant3(e);

        case "submit5":
          return traiterSuivant4(e);

        case "submit6":
          return traiterModificationUtilisateur(e);
  
        default:
        // ignore
      }
    };

    const cleanNom = DOMPurify.sanitize(nom, {
      ALLOWED_CHARS: /^[A-Z.-\s]{3,20}$/
    });
    const cleanPostNom = DOMPurify.sanitize(postNom, {
      ALLOWED_CHARS: /^[A-Z.-\s]{3,20}$/
    });
    const cleanPrEnom = DOMPurify.sanitize(prEnom, {
      ALLOWED_CHARS: /^[A-Z.-\s]{3,20}$/
    });
    const cleanEmail = DOMPurify.sanitize(email, {
      ALLOWED_CHARS: /^[A-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Z0-9](?:[A-Z0-9-]*[A-Z0-9])?\.)+[A-Z]{2,}$/i
    });
    const cleanPhone = DOMPurify.sanitize(phone, {
      ALLOWED_CHARS: /^(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{4,12}$/
    });
    const cleanProvince = DOMPurify.sanitize(province?.Nom, {
      ALLOWED_CHARS: /^[A-Z0-9'.-\s\/]{3,20}$/
    });
    const cleanVille = DOMPurify.sanitize(ville?.Nom, {
      ALLOWED_CHARS: /^[A-Z0-9'.-\s\/]{3,20}$/
    });
    const cleanCommune = DOMPurify.sanitize(commune?.Nom, {
      ALLOWED_CHARS: /^[A-Z0-9'.-\s\/]{3,20}$/
    });
    const cleanQuartier = DOMPurify.sanitize(quartier, {
      ALLOWED_CHARS: /^[A-Z0-9'.-\s\/]{3,20}$/
    });
    const cleanAvenue = DOMPurify.sanitize(avenue, {
      ALLOWED_CHARS: /^[A-Z0-9'.-\s\/]{3,20}$/
    });
    const cleanNo = DOMPurify.sanitize(no, {
      ALLOWED_CHARS: /^(?![A-Z])[0-9A-Z]{1,6}$/
    });
    const cleanNouvelEmail = DOMPurify.sanitize(nouvelEmail, {
      ALLOWED_CHARS: /^[A-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Z0-9](?:[A-Z0-9-]*[A-Z0-9])?\.)+[A-Z]{2,}$/i
    });
    const cleanNouveauPhone = DOMPurify.sanitize(nouveauPhone, {
      ALLOWED_CHARS: /^(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{4,12}$/
    });
    const cleanNouvelleProvince = DOMPurify.sanitize(nouvelleProvince?.Nom, {
      ALLOWED_CHARS: /^[A-Z0-9'.-\s\/]{3,20}$/
    });
    const cleanNouvelleVille = DOMPurify.sanitize(nouvelleVille?.Nom, {
      ALLOWED_CHARS: /^[A-Z0-9'.-\s\/]{3,20}$/
    });
    const cleanNouvelleCommune = DOMPurify.sanitize(nouvelleCommune?.Nom, {
      ALLOWED_CHARS: /^[A-Z0-9'.-\s\/]{3,20}$/
    });
    const cleanNouveauQuartier = DOMPurify.sanitize(nouveauQuartier, {
      ALLOWED_CHARS: /^[A-Z0-9'.-\s\/]{3,20}$/
    });
    const cleanNouvelleAvenue = DOMPurify.sanitize(nouvelleAvenue, {
      ALLOWED_CHARS: /^[A-Z0-9'.-\s\/]{3,20}$/
    });
    const cleanNouveauNo = DOMPurify.sanitize(nouveauNo, {
      ALLOWED_CHARS: /^(?![A-Z])[0-9A-Z]{1,6}$/
    });

    const traiterSuivant1 = async (e) => {
      e.preventDefault();
      await envoi(validationProfilAsync1(cleanNom, cleanPostNom, cleanPrEnom, cleanEmail.trim(), cleanPhone.trim(), null));
    }
    const traiterSuivant2 = async (e) => {
      e.preventDefault();
      await envoi(validationProfilAsync2(cleanNo.trim(), cleanAvenue, cleanQuartier, cleanCommune, cleanVille, cleanProvince));
    }
    const traiterSuivant3 = async (e) => {
      e.preventDefault();
      await envoi(validationProfilAsync1(null, null, null, cleanNouvelEmail.trim(), cleanNouveauPhone.trim(), cleanIdProfil.trim()));
    }
    const traiterSuivant4 = async (e) => {
      e.preventDefault();
      await envoi(validationProfilAsync2(cleanNouveauNo, cleanNouvelleAvenue, cleanNouveauQuartier, cleanNouvelleCommune, cleanNouvelleVille, cleanNouvelleProvince));
    }
    const traiterAvant1 = () => {
      envoi(avant1());
      envoi(effacerErreurModifU());
    };
    const traiterAvant2 = () => {
      envoi(avant2());
      envoi(effacerErreurModifU());
    };

    const cleanIdProfilService = null;
    /*const cleanIdProfilService = DOMPurify.sanitize(idProfilService, {
      ALLOWED_CHARS: /[^a-z0-9-]$/
    });*/

    const traiterAjoutUtilisateur = async (e) => {
      e.preventDefault();
      const cleanFonction = DOMPurify.sanitize(fonction, {
        ALLOWED_CHARS: /^[A-Z0-9'.-\s\/]{3,20}$/
      });
      const cleanChefLieu = DOMPurify.sanitize(chefLieu, {
        ALLOWED_CHARS: /^[A-Z0-9'.-\s\/]{3,20}$/
      });
      await envoi(ajoutUtilisateurAsync(null, cleanNom, cleanPostNom, cleanPrEnom, cleanEmail.trim(), cleanPhone.trim(), cleanNo.trim(), cleanAvenue, cleanQuartier, cleanCommune, cleanVille, cleanProvince, admin, cleanFonction, cleanChefLieu, cleanIdProfilService, dispatch, socket));
    }

    const cleanIdProfil = DOMPurify.sanitize(idProfil, {
      ALLOWED_CHARS: /[^a-z0-9-]$/
    });

    const traiterModificationUtilisateur = async (e) => {
      e.preventDefault();
      const cleanNouvelleFonction = DOMPurify.sanitize(nouvelleFonction, {
        ALLOWED_CHARS: /^[A-Z0-9'.-\s\/]{3,20}$/
      });
      const cleanNouveauChefLieu = DOMPurify.sanitize(nouveauChefLieu, {
        ALLOWED_CHARS: /^[A-Z0-9'.-\s\/]{3,20}$/
      });
      await envoi(modificationUtilisateurAsync(cleanIdProfil, cleanNouvelEmail.trim(), cleanNouveauPhone.trim(), cleanNouveauNo.trim(), cleanNouvelleAvenue, cleanNouveauQuartier, cleanNouvelleCommune, cleanNouvelleVille, cleanNouvelleProvince, nouvelAdmin, statut, cleanNouvelleFonction, cleanNouveauChefLieu, cleanIdProfilService, dispatch, socket));
    }
    const traiterSuppressionUtilisateur = async (e) => {
      e.preventDefault();
      const cleanSession = DOMPurify.sanitize(session, {
        ALLOWED_CHARS: /[^a-z0-9-]$/
      });
      await envoi(suppressionUtilisateurAsync(cleanSession.trim(), cleanIdProfil.trim(), dispatch, socket));
    }
  
    return (
      <>
        <Snackbar 
          anchorOrigin={{ vertical, horizontal }} 
          open={notifAjoutUtilisateur} 
          autoHideDuration={6000} 
          onClose={traiterFermerNotification1}
          key={vertical + horizontal + "1"}
        >
          <Notification onClose={traiterFermerNotification1} severity="success" sx={{ width: "100%", bgcolor: "background.paper"}}>
            {lang === "fr" && "Utilisateur ajouté avec succès"}
            {lang === "eng" && "User successfully added"}
          </Notification>
        </Snackbar>
        <Snackbar 
          anchorOrigin={{ vertical, horizontal }} 
          open={notifModifUtilisateur} 
          autoHideDuration={6000} 
          onClose={traiterFermerNotification2}
          key={vertical + horizontal + "2"}
        >
          <Notification onClose={traiterFermerNotification2} severity="warning" sx={{ width: "100%", bgcolor: "background.paper"}}>
            {lang === "fr" && "Utilisateur modifié avec succès"}
            {lang === "eng" && "User successfully edited"}
          </Notification>
        </Snackbar>
        <Snackbar 
          anchorOrigin={{ vertical, horizontal }} 
          open={notifSupprUtilisateur} 
          autoHideDuration={6000} 
          onClose={traiterFermerNotification3}
          key={vertical + horizontal + "3"}
        >
          <Notification onClose={traiterFermerNotification3} severity="error" sx={{ width: "100%", bgcolor: "background.paper"}}>
            {lang === "fr" && "Utilisateur supprimé avec succès"}
            {lang === "eng" && "User successfully deleted"}
          </Notification>
        </Snackbar>
        <Tooltip title="Ajouter">
          <MDButton variant="gradient" color="success" iconOnly sx={{float: "right"}} onClick={traiterOuvrir1}>
            <Icon>person_add_alt</Icon>
          </MDButton>
        </Tooltip>
        <Modal isOpen={ouvrir3} onRequestClose={traiterFermer1} ariaHideApp={false} style={styleModal430}>
          <MDBox 
            sx={{padding: 2.5, height: 785}}
            variant="gradient" 
            bgColor={darkMode ? "dark": "#fff"}
            borderRadius="lg"
            coloredShadow={darkMode ? "dark": "light"}
          >
            <Grid container spacing={3} alignItems="center">
              <Grid item>
                <MDBox height="100%" lineHeight={1}>
                  <MDTypography variant="h6" fontWeight="medium">
                    CREATION
                  </MDTypography>
                  <MDTypography variant="button" color="text" fontWeight="regular">
                      {lang === "fr" && "Vous pouvez créer un utilisateur en 3 étapes."}
                      {lang === "eng" && "You can create a user in 3 steps."}
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>
            <MDBox component="form" role="form" onSubmit={submitHandler} autoComplete="off">
              <Stepper activeStep={activeStep} orientation="vertical" sx={{background: "transparent", border: 0, boxShadow: "none"}} connector={null}>
                <Step key="Profil">
                  <StepLabel icon={<Icon>looks_one</Icon>} sx={{color: darkMode ? "#fff" : "rgba(0,0,0,.85)"}}>
                    <MDTypography display="block" variant="button" color="text" fontWeight="medium">
                      {lang === "fr" && "PROFIL"}
                      {lang === "eng" && "PROFILE"}
                    </MDTypography>
                  </StepLabel>
                  <StepContent>
                    <MDBox mb={2} sx={{height: 60, lineHeight: 0.5}}>
                      <MDInput 
                        type="text" 
                        required 
                        label={lang === "fr" ? "Nom" : "Last name"}  
                        value={nom}
                        onChange={traiterNom} 
                        sx={{width: "97%"}} 
                        error={nom === "" ? false : patternNoms.test(nom) ? false : true}
                        success={nom === "" ? false : patternNoms.test(nom) ? true : false} 
                      />
                      {nom !== "" && !(patternNoms.test(nom)) && 
                        <MDTypography variant="button" color="error">
                          <br />
                          {lang === "fr" && "3 caractères min et 20 caractères max"}
                          {lang === "eng" && "3 characters min and 20 characters max"}
                        </MDTypography>
                      }
                    </MDBox>
                    <MDBox mb={2} sx={{height: 60, lineHeight: 0.5}}>
                      <MDInput 
                        type="text" 
                        required 
                        label={lang === "fr" ? "Post-nom" : "Middle name"}
                        value={postNom} 
                        onChange={traiterPostNom} 
                        sx={{width: "97%"}} 
                        error={postNom === "" ? false : patternNoms.test(postNom ) ? false : true}
                        success={postNom  === "" ? false : patternNoms.test(postNom ) ? true : false} 
                      />
                      {postNom !== "" && !(patternNoms.test(postNom)) && 
                        <MDTypography variant="button" color="error">
                          <br />
                          {lang === "fr" && "3 caractères min et 20 caractères max"}
                          {lang === "eng" && "3 characters min and 20 characters max"}
                        </MDTypography>
                      }
                    </MDBox>
                    <MDBox mb={2} sx={{height: 60, lineHeight: 0.5}}>
                      <MDInput 
                        type="text" 
                        required 
                        label={lang === "fr" ? "Prénom" : "First name"} 
                        value={prEnom} 
                        onChange={traiterPrEnom} 
                        sx={{width: "97%"}} 
                        error={prEnom === "" ? false : patternNoms.test(prEnom) ? false : true}
                        success={prEnom === "" ? false : patternNoms.test(prEnom) ? true : false} 
                      />
                      {prEnom !== "" && !(patternNoms.test(prEnom)) && 
                        <MDTypography variant="button" color="error">
                          <br />
                          {lang === "fr" && "3 caractères min et 20 caractères max"}
                          {lang === "eng" && "3 characters min and 20 characters max"}
                        </MDTypography>
                      }
                    </MDBox>
                    <MDBox mb={2} sx={{height: 60, lineHeight: 0.5}}>
                      <MDInput 
                        type="text" 
                        required 
                        label="Email" 
                        value={email} 
                        onChange={traiterEmail} 
                        sx={{width: "97%"}} 
                        error={email === "" ? false : patternEmail.test(email) ? false : true}
                        success={email === "" ? false : patternEmail.test(email) ? true : false} 
                      />
                      {email !== "" && !(patternEmail.test(email)) && 
                        <MDTypography variant="button" color="error">
                          {lang === "fr" && "Veuillez entrer une adresse e-mail valide"}
                          {lang === "eng" && "Please enter a valid email address"}
                        </MDTypography>
                      }
                      {erreurValidationProfil === "Email déjà utilisé" && 
                        <MDTypography variant="button" color="error">
                          {lang === "fr" && "Email déjà utilisé"}
                          {lang === "eng" && "Email already used"}
                        </MDTypography>
                      }
                    </MDBox>
                    <MDBox mb={2} sx={{height: 60, lineHeight: 1}}>
                      <PhoneInput 
                        inputStyle={{width:"97%", height:"41px"}} 
                        dropdownStyle={{width:"290px"}}
                        country={'cd'}
                        localization={lang === "fr" ? fr : ""}  
                        placeholder={lang === "fr" ? "Téléphone *" : "Phone *"}
                        inputProps={{required: true, name: "phone"}}
                        value={phone} 
                        onChange={phone => {envoi(effacerErreurValidationP()), assignerPhone(phone)}}  
                      />
                     {phone !== "" && !(patternPhone.test(phone)) &&  
                        <MDTypography variant="button" color="error">
                          {lang === "fr" && "Veuillez entrer un numéro valide"}
                          {lang === "eng" && "Please enter a valid number"}
                        </MDTypography>
                      }
                      {erreurValidationProfil === "Numéro déjà utilisé" && 
                        <MDTypography variant="button" color="error">
                          {lang === "fr" && "Numéro déjà utilisé"}
                          {lang === "eng" && "Number already used"}
                        </MDTypography>
                      }
                    </MDBox>
                    <MDBox mt={2} sx={{textAlign: "center", height: 60}}>
                      <MDBox mb={1}>
                        <MDButton variant="gradient" color="error" onClick={traiterFermer1}>
                          {lang === "fr" && "Annuler"}
                          {lang === "eng" && "Cancel"}
                        </MDButton>
                        &nbsp;&nbsp;
                        <MDButton disabled={disabled} id="submit1" type="submit" variant="gradient" color="info">
                          {lang === "fr" && "Suivant"}
                          {lang === "eng" && "Next"}
                        </MDButton>
                      </MDBox>
                    <MDBox>
                    {erreurValidationProfil === "Champ(s) vide(s) dans la requête" && 
                      <MDTypography variant="button" color="error" mt={1}>
                        {lang === "fr" && "Champ(s) vide(s) dans la requête"}
                        {lang === "eng" && "Empty field(s) in the query"}
                      </MDTypography>
                    }
                    </MDBox>
                  </MDBox>
                  </StepContent>
                </Step>
                <Step key="adresse">
                  <StepLabel icon={<Icon>looks_one</Icon>} sx={{color: darkMode ? "#fff" : "rgba(0,0,0,.85)"}}>
                    <MDTypography display="block" variant="button" color="text" fontWeight="medium">
                      {lang === "fr" && "ADRESSE"}
                      {lang === "eng" && "ADDRESS"}
                    </MDTypography>
                  </StepLabel>
                  <StepContent>
                    <MDBox mt={2} mb={3.5} sx={{height: 40}}>
                      {chargementLieux ? 
                        <MDTypography variant="button" color="dark" mt={1}>
                          {lang === "fr" && "Chargement..."}
                          {lang === "eng" && "Loading..."}
                        </MDTypography> :
                        <StyledAutocomplete
                          disableClearable
                          openOnFocus
                          clearOnEscape
                          options={listeLieux}
                          getOptionLabel={(option) => option ? option.Nom : ""}
                          isOptionEqualToValue={(option, value) => option.Nom === value.Nom}
                          noOptionsText={lang === "fr" ? "Non trouvé" : "Not found"}
                          sx={{width: "97%"}} 
                          value={province} 
                          onChange={traiterProvince}
                          renderInput={(params) => (
                            <MDInput required type="text" label="Province" {...params} />
                          )}
                        />
                      }
                    </MDBox>
                    <MDBox mb={3.5} sx={{height: 40}}>
                      {chargementLieux ? 
                        <MDTypography variant="button" color="dark" mt={1}>
                          {lang === "fr" && "Chargement..."}
                          {lang === "eng" && "Loading..."}
                        </MDTypography> :
                        province === null ? 
                        <MDTypography variant="button" color="dark" mt={1}>
                          {lang === "fr" && "Sélectionner la province..."}
                          {lang === "eng" && "Select province..."}
                        </MDTypography> :
                        <StyledAutocomplete
                          disableClearable
                          openOnFocus
                          clearOnEscape
                          options={listeVilles}
                          getOptionLabel={(option) => option ? option.Nom : ""}
                          isOptionEqualToValue={(option, value) => option.Nom === value.Nom}
                          noOptionsText={lang === "fr" ? "Non trouvé" : "Not found"}
                          sx={{width: "97%"}} 
                          value={ville} 
                          onChange={traiterVille}
                          renderInput={(params) => (
                            <MDInput required type="text" label={lang === "fr" ? "Ville/Territoire" : "City/Territory"} {...params} />
                          )}
                        />
                      }
                    </MDBox>
                    <MDBox mb={3.5} sx={{height: 40}}>
                      {chargementLieux ? 
                        <MDTypography variant="button" color="dark" mt={1}>
                          {lang === "fr" && "Chargement..."}
                          {lang === "eng" && "Loading..."}
                        </MDTypography> :
                        ville === null ? 
                        <MDTypography variant="button" color="dark" mt={1}>
                          {lang === "fr" && "Sélectionner la ville/le territoire..."}
                          {lang === "eng" && "Select city/territory..."}
                        </MDTypography> :
                        <StyledAutocomplete
                          disableClearable
                          openOnFocus
                          clearOnEscape
                          options={listeCommunes}
                          getOptionLabel={(option) => option ? option.Nom : ""}
                          isOptionEqualToValue={(option, value) => option.Nom === value.Nom}
                          noOptionsText={lang === "fr" ? "Non trouvé" : "Not found"}
                          sx={{width: "97%"}} 
                          value={commune} 
                          onChange={traiterCommune}
                          renderInput={(params) => (
                            <MDInput required type="text" label={lang === "fr" ? "Commune/Secteur" : "Municipality/Sector"} {...params} />
                          )}
                        />
                      }
                    </MDBox>
                    <MDBox mb={3.5} sx={{height: 60, lineHeight: 0.5}}>
                      <MDInput 
                        type="text" 
                        required 
                        label={lang === "fr" ? "Quartier" : "Area"}
                        value={quartier} 
                        onChange={traiterQuartier} 
                        sx={{width: "97%"}} 
                        error={quartier === "" ? false : patternAdresseFonction.test(quartier) ? false : true}
                        success={quartier === "" ? false : patternAdresseFonction.test(quartier) ? true : false} 
                      />
                      {quartier !== "" && !(patternAdresseFonction.test(quartier)) &&  
                        <MDTypography variant="button" color="error">
                          <br />
                          {lang === "fr" && "3 caractères min et 20 caractères max"}
                          {lang === "eng" && "3 characters min and 20 characters max"}
                        </MDTypography>
                      }
                    </MDBox>
                    <MDBox mb={3.5} sx={{height: 60, lineHeight: 0.5}}>
                      <MDInput 
                        type="text" 
                        required 
                        label="Avenue" 
                        value={avenue} 
                        onChange={traiterAvenue} 
                        sx={{width: "97%"}} 
                        error={avenue === "" ? false : patternAdresseFonction.test(avenue) ? false : true}
                        success={avenue === "" ? false : patternAdresseFonction.test(avenue) ? true : false} 
                      />
                      {avenue !== "" && !(patternAdresseFonction.test(avenue)) &&  
                        <MDTypography variant="button" color="error">
                          <br />
                          {lang === "fr" && "3 caractères min et 20 caractères max"}
                          {lang === "eng" && "3 characters min and 20 characters max"}
                        </MDTypography>
                      }
                    </MDBox>
                    <MDBox mb={3.5} sx={{height: 60, lineHeight: 0.5}}>
                      <MDInput
                        type="text" 
                        required 
                        label={lang === "fr" ? "Numéro" : "Number"}
                        value={no} 
                        onChange={traiterNo} 
                        sx={{width: "97%"}} 
                        error={no === "" ? false : patternNo.test(no) ? false : true}
                        success={no === "" ? false : patternNo.test(no) ? true : false} 
                      />
                      {no !== "" && !(patternNo.test(no)) &&  
                        <MDTypography variant="button" color="error">
                            <br />
                            {lang === "fr" && "Numéro non valid/6 caractères max"}
                            {lang === "eng" && "Invalid number/6 characters max"}
                        </MDTypography>
                      }
                    </MDBox>
                    <MDBox mt={2} sx={{textAlign: "center", height: 60}}>
                      <MDBox mb={1}>
                        <MDButton variant="gradient" color="error" onClick={traiterAvant1}>
                          {lang === "fr" && "Précédent"}
                          {lang === "eng" && "Previous"}
                        </MDButton>
                        &nbsp;&nbsp;
                        <MDButton disabled={disabled} id="submit2" type="submit" variant="gradient" color="info">
                          {lang === "fr" && "Suivant"}
                          {lang === "eng" && "Next"}
                        </MDButton>
                      </MDBox>
                    <MDBox>
                    {erreurValidationProfil === "Champ(s) vide(s) dans la requête" && 
                      <MDTypography variant="button" color="error" mt={1}>
                        {lang === "fr" && "Champ(s) vide(s) dans la requête"}
                        {lang === "eng" && "Empty field(s) in the query"}
                      </MDTypography>
                    }
                    </MDBox>
                  </MDBox>
                  </StepContent>
                </Step>
                <Step key="role">
                  <StepLabel icon={<Icon>looks_two</Icon>} sx={{color: darkMode ? "#fff" : "rgba(0,0,0,.85)"}}>
                    <MDTypography  display="block" variant="button" color="text" fontWeight="medium" mt={1}>
                      {lang === "fr" && "TYPE DE COMPTE"}
                      {lang === "eng" && "ACCOUNT TYPE"}
                    </MDTypography>
                  </StepLabel>
                  <StepContent>
                    <MDBox mt={2} mb={3.5} sx={{height: 40}}>
                      <FormControl sx={{height: 45, width: "97%"}}>
                        <InputLabel id="select-insert4-label" htmlFor="select-insert4">
                          {lang === "fr" && "Rôle *"}
                          {lang === "eng" && "Role *"}
                        </InputLabel>
                        <Select 
                          sx={{height: 46}} 
                          required
                          labelId="select-insert4-label"
                          inputProps={{id: "select-insert4"}}
                          label={lang === "fr" ? "Rôle *" : "Role *"}
                          value={admin}
                          onChange={traiterAdmin}
                        >
                          <MenuItem key={"1"} value={"1"}>
                            <span style={styleSelect}>
                              {lang === "fr" && "Administrateur"}
                              {lang === "eng" && "Administrator"}
                            </span>        
                          </MenuItem>
                          <MenuItem key={"0"} value={"0"}>
                            <span style={styleSelect}>
                              {lang === "fr" && "Non Administrateur"}
                              {lang === "eng" && "No Administrator"}
                            </span>        
                          </MenuItem>
                          <MenuItem key={"2"} value={"2"}>
                            <span style={styleSelect}>
                              {lang === "fr" && "Division Provinciale"}
                              {lang === "eng" && "Provincial Division"}
                            </span>        
                          </MenuItem>
                          <MenuItem key={"3"} value={"3"}>
                            <span style={styleSelect}>
                              {lang === "fr" && "Service d'assiette"}
                              {lang === "eng" && "Tax base service"}
                            </span>        
                          </MenuItem>
                          <MenuItem key={"4"} value={"4"}>
                            <span style={styleSelect}>
                              {lang === "fr" && "Autre"}
                              {lang === "eng" && "Other"}
                            </span>        
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </MDBox>
                    <MDBox sx={{height: 110}}>
                      {admin === "2" &&
                        <MDBox>
                           <MDBox mb={2} sx={{height: 60, lineHeight: 0.5}}>
                              <MDInput 
                                type="text" 
                                required 
                                label="Fonction" 
                                value={fonction} 
                                onChange={traiterFonction} 
                                sx={{width: "97%"}} 
                                error={fonction === "" ? false : patternAdresseFonction.test(fonction) ? false : true}
                                success={fonction === "" ? false : patternAdresseFonction.test(fonction) ? true : false} 
                              />
                              {fonction !== "" && !(patternAdresseFonction.test(fonction)) && 
                                <MDTypography variant="button" color="error">
                                  <br />
                                  {lang === "fr" && "3 caractères min et 20 caractères max"}
                                  {lang === "eng" && "3 characters min and 20 characters max"}
                                </MDTypography>
                              }
                          </MDBox>
                          <MDBox mb={4.5} sx={{height: 40}}>
                            <MDInput type="text" readOnly label="Province" value={province.Nom} sx={{width: "97%"}} />  
                          </MDBox>
                          <MDBox sx={{height: 40}}>
                            <MDInput type="text" readOnly label="Chef-lieu" value={chefLieu} sx={{width: "97%"}} />   
                          </MDBox>
                        </MDBox>
                      }
                    </MDBox>
                    <MDBox mt= {17} mb={1.5} sx={{textAlign: "center", height: 60}}>
                      <MDBox mb={1}>
                        <MDButton type="button" variant="gradient" color="info" onClick={traiterAvant2}>
                          {lang === "fr" && "Précédent"}
                          {lang === "eng" && "Previous"}
                        </MDButton> 
                        &nbsp;&nbsp;
                        <MDButton disabled={disabled} id="submit3" type="submit" variant="gradient" color="info">
                          {lang === "fr" && "Ajouter"}
                          {lang === "eng" && "Add"}
                        </MDButton> 
                      </MDBox>
                      <MDBox>
                        {erreurAjoutUtilisateur === "Champ(s) vide(s) dans la requête" && 
                          <MDTypography variant="button" color="error" mt={1}>
                            {lang === "fr" && "Champ(s) vide(s) dans la requête"}
                            {lang === "eng" && "Empty field(s) in the query"}
                          </MDTypography>
                        }
                      </MDBox>
                    </MDBox>
                    <MDBox sx={{textAlign: "center"}}>
                      <MDButton variant="gradient" color="error" onClick={traiterFermer1}>
                        {lang === "fr" && "Annuler"}
                        {lang === "eng" && "Cancel"}
                      </MDButton>
                    </MDBox> 
                  </StepContent>
                </Step>
                <Step key="fin">
  
                </Step>
              </Stepper>
            </MDBox>
          </MDBox>
        </Modal>
        <Modal 
          isOpen={ouvrir4}  
          onAfterOpen={traiterOuvrir2(
            data.idProfil, 
            data.email, 
            data.phone, 
            data.province, 
            data.ville, 
            data.commune, 
            data.quartier, 
            data.avenue, 
            data.no,
            data.activation, 
            data.admin, 
            data.estStaff,
            data.chefLieu,
            data.fonction,
            listeLieux
          )} 
          onRequestClose={traiterFermer2} 
          ariaHideApp={false} 
          style={styleModal430}
        >
          <MDBox 
            sx={{padding: 2.5, height: 785}}
            variant="gradient" 
            bgColor={darkMode ? "dark": "#fff"}
            borderRadius="lg"
            coloredShadow={darkMode ? "dark": "light"}
          >
            <Grid container spacing={3} alignItems="center">
              <Grid item>
                <MDBox height="100%" lineHeight={1}>
                  <MDTypography variant="h6" fontWeight="medium">
                    MODIFICATION
                  </MDTypography>
                  <MDTypography variant="button" color="text" fontWeight="regular">
                      {lang === "fr" && "Vous pouvez modifier une ou plusieurs cases."}
                      {lang === "eng" && "You can edit one or more boxes."}
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>
            <MDBox component="form" role="form" onSubmit={submitHandler} autoComplete="off">
              <Stepper activeStep={activeStep} orientation="vertical" sx={{background: "transparent", border: 0, boxShadow: "none"}} connector={null}>
                <Step key="Profil">
                  <StepLabel icon={<Icon>looks_one</Icon>} sx={{color: darkMode ? "#fff" : "rgba(0,0,0,.85)"}}>
                    <MDTypography display="block" variant="button" color="text" fontWeight="medium">
                      {lang === "fr" && "PROFIL"}
                      {lang === "eng" && "PROFILE"}
                    </MDTypography>
                  </StepLabel>
                  <StepContent>
                    <MDBox mb={2} sx={{height: 60, lineHeight: 0.5}}>
                      <MDInput 
                        type="text" 
                        required 
                        label="Email" 
                        value={nouvelEmail} 
                        onChange={traiterNouvelEmail} 
                        sx={{width: "97%"}} 
                        error={nouvelEmail === "" ? false : patternEmail.test(nouvelEmail) ? false : true}
                        success={nouvelEmail === "" ? false : patternEmail.test(nouvelEmail) ? true : false} 
                      />
                      {nouvelEmail !== "" && !(patternEmail.test(nouvelEmail)) && 
                        <MDTypography variant="button" color="error">
                          {lang === "fr" && "Veuillez entrer une adresse e-mail valide"}
                          {lang === "eng" && "Please enter a valid email address"}
                        </MDTypography>
                      }
                      {erreurValidationProfil === "Email déjà utilisé" && 
                        <MDTypography variant="button" color="error">
                          {lang === "fr" && "Email déjà utilisé"}
                          {lang === "eng" && "Email already used"}
                        </MDTypography>
                      }
                    </MDBox>
                    <MDBox mb={2} sx={{height: 60, lineHeight: 1}}>
                      <PhoneInput 
                        inputStyle={{width:"97%", height:"41px"}} 
                        dropdownStyle={{width:"290px"}}
                        country={'cd'}
                        localization={lang === "fr" ? fr : ""}  
                        placeholder={lang === "fr" ? "Téléphone *" : "Phone *"}
                        inputProps={{required: true, name: "phone"}}
                        value={nouveauPhone} 
                        onChange={phone => {envoi(effacerErreurValidationP()), assignerNouveauPhone(phone)}}  
                      />
                     {nouveauPhone !== "" && !(patternPhone.test(nouveauPhone)) &&  
                        <MDTypography variant="button" color="error">
                          {lang === "fr" && "Veuillez entrer un numéro valide"}
                          {lang === "eng" && "Please enter a valid number"}
                        </MDTypography>
                      }
                      {erreurValidationProfil === "Numéro déjà utilisé" && 
                        <MDTypography variant="button" color="error">
                          {lang === "fr" && "Numéro déjà utilisé"}
                          {lang === "eng" && "Number already used"}
                        </MDTypography>
                      }
                    </MDBox>
                    <MDBox mt={2} sx={{textAlign: "center", height: 60}}>
                      <MDBox mb={1}>
                        <MDButton variant="gradient" color="error" onClick={traiterFermer2}>
                          {lang === "fr" && "Annuler"}
                          {lang === "eng" && "Cancel"}
                        </MDButton>
                        &nbsp;&nbsp;
                        <MDButton disabled={disabled} id="submit4" type="submit" variant="gradient" color="info">
                          {lang === "fr" && "Suivant"}
                          {lang === "eng" && "Next"}
                        </MDButton>
                      </MDBox>
                    <MDBox>
                    {erreurValidationProfil === "Champ(s) vide(s) dans la requête" && 
                      <MDTypography variant="button" color="error" mt={1}>
                        {lang === "fr" && "Champ(s) vide(s) dans la requête"}
                        {lang === "eng" && "Empty field(s) in the query"}
                      </MDTypography>
                    }
                    </MDBox>
                  </MDBox>
                  </StepContent>
                </Step>
                <Step key="adresse">
                  <StepLabel icon={<Icon>looks_one</Icon>} sx={{color: darkMode ? "#fff" : "rgba(0,0,0,.85)"}}>
                    <MDTypography display="block" variant="button" color="text" fontWeight="medium">
                      {lang === "fr" && "ADRESSE"}
                      {lang === "eng" && "ADDRESS"}
                    </MDTypography>
                  </StepLabel>
                  <StepContent>
                    <MDBox mt={2} mb={3.5} sx={{height: 40}}>
                      {chargementLieux ? 
                        <MDTypography variant="button" color="dark" mt={1}>
                          {lang === "fr" && "Chargement..."}
                          {lang === "eng" && "Loading..."}
                        </MDTypography> :
                        <StyledAutocomplete
                          disableClearable
                          openOnFocus
                          clearOnEscape
                          options={listeLieux}
                          getOptionLabel={(option) => option ? option.Nom : ""}
                          isOptionEqualToValue={(option, value) => option.Nom === value.Nom}
                          noOptionsText={lang === "fr" ? "Non trouvé" : "Not found"}
                          sx={{width: "97%"}} 
                          value={nouvelleProvince} 
                          onChange={traiterNouvelleProvince}
                          renderInput={(params) => (
                            <MDInput required type="text" label="Province" {...params} />
                          )}
                        />
                      }
                    </MDBox>
                    <MDBox mb={3.5} sx={{height: 40}}>
                      {chargementLieux ? 
                        <MDTypography variant="button" color="dark" mt={1}>
                          {lang === "fr" && "Chargement..."}
                          {lang === "eng" && "Loading..."}
                        </MDTypography> :
                        nouvelleProvince === null ? 
                        <MDTypography variant="button" color="dark" mt={1}>
                          {lang === "fr" && "Sélectionner la province..."}
                          {lang === "eng" && "Select province..."}
                        </MDTypography> :
                        <StyledAutocomplete
                          disableClearable
                          openOnFocus
                          clearOnEscape
                          options={listeNouvellesVilles}
                          getOptionLabel={(option) => option ? option.Nom : ""}
                          isOptionEqualToValue={(option, value) => option.Nom === value.Nom}
                          noOptionsText={lang === "fr" ? "Non trouvé" : "Not found"}
                          sx={{width: "97%"}} 
                          value={nouvelleVille} 
                          onChange={traiterNouvelleVille}
                          renderInput={(params) => (
                            <MDInput required type="text" label={lang === "fr" ? "Ville/Territoire" : "City/Territory"} {...params} />
                          )}
                        />
                      }
                    </MDBox>
                    <MDBox mb={3.5} sx={{height: 40}}>
                      {chargementLieux ? 
                        <MDTypography variant="button" color="dark" mt={1}>
                          {lang === "fr" && "Chargement..."}
                          {lang === "eng" && "Loading..."}
                        </MDTypography> :
                        nouvelleVille === null ? 
                        <MDTypography variant="button" color="dark" mt={1}>
                          {lang === "fr" && "Sélectionner la ville/le territoire..."}
                          {lang === "eng" && "Select city/territory..."}
                        </MDTypography> :
                        <StyledAutocomplete
                          disableClearable
                          openOnFocus
                          clearOnEscape
                          options={listeNouvellesCommunes}
                          getOptionLabel={(option) => option ? option.Nom : ""}
                          isOptionEqualToValue={(option, value) => option.Nom === value.Nom}
                          noOptionsText={lang === "fr" ? "Non trouvé" : "Not found"}
                          sx={{width: "97%"}} 
                          value={nouvelleCommune} 
                          onChange={traiterNouvelleCommune}
                          renderInput={(params) => (
                            <MDInput required type="text" label={lang === "fr" ? "Commune/Secteur" : "Municipality/Sector"} {...params} />
                          )}
                        />
                      }
                    </MDBox>
                    <MDBox mb={3.5} sx={{height: 60, lineHeight: 0.5}}>
                      <MDInput 
                        type="text" 
                        required 
                        label={lang === "fr" ? "Quartier" : "Area"}
                        value={nouveauQuartier} 
                        onChange={traiterNouveauQuartier} 
                        sx={{width: "97%"}} 
                        error={nouveauQuartier === "" ? false : patternAdresseFonction.test(nouveauQuartier) ? false : true}
                        success={nouveauQuartier === "" ? false : patternAdresseFonction.test(nouveauQuartier) ? true : false} 
                      />
                      {nouveauQuartier !== "" && !(patternAdresseFonction.test(nouveauQuartier)) &&  
                        <MDTypography variant="button" color="error">
                          <br />
                          {lang === "fr" && "3 caractères min et 20 caractères max"}
                          {lang === "eng" && "3 characters min and 20 characters max"}
                        </MDTypography>
                      }
                    </MDBox>
                    <MDBox mb={3.5} sx={{height: 60, lineHeight: 0.5}}>
                      <MDInput 
                        type="text" 
                        required 
                        label="Avenue" 
                        value={nouvelleAvenue} 
                        onChange={traiterNouvelleAvenue} 
                        sx={{width: "97%"}} 
                        error={nouvelleAvenue === "" ? false : patternAdresseFonction.test(nouvelleAvenue) ? false : true}
                        success={nouvelleAvenue === "" ? false : patternAdresseFonction.test(nouvelleAvenue) ? true : false} 
                      />
                      {nouvelleAvenue !== "" && !(patternAdresseFonction.test(nouvelleAvenue)) &&  
                        <MDTypography variant="button" color="error">
                          <br />
                          {lang === "fr" && "3 caractères min et 20 caractères max"}
                          {lang === "eng" && "3 characters min and 20 characters max"}
                        </MDTypography>
                      }
                    </MDBox>
                    <MDBox mb={3.5} sx={{height: 60, lineHeight: 0.5}}>
                      <MDInput
                        type="text" 
                        required 
                        label={lang === "fr" ? "Numéro" : "Number"}
                        value={nouveauNo} 
                        onChange={traiterNouveauNo} 
                        sx={{width: "97%"}} 
                        error={nouveauNo === "" ? false : patternNo.test(nouveauNo) ? false : true}
                        success={nouveauNo === "" ? false : patternNo.test(nouveauNo) ? true : false} 
                      />
                      {nouveauNo !== "" && !(patternNo.test(nouveauNo)) &&  
                        <MDTypography variant="button" color="error">
                            <br />
                            {lang === "fr" && "Numéro non valid/6 caractères max"}
                            {lang === "eng" && "Invalid number/6 characters max"}
                        </MDTypography>
                      }
                    </MDBox>
                    <MDBox mt={2} sx={{textAlign: "center", height: 60}}>
                      <MDBox mb={1}>
                        <MDButton variant="gradient" color="error" onClick={traiterAvant1}>
                          {lang === "fr" && "Précédent"}
                          {lang === "eng" && "Previous"}
                        </MDButton>
                        &nbsp;&nbsp;
                        <MDButton disabled={disabled} id="submit5" type="submit" variant="gradient" color="info">
                          {lang === "fr" && "Suivant"}
                          {lang === "eng" && "Next"}
                        </MDButton>
                      </MDBox>
                    <MDBox>
                    {erreurValidationProfil === "Champ(s) vide(s) dans la requête" && 
                      <MDTypography variant="button" color="error" mt={1}>
                        {lang === "fr" && "Champ(s) vide(s) dans la requête"}
                        {lang === "eng" && "Empty field(s) in the query"}
                      </MDTypography>
                    }
                    </MDBox>
                  </MDBox>
                  </StepContent>
                </Step>
                <Step key="role">
                  <StepLabel icon={<Icon>looks_two</Icon>} sx={{color: darkMode ? "#fff" : "rgba(0,0,0,.85)"}}>
                    <MDTypography  display="block" variant="button" color="text" fontWeight="medium" mt={1}>
                      {lang === "fr" && "TYPE DE COMPTE"}
                      {lang === "eng" && "ACCOUNT TYPE"}
                    </MDTypography>
                  </StepLabel>
                  <StepContent>
                    <MDBox>
                      <MDBox mb={2}>
                        <MDTypography display="block" variant="caption" color="text" fontWeight="medium" mt={1}>
                          {lang === "fr" && "Statut (Actif/Inactif) *"}
                          {lang === "eng" && "Statut (Active/Inactive) *"}
                        </MDTypography>
                        <Switch checked={statut === "1" ? true : false} onChange={traiterStatut} />
                      </MDBox>
                    </MDBox>
                    {estStaff &&
                      <MDBox mb={2}>
                        <MDTypography display="block" variant="caption" color="text" fontWeight="medium" mt={1}>
                          {lang === "fr" && "Rôle (Administrateur/Non administrateur) *"}
                          {lang === "eng" && "Role (Administrator/No administrator)*"}
                        </MDTypography>
                        <Switch checked={nouvelAdmin === "1" ? true : false} onChange={traiterNouvelAdmin} />
                      </MDBox>
                    }
                    {data.fonction !== null &&
                      <MDBox>
                        <MDBox mb={1} sx={{height: 70, lineHeight: 0.5}}>
                          <MDInput 
                            type="text" 
                            required 
                            label="Fonction" 
                            value={nouvelleFonction} 
                            onChange={traiterNouvelleFonction} 
                            sx={{width: "97%"}} 
                            error={nouvelleFonction === "" ? false : patternAdresseFonction.test(nouvelleFonction) ? false : true}
                            success={nouvelleFonction === "" ? false : patternAdresseFonction.test(nouvelleFonction) ? true : false} 
                          />
                          {nouvelleFonction !== "" && !(patternAdresseFonction.test(nouvelleFonction)) && 
                            <MDTypography variant="button" color="error">
                              <br />3 caractères min et 20 caractères max
                            </MDTypography>
                          }
                        </MDBox>
                        <MDBox mb={3.5} sx={{height: 45}}>
                          <MDInput type="text" readOnly label="Province" value={nouvelleProvince.Nom} sx={{width: "97%"}} />  
                        </MDBox>
                        <MDBox>
                          <MDInput type="text" readOnly label="Chef-lieu" value={nouveauChefLieu} sx={{width: "97%"}} />   
                        </MDBox>
                      </MDBox>
                    }
                    <MDBox mt= {17} mb={1.5} sx={{textAlign: "center", height: 60}}>
                      <MDBox>
                        <MDButton type="button" variant="gradient" color="info" onClick={traiterAvant2}>
                          {lang === "fr" && "Précédent"}
                          {lang === "eng" && "Previous"}
                        </MDButton> 
                        &nbsp;&nbsp;
                        <MDButton disabled={disabled} id="submit6" type="submit" variant="gradient" color="info">
                          {lang === "fr" && "Modifier"}
                          {lang === "eng" && "Edit"}
                        </MDButton> 
                      </MDBox>
                      <MDBox>
                        {erreurModificationUtilisateur === "Champ(s) vide(s) dans la requête" && 
                          <MDTypography variant="button" color="error" mt={1}>
                            {lang === "fr" && "Champ(s) vide(s) dans la requête"}
                            {lang === "eng" && "Empty field(s) in the query"}
                          </MDTypography>
                        }
                        {erreurModificationUtilisateur === "Aucune modification" && 
                          <MDTypography variant="button" color="error" mt={1}>
                            {lang === "fr" && "Aucune modification"}
                            {lang === "eng" && "No modification"}
                          </MDTypography>
                        }
                      </MDBox>
                    </MDBox>
                    <MDBox sx={{textAlign: "center"}}>
                      <MDButton variant="gradient" color="error" onClick={traiterFermer2}>
                        {lang === "fr" && "Annuler"}
                        {lang === "eng" && "Cancel"}
                      </MDButton>
                    </MDBox> 
                  </StepContent>
                </Step>
                <Step key="fin">
  
                </Step>
              </Stepper>
            </MDBox>
          </MDBox>
        </Modal>
        <Modal isOpen={ouvrir5} onAfterOpen={traiterOuvrir3(data.idProfil, data.email)} onRequestClose={traiterFermer3} ariaHideApp={false} style={styleModal430}>
          <MDBox 
            sx={{padding: 3, height: 190, textAlign: "center"}}
            variant="gradient" 
            bgColor={darkMode ? "dark": "#fff"}
            borderRadius="lg"
            coloredShadow={darkMode ? "dark": "light"}
          >
            <MDBox component="form" role="form" onSubmit={traiterSuppressionUtilisateur} autoComplete="off">
              <MDTypography display="block" variant="button" color="text" fontWeight="medium">
                {lang === "fr" && "Êtes-vous sûre de vouloir supprimer"}
                {lang === "eng" && "Are you sure you want to delete"}
                <br />"{data.email}" ?
              </MDTypography>
              <MDBox mt={4} mb={1}>
                <MDButton disabled={disabled} type="submit" variant="gradient" color="info">
                  {lang === "fr" && "Oui"}
                  {lang === "eng" && "Yes"}
                </MDButton>
                &nbsp;&nbsp;
                <MDButton variant="gradient" color="info" onClick={traiterFermer3}>
                  {lang === "fr" && "Non"}
                  {lang === "eng" && "No"}
                </MDButton>
              </MDBox>
              {erreurSuppressionUtilisateur && 
                <MDTypography variant="button" color="error" mt={1}>
                  {lang === "fr" && "Impossible de supprimer"}
                  {lang === "eng" && "Unable to delete"}
                </MDTypography>
              }
            </MDBox>
          </MDBox>
        </Modal>
      </>
    );
  };

  export default NotificationsEtDialoguesUtilisateur;