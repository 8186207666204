import { chargement, failliteModifRepartitionRedev, modifRepartitionRedevFaite } from "Simplificateurs/SimplificateursREpartitionRedevance";
import { obtentionREpartitionRedev } from "Simplificateurs/SimplificateursTable";
import { serviceREpartitionRedev, serviceModifREpartitionRedev } from "Services/REpartitionRedevance";
import { disjonctionAsync } from "ActionsAsync/ActionsAuthAsync";

export const rEpartitionRedevAsync = (dispatch, socket) => async envoi => {
    const resultat = await serviceREpartitionRedev();
    if (resultat.error) {
        return envoi(disjonctionAsync(dispatch, socket));
    }
    envoi(obtentionREpartitionRedev(resultat.data));
}

export const modifREpartitionRedevAsync = (rEpartitionRedevances, dispatch, socket) => async envoi => {
    envoi(chargement());
    const resultat = await serviceModifREpartitionRedev(rEpartitionRedevances);
    if (resultat.error) {
        return envoi(failliteModifRepartitionRedev(resultat.response.data));
    }
    envoi(modifRepartitionRedevFaite());
    envoi(rEpartitionRedevAsync(dispatch, socket));
}