import axios from "axios";
axios.defaults.withCredentials = true;
 
const API_URL = window.location.protocol + "//" + window.location.host;

export const serviceListeDEclaration = async (idProfil) => {
    try {
        return await axios.get(`${API_URL}/api/dcl/liste/${idProfil}`);
    } catch (err) {
        return {
            error: true,
            response: err.response
        };
    }
}

export const serviceValidationProduit1 = async ( 
    natureProduit, 
    chantierExtraction, 
    provinceExtraction, 
    emplacementSite,
    zoneExploitation,
    localitEGroupement,
    secteur,
    territoire,
    qtENet,
    qtEHumide,
    qtESec,
    qualitESecPourc,
    qualitEHumidePourc
) => {
    try {
        return await axios.post(`${API_URL}/api/dcl/produit/validation1`, { 
            natureProduit, 
            chantierExtraction, 
            provinceExtraction, 
            emplacementSite,
            zoneExploitation,
            localitEGroupement,
            secteur,
            territoire,
            qtENet,
            qtEHumide,
            qtESec,
            qualitESecPourc,
            qualitEHumidePourc
        });
    } catch (err) {
        return {
            error: true,
            response: err.response
        };
    }
};

export const serviceValidationProduit2 = async ( 
    prixUnitEUSD,
    prixTotalUSD,
    identitEAcheteurs,
    raisonSocial,
    rueAcheteur,
    paysAcheteur,
    emailAcheteur,
    rEglement,
    dateExportation,
    fraisTransport,
    fraisAnalyse,
    fraisAssurance,
    fraisCommercialisation,
    venteEntitETransformation
) => {
    try {
        return await axios.post(`${API_URL}/api/dcl/produit/validation2`, { 
            prixUnitEUSD,
            prixTotalUSD,
            identitEAcheteurs,
            raisonSocial,
            rueAcheteur,
            paysAcheteur,
            emailAcheteur,
            rEglement,
            dateExportation,
            fraisTransport,
            fraisAnalyse,
            fraisAssurance,
            fraisCommercialisation,
            venteEntitETransformation
        });
    } catch (err) {
        return {
            error: true,
            response: err.response
        };
    }
};

export const serviceAjoutDEclaration = async (
    idProfil,
    natureProduit, 
    chantierExtraction, 
    provinceExtraction, 
    emplacementSite,
    zoneExploitation,
    localitEGroupement,
    secteur,
    territoire,
    qtENetNature,
    qtENet,
    qtEHumide,
    qtESec,
    qualitESecPourc,
    qualitEHumidePourc,
    prixUnitEUSD,
    prixTotalUSD,
    identitEAcheteurs,
    raisonSocial,
    rueAcheteur,
    paysAcheteur,
    emailAcheteur,
    rEglement,
    dateExportation,
    fraisTransport,
    fraisAnalyse,
    fraisAssurance,
    fraisCommercialisation,
    venteEntitETransformation,
    listeCamions
) => {
    try {
        return await axios.post(`${API_URL}/api/dcl/nouveau`, {
            idProfil,
            natureProduit, 
            chantierExtraction, 
            provinceExtraction, 
            emplacementSite,
            zoneExploitation,
            localitEGroupement,
            secteur,
            territoire,
            qtENetNature,
            qtENet,
            qtEHumide,
            qtESec,
            qualitESecPourc,
            qualitEHumidePourc,
            prixUnitEUSD,
            prixTotalUSD,
            identitEAcheteurs,
            raisonSocial,
            rueAcheteur,
            paysAcheteur,
            emailAcheteur,
            rEglement,
            dateExportation,
            fraisTransport,
            fraisAnalyse,
            fraisAssurance,
            fraisCommercialisation,
            venteEntitETransformation,
            listeCamions
        });
    } catch (err) {
        return {
            error: true,
            response: err.response
        };
    }
};

export const serviceModifDEclaration = async (id, redevancePourc) => {
    try {
        return await axios.post(`${API_URL}/api/dcl/modifier`, {id, redevancePourc});
    } catch (err) {
        return {
            error: true,
            response: err.response
        };
    }
};

export const serviceValidDEclaration = async (idDEclaration, nomVisa, fonctionVisa, gouverneur, maireAdm) => {
    try {
        return await axios.post(`${API_URL}/api/dcl/valider`, {idDEclaration, nomVisa, fonctionVisa, gouverneur, maireAdm});
    } catch (err) {
        return {
            error: true,
            response: err.response
        };
    }
};