import store from "Store";
import {
    chargement,
    failliteValidationProduit1,
    validationProduit1Faite,
    failliteValidationProduit2,
    validationProduit2Faite,
    failliteAjoutDEclaration,
    ajoutDEclarationFait,



    failliteValidDEclaration,
    validDEclarationFaite,
} from "Simplificateurs/SimplificateursDEclaration";
import { obtentionListeDEclarations } from "Simplificateurs/SimplificateursTable";
import { 
    serviceListeDEclaration, 
    serviceValidationProduit1,
    serviceValidationProduit2,
    serviceAjoutDEclaration,
    serviceModifDEclaration,
    serviceValidDEclaration,
} from "Services/DEclaration";
import { disjonctionAsync } from "ActionsAsync/ActionsAuthAsync";

export const listeDEclarationsAsync = (dispatch, socket) => async envoi => {
    if (store.getState().authentification.info.Id) {
        const idProfil = store.getState().authentification.info.Id;
        const resultat = await serviceListeDEclaration(idProfil);
        if (resultat.error) {
            return envoi(disjonctionAsync(dispatch, socket));
        }
        envoi(obtentionListeDEclarations(resultat.data));
    }
}

export const validationProduit1Async = (
    natureProduit, 
    chantierExtraction, 
    provinceExtraction, 
    emplacementSite,
    zoneExploitation,
    localitEGroupement,
    secteur,
    territoire,
    qtENet,
    qtEHumide,
    qtESec,
    qualitESecPourc,
    qualitEHumidePourc
) => async envoi => {
    envoi(chargement());
    const resultat = await serviceValidationProduit1(
        natureProduit, 
        chantierExtraction, 
        provinceExtraction, 
        emplacementSite,
        zoneExploitation,
        localitEGroupement,
        secteur,
        territoire,
        qtENet,
        qtEHumide,
        qtESec,
        qualitESecPourc,
        qualitEHumidePourc
    );
    if (resultat.error) {
        return envoi(failliteValidationProduit1(resultat.response.data));
    }
    envoi(validationProduit1Faite());
}

export const validationProduit2Async = (
    prixUnitEUSD,
    prixTotalUSD,
    identitEAcheteurs,
    raisonSocial,
    rueAcheteur,
    paysAcheteur,
    emailAcheteur,
    rEglement,
    dateExportation,
    fraisTransport,
    fraisAnalyse,
    fraisAssurance,
    fraisCommercialisation,
    venteEntitETransformation
) => async envoi => {
    envoi(chargement());
    const resultat = await serviceValidationProduit2(
        prixUnitEUSD,
        prixTotalUSD,
        identitEAcheteurs,
        raisonSocial,
        rueAcheteur,
        paysAcheteur,
        emailAcheteur,
        rEglement,
        dateExportation,
        fraisTransport,
        fraisAnalyse,
        fraisAssurance,
        fraisCommercialisation,
        venteEntitETransformation
    );
    if (resultat.error) {
        return envoi(failliteValidationProduit2(resultat.response.data));
    }
    envoi(validationProduit2Faite());
}

export const ajoutDEclarationAsync = (
    idProfil,
    natureProduit, 
    chantierExtraction, 
    provinceExtraction, 
    emplacementSite,
    zoneExploitation,
    localitEGroupement,
    secteur,
    territoire,
    qtENetNature,
    qtENet,
    qtEHumide,
    qtESec,
    qualitESecPourc,
    qualitEHumidePourc,
    prixUnitEUSD,
    prixTotalUSD,
    identitEAcheteurs,
    raisonSocial,
    rueAcheteur,
    paysAcheteur,
    emailAcheteur,
    rEglement,
    dateExportation,
    fraisTransport,
    fraisAnalyse,
    fraisAssurance,
    fraisCommercialisation,
    venteEntitETransformation,
    listeCamions,
    dispatch, 
    socket
) => async envoi => {
    envoi(chargement());
    const resultat = await serviceAjoutDEclaration(
        idProfil,
        natureProduit, 
        chantierExtraction, 
        provinceExtraction, 
        emplacementSite,
        zoneExploitation,
        localitEGroupement,
        secteur,
        territoire,
        qtENetNature,
        qtENet,
        qtEHumide,
        qtESec,
        qualitESecPourc,
        qualitEHumidePourc,
        prixUnitEUSD,
        prixTotalUSD,
        identitEAcheteurs,
        raisonSocial,
        rueAcheteur,
        paysAcheteur,
        emailAcheteur,
        rEglement,
        dateExportation,
        fraisTransport,
        fraisAnalyse,
        fraisAssurance,
        fraisCommercialisation,
        venteEntitETransformation,
        listeCamions
    );
    if (resultat.error) {
        return envoi(failliteAjoutDEclaration(resultat.response.data));
    }
    envoi(ajoutDEclarationFait());
    envoi(listeDEclarationsAsync(dispatch, socket));
}

export const modifDEclarationAsync = (dispatch, socket) => async envoi => {
    envoi(chargement());
    const resultat = await serviceModifDEclaration();
    if (resultat.error) {
        //return envoi(failliteModifNatureProduit(resultat.response.data));
    }
    //envoi(modifNatureProduitFaite());
    envoi(listeDEclarationsAsync(dispatch, socket));
}

export const validDEclarationAsync = (idDEclaration, nomVisa, fonctionVisa, gouverneur, maireAdm, dispatch, socket) => async envoi => {
    envoi(chargement());
    const resultat = await serviceValidDEclaration(idDEclaration, nomVisa, fonctionVisa, gouverneur, maireAdm);
    if (resultat.error) {
        return envoi(failliteValidDEclaration(resultat.response.data));
    }
    envoi(validDEclarationFaite());
    envoi(listeDEclarationsAsync(dispatch, socket));
}