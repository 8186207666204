/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types est une bibliothèque pour la vérification de type d'accessoires
import PropTypes from "prop-types";

// Comlposants du @mui material
import Grid from "@mui/material/Grid";

// Composants du Material Dashboard 2 React
import MDBox from "Composants/MDBox";
//import DefaultNavbar from "Composants/Navbars/DefaultNavbar";
import PageLayout from "Composants/LayoutContainers/PageLayout";

// Composants des pages d'authentification
import Pied from "Vues/Authentication/Composants/Pied";

function Base({ image, children }) {
  return (
    <PageLayout>
      <MDBox
        position="fixed"
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            image &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MDBox px={1} width="100%" height="100vh" mx="auto">
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            {children}
          </Grid>
        </Grid>
      </MDBox>
      <Pied />
    </PageLayout>
  );
}

// Typechecking props for the Base
Base.propTypes = {
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Base;
