import store from "Store";
import {
    chargement,
    failliteNouveauProfil,
    nouveauProfilFait,
    failliteDemandeUtilisateur,
    demandeUtilisateur,
    failliteModificationPasse,
    modificationPasseFaite,
    failliteValidationProfil1,
    validationProfilFaite1,
    failliteValidationProfil2,
    validationProfilFaite2,
    failliteAjoutUtilisateur,
    ajoutUtilisateurFait,
    failliteModificationProfil,
    modificationProfilFaite,
    failliteModificationUtilisateur,
    modificationUtilisateurFaite,
    failliteModifAutoDisjonction,
    modifAutoDisjonctionFaite,
    failliteModifAutoDEsactivation,
    modifAutoDEsactivationFaite,
    failliteSuppressionUtilisateur,
    suppressionUtilisateurFaite,
} from "Simplificateurs/SimplificateursUtilisateur";
import { obtentionListeProfils, obtentionListeUtilisateurs, obtentionListeDP } from "Simplificateurs/SimplificateursTable";
import { 
    serviceListeProfils,
    serviceListeUtilisateurs,
    serviceListeDP,
    serviceValidationProfil1,
    serviceValidationProfil2,
    serviceInscription,
    serviceAjoutUtilisateur,
    serviceModificationProfil,
    serviceModificationUtilisateur,
    serviceModificationPasse,
    serviceModificationAutoDisjonction,
    serviceModificationAutoDEsactivation,
    serviceSuppressionUtilisateur,
    serviceDemande,
    serviceRestauration,
    serviceEffacerNotifications
} from "Services/Utilisateurs";
import { disjonctionAsync } from "ActionsAsync/ActionsAuthAsync";

export const listeProfilsAsync = (idProfil, dispatch, socket) => async envoi => {
    const resultat = await serviceListeProfils(idProfil);
    if (resultat.error) {
        return envoi(disjonctionAsync(dispatch, socket));
    }
    envoi(obtentionListeProfils(resultat.data));
}

export const listeUtilisateursAsync = (dispatch, socket) => async envoi => {
    const resultat = await serviceListeUtilisateurs();
    if (resultat.error) {
        return envoi(disjonctionAsync(dispatch, socket));
    }
    envoi(obtentionListeUtilisateurs(resultat.data));
}

export const listeDPAsync = (idProfil, dispatch, socket) => async envoi => {
    const resultat = await serviceListeDP(idProfil);
    if (resultat.error) {
        return envoi(disjonctionAsync(dispatch, socket));
    }
    envoi(obtentionListeDP(resultat.data));
}

export const validationProfilAsync1 = (nom, postNom, prEnom, email, phone, idProfil) => async envoi => {
    envoi(chargement());
    const resultat = await serviceValidationProfil1(nom, postNom, prEnom, email, phone, idProfil);
    if (resultat.error) {
        return envoi(failliteValidationProfil1(resultat.response.data));
    }
    envoi(validationProfilFaite1());
}

export const validationProfilAsync2 = (no, avenue, quartier, commune, ville, province) => async envoi => {
    envoi(chargement());
    const resultat = await serviceValidationProfil2(no, avenue, quartier, commune, ville, province);
    if (resultat.error) {
        return envoi(failliteValidationProfil2(resultat.response.data));
    }
    envoi(validationProfilFaite2());
}

export const inscriptionAsync = (typeId, dEnomination, idNat, nom, postNom, prEnom, no, avenue, quartier, commune, ville, province, noAgr, dateD, dateF, email, phone) => async envoi => {
    envoi(chargement());
    const resultat = await serviceInscription(typeId, dEnomination, idNat, nom, postNom, prEnom, no, avenue, quartier, commune, ville, province, noAgr, dateD, dateF, email, phone);
    if (resultat.error) {
        return envoi(failliteNouveauProfil(resultat.response.data));
    }
    envoi(nouveauProfilFait());
}

export const ajoutUtilisateurAsync = (idProfil, nom, postNom, prEnom, email, phone, no, avenue, quartier, commune, ville, province, admin, fonction, chefLieu, idProfilService, dispatch, socket) => async envoi => {
    envoi(chargement());
    const resultat = await serviceAjoutUtilisateur(idProfil, nom, postNom, prEnom, email, phone, no, avenue, quartier, commune, ville, province, admin, fonction, chefLieu, idProfilService);
    if (resultat.error) {
        return envoi(failliteAjoutUtilisateur(resultat.response.data));
    }
    envoi(ajoutUtilisateurFait());
    envoi(listeUtilisateursAsync(dispatch, socket));
    if (Object.keys(store.getState().authentification.info).length !== 0) {
        const info = store.getState().authentification.info;
        if (info.TypeService === 1) envoi(listeProfilsAsync(info.Id, dispatch, socket));
    }
}

export const modificationProfilAsync = (idProfil, typeId, dEnomination, idNat, nom, postNom, prEnom, no, avenue,  quartier, commune, ville, province, noAgr, dateD, dateF, email, phone) => async envoi => {
    envoi(chargement());
    const resultat = await serviceModificationProfil(idProfil, typeId, dEnomination, idNat, nom, postNom, prEnom, no, avenue,  quartier, commune, ville, province, noAgr, dateD, dateF, email, phone);
    if (resultat.error) {
        return envoi(failliteModificationProfil(resultat.response.data));
    }
    envoi(modificationProfilFaite());
}

export const modificationUtilisateurAsync = (idProfil, email, phone, no, avenue, quartier, commune, ville, province, admin, statut, fonction, chefLieu, idProfilService, dispatch, socket) => async envoi => {
    envoi(chargement());
    const resultat = await serviceModificationUtilisateur(idProfil, email, phone, no, avenue, quartier, commune, ville, province, admin, statut, fonction, chefLieu, idProfilService);
    if (resultat.error) {
        return envoi(failliteModificationUtilisateur(resultat.response.data));
    }
    envoi(modificationUtilisateurFaite());
    envoi(listeUtilisateursAsync(dispatch, socket));
}

export const modificationPasseAsync = (idProfil, passeActuel, passe, confPasse) => async envoi => {
    envoi(chargement());
    const resultat = await serviceModificationPasse(idProfil, passeActuel, passe, confPasse);
    if (resultat.error) {
        return envoi(failliteModificationPasse(resultat.response.data));
    }
    envoi(modificationPasseFaite());
}

export const modificationAutoDisjonctionAsync = (idProfil, autoDisjonction) => async envoi => {
    const resultat = await serviceModificationAutoDisjonction(idProfil, autoDisjonction);
    if (resultat.error) {
        return envoi(failliteModifAutoDisjonction(resultat.response.data));
    }
    envoi(modifAutoDisjonctionFaite(resultat.data));
}

export const modificationAutoDEsactivationAsync = (idProfil, autoDEsactivation) => async envoi => {
    const resultat = await serviceModificationAutoDEsactivation(idProfil, autoDEsactivation);
    if (resultat.error) {
        return envoi(failliteModifAutoDEsactivation(resultat.response.data));
    }
    envoi(modifAutoDEsactivationFaite(resultat.data));
}

export const suppressionUtilisateurAsync = (session, idProfil, dispatch, socket) => async envoi => {
    envoi(chargement());
    const resultat = await serviceSuppressionUtilisateur(session, idProfil);
    if (resultat.error) {
        return envoi(failliteSuppressionUtilisateur(resultat.response.data));
    }
    envoi(suppressionUtilisateurFaite());
    envoi(listeUtilisateursAsync(dispatch, socket));
}

export const demandeAsync = (email) => async envoi => {
    envoi(chargement());
    const resultat = await serviceDemande(email);
    if (resultat.error) {
        return envoi(failliteDemandeUtilisateur());
    }
    envoi(demandeUtilisateur());
}

export const restaurationAsync = (tokenRest, passe, confPasse) => async envoi => {
    envoi(chargement());
    const resultat = await serviceRestauration(tokenRest, passe, confPasse);
    if (resultat.error) {
        return envoi(failliteModificationPasse(resultat.response.data));
    }
    envoi(modificationPasseFaite());
}

export const effacerNotificationsAsync = (idProfil, dispatch, socket) => async envoi => {
    const resultat = await serviceEffacerNotifications(idProfil);
    if (resultat.error) {
        return envoi(disjonctionAsync(dispatch, socket));
    }
}