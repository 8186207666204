/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useSelector, useDispatch } from "react-redux";
import { setChangeColD } from "Simplificateurs/SimplificateursTable";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "Composants/MDBox";
import MDTypography from "Composants/MDTypography";
import Switch from "@mui/material/Switch";

// Contexte Material Dashboard 2 PRO React
import { useMaterialUIController } from "Contexte";

// Data
import NotificationsEtDialoguesDEclaration from "./Data/NotificationsEtDialoguesDEclaration";
import TableDEclarations from "Vues/DEclarations/Data/TableDEclarations";

function DEclarations() {
  const [controller, dispatch] = useMaterialUIController();
  const {lang} = controller;

  const envoi = useDispatch();

  const tableObj = useSelector((state) => state.table);
  const {changeColD} = tableObj;

  const traiterChangement = e => {
    localStorage.setItem("changeColD", e.target.checked ?? false);
    envoi(setChangeColD());
  };
  
  return (
    <MDBox sx={{ width: '100%' }}>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  {lang === "fr" && "Déclarations"}
                  {lang === "eng" && "Declarations"}
                  <Switch checked={changeColD ?? false} onChange={traiterChangement} />
                  {lang === "fr" && "Notes de débit"}
                  {lang === "eng" && "Debit notes"}
                </MDTypography>
              </MDBox>
              <MDBox p={2} pt={3}>
                <NotificationsEtDialoguesDEclaration />
                <br />
                <TableDEclarations />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox> 
  );
}

export default DEclarations;
