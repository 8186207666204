import {chargement, failliteModifNatureProduit, modifNatureProduitFaite } from "Simplificateurs/SimplificateursNatureProduit";
import { obtentionListeNatureProduits } from "Simplificateurs/SimplificateursTable";
import { serviceListeNatureProduits, serviceModifNatureProduit } from "Services/NatureProduit";
import { disjonctionAsync } from "ActionsAsync/ActionsAuthAsync";

export const listeNatureProduitsAsync = (dispatch, socket) => async envoi => {
    const resultat = await serviceListeNatureProduits();
    if (resultat.error) {
        return envoi(disjonctionAsync(dispatch, socket));
    }
    envoi(obtentionListeNatureProduits(resultat.data));
}

export const modifNatureProduitAsync = (id, redevancePourc, dispatch, socket) => async envoi => {
    envoi(chargement());
    const resultat = await serviceModifNatureProduit(id, redevancePourc);
    if (resultat.error) {
        return envoi(failliteModifNatureProduit(resultat.response.data));
    }
    envoi(modifNatureProduitFaite());
    envoi(listeNatureProduitsAsync(dispatch, socket));
}