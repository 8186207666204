/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  La fonction hexToRgb() vous aide à changer le code de couleur hexadécimal en rgb
  en utilisant la bibliothèque chroma-js.
 */

// chroma-js est une bibliothèque pour toutes sortes de conversions de couleurs et d'échelles de couleurs.
import chroma from "chroma-js";

function hexToRgb(color) {
  return chroma(color).rgb().join(", ");
}

export default hexToRgb;
