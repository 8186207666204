/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Composants du @mui material
//import Grid from "@mui/material/Grid";
//import Card from "@mui/material/Card";

// Composants du Material Dashboard 2 React
import MDBox from "Composants/MDBox";
//import MDTypography from "Composants/MDTypography";
import DashboardLayout from "Composants/LayoutContainers/DashboardLayout";

// Contexte du Material Dashboard 2 React
//import { useMaterialUIController } from "Contexte";

// Données
import IllustrationsGraphiques from "Vues/CP/Data/IllustrationsGraphiques";

function Dashboard() {
  return (
    <DashboardLayout>
      <MDBox sx={{ width: '100%' }}>
        <br />
          <IllustrationsGraphiques />
        <br /><br />
      </MDBox> 
    </DashboardLayout>
  );
}

export default Dashboard;
