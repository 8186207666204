import axios from "axios";
axios.defaults.withCredentials = true;
 
const API_URL = window.location.protocol + "//" + window.location.host;

export const serviceListeProfils = async (idProfil) => {
    try {
        return await axios.get(`${API_URL}/api/utilisateur/profil/liste/${idProfil}`);
    } catch (err) {
        return {
            error: true,
            response: err.response
        };
    }
}

export const serviceListeUtilisateurs = async () => {
    try {
        return await axios.get(`${API_URL}/api/utilisateur/compte/liste`);
    } catch (err) {
        return {
            error: true,
            response: err.response
        };
    }
}

export const serviceListeDP = async (idProfil) => {
    try {
        return await axios.get(`${API_URL}/api/utilisateur/divisionProvinciale/liste/${idProfil}`);
    } catch (err) {
        return {
            error: true,
            response: err.response
        };
    }
}

export const serviceValidationProfil1 = async (nom, postNom, prEnom, email, phone, idProfil) => {
    try {
        return await axios.post(`${API_URL}/api/utilisateur/profil/valider/1`, {nom, postNom, prEnom, email, phone, idProfil});
    } catch (err) {
        return {
            error: true,
            response: err.response
        };
    }
}

export const serviceValidationProfil2 = async (no, avenue, quartier, commune, ville, province) => {
    try {
        return await axios.post(`${API_URL}/api/utilisateur/profil/valider/2`, {no, avenue, quartier, commune, ville, province});
    } catch (err) {
        return {
            error: true,
            response: err.response
        };
    }
}

export const serviceInscription = async (typeId, dEnomination, idNat, nom, postNom, prEnom, no, avenue, quartier, commune, ville, province, noAgr, dateD, dateF, email, phone) => {
    try {
        return await axios.post(`${API_URL}/api/utilisateur/profil/assujetti/nouveau`, {typeId, dEnomination, idNat, nom, postNom, prEnom, no, avenue, quartier, commune, ville, province, noAgr, dateD, dateF, email, phone});
    } catch (err) {
        return {
            error: true,
            response: err.response
        };
    }
};

export const serviceAjoutUtilisateur = async (idProfil, nom, postNom, prEnom, email, phone, no, avenue, quartier, commune, ville, province, admin, fonction, chefLieu, idProfilService) => {
    try {
        return await axios.post(`${API_URL}/api/utilisateur/compte/nouveau`, {idProfil, nom, postNom, prEnom, email, phone, no, avenue, quartier, commune, ville, province, admin, fonction, chefLieu, idProfilService});
    } catch (err) {
        return {
            error: true,
            response: err.response
        };
    }
};

export const serviceConfirmationProfil = async (idProfil) => {
    try {
        return await axios.post(`${API_URL}/api/utilisateur/profil/confirmer`, {idProfil});
    } catch (err) {
        return {
            error: true,
            response: err.response
        };
    }
};

export const serviceObtentionProfil = async (idProfil) => {
    try {
        return await axios.get(`${API_URL}/api/utilisateur/profil/obtenir/${idProfil}`);
    } catch (err) {
        return {
            error: true,
            response: err.response
        };
    }
};

export const serviceModificationProfil = async (idProfil, typeId, dEnomination, idNat, nom, postNom, prEnom, no, avenue,  quartier, commune, ville, province, noAgr, dateD, dateF, email, phone) => {
    try {
        return await axios.post(`${API_URL}/api/utilisateur/profil/modifier`, {idProfil, typeId, dEnomination, idNat, nom, postNom, prEnom, no, avenue,  quartier, commune, ville, province, noAgr, dateD, dateF, email, phone});
    } catch (err) {
        return {
            error: true,
            response: err.response
        };
    }
};

export const serviceModificationUtilisateur = async (idProfil, email, phone, no, avenue, quartier, commune, ville, province, admin, statut, fonction, chefLieu, idProfilService) => {
    try {
        return await axios.post(`${API_URL}/api/utilisateur/compte/modifier`, {idProfil, email, phone, admin, no, avenue, quartier, commune, ville, province, statut, fonction, chefLieu, idProfilService});
    } catch (err) {
        return {
            error: true,
            response: err.response
        };
    }
};

export const serviceModificationPasse = async (idProfil, passeActuel, passe, confPasse) => {
    try {
        return await axios.post(`${API_URL}/api/utilisateur/compte/passe/modifier`, {idProfil, passeActuel, passe, confPasse});
    } catch (err) {
        return {
            error: true,
            response: err.response
        };
    }
};

export const serviceModificationAutoDisjonction = async (idProfil, autoDisjonction) => {
    try {
        return await axios.post(`${API_URL}/api/utilisateur/compte/autoDisjonction/modifier`, {idProfil, autoDisjonction});
    } catch (err) {
        return {
            error: true,
            response: err.response
        };
    }
};

export const serviceModificationAutoDEsactivation = async (idProfil, autoDEsactivation) => {
    try {
        return await axios.post(`${API_URL}/api/utilisateur/compte/autoDEsactivation/modifier`, {idProfil, autoDEsactivation});
    } catch (err) {
        return {
            error: true,
            response: err.response
        };
    }
};

export const serviceSuppressionUtilisateur = async (session, idProfil) => {
    try {
        return await axios.post(`${API_URL}/api/utilisateur/compte/supprimer`, {session, idProfil});
    } catch (err) {
        return {
            error: true,
            response: err.response
        };
    }
};


export const serviceDemande = async (email) => {
    try {
        return await axios.post(`${API_URL}/api/utilisateur/compte/passe/demander`, {email});
    } catch (err) {
        return {
            error: true,
            response: err.response
        };
    }
};

export const serviceRestauration = async (tokenRest, passe, confPasse) => {
    try {
        return await axios.put(`${API_URL}/api/utilisateur/compte/passe/restaurer/${tokenRest}`, {passe, confPasse});
    } catch (err) {
        return {
            error: true,
            response: err.response
        };
    }
};

export const serviceEffacerNotifications = async (idProfil) => {
    try {
        return await axios.post(`${API_URL}/api/utilisateur/compte/notification/effacer`, {idProfil});
    } catch (err) {
        return {
            error: true,
            response: err.response
        };
    }
};

