/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";

// Composants du react-router-dom
import { useLocation } from "react-router-dom";

// prop-types est une bibliothèque pour la vérification de type d'accessoires
import PropTypes from "prop-types";

// Composants du Material Dashboard 2 React
import MDBox from "Composants/MDBox";

// Contexte du Material Dashboard 2 PRO React
import { useMaterialUIController, setLayout } from "Contexte";

function PageLayout({ background = "default", children }) {
  const [, dispatch] = useMaterialUIController();
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, "page");
  }, [pathname]);

  return (
    <MDBox
      width="100vw"
      height="100%"
      minHeight="100vh"
      bgColor={background}
      sx={{ overflowX: "hidden" }}
    >
      {children}
    </MDBox>
  );
}

// Vérification de type d'accessoires pour PageLayout
PageLayout.propTypes = {
  background: PropTypes.oneOf(["white", "light", "default"]),
  children: PropTypes.node.isRequired,
};

export default PageLayout;
