/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";

// Composants du react-router-dom
import { useLocation, NavLink } from "react-router-dom";

// prop-types est une bibliothèque pour la vérification de type d'accessoires
import PropTypes from "prop-types";

// Composants du @mui material
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";

// @mui icons
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

// Composants du Material Dashboard 2 React
import MDBox from "Composants/MDBox";
import MDTypography from "Composants/MDTypography";
import SidenavCollapse from "Composants/Sidenav/SidenavCollapse";
import MDButton from "Composants/MDButton";

// Styles personnalisés pour le Sidenav
import SidenavRoot from "Composants/Sidenav/SidenavRoot";
import sidenavLogoLabel from "Composants/Sidenav/styles/sidenav";

// Contexte Material Dashboard 2 PRO React
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "Contexte";

function Sidenav({ color = "info", brand = "", brandName, routes, ...rest }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, lang } = controller;
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // Une fonction qui définit l'état mini du sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
    }

    /** 
      L'écouteur d'événement qui appelle la fonction handleMiniSidenav lors du redimensionnement de la fenêtre.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Appelez la fonction handleMiniSidenav pour définir l'état avec la valeur initiale.
    handleMiniSidenav();

    // Supprimer l'écouteur d'événement lors du nettoyage
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Rendre toutes les routes à partir de routes.js (Tous les éléments visibles sur le Sidenav)
  const renderRoutes = routes.map(({ type, nom, name, icon, title, noCollapse, key, href, route }) => {
    let returnValue;

    if (type === "collapse") {
      returnValue = href ? (
        <Link
          href={href}
          key={key}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavCollapse
            name={lang === "fr" ? nom : name}
            icon={icon}
            active={key === collapseName}
            noCollapse={noCollapse}
          />
        </Link>
      ) : (
        <NavLink key={key} to={route}>
          <SidenavCollapse 
            name={lang === "fr" ? nom : name}
            icon={icon} 
            active={key === collapseName} 
          />
        </NavLink>
      );
    } else if (type === "title") {
      returnValue = (
        <MDTypography
          key={key}
          color={textColor}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {title}
        </MDTypography>
      );
    } else if (type === "divider") {
      returnValue = (
        <Divider
          key={key}
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />
      );
    }

    return returnValue;
  });

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox component={NavLink} to="/" display="flex" alignItems="center">
          {brand && <MDBox component="img" src={brand} alt="Brand" width={200} height={75} />}
          <MDBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
              {brandName}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>{renderRoutes}</List>
      <MDBox p={2} mt="auto" ml={-5}>
        <Grid container spacing={6} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
          <Grid item xs={2}>
            <MDButton 
              component="a"
              href="https://twitter.com/"
              target="_blank"
              rel="noreferrer" 
              variant="gradient" 
              color="light" 
              iconOnly
            >
              <TwitterIcon color="inherit" />
            </MDButton>
          </Grid>
          <Grid item xs={2}>
            <MDButton 
              component="a"
              href="https://web.facebook.com/"
              target="_blank"
              rel="noreferrer" 
              variant="gradient" 
              color="light" 
              iconOnly
            >
              <FacebookIcon color="inherit" />
            </MDButton>
          </Grid>
          <Grid item xs={2}>
            <MDButton 
              component="a"
              href="https://cd.linkedin.com/"
              target="_blank"
              rel="noreferrer" 
              variant="gradient" 
              color="light" 
              iconOnly
            >
              <LinkedInIcon color="inherit" />
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
    </SidenavRoot>
  );
}

// Vérification de type d'accessoires
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
