/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import DOMPurify from 'dompurify';
import Modal from "react-modal";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import fr from "react-phone-input-2/lang/fr.json";
import moment from "moment";
import { 
  ouvrirFDialogueInscription,
  fermerFDialogueInscription,
  fermerNotifAjoutP,
  chargement,
  failliteConfirmationProfil,
  confirmationProfilFaite,
  obtentionProfilFaite,
  fermerNotifModifP,
  effacerErreurProfil,
  effacerConfirmationProfil,
  effacerObtentionProfil,
  ouvrirFDialogueDemande, 
  fermerFDialogueDemande,
  fermerNotifDemande,
  initialOui,
  initialNon
} from "Simplificateurs/SimplificateursUtilisateur";
import { serviceConfirmationProfil, serviceObtentionProfil } from "Services/Utilisateurs";
import { connexionAsync }from "ActionsAsync/ActionsAuthAsync";
import { listeLieuxAsync } from "ActionsAsync/ActionsUtilitaireAsync";
import { inscriptionAsync, modificationProfilAsync, demandeAsync }from "ActionsAsync/ActionsUtilisateurAsync";
import Rebours1 from "Vues/Authentication/Connexion/Rebours1"
import Rebours2 from "Vues/Authentication/Connexion/Rebours2"

// Composants du @mui material
import Card from "@mui/material/Card";
import Snackbar from '@mui/material/Snackbar';
import Grid from "@mui/material/Grid";
/*import Switch from "@mui/material/Switch";*/
import Icon from "@mui/material/Icon";
import IconButton from '@mui/material/IconButton';
import Tooltip from "@mui/material/Tooltip";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Select from '@mui/material/Select';

// Composants du Material Dashboard 2 React
import MDBox from "Composants/MDBox";
import MDTypography from "Composants/MDTypography";
import MDInput from "Composants/MDInput";
import MDButton from "Composants/MDButton";

// Composants de mise en page d'authentification
import Base from "Vues/Authentication/Composants/Base";

// Contexte du Material Dashboard 2 React
import { useMaterialUIController, setLang } from "Contexte";

// Images
import bgImage from "Outils/images/bg-profile.jpeg";
import brand from "Outils/images/logo.png";

import { Notification, styleSelect, StyledAutocomplete, styleModalFull, styleModal430 } from "Vues/Utilitaires";

let listeTypeIds = require('../../../listeTypeIds.json');

function Connexion() {
  //const [rememberMe, setRememberMe] = useState(false);
  //const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, lang, socket } = controller;

  const envoi = useDispatch();

  const authObj = useSelector((state) => state.authentification);
  const disabled1 = authObj.disabled;
  const erreurConnexion = authObj.erreurConnexion;
  const utilitaireObj = useSelector((state) => state.utilitaire);
  const {chargementLieux, listeLieux} = utilitaireObj;
  const utilisateurObj = useSelector((state) => state.utilisateur);
  const disabled2 = utilisateurObj.disabled;
  const { 
    vertical, 
    horizontal,
    ouvrir0, 
    erreurNouveauProfil, 
    notifAjoutProfil,
    erreurModificationProfil,
    notifModifProfil,
    confirmationProfil,
    obtentionProfil,
    ouvrir1,  
    erreurDemande,
    notifDemande,
    initial
  } = utilisateurObj;

  useEffect(() => {
    const minuteurObtentionListeLieux = setTimeout(() => {
      envoi(listeLieuxAsync(dispatch, socket));
    }, 1000);
    return () => {
      clearTimeout(minuteurObtentionListeLieux);
    };
  }, []);

  const [montrerPasse, assignerMontrerPasse] = useState(false);
  const traiterClickMontrerPasse = () => assignerMontrerPasse((show) => !show);
  const traiterMouseDownPasse = (e) => {
    e.preventDefault();
  };

  const traiterOuvrir0 = () => envoi(ouvrirFDialogueInscription());
  const traiterFermer0 = () => {
    envoi(fermerFDialogueInscription());
    envoi(effacerErreurProfil());
    envoi(effacerConfirmationProfil());
    envoi(effacerObtentionProfil());
    envoi(initialOui());
    assignerIdProfil("");
    assignerTypeId("");
    assignerDEnomination("");
    assignerIdNat("");
    assignerNom("");
    assignerPostNom("");
    assignerPrEnom("");
    assignerProvince(null);
    assignerVille(null);
    assignerCommune(null);
    assignerQuartier("");
    assignerAvenue("");
    assignerNo("");
    assignerNoAgr("");
    assignerDateD(`${aujourd8}`);
    assignerDateF(`${aujourd8}`);
    assignerEmail("");
    assignerPhone("");
  }
  const traiterFermerNotification0 = () => {
    envoi(fermerNotifAjoutP());
    envoi(fermerFDialogueInscription());
    envoi(effacerErreurProfil());
    envoi(effacerConfirmationProfil());
    envoi(effacerObtentionProfil());
    envoi(initialOui());
    assignerIdProfil("");
    assignerTypeId("");
    assignerDEnomination("");
    assignerIdNat("");
    assignerNom("");
    assignerPostNom("");
    assignerPrEnom("");
    assignerProvince(null);
    assignerVille(null);
    assignerCommune(null);
    assignerQuartier("");
    assignerAvenue("");
    assignerNo("");
    assignerNoAgr("");
    assignerDateD(`${aujourd8}`);
    assignerDateF(`${aujourd8}`);
    assignerEmail("");
    assignerPhone("");
  };

  const traiterOuvrir1 = () => envoi(ouvrirFDialogueDemande());
  const traiterFermer1 = () => {
    envoi(fermerFDialogueDemande());
    assignerEmail("");
  }
  const traiterFermerNotification1 = () => {
    envoi(fermerNotifDemande());
    assignerEmail("");
  };

  const traiterFermerNotification2 = () => {
    envoi(fermerNotifModifP());
    envoi(fermerFDialogueInscription());
    envoi(effacerConfirmationProfil());
    envoi(effacerObtentionProfil());
    envoi(initialOui())
    assignerIdProfil("");
    assignerTypeId("");
    assignerDEnomination("");
    assignerIdNat("");
    assignerNom("");
    assignerPostNom("");
    assignerPrEnom("");
    assignerProvince(null);
    assignerVille(null);
    assignerCommune(null);
    assignerQuartier("");
    assignerAvenue("");
    assignerNo("");
    assignerNoAgr("");
    assignerDateD(`${aujourd8}`);
    assignerDateF(`${aujourd8}`);
    assignerEmail("");
    assignerPhone("");
  }

  const identifiant = variableFormulaire("");
  const passe = variableFormulaire("");
  const traiterConnexion = async (e) => {
    e.preventDefault();
    const cleanIdentifiant = DOMPurify.sanitize(identifiant.value, {
      ALLOWED_CHARS: /^[A-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Z0-9](?:[A-Z0-9-]*[A-Z0-9])?\.)+[A-Z]{2,}$/i
    });
    await envoi(connexionAsync(cleanIdentifiant, passe.value, dispatch));
  };

  const [typeId, assignerTypeId] = useState("");
  const traiterTypeId = e => {
    assignerTypeId(e.target.value);
  };

  const patternId = new RegExp(/^[A-Z0-9.-\s\/]{3,50}$/);
  const [dEnomination, assignerDEnomination] = useState("");
  const traiterDEnomination = e => {
    let target = e.target;
    const cursor = e.target.selectionStart;
    assignerDEnomination((e.target.value).replace(/[^a-zA-Z0-9.-\s\/]/gi, "").toUpperCase());
    setTimeout(() => {
      target.setSelectionRange(cursor, cursor);
    }, 10);
  };
  const [idNat, assignerIdNat] = useState("");
  const traiterIdNat = e => {
    envoi(effacerErreurProfil());
    let target = e.target;
    const cursor = e.target.selectionStart;
    assignerIdNat((e.target.value).replace(/[^a-zA-Z0-9.-\s\/]/gi, "").toUpperCase());
    setTimeout(() => {
      target.setSelectionRange(cursor, cursor);
    }, 10);
  };

  const patternNoms = new RegExp(/^[A-Z.-\s]{3,20}$/);
  const [nom, assignerNom] = useState("");
  const traiterNom = e => {
    let target = e.target;
    const cursor = e.target.selectionStart;
    assignerNom((e.target.value).replace(/[^a-zA-Z.-\s]/gi, "").toUpperCase());
    setTimeout(() => {
      target.setSelectionRange(cursor, cursor);
    }, 10);
  };
  const [postNom, assignerPostNom] = useState("");
  const traiterPostNom = e => {
    let target = e.target;
    const cursor = e.target.selectionStart;
    assignerPostNom((e.target.value).replace(/[^a-zA-Z.-\s]/gi, "").toUpperCase());
    setTimeout(() => {
      target.setSelectionRange(cursor, cursor);
    }, 10);
  };
  const [prEnom, assignerPrEnom] = useState("");
  const traiterPrEnom = e => {
    let target = e.target;
    const cursor = e.target.selectionStart;
    assignerPrEnom((e.target.value).replace(/[^a-zA-Z.-\s]/gi, "").toUpperCase());
    setTimeout(() => {
      target.setSelectionRange(cursor, cursor);
    }, 10);
  };
  const [listeVilles, assignerListeVilles] = useState([]);
  const [province, assignerProvince] = useState(null);
  const traiterProvince = (event, nouvelleValeur) => {
    assignerVille(null);
    assignerProvince(nouvelleValeur);
    const villesArray = listeLieux.filter(province => province.Nom === nouvelleValeur.Nom)[0].VilleTerritoires;
    assignerListeVilles([...villesArray].sort((a, b) => a.Nom.localeCompare(b.Nom)) || []);
  };
  const [listeCommunes, assignerListeCommunes] = useState([]);
  const [ville, assignerVille] = useState(null);
  const traiterVille = (event, nouvelleValeur) => {
    assignerCommune(null);
    assignerVille(nouvelleValeur);
    const communesArray = listeVilles.filter(ville => ville.Nom === nouvelleValeur.Nom)[0].CommuneSecteurs;
    assignerListeCommunes([...communesArray].sort((a, b) => a.Nom.localeCompare(b.Nom)) || []);
  };
  const [commune, assignerCommune] = useState(null);
  const traiterCommune = (event, nouvelleValeur) => {
    assignerCommune(nouvelleValeur);
  };
  const patternAdresse = new RegExp(/^[A-Z0-9'.-\s\/]{3,20}$/);
  const [quartier, assignerQuartier] = useState("");
  const traiterQuartier = e => {
    let target = e.target;
    const cursor = e.target.selectionStart;
    assignerQuartier((e.target.value).replace(/[^a-zA-Z0-9'.-\s\/]/gi, "").toUpperCase());
    setTimeout(() => {
      target.setSelectionRange(cursor, cursor);
    }, 10);
  };
  const [avenue, assignerAvenue] = useState("");
  const traiterAvenue = e => {
    let target = e.target;
    const cursor = e.target.selectionStart;
    assignerAvenue((e.target.value).replace(/[^a-zA-Z0-9'.-\s\/]/gi, "").toUpperCase());
    setTimeout(() => {
      target.setSelectionRange(cursor, cursor);
    }, 10);
  };
  const patternNo = new RegExp(/^(?![A-Z])[0-9A-Z]{1,6}$/);
  const [no, assignerNo] = useState("");
  const traiterNo = e => {
    let target = e.target;
    const cursor = e.target.selectionStart;
    assignerNo((e.target.value).replace(/[^a-zA-Z0-9]/gi, "").toUpperCase());
    setTimeout(() => {
      target.setSelectionRange(cursor, cursor);
    }, 10);
  };
  const [noAgr, assignerNoAgr] = useState("");
  const traiterNoAgr = e => {
    envoi(effacerErreurProfil());
    let target = e.target;
    const cursor = e.target.selectionStart;
    assignerNoAgr((e.target.value).replace(/[^a-zA-Z0-9.-\s\/]/gi, "").toUpperCase());
    setTimeout(() => {
      target.setSelectionRange(cursor, cursor);
    }, 10);
  };
  var aujourd8 = new Date();
  var jj = String(aujourd8.getDate()).padStart(2, '0');
  var mm = String(aujourd8.getMonth() + 1).padStart(2, '0');
  var yyyy = aujourd8.getFullYear();
  aujourd8 = yyyy + '-' + mm + '-' + jj;
  const [dateD, assignerDateD] = useState(`${aujourd8}`);
  const traiterDateD = e => {
    assignerDateD(e.target.value);
  };
  const [dateF, assignerDateF] = useState(`${aujourd8}`);
  const traiterDateF = e => {
    assignerDateF(e.target.value);
  };

  const patternEmail = new RegExp(/^[A-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Z0-9](?:[A-Z0-9-]*[A-Z0-9])?\.)+[A-Z]{2,}$/i);
  const [email, assignerEmail] = useState("");
  const traiterEmail = e => {
    envoi(effacerErreurProfil());
    assignerEmail((e.target.value).toLowerCase());
  };
  const patternPhone = new RegExp(/^(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{4,12}$/);
  const [phone, assignerPhone] = useState("");

  const [idProfil, assignerIdProfil] = useState("");
  const traiterIdProfil = e => {
    assignerIdProfil((e.target.value).replace(/[^a-zA-Z0-9-]/gi, "").toLowerCase());
    envoi(effacerConfirmationProfil());
    envoi(effacerObtentionProfil());
  };

  const cleanTypeId = DOMPurify.sanitize(typeId, {
    ALLOWED_CHARS: /^[A-Z0-9'.-\s\/]{3,100}$/
  });
  const cleanDEnomination = DOMPurify.sanitize(dEnomination, {
    ALLOWED_CHARS: /^[A-Z0-9.-\s\/]{3,50}$/
  });
  const cleanIdNat = DOMPurify.sanitize(idNat, {
    ALLOWED_CHARS: /^[A-Z0-9.-\s\/]{3,50}$/
  });
  const cleanNom = DOMPurify.sanitize(nom, {
    ALLOWED_CHARS: /^[A-Z.-\s]{3,20}$/
  });
  const cleanPostNom = DOMPurify.sanitize(postNom, {
    ALLOWED_CHARS: /^[A-Z.-\s]{3,20}$/
  });
  const cleanPrEnom = DOMPurify.sanitize(prEnom, {
    ALLOWED_CHARS: /^[A-Z.-\s]{3,20}$/
  });
  const cleanNo = DOMPurify.sanitize(no, {
    ALLOWED_CHARS: /^(?![A-Z])[0-9A-Z]{1,6}$/
  });
  const cleanAvenue = DOMPurify.sanitize(avenue, {
    ALLOWED_CHARS: /^[A-Z0-9'.-\s\/]{3,20}$/
  });
  const cleanQuartier = DOMPurify.sanitize(quartier, {
    ALLOWED_CHARS: /^[A-Z0-9'.-\s\/]{3,20}$/
  });
  const cleanCommune = DOMPurify.sanitize(commune?.Nom, {
    ALLOWED_CHARS: /^[A-Z0-9'.-\s\/]{3,20}$/
  });
  const cleanVille = DOMPurify.sanitize(ville?.Nom, {
    ALLOWED_CHARS: /^[A-Z0-9'.-\s\/]{3,20}$/
  });
  const cleanProvince = DOMPurify.sanitize(province?.Nom, {
    ALLOWED_CHARS: /^[A-Z0-9'.-\s\/]{3,20}$/
  });
  const cleanNoAgr = DOMPurify.sanitize(noAgr, {
    ALLOWED_CHARS: /^[A-Z0-9.-\s\/]{3,50}$/
  });
  const cleanDateD = DOMPurify.sanitize(dateD, {
    ALLOWED_CHARS: /^\d{4}-\d{2}-\d{2}$/
  });
  const cleanDateF = DOMPurify.sanitize(dateF, {
    ALLOWED_CHARS: /^\d{4}-\d{2}-\d{2}$/
  });
  const cleanEmail = DOMPurify.sanitize(email, {
    ALLOWED_CHARS: /^[A-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Z0-9](?:[A-Z0-9-]*[A-Z0-9])?\.)+[A-Z]{2,}$/i
  });
  const cleanPhone = DOMPurify.sanitize(phone, {
    ALLOWED_CHARS: /^(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{4,12}$/
  });
  const cleanIdProfil = DOMPurify.sanitize(idProfil, {
    ALLOWED_CHARS: /[^a-z0-9-]$/
  });

  const traiterInscription = async (e) => {
    e.preventDefault();
    if (Object.keys(obtentionProfil).length === 0) {
      await envoi(inscriptionAsync(cleanTypeId, cleanDEnomination, cleanIdNat, cleanNom, cleanPostNom, cleanPrEnom, cleanNo.trim(), cleanAvenue, cleanQuartier, cleanCommune, cleanVille, cleanProvince, cleanNoAgr, cleanDateD.trim(), cleanDateF.trim(), cleanEmail.trim(), cleanPhone.trim()));
    }
    else {
      await envoi(modificationProfilAsync(cleanIdProfil.trim(), cleanTypeId, cleanDEnomination, cleanIdNat, cleanNom, cleanPostNom, cleanPrEnom, cleanNo.trim(), cleanAvenue, cleanQuartier, cleanCommune, cleanVille, cleanProvince, cleanNoAgr, cleanDateD.trim(), cleanDateF.trim(), cleanEmail.trim(), cleanPhone.trim()));
    }
  };

  const traiterDemande = async (e) => {
    e.preventDefault();
    await envoi(demandeAsync(cleanEmail.trim()));
  };

  function queDesChiffres(str) {
    return /^[0-9]+$/.test(str);
  }

  const traiterConfirmationProfil = async (e) => {
    e.preventDefault();
    envoi(chargement());
    const resultat = await serviceConfirmationProfil(cleanIdProfil.trim());
    if (resultat.error) {
        await envoi(failliteConfirmationProfil(resultat.response.data));
        return;
    }
    await envoi(confirmationProfilFaite(resultat.data));
    const { msg } = resultat.data;
    if (queDesChiffres(msg)) {
        const tempsActuelInitial = Date.now();
        const diffInitiale = (parseInt(msg)) - tempsActuelInitial;
        const heuresInitiales = Math.floor((diffInitiale / 1000 / 60 / 60) % 60);
        const minutesInitiales = Math.floor((diffInitiale / 1000 / 60) % 60);
        const secondesInitiales = Math.floor((diffInitiale / 1000) % 60);
        if (heuresInitiales + minutesInitiales + secondesInitiales > 0) {
          const resultat = await serviceObtentionProfil(cleanIdProfil.trim());
          await envoi(obtentionProfilFaite(resultat.data));
          assignerTypeId(resultat.data.Assujetti.TypeId);
          assignerDEnomination(resultat.data.DEnomination);
          assignerIdNat(resultat.data.Assujetti.IdNat ?? "");
          assignerNom(resultat.data.Nom ?? "");
          assignerPostNom(resultat.data.PostNom ?? "");
          assignerPrEnom(resultat.data.PrEnom ?? "");
          assignerProvince({Nom: resultat.data.Province});
          const villesArray = listeLieux.filter(province => province.Nom === resultat.data.Province)[0].VilleTerritoires;
          assignerListeVilles([...villesArray].sort((a, b) => a.Nom.localeCompare(b.Nom)) || []);
          assignerVille({Nom: resultat.data.Ville});
          const communesArray = villesArray.filter(ville => ville.Nom === resultat.data.Ville)[0].CommuneSecteurs;
          assignerListeCommunes([...communesArray].sort((a, b) => a.Nom.localeCompare(b.Nom)) || []);
          assignerCommune({Nom: resultat.data.Commune});
          assignerQuartier(resultat.data.Quartier);
          assignerAvenue(resultat.data.Avenue);
          assignerNo(resultat.data.No);
          assignerNoAgr(resultat.data.Assujetti.NoAgr);
          assignerDateD(moment(resultat.data.Assujetti.DateD).format('YYYY-MM-DD'));
          assignerDateF(moment(resultat.data.Assujetti.DateF).format('YYYY-MM-DD'));
          assignerEmail(resultat.data.Email);
          assignerPhone(resultat.data.Phone);
          envoi(initialNon());
        }
    }
  };

  const traiterIgnorer = () => {
    envoi(initialNon());
  };

  const traiterModLang = e => {
    localStorage.setItem("lang", JSON.stringify(e.target.value));
    setLang(dispatch, e.target.value);
  };

  return (
    <Base image={bgImage}>
      <Snackbar 
        anchorOrigin={{ vertical, horizontal }} 
        open={notifAjoutProfil} 
        autoHideDuration={6000} 
        onClose={traiterFermerNotification0}
        key={vertical + horizontal + "0"}
      >
        <Notification onClose={traiterFermerNotification0} severity="success" sx={{ width: "100%", bgcolor: "background.paper"}}>
          {lang === "fr" && "Profil ajouté avec succès"}
          {lang === "eng" && "Profile successfully added"}
        </Notification>
      </Snackbar>
      <Snackbar 
        anchorOrigin={{ vertical, horizontal }} 
        open={notifDemande} 
        autoHideDuration={6000} 
        onClose={traiterFermerNotification1}
        key={vertical + horizontal + "1"}
      >
        <Notification onClose={traiterFermerNotification1} severity="success" sx={{ width: "100%", bgcolor: "background.paper"}}>
          {lang === "fr" && "Email envoyé avec succès"}
          {lang === "eng" && "Email successfully sent"}
        </Notification>
      </Snackbar>
      <Snackbar 
        anchorOrigin={{ vertical, horizontal }} 
        open={notifModifProfil} 
        autoHideDuration={6000} 
        onClose={traiterFermerNotification2}
        key={vertical + horizontal + "2"}
      >
        <Notification onClose={traiterFermerNotification2} severity="warning" sx={{ width: "100%", bgcolor: "background.paper"}}>
          {lang === "fr" && "Profil modifié avec succès"}
          {lang === "eng" && "Profile successfully edited"}
        </Notification>
      </Snackbar>
      <Card sx={{height: 540}}>
        <MDBox
          variant="gradient"
          bgColor="white"
          borderRadius="lg"
          coloredShadow="light"
          mx={2}
          mt={-3}
          p={0}
          mb={1}
          textAlign="center"
        >
          <MDBox component="img"  src={brand}  alt="logo" width={100} height={80} sx={{marginTop: "15px"}} />
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={traiterConnexion} autoComplete="off">
            <MDBox mb={2}>
              <MDInput type="text" required label="E-mail" {...identifiant} fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <FormControl sx={{mt: 1, width: "100%"}} variant="outlined">
                <InputLabel htmlFor="passe">
                  {lang === "fr" && "Mot de passe *"}
                  {lang === "eng" && "Password *"}
                </InputLabel>
                <OutlinedInput 
                  id="passe"
                  type={montrerPasse ? "text" : "password"} 
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={traiterClickMontrerPasse}
                        onMouseDown={traiterMouseDownPasse}
                        edge="end"
                        style={{color: darkMode ? "#7b809a" : "#808080"}}
                      >
                        {montrerPasse ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  required 
                  label={lang === "fr" ? "Mot de passe *" : "Password *"}
                  {...passe} 
                />
              </FormControl>
            </MDBox>
            <MDBox display="flex" alignItems="center" mt={4} ml={-1}>
              {/*<Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>*/}
              <MDTypography variant="button" color="text" ml={1}>
                {lang === "fr" ? "Mot de passe oublié ?" : "Forgot your password?"}
                <MDTypography
                  component="a"
                  href="#"
                  variant="button"
                  color="error"
                  fontWeight="medium"
                  textGradient
                  onClick={traiterOuvrir1}
                >
                  &nbsp;
                  {lang === "fr" && "Par ici"}
                  {lang === "eng" && "This way"}
                </MDTypography>
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton disabled={disabled1} type="submit" variant="gradient" color="info" fullWidth>
                {lang === "fr" && "Connexion"}
                {lang === "eng" && "Login"}
              </MDButton>
            </MDBox>
            <MDBox sx={{height: "30px"}}>
              {erreurConnexion === "Champ(s) vide(s) dans la requête" && 
                <MDTypography variant="button" color="error" mt={1}>
                  {lang === "fr" && "Champ(s) vide(s) dans la requête"}
                  {lang === "eng" && "Empty field(s) in the query"}
                </MDTypography>
              }
              {erreurConnexion == "Identifiant ou mot de passe incorrect" && 
                <MDTypography variant="button" color="error" mt={1}>
                  {lang === "fr" && "Identifiant ou mot de passe incorrect"}
                  {lang === "eng" && "Incorrect username or password"}
                </MDTypography>
              }
              {erreurConnexion === "Désolé, compte suspendu" && 
                <MDTypography variant="button" color="error" mt={1}>
                  {lang === "fr" && "Désolé, compte suspendu"}
                  {lang === "eng" && "Sorry, account suspended"}
                </MDTypography>
              }
              {erreurConnexion !== null && queDesChiffres(erreurConnexion) && 
                <MDTypography variant="button" color="error" mt={1}>
                  <Rebours1 temps={erreurConnexion} />
                </MDTypography>
              }
            </MDBox>
          </MDBox>
          <MDBox mt={1} mb={1} textAlign="center">
            <MDTypography variant="button" color="text">
              {lang === "fr" && "Vous n'avez pas de compte ?"}
              {lang === "eng" && "You do not have an account?"}
              &nbsp;
              <MDTypography
                component="a"
                href="#"
                variant="button"
                color="success"
                fontWeight="medium"
                textGradient
                onClick={traiterOuvrir0}
              >
                {lang === "fr" ? "S'inscrire" : "Register"}
              </MDTypography>
            </MDTypography>
          </MDBox>
          <MDBox mt={2} sx={{height: 45, width: 105}}>
            <FormControl sx={{height: 45, width: "100%", pr: 5.5}}>
              <InputLabel id="select-insert" htmlFor="select-insert2">
                LANG
              </InputLabel>
              <Select 
                sx={{height: 46}} 
                inputProps={{id: "select-insert2"}}
                required
                label="LANG"
                value={lang}
                onChange={traiterModLang}
              >
                <MenuItem key={1} value="fr" sx={{ display: "flex", gap: 1 }}>
                  <img src={require(`../../../drapeaux/128x128/fr.png`)} alt="fr" width={18} />
                  <span style={styleSelect}>
                    FR
                  </span>        
                </MenuItem>    
                <MenuItem key={2} value="eng" style={{marginTop: "20px"}} sx={{ display: "flex", gap: 1 }}>
                  <img src={require(`../../../drapeaux/128x128/gb.png`)} alt="gb" width={18} />
                  <span style={styleSelect}>
                    {lang === "fr" && "ANG"}
                    {lang === "eng" && "ENG"}
                  </span>        
                </MenuItem>  
              </Select>
            </FormControl>
          </MDBox>
        </MDBox>
        <Modal isOpen={ouvrir0} onRequestClose={traiterFermer0} ariaHideApp={false} style={initial ? styleModal430 : styleModalFull}>
          <MDBox 
            sx={{padding: 3}}
            variant="gradient" 
            bgColor={darkMode ? "dark": "white"}
            borderRadius="lg"
            coloredShadow={darkMode ? "dark": "light"}
          >
            <Grid container spacing={3} alignItems="center">
              <Grid item>
                {initial && 
                  <MDBox component="form" role="form" onSubmit={traiterConfirmationProfil} sx={{ml: 2.5, textAlign: "center"}} autoComplete="off">
                    <MDTypography display="block" variant="button" color="text" fontWeight="medium">
                      {lang === "fr" && "Disposez-vous d'un ID de profil ? "}
                      {lang === "eng" && "Do you have a profile ID?"}
                      &nbsp;
                      <Tooltip title={lang === "fr" ? "Vous pouvez utiliser votre ID de profil pour modifier une ou plusieurs informations contenues dans le formulaire d'inscription que vous avez eu à remplir plus tôt. Attention! ceci n'est possible que dans les 24 heures suivant votre inscription. La validation de vos informations se fera dans les 48 heures. Ensuite vous recevrez un e-mail avec vos identifiants." : "You can use your profile ID to edit one or more information contained in the registration form that you had to fill out earlier. Attention! this is only possible within 24 hours of your registration. Validation of your information will be done within 48 hours. You will then receive an email with your login details."}>
                        <Icon color="success">info</Icon>
                      </Tooltip>
                    </MDTypography>
                    <br />
                    <MDBox>
                      <Tooltip title={lang === "fr" ? "Si vous ne disposez pas d'un ID de profil, veuillez sauter cette case et cliquer sur ignorer." : "If you do not have a profile ID, please skip this box and click ignore."}>
                        <MDInput 
                          type="text" 
                          label={lang === "fr" ? "ID de profil" : "Profile ID"}
                          value={idProfil} 
                          onChange={traiterIdProfil} 
                          sx={{width: 300}}
                          required
                        />
                      </Tooltip>
                    </MDBox>
                    <MDBox mt={4} sx={{height: 65}}>
                      <MDBox mb={1} >
                        <MDButton disabled={disabled2} type="submit" variant="gradient" color="info">
                          {lang === "fr" && "Procéder"}
                          {lang === "eng" && "Proceed"}
                        </MDButton>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <MDButton variant="gradient" color="info" onClick={traiterIgnorer}>
                          {lang === "fr" && "Ignorer"}
                          {lang === "eng" && "Ignore"}
                        </MDButton>
                      </MDBox>
                      {confirmationProfil === "ID de profil invalid" && 
                        <MDTypography variant="button" color="error">
                          {lang === "fr" && "ID de profil invalid"}
                          {lang === "eng" && "Invalid profile ID"}
                        </MDTypography>
                      }
                      {Object.keys(obtentionProfil).length === 0 && queDesChiffres(confirmationProfil) && 
                        <MDTypography variant="button" color="error">
                          <Rebours2 temps={confirmationProfil} />
                        </MDTypography>
                      }
                    </MDBox>
                  </MDBox>
                }
                {!initial && 
                  <MDBox lineHeight={1}>
                    <MDTypography variant="h6" fontWeight="medium">
                      {lang === "fr" && "FORMULAIRE D'INSCRIPTION"}
                      {lang === "eng" && "REGISTRATION FORM"}
                    </MDTypography>
                    <MDTypography variant="button" color="text" fontWeight="regular">
                      {lang === "fr" && "Notez bien que les caractères invalides n'apparaîtront pas dans les champs d'entrée."}
                      {lang === "eng" && "Please note that invalid characters will not appear in input fields."}
                      &nbsp;
                      <Tooltip title={lang === "fr" ? "Pas d'accents, de tréma ou de cédille et autres caractères spéciaux en dehors de certaines ponctuations tels que le point (.), le trait d'union (-) ainsi que la barre oblique(/) ou l'espace ( ) dans certains cas." : "No accents, diaeresis or cedilla and other special characters apart from certain punctuation such as the period (.), the hyphen (-) as well as the slash (/) or the space ( ) in some cases."}>
                        <Icon color="success">info</Icon>
                      </Tooltip>
                    </MDTypography>
                    &nbsp;&nbsp;&nbsp;
                    {Object.keys(obtentionProfil).length === 0 && <MDTypography variant="button" color="text" fontWeight="regular">
                      {lang === "fr" && "Vous recevrez un e-mail dans un délai maximum de 60 secondes."}
                      {lang === "eng" && "You will receive an email within a maximum of 60 seconds."}
                      &nbsp;
                      <Tooltip title={lang === "fr" ? "Après avoir reçu un e-mail avec votre ID de profil, vous pouvez l'utiliser pour modifier une ou plusieurs informations contenues dans ce formulaire. Attention! ceci n'est possible que dans les 24 heures suivant votre inscription. La validation de vos informations se fera dans les 48 heures. Ensuite vous recevrez un e-mail avec vos identifiants." : "After receiving an email with your profile ID, you can use it to edit one or more information contained in this form. Attention! this is only possible within 24 hours of your registration. Validation of your information will be done within 48 hours. You will then receive an email with your login details."}>
                        <Icon color="success">info</Icon>
                      </Tooltip>
                    </MDTypography>}
                    {Object.keys(obtentionProfil).length !== 0 && queDesChiffres(confirmationProfil) && 
                      <MDTypography variant="button" color="error">
                        <Rebours2 temps={confirmationProfil} />
                      </MDTypography>
                    }
                  </MDBox>
                }
              </Grid>
            </Grid>
            {!initial && 
              <MDBox component="form" role="form" onSubmit={traiterInscription} autoComplete="off">
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6} xl={4}>
                    <MDBox mt={2} mb={2}>
                      <MDTypography variant="button" color="text" fontWeight="regular">
                        <u>
                          {lang === "fr" && "INFORMATIONS DE BASE"}
                          {lang === "eng" && "BASIC INFORMATION"}
                        </u>
                      </MDTypography>
                      <MDBox mt={2} mb={3.5} sx={{height: 65}}>
                        <FormControl sx={{height: 45, width: "97%"}}>
                          <InputLabel id="select-insert1-label" htmlFor="select-insert1">
                            {lang === "fr" && "Exportateur *"}
                            {lang === "eng" && "Exporter *"}
                          </InputLabel>
                          <Select 
                            sx={{height: 46}} 
                            required
                            labelId="select-insert1-label"
                            inputProps={{id: "select-insert1"}}
                            label={lang === "fr" ? "Exportateur *" : "Exporter *"}
                            value={typeId}
                            onChange={traiterTypeId}
                          >
                            {
                              listeTypeIds.map((typeIds) => (
                                <MenuItem key={typeIds.Id} value={typeIds.NomFr}>
                                  <span style={styleSelect}>
                                    {lang === "fr" && typeIds.NomFr}
                                    {lang === "eng" && typeIds.NomAng}
                                  </span>        
                                </MenuItem>
                              ))
                            }      
                          </Select>
                        </FormControl>
                      </MDBox>
                      <MDBox mb={3.5} sx={{height: 65, lineHeight: 0.5}}>
                        <MDInput 
                          type="text" 
                          required 
                          label={lang === "fr" ? "Dénomination ou raison social" : "Company name"} 
                          value={dEnomination} 
                          onChange={traiterDEnomination} 
                          sx={{width: "97%"}} 
                          error={dEnomination === "" ? false : patternId.test(dEnomination) ? false : true}
                          success={dEnomination === "" ? false : patternId.test(dEnomination) ? true : false} 
                        />
                        {dEnomination !== "" && !(patternId.test(dEnomination)) &&  
                          <MDTypography variant="button" color="error">
                            <br />
                            {lang === "fr" && "3 caractères min et 50 caractères max"}
                            {lang === "eng" && "3 characters min and 50 characters max"}
                          </MDTypography>
                        }
                      </MDBox>
                      {typeId === "TITULAIRE DES DROITS MINIERS" && 
                        <>
                          <MDBox mb={3.5} sx={{height: 65, lineHeight: 0.5}}>
                            <MDInput 
                              type="text" 
                              required 
                              label={lang === "fr" ? "Numéro d'identification nationale" : "National identification number"} 
                              value={idNat} 
                              onChange={traiterIdNat} 
                              sx={{width: "97%"}} 
                              error={idNat === "" ? false : patternId.test(idNat) ? false : true}
                              success={idNat === "" ? false : patternId.test(idNat) ? true : false} 
                            />
                            {idNat !== "" && !(patternId.test(idNat)) &&  
                              <MDTypography variant="button" color="error">
                               <br />
                                {lang === "fr" && "3 caractères min et 20 caractères max"}
                                {lang === "eng" && "3 characters min and 20 characters max"}
                              </MDTypography>
                            }
                            {erreurNouveauProfil === "Indentification Nationale déjà utilisée" &&
                              <MDTypography variant="button" color="error">
                                {lang === "fr" && "Identification Nationale déjà utilisée"}
                                {lang === "eng" && "National Identification already used"}
                              </MDTypography>
                            }
                            {erreurModificationProfil === "Identification Nationale déjà utilisée" &&
                              <MDTypography variant="button" color="error">
                                {lang === "fr" && "Identification Nationale déjà utilisée"}
                                {lang === "eng" && "National Identification already used"}
                              </MDTypography>
                            }
                          </MDBox>
                          <MDBox mb={3.5} sx={{height: 65, lineHeight: 0.5}}>
                            <MDInput 
                              type="text"
                              label={lang === "fr" ? "Nom" : "Last name"} 
                              value={nom} 
                              onChange={traiterNom} 
                              sx={{width: "97%"}} 
                              error={nom === "" ? false : patternNoms.test(nom) ? false : true}
                              success={nom === "" ? false : patternNoms.test(nom) ? true : false} 
                            />
                            {nom !== "" && !(patternNoms.test(nom)) && 
                              <MDTypography variant="button" color="error">
                                <br />
                                {lang === "fr" && "3 caractères min et 20 caractères max"}
                                {lang === "eng" && "3 characters min and 20 characters max"}
                              </MDTypography>
                            }
                          </MDBox>
                          <MDBox mb={3.5} sx={{height: 65, lineHeight: 0.5}}>
                            <MDInput 
                              type="text"
                              label={lang === "fr" ? "Post-nom" : "Middle name"}
                              value={postNom} 
                              onChange={traiterPostNom} 
                              sx={{width: "97%"}} 
                              error={postNom === "" ? false : patternNoms.test(postNom ) ? false : true}
                              success={postNom  === "" ? false : patternNoms.test(postNom ) ? true : false} 
                            />
                            {postNom !== "" && !(patternNoms.test(postNom)) && 
                              <MDTypography variant="button" color="error">
                                <br />
                                {lang === "fr" && "3 caractères min et 20 caractères max"}
                                {lang === "eng" && "3 characters min and 20 characters max"}
                              </MDTypography>
                            }
                          </MDBox>
                          <MDBox mb={3.5} sx={{height: 65, lineHeight: 0.5}}>
                            <MDInput 
                              type="text"
                              label={lang === "fr" ? "Prénom" : "First name"} 
                              value={prEnom} 
                              onChange={traiterPrEnom} 
                              sx={{width: "97%"}} 
                              error={prEnom === "" ? false : patternNoms.test(prEnom) ? false : true}
                              success={prEnom === "" ? false : patternNoms.test(prEnom) ? true : false} 
                            />
                            {prEnom !== "" && !(patternNoms.test(prEnom)) && 
                              <MDTypography variant="button" color="error">
                                <br />
                                {lang === "fr" && "3 caractères min et 20 caractères max"}
                                {lang === "eng" && "3 characters min and 20 characters max"}
                              </MDTypography>
                            }
                          </MDBox>
                        </> 
                      }
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} xl={4}>
                    <MDBox mt={2}  mb={2}>
                      <MDTypography variant="button" color="text" fontWeight="regular">
                        <u>
                          {lang === "fr" && "SIEGE SOCIAL - SIEGE D'EXPLOITATION"}
                          {lang === "eng" && "HEAD OFFICE - OPERATING HEADQUARTERS"}
                        </u>
                      </MDTypography>
                      <MDBox mt={2} mb={3.5} sx={{height: 65}}>
                        {chargementLieux ? 
                          <MDTypography variant="button" color="dark" mt={1}>
                            {lang === "fr" && "Chargement..."}
                            {lang === "eng" && "Loading..."}
                          </MDTypography> :
                          <StyledAutocomplete
                            disableClearable
                            openOnFocus
                            clearOnEscape
                            options={listeLieux}
                            getOptionLabel={(option) => option ? option.Nom : ""}
                            isOptionEqualToValue={(option, value) => option.Nom === value.Nom}
                            noOptionsText={lang === "fr" ? "Non trouvé" : "Not found"}
                            sx={{width: "97%"}} 
                            value={province} 
                            onChange={traiterProvince}
                            renderInput={(params) => (
                              <MDInput required type="text" label="Province" {...params} />
                            )}
                          />
                        }
                      </MDBox>
                      <MDBox mb={3.5} sx={{height: 65}}>
                        {chargementLieux ? 
                          <MDTypography variant="button" color="dark" mt={1}>
                            {lang === "fr" && "Chargement..."}
                            {lang === "eng" && "Loading..."}
                          </MDTypography> :
                          province === null ? 
                          <MDTypography variant="button" color="dark" mt={1}>
                            {lang === "fr" && "Sélectionner la province..."}
                            {lang === "eng" && "Select province..."}
                          </MDTypography> :
                          <StyledAutocomplete
                            disableClearable
                            openOnFocus
                            clearOnEscape
                            options={listeVilles}
                            getOptionLabel={(option) => option ? option.Nom : ""}
                            isOptionEqualToValue={(option, value) => option.Nom === value.Nom}
                            noOptionsText={lang === "fr" ? "Non trouvé" : "Not found"}
                            sx={{width: "97%"}} 
                            value={ville} 
                            onChange={traiterVille}
                            renderInput={(params) => (
                              <MDInput required type="text" label={lang === "fr" ? "Ville/Territoire" : "City/Territory"} {...params} />
                            )}
                          />
                        }
                      </MDBox>
                      <MDBox mb={3.5} sx={{height: 65}}>
                        {chargementLieux ? 
                          <MDTypography variant="button" color="dark" mt={1}>
                            {lang === "fr" && "Chargement..."}
                            {lang === "eng" && "Loading..."}
                          </MDTypography> :
                          ville === null ? 
                          <MDTypography variant="button" color="dark" mt={1}>
                            {lang === "fr" && "Sélectionner la ville/le territoire..."}
                            {lang === "eng" && "Select city/territory..."}
                          </MDTypography> :
                          <StyledAutocomplete
                            disableClearable
                            openOnFocus
                            clearOnEscape
                            options={listeCommunes}
                            getOptionLabel={(option) => option ? option.Nom : ""}
                            isOptionEqualToValue={(option, value) => option.Nom === value.Nom}
                            noOptionsText={lang === "fr" ? "Non trouvé" : "Not found"}
                            sx={{width: "97%"}} 
                            value={commune} 
                            onChange={traiterCommune}
                            renderInput={(params) => (
                              <MDInput required type="text" label={lang === "fr" ? "Commune/Secteur" : "Municipality/Sector"} {...params} />
                            )}
                          />
                        }
                      </MDBox>
                      <MDBox mb={3.5} sx={{height: 65, lineHeight: 0.5}}>
                        <MDInput 
                          type="text" 
                          required 
                          label={lang === "fr" ? "Quartier" : "Area"}
                          value={quartier} 
                          onChange={traiterQuartier} 
                          sx={{width: "97%"}} 
                          error={quartier === "" ? false : patternAdresse.test(quartier) ? false : true}
                          success={quartier === "" ? false : patternAdresse.test(quartier) ? true : false} 
                        />
                        {quartier !== "" && !(patternAdresse.test(quartier)) &&  
                          <MDTypography variant="button" color="error">
                            <br />
                            {lang === "fr" && "3 caractères min et 20 caractères max"}
                            {lang === "eng" && "3 characters min and 20 characters max"}
                          </MDTypography>
                        }
                      </MDBox>
                      <MDBox mb={3.5} sx={{height: 65, lineHeight: 0.5}}>
                        <MDInput 
                          type="text" 
                          required 
                          label="Avenue" 
                          value={avenue} 
                          onChange={traiterAvenue} 
                          sx={{width: "97%"}} 
                          error={avenue === "" ? false : patternAdresse.test(avenue) ? false : true}
                          success={avenue === "" ? false : patternAdresse.test(avenue) ? true : false} 
                        />
                        {avenue !== "" && !(patternAdresse.test(avenue)) &&  
                          <MDTypography variant="button" color="error">
                            <br />
                            {lang === "fr" && "3 caractères min et 20 caractères max"}
                            {lang === "eng" && "3 characters min and 20 characters max"}
                          </MDTypography>
                        }
                      </MDBox>
                      <MDBox mb={3.5} sx={{height: 65, lineHeight: 0.5}}>
                        <MDInput
                          type="text" 
                          required 
                          label={lang === "fr" ? "Numéro" : "Number"}
                          value={no} 
                          onChange={traiterNo} 
                          sx={{width: "97%"}} 
                          error={no === "" ? false : patternNo.test(no) ? false : true}
                          success={no === "" ? false : patternNo.test(no) ? true : false} 
                        />
                        {no !== "" && !(patternNo.test(no)) &&  
                          <MDTypography variant="button" color="error">
                            <br />
                            {lang === "fr" && "Numéro non valid/6 caractères max"}
                            {lang === "eng" && "Invalid number/6 characters max"}
                          </MDTypography>
                        }
                      </MDBox>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} xl={4}>
                    <MDBox mt={2} mb={2}>
                      <MDTypography variant="button" color="text" fontWeight="regular">
                        <u>
                          {lang === "fr" && "INFORMATIONS ADDITIONELLES"}
                          {lang === "eng" && "ADDITIONAL INFORMATION"}
                        </u>
                      </MDTypography>
                      <MDBox mt={2} mb={9} sx={{height: 65, lineHeight: 0.5}}>
                        <MDInput 
                          type="text" 
                          required 
                          label={lang === "fr" ? "Numéro de l'Arrêté d'agrément" : "Approval Order number"}
                          value={noAgr} 
                          onChange={traiterNoAgr} 
                          sx={{width: "97%"}} 
                          error={noAgr === "" ? false : patternId.test(noAgr) ? false : true}
                          success={noAgr === "" ? false : patternId.test(noAgr) ? true : false} 
                        />
                        {noAgr !== "" && !(patternId.test(noAgr)) &&  
                          <MDTypography variant="button" color="error">
                            <br />
                            {lang === "fr" && "3 caractères min et 50 caractères max"}
                            {lang === "eng" && "3 characters min and 50 characters max"}
                          </MDTypography>
                        }
                        {erreurNouveauProfil === "Numéro de l'Arrêté d'agrément déjà utilisé" && 
                          <MDTypography variant="button" color="error">
                            {lang === "fr" && "Numéro de l'Arrêté d'agrément déjà utilisé"}
                            {lang === "eng" && "Approval Order number already used"}
                          </MDTypography>
                        }
                        {erreurModificationProfil === "Numéro de l'Arrêté d'agrément déjà utilisé" && 
                          <MDTypography variant="button" color="error">
                            {lang === "fr" && "Numéro de l'Arrêté d'agrément déjà utilisé"}
                            {lang === "eng" && "Approval Order number already used"}
                          </MDTypography>
                        }
                      </MDBox>
                      <MDBox mb={7} sx={{flexGrow: 1, height: 85, lineHeight: 0.5}}>
                        <MDTypography variant="button" color="text">
                          {lang === "fr" && "Période de validité de l'agrément *"}
                          {lang === "eng" && "Approval validity period *"}
                        </MDTypography>
                        <Grid mt={1.5} container spacing={1} sx={{width: "99%"}}>
                          <Grid item xs={6}>
                            <MDInput type="date" required label={lang === "fr" ? "Date de début" : "Start date"} value={dateD} onChange={traiterDateD} fullWidth />
                          </Grid>
                          <Grid item xs={6}>
                            <MDInput type="date" required label={lang === "fr" ? "Date d'expiration" : "Expiration date"} value={dateF} onChange={traiterDateF} fullWidth />
                          </Grid>
                        </Grid>
                        {new Date(dateD).getTime() > new Date(dateF).getTime() && 
                          <MDTypography variant="button" color="error">
                            {lang === "fr" && "La date d'expiration ne peut pas être < à la date de début"}
                            {lang === "eng" && "Expiration date cannot be < start date"}
                          </MDTypography>
                        }
                      </MDBox>
                      <MDBox mt={2} mb={3.5} sx={{height: 65, lineHeight: 0.5}}>
                        <MDInput 
                          type="text" 
                          required 
                          label="Email" 
                          value={email} 
                          onChange={traiterEmail} 
                          sx={{width: "97%"}} 
                          error={email === "" ? false : patternEmail.test(email) ? false : true}
                          success={email === "" ? false : patternEmail.test(email) ? true : false} 
                        />
                        {email !== "" && !(patternEmail.test(email)) && 
                          <MDTypography variant="button" color="error">
                            {lang === "fr" && "Veuillez entrer une adresse e-mail valide"}
                            {lang === "eng" && "Please enter a valid email address"}
                          </MDTypography>
                        }
                        {erreurNouveauProfil === "Email déjà utilisé" && 
                          <MDTypography variant="button" color="error">
                            <br />
                            {lang === "fr" && "Email déjà utilisé"}
                            {lang === "eng" && "Email already used"}
                          </MDTypography>
                        }
                        {erreurModificationProfil === "Email déjà utilisé" && 
                          <MDTypography variant="button" color="error">
                            <br />
                            {lang === "fr" && "Email déjà utilisé"}
                            {lang === "eng" && "Email already used"}
                          </MDTypography>
                        }
                      </MDBox>
                      <MDBox mb={3.5} sx={{height: 65, lineHeight: 1}}>
                        <PhoneInput 
                          inputStyle={{width: "97%", height: "43px"}} 
                          dropdownStyle={{letterSpacing: "normal", fontSize: "13px", color: "rgba(0,0,0,.85)"}}
                          country={'cd'}
                          localization={lang === "fr" ? fr : ""} 
                          placeholder={lang === "fr" ? "Téléphone *" : "Phone *"}
                          inputProps={{required: true, name: "phone"}}
                          value={phone} 
                          onChange={phone => {envoi(effacerErreurProfil()), assignerPhone(phone)}} 
                        />
                        {phone !== "" && !(patternPhone.test(phone)) &&  
                          <MDTypography variant="button" color="error">
                            {lang === "fr" && "Veuillez entrer un numéro valide"}
                            {lang === "eng" && "Please enter a valid number"}
                          </MDTypography>
                        }
                        {erreurNouveauProfil === "Numéro déjà utilisé" && 
                          <MDTypography variant="button" color="error">
                            {lang === "fr" && "Numéro déjà utilisé"}
                            {lang === "eng" && "Number already used"}
                          </MDTypography>
                        }
                        {erreurModificationProfil === "Numéro déjà utilisé" && 
                          <MDTypography variant="button" color="error">
                            {lang === "fr" && "Numéro déjà utilisé"}
                            {lang === "eng" && "Number already used"}
                          </MDTypography>
                        }
                      </MDBox>
                    </MDBox>
                  </Grid>
                </Grid>
                <MDBox sx={{textAlign: "center", height: 60}}>
                  <MDBox mb={1}>
                    <MDButton type="button" variant="gradient" color="error" onClick={traiterFermer0}>
                      {lang === "fr" && "Annuler"}
                      {lang === "eng" && "Cancel"}
                    </MDButton>
                    &nbsp;&nbsp;
                    <MDButton disabled={disabled2} type="submit" variant="gradient" color="info">
                      {lang === "fr" && "Enregistrer"}
                      {lang === "eng" && "Save"}
                    </MDButton>
                  </MDBox>
                  <MDBox>
                    {erreurNouveauProfil === "Champ(s) vide(s) dans la requête" && 
                      <MDTypography variant="button" color="error" mt={1}>
                        {lang === "fr" && "Champ(s) vide(s) dans la requête"}
                        {lang === "eng" && "Empty field(s) in the query"}
                      </MDTypography>
                    }
                    {erreurModificationProfil === "Aucune modification" && 
                      <MDTypography variant="button" color="error" mt={1}>
                        {lang === "fr" && "Aucune modification"}
                        {lang === "eng" && "No modification"}
                      </MDTypography>
                    }
                    {erreurModificationProfil === "Champ(s) vide(s) dans la requête" && 
                      <MDTypography variant="button" color="error" mt={1}>
                        {lang === "fr" && "Champ(s) vide(s) dans la requête"}
                        {lang === "eng" && "Empty field(s) in the query"}
                      </MDTypography>
                    }
                  </MDBox>
                </MDBox>
              </MDBox>
            }
          </MDBox>
        </Modal>
        <Modal isOpen={ouvrir1} onRequestClose={traiterFermer1} ariaHideApp={false} style={styleModal430}>
          <MDBox 
            sx={{padding: 3, height: 300}}
            variant="gradient" 
            bgColor={darkMode ? "dark": "white"}
            borderRadius="lg"
            coloredShadow={darkMode ? "dark": "light"}
          >
            <Grid container spacing={3} alignItems="center">
              <Grid item>
                <MDBox height="100%" lineHeight={1}>
                  <MDTypography variant="h6" fontWeight="medium">
                    {lang === "fr" && "REINITIALISATION"}
                    {lang === "eng" && "RESET"}
                  </MDTypography>
                  <MDTypography variant="button" color="text" fontWeight="regular">
                    {lang === "fr" && "Vous recevrez un e-mail dans un délai maximum de 60 secondes."}
                    {lang === "eng" && "You will receive an email within a maximum of 60 seconds."}
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>
            <br />
            <MDBox component="form" role="form" onSubmit={traiterDemande} autoComplete="off">
              <MDBox mb={2}>
                <MDInput type="text" required label="Email" value={email} onChange={traiterEmail} fullWidth />
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton disabled={disabled2} type="submit" variant="gradient" color="info" fullWidth>
                  {lang === "fr" && "Envoyer"}
                  {lang === "eng" && "Send"}
                </MDButton>
              </MDBox>
              {erreurDemande && 
                <MDTypography variant="button" color="error" mt={1}>
                  {lang === "fr" && "Le mail n'a pas pu être envoyé"}
                  {lang === "eng" && "Email could not be sent"}
                </MDTypography>
              }
            </MDBox>
          </MDBox>
        </Modal>
      </Card>
      <br /><br />
    </Base>
  );
};

const variableFormulaire = valeurInitiale => {
  const [value, assignerValeur] = useState(valeurInitiale);
  const traiterChangement = e => {
    assignerValeur(e.target.value);
  }
  return {
    value,
    onChange: traiterChangement
  }
};

export default Connexion;
