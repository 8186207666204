import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    disabled: false,
    vertical: "top",
    horizontal: "center",
    ouvrir8: false,
    erreurAjoutCompteBancaire: null,
    notifAjoutCompteBancaire: false,
    data: {},
    ouvrir08: false,
    erreurModifCompteBancaire: null,
    notifModifCompteBancaire: false,
    ouvrir9: false,
    erreurSupprCompteBancaire: false,
    notifSupprCompteBancaire: false
}
   
export const partieUtilisateur = createSlice({
    name: "utilisateur",
    initialState,
    reducers: {
        chargement: (state) => {
            return {
                ...state,
                disabled: true,
            }
        },
        ouvrirFDialogueAjoutCB: (state) => {
            return {
                ...state,
                ouvrir8: true,
                disabled: false
            }
        },
        fermerFDialogueAjoutCB: (state) => {
            return {
                ...state,
                ouvrir8: false,
                erreurAjoutCompteBancaire: null,
            }
        },
        failliteAjoutCompteBancaire: (state, action) => {
            const { error } = action.payload;
            return {
                ...state,
                erreurAjoutCompteBancaire: error,
                disabled: false
            }
        },
        ajoutCompteBancaireFait: (state) => {
            return {
                ...state,
                erreurAjoutCompteBancaire: null,
                disabled: false,
                ouvrir8: false,
                notifAjoutCompteBancaire: true
            }
        },
        fermerNotifAjoutCB: (state) => {
            return {
                ...state,
                notifAjoutCompteBancaire: false
            }
        },
        ouvrirFDialogueModifCB: (state, action) => {
            return {
                ...state,
                data: action.payload,
                ouvrir08: true,
                disabled: false
            }
        },
        fermerFDialogueModifCB: (state) => {
            return {
                ...state,
                ouvrir08: false,
                data: {},
                erreurModifCompteBancaire: null
            }
        },
        failliteModifCompteBancaire: (state, action) => {
            const { error } = action.payload;
            return {
                ...state,
                erreurModifCompteBancaire: error,
                disabled: false
            }
        },
        modifCompteBancaireFaite: (state) => {
            return {
                ...state,
                erreurModifCompteBancaire: null,
                disabled: false,
                ouvrir08: false,
                notifModifCompteBancaire: true
            }
        },
        fermerNotifModifCB: (state) => {
            return {
                ...state,
                notifModifCompteBancaire: false
            }
        },
        ouvrirFDialogueSupprCB: (state, action) => {
            return {
                ...state,
                data: action.payload,
                ouvrir9: true,
                disabled: false
            }
        },
        fermerFDialogueSupprCB: (state) => {
            return {
                ...state,
                ouvrir9: false,
                data: {},
                erreurSupprCompteBancaire: false
            }
        },
        failliteSupprCompteBancaire: (state) => {
            return {
                ...state,
                erreurSupprCompteBancaire: true,
                disabled: false
            }
        },
        supprCompteBancaireFaite: (state) => {
            return {
                ...state,
                erreurSupprCompteBancaire: false,
                disabled: false,
                ouvrir9: false,
                notifSupprCompteBancaire: true
            }
        },
        fermerNotifSupprCB: (state) => {
            return {
                ...state,
                notifSupprCompteBancaire: false
            }
        },
        effacerErreurCompteBancaire: (state) => {
            return {
                ...state,
                erreurAjoutCompteBancaire: null,
                erreurModifCompteBancaire: null
            }
        },
        fermerTousDialoguesCB: (state) => {
            return {
                ...state,
                ouvrir8: false,
                ouvrir08: false,
                ouvrir9: false
            }
        }
    }
});

export const {
    chargement,
    ouvrirFDialogueAjoutCB,
    fermerFDialogueAjoutCB,
    failliteAjoutCompteBancaire,
    ajoutCompteBancaireFait,
    fermerNotifAjoutCB,
    ouvrirFDialogueModifCB,
    fermerFDialogueModifCB,
    failliteModifCompteBancaire,
    modifCompteBancaireFaite,
    fermerNotifModifCB,
    ouvrirFDialogueSupprCB,
    fermerFDialogueSupprCB,
    failliteSupprCompteBancaire,
    supprCompteBancaireFaite,
    fermerNotifSupprCB,
    effacerErreurCompteBancaire,
    fermerTousDialoguesCB
} = partieUtilisateur.actions;
   
export default partieUtilisateur.reducer;