/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "Composants/MDBox";
import MDTypography from "Composants/MDTypography";

// Contexte Material Dashboard 2 PRO React
import { useMaterialUIController } from "Contexte";

// Data
import NotificationsEtDialoguesNP from "Vues/Banques/Data/NotificationsEtDialoguesB";
import TableBanques from "Vues/Banques/Data/TableBanques";

function Banques() {  
  const [controller, dispatch] = useMaterialUIController();
  const { lang } = controller;

  return (
    <MDBox sx={{ width: '100%' }}>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  {lang === "fr" && "Comptes bancaires"}
                  {lang === "eng" && "Bank accounts"}
                </MDTypography>
              </MDBox>
              <MDBox p={2} pt={3}>
                <NotificationsEtDialoguesNP />
                  <br />
                <TableBanques />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox> 
  );
}

export default Banques;
