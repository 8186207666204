import { configureStore } from '@reduxjs/toolkit';
import simplificateurAuthentification from 'Simplificateurs/SimplificateursAuthentification';
import simplificateurUtilitaire from 'Simplificateurs/SimplificateursUtilitaire';
import simplificateurUtilisateur from 'Simplificateurs/SimplificateursUtilisateur';
import SimplificateursNatureProduit from 'Simplificateurs/SimplificateursNatureProduit';
import SimplificateursREpartitionRedevance from 'Simplificateurs/SimplificateursREpartitionRedevance';
import SimplificateursCompteBancaire from 'Simplificateurs/SimplificateursCompteBancaire';
import SimplificateursDEclaration from 'Simplificateurs/SimplificateursDEclaration';
import SimplificateursNoteDEbit from 'Simplificateurs/SimplificateursNoteDEbit';
import simplificateurTable from 'Simplificateurs/SimplificateursTable';

export default configureStore({
  reducer: {
    authentification: simplificateurAuthentification,
    utilitaire: simplificateurUtilitaire,
    utilisateur: simplificateurUtilisateur,
    natureProduit: SimplificateursNatureProduit,
    rEpartitionRedevance: SimplificateursREpartitionRedevance,
    compteBancaire: SimplificateursCompteBancaire,
    dEclaration: SimplificateursDEclaration,
    noteDEbit: SimplificateursNoteDEbit,
    table: simplificateurTable,
  }
});
