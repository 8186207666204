import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    disabled: false,
    vertical: "top",
    horizontal: "center",
    erreurModifRepartitionRedev: null,
    notifModifRepartitionRedev: false,
    //data: {}
}
   
export const partieREpartitionRedev = createSlice({
    name: "rEpartitionRedevance",
    initialState,
    reducers: {
        chargement: (state) => {
            return {
                ...state,
                disabled: true,
            }
        },
        failliteModifRepartitionRedev: (state, action) => {
            const { error } = action.payload;
            return {
                ...state,
                erreurModifRepartitionRedev: error,
                disabled: false
            }
        },
        modifRepartitionRedevFaite: (state) => {
            return {
                ...state,
                erreurModifRepartitionRedev: null,
                disabled: false,
                notifModifRepartitionRedev: true
            }
        },
        fermerNotifModifREpRedev: (state) => {
            return {
                ...state,
                notifModifRepartitionRedev: false
            }
        },
        effacerErreurModifREpRedev: (state) => {
            return {
                ...state,
                erreurModifRepartitionRedev: null
            }
        }
    }
});

export const {
    chargement,
    failliteModifRepartitionRedev,
    modifRepartitionRedevFaite,
    fermerNotifModifREpRedev,
    effacerErreurModifREpRedev
} = partieREpartitionRedev.actions;
   
export default partieREpartitionRedev.reducer;