/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { listeComptesBancairesAsync } from "ActionsAsync/ActionsCompteBancaireAsync";
import { setPageCB, setSelectedRowCB } from "Simplificateurs/SimplificateursTable";
import { ouvrirFDialogueModifCB, ouvrirFDialogueSupprCB } from "Simplificateurs/SimplificateursCompteBancaire";

// Composants du @mui material
import LinearProgress from '@mui/material/LinearProgress';
import Icon from "@mui/material/Icon";
import Tooltip from '@mui/material/Tooltip';
import { styled } from "@mui/material/styles";
import { enUS } from '@mui/material/locale';

// Composants du Material Dashboard 2 React
import MDBox from "Composants/MDBox";
import MDButton from "Composants/MDButton";

// Contexte Material Dashboard 2 PRO React
import { useMaterialUIController } from "Contexte";

// Table
import { 
  DataGrid,
  GridToolbarContainer,
  //GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport, 
} from "@mui/x-data-grid";
import { GlobalStyles } from "@mui/material";

import { 
  frFR, 
  globalStyles,
  CustomNoRowsOverlay, 
  CustomPagination
} from "Vues/Utilitaires";

var h = window.innerHeight - 340;

const TableBanques = () => {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, lang } = controller;

  const envoi = useDispatch();

  const tableObj = useSelector((state) => state.table);
  const {
    chargementListeComptesBancaires, 
    listeComptesBancaires,
    pageCB,
    selectedRowCB
  } = tableObj;

  useEffect(() => {
    const minuteurObtenirListeCB = setTimeout(() => {
      envoi(listeComptesBancairesAsync());
    }, 1000);
    return () => {
      clearTimeout(minuteurObtenirListeCB);
    };
  }, []);

  const traiterOuvrir2 = (id, nom, num, banque, lieu, statut) => () => {
    envoi(ouvrirFDialogueModifCB({id, nom, num, banque, lieu, statut}));
  };

  const traiterOuvrir3 = (id, nom) => () => {
    envoi(ouvrirFDialogueSupprCB({id, nom}));
  };

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    border: 0,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    WebkitFontSmoothing: "auto",
    letterSpacing: "normal",
    fontSize: "13px",
    backgroundColor: darkMode ? "#202940" : "#fff",
    color: darkMode ? "#7b809a" : "rgba(0,0,0,.85)",
    "& .MuiDataGrid-main": {
      color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
    },
    "& .MuiDataGrid-columnSeparator": {
      color: "#7b809a"
    },
    "& .MuiDataGrid-columnHeaders, & .MuiDataGrid-cell": {
      borderBottom: darkMode ? "1px solid #344767" : "1px solid #f0f2f5"
    },
    "& .MuiDataGrid-footerContainer": {
      borderTop: darkMode ? "1px solid #344767" : "1px solid #f0f2f5"
    },
    "& .MuiIconButton-root": {
      color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
    },
    "& .MuiPaginationItem-previousNext": {
      color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
    },
    "& .MuiPaginationItem-textInfo": {
      color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
    },
    "& .Mui-selected": {
      color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)"
    },
    "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
      outline: "none"
    }
  }));
  
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        {/*<GridToolbarColumnsButton />*/}
        <GridToolbarFilterButton />
        <GridToolbarExport 
          printOptions={{ disableToolbarButton: true }} 
          csvOptions={{
            fileName: lang === "fr" ? `${"Liste des banques (" + moment(new Date()).format('DD-MM-YYYY HHmmss') + ")"}` : `${"Banks list (" + moment(new Date()).format('DD-MM-YYYY HHmmss') + ")"}`,
            delimiter: ',',
            utf8WithBom: true,
          }}
        />
      </GridToolbarContainer>
    );
  }

  const colonnes = [
    {
      field: "Options",
      width: 95,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title={lang === "fr" ? "Modifier" : "Edit"}>
              <MDButton variant="gradient" color="warning" size="small" iconOnly onClick={traiterOuvrir2(
                params.row.Id,
                params.row.Nom,
                params.row.Num,
                params.row.Banque,
                params.row.Lieu,
                params.row.Statut
              )}>
                <Icon>edit</Icon>
              </MDButton>
            </Tooltip>
            &nbsp;&nbsp;&nbsp;&nbsp;
            {
              params.row.NoteDEbit ? null :
              <Tooltip title={lang === "fr" ? "Supprimer" : "Delete"}>
                <MDButton variant="gradient" color="error" size="small" iconOnly onClick={traiterOuvrir3(
                  params.row.Id,
                  params.row.Nom
                )}>
                  <Icon>delete_forever</Icon>
                </MDButton>
              </Tooltip>
            }
          </>
        );
      }, 
      sortable: false, 
      filterable: false,
      disableColumnMenu: true,
      disableExport: true
    },
    { 
      field: "Nom", 
      headerName: lang === "fr" ? "Intitulé Compte" : "Account name", 
      width: 240,
      disableColumnMenu: true
    },
    { 
      field: "Num", 
      headerName: lang === "fr" ? "N° Compte" : "Account number", 
      width: 200,
      disableColumnMenu: true
    },
    { 
      field: "Banque", 
      headerName: lang === "fr" ? "Nom de la banque" : "Bank name", 
      width: 200,
      disableColumnMenu: true
    },
    { 
      field: "Lieu", 
      headerName: lang === "fr" ? "Lieu" : "Place", 
      width: 240,
      disableColumnMenu: true
    },
    { 
      field: "Statut", 
      type: "singleSelect", 
      valueOptions: [
        { value: true, label: lang === "fr" ? "Oui" : "Yes"},
        { value: false, label: lang === "fr" ? "Non" : "No" }
      ],
      width: 85, 
      renderCell: (params) => (
        <MDBox ml={-1}>
          {params.value === true && <MDButton variant="outlined" color="success" size="small" iconOnly disabled><Icon>check</Icon></MDButton>}
          {params.value === false && <MDButton variant="outlined" color="error" size="small" iconOnly disabled><Icon>close</Icon></MDButton>}
        </MDBox>
      ),
      disableColumnMenu: true
    }
  ]; 

  const [paginationModel, setPaginationModel] = useState(pageCB);
  useEffect(() => {
    localStorage.setItem("pageCB", JSON.stringify(paginationModel));
    envoi(setPageCB());
  },[paginationModel])

  return (
    <div style={{ height: h, width: "100%" }}>
      {globalStyles()}
      <StyledDataGrid
        checkboxSelection
        disableRowSelectionOnClick
        density="compact"
        rows={listeComptesBancaires} 
        getRowId={(row) => row.Id}
        columns={colonnes} 
        slots= {{
          loadingOverlay: LinearProgress, 
          noRowsOverlay: CustomNoRowsOverlay, 
          noResultsOverlay: CustomNoRowsOverlay,
          toolbar: CustomToolbar, 
          pagination: CustomPagination
        }}
        loading={chargementListeComptesBancaires}
        componentsProps={{
          panel: {
            sx: {
              "& .MuiDataGrid-panelWrapper": {
                backgroundColor: darkMode ? "#202940" : "#fff",
              },
              "& .MuiNativeSelect-select": {
                color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
              },
              "& .MuiSvgIcon-root": {
                color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
              },
              "& .MuiFormControl-root": {
                color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
              }
            }
          }
        }}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 50},
          },
          sorting: {
            sortModel: !JSON.parse(localStorage.getItem("sortModelCB")) ? [] : JSON.parse(localStorage.getItem("sortModelCB"))
          },
          filter: {
            filterModel: !JSON.parse(localStorage.getItem("filterModelCB")) ? {items: []} : JSON.parse(localStorage.getItem("filterModelCB"))
          }
        }}
        paginationModel={pageCB}
        onPaginationModelChange={setPaginationModel}
        rowSelectionModel={selectedRowCB}
        onRowSelectionModelChange={
          (model) => {
            envoi(setSelectedRowCB(model));
          }
        }
        onSortModelChange={(model) => localStorage.setItem("sortModelCB", JSON.stringify(model))}
        onFilterModelChange={(model)=> localStorage.setItem("filterModelCB", JSON.stringify(model))}
        localeText={lang === "fr" ? frFR : enUS}
      />
    </div>
  );
};

export default TableBanques;
