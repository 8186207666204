/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DOMPurify from 'dompurify';
import { validNoteDEbitAsync } from "ActionsAsync/ActionsNoteDEbitAsync";
import Modal from "react-modal";

// Composants du @mui material
import Grid from "@mui/material/Grid";
import Snackbar from "@mui/material/Snackbar";

// Composants du Material Dashboard 2 React
import MDBox from "Composants/MDBox";
import MDInput from "Composants/MDInput";
import MDTypography from "Composants/MDTypography";
import MDButton from "Composants/MDButton";

// Contexte Material Dashboard 2 PRO React
import { useMaterialUIController } from "Contexte";

import { Notification, styleModal430 } from "Vues/Utilitaires";
import { fermerFDialogueValidND } from "Simplificateurs/SimplificateursNoteDEbit";
import { fermerNotifValidNoteDEbit1, fermerNotifValidNoteDEbit2 } from "Simplificateurs/SimplificateursNoteDEbit";

function NotificationsEtDialoguesNoteDEbit() {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, lang, socket } = controller;

  const envoi = useDispatch();

  const authObj = useSelector((state) => state.authentification);
  const idProfil = authObj.info.Id;

  const noteDEbitObj = useSelector((state) => state.noteDEbit);
  const {
    disabled,
    vertical,
    horizontal,
    ouvrir13,
    data,
    erreurValidNoteDEbit,
    notifValidNoteDEbit1,
    notifValidNoteDEbit2
  } = noteDEbitObj;

  const traiterOuvrir = (idDEclaration) => () => {
    assignerIdDEclaration(idDEclaration);
  };
  const traiterFermer = () => {
    envoi(fermerFDialogueValidND());
    assignerIdDEclaration("");
    assignerCommentaire("");
  };
  const traiterFermerNotification1 = () => {
    envoi(fermerNotifValidNoteDEbit1());
    assignerIdDEclaration("");
    assignerCommentaire("");
  };
  const traiterFermerNotification2 = () => {
    envoi(fermerNotifValidNoteDEbit2());
    assignerIdDEclaration("");
    assignerCommentaire("");
  };

  const [idDEclaration, assignerIdDEclaration] = useState("");
  const [commentaire, assignerCommentaire] = useState("");
  const patternCommentaire = new RegExp(/^[A-zA-Z0-9.,'-\s\/\(\)]{3,140}$/);
  const traiterCommentaire = e => {
    let target = e.target;
    const cursor = e.target.selectionStart;
    assignerCommentaire((e.target.value).replace(/[^A-zA-Z0-9.,'-\s\/\(\)]/gi, "").toUpperCase());
    setTimeout(() => {
      target.setSelectionRange(cursor, cursor);
    }, 10);
  };

  const cleanIdDEclaration = DOMPurify.sanitize(idDEclaration, {
    ALLOWED_CHARS: /[^a-z0-9-]$/
  });
  const cleanCommentaire = DOMPurify.sanitize(commentaire, {
    ALLOWED_CHARS: /^[A-zA-Z0-9.,'-\s\/\(\)]{3,140}$/
  });

  const traiterValidNoteDEbit = async (e) => {
    e.preventDefault();
    const { id } = e.nativeEvent.submitter;
    let statut;
    switch (id) {
      case "submit1":
        statut = "1";
        break;
      case "submit2":
        statut = "0";
        break;
      default:
      // ignore
    }
    envoi(validNoteDEbitAsync(cleanIdDEclaration.trim(), statut, cleanCommentaire, dispatch, socket));
  }

  return (
    <div>
      <Snackbar 
        anchorOrigin={{ vertical, horizontal }} 
        open={notifValidNoteDEbit1} 
        autoHideDuration={6000} 
        onClose={traiterFermerNotification1}
        key={vertical + horizontal + "1"}
      >
        <Notification onClose={traiterFermerNotification1} severity="success" sx={{ width: "100%", bgcolor: "background.paper"}}>
          {lang === "fr" && "Note de débit validée avec succès"}
          {lang === "eng" && "Debit note successfully validated"}
        </Notification>
      </Snackbar>
      <Snackbar 
        anchorOrigin={{ vertical, horizontal }} 
        open={notifValidNoteDEbit2} 
        autoHideDuration={6000} 
        onClose={traiterFermerNotification2}
        key={vertical + horizontal + "2"}
      >
        <Notification onClose={traiterFermerNotification2} severity="error" sx={{ width: "100%", bgcolor: "background.paper"}}>
          {lang === "fr" && "Note de débit rejetée"}
          {lang === "eng" && "Debit note rejected"}
        </Notification>
      </Snackbar>
      <Modal isOpen={ouvrir13} onAfterOpen={traiterOuvrir(data.idDEclaration)} onRequestClose={traiterFermer} ariaHideApp={false} style={styleModal430}>
        <MDBox 
          sx={{padding: 3, height: 400, textAlign: "center"}}
          variant="gradient" 
          bgColor={darkMode ? "dark": "#fff"}
          borderRadius="lg"
          coloredShadow={darkMode ? "dark": "light"}
        >
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <MDBox height="100%" lineHeight={1}>
                <MDTypography variant="h6" fontWeight="medium">
                  VALIDATION
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
          <br />
          <MDBox component="form" role="form" onSubmit={traiterValidNoteDEbit} autoComplete="off">
            <MDBox style={{height: 155}}>
              <MDInput 
                required 
                label={lang == "fr" ? "Avis de l'agent FOMIN." : "FOMIN agent review."} 
                value={commentaire} 
                onChange={traiterCommentaire} 
                multiline 
                rows={5} 
                fullWidth
                error={commentaire === "" ? false : patternCommentaire.test(commentaire) ? false : true}
                success={commentaire === "" ? false : patternCommentaire.test(commentaire) ? true : false} 
              />
              {commentaire !== "" && !(patternCommentaire.test(commentaire)) &&  
                <MDTypography variant="button" color="error" mt={1}>
                  {lang === "fr" && "3 caractères min et 140 caractères max"}
                  {lang === "eng" && "3 characters min and 140 characters max"}
                </MDTypography>
              }
            </MDBox>
            <MDBox mb={4}>
              <MDButton disabled={disabled} type="submit" id="submit1" variant="gradient" color="info">
                {lang === "fr" && "Valider"}
                {lang === "eng" && "Validate"}
              </MDButton>
              &nbsp;&nbsp;
              <MDButton disabled={disabled} type="submit" id="submit2" variant="gradient" color="info">
                {lang === "fr" && "Rejeter"}
                {lang === "eng" && "Dismiss"}
              </MDButton>
            </MDBox>
            <MDButton variant="gradient" color="error" onClick={traiterFermer}>
              {lang === "fr" && "Annuler"}
              {lang === "eng" && "Cancel"}
            </MDButton>     
            {erreurValidNoteDEbit == "Statut présent pour la note de débit" && 
              <MDTypography variant="button" color="error" mt={1}>
                {lang === "fr" && "Statut présent pour la note de débit"}
                {lang === "eng" && "Status present for debit note"}
              </MDTypography>
            }
          </MDBox>
        </MDBox>
      </Modal>
    </div>
  );
};

export default NotificationsEtDialoguesNoteDEbit;