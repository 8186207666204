import { forwardRef } from "react";
import MuiAlert from '@mui/material/Alert';
import { GlobalStyles } from "@mui/material";
import { styled } from "@mui/material/styles";
import Autocomplete from '@mui/material/Autocomplete';
import { 
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector
} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Pagination from "@mui/material/Pagination";

// Contexte Material Dashboard 2 PRO React
import { useMaterialUIController } from "Contexte";

export const Notification = forwardRef(function Notification(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="outlined" {...props} />;
});

export function globalStyles() {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;
  return(
    <GlobalStyles
      styles={{
        "& .MuiDataGrid-menuList": {
          backgroundColor: darkMode ? "#202940" : "#fff",
          color: darkMode ? "#7b809a" : "rgba(0,0,0,.85)",
          "& .MuiListItemIcon-root": {
            color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
          }
        },
        "& .super-app-theme": {
          border: darkMode ? "2px dotted #f0f2f5" : "2px dotted #7b809a",
          animation: "blink-animation 1s steps(5, start) infinite"
        },
        "@keyframes blink-animation": {
          "to": {
            borderColor: darkMode ? "#202940" : "#f0f2f5"
          }
        }
      }}
    />
  );
}

export const frFR = {
  // Base
  noRowsLabel: 'Pas de résultats',
  noResultsOverlayLabel: 'Aucun résultat.',

  // Textes des boutons du sélecteur de densité de la barre d'outils 
  toolbarDensity: 'Densité',
  toolbarDensityLabel: 'Densité',
  toolbarDensityCompact: 'Compact',
  toolbarDensityStandard: 'Standard',
  toolbarDensityComfortable: 'Confortable',

  // Textes des boutons du sélecteur de colonne de la barre d'outils 
  toolbarColumns: 'Colonnes',
  toolbarColumnsLabel: 'Choisir les colonnes',

  // Textes des boutons des filtres de barre d'outils
  toolbarFilters: 'Filtres',
  toolbarFiltersLabel: 'Afficher les filtres',
  toolbarFiltersTooltipHide: 'Cacher les filtres',
  toolbarFiltersTooltipShow: 'Afficher les filtres',
  toolbarFiltersTooltipActive: (count) =>
    count > 1 ? `${count} filtres actifs` : `${count} filtre actif`,

  // Champs de filtre rapide de barre d'outils 
  toolbarQuickFilterPlaceholder: 'Recherche…',
  toolbarQuickFilterLabel: 'Recherche',
  toolbarQuickFilterDeleteIconLabel: 'Supprimer',

  // Textes des boutons du sélecteur d'exportation de la barre d'outils
  toolbarExport: 'Exporter',
  toolbarExportLabel: 'Exporter',
  toolbarExportCSV: 'Télécharger en CSV',
  toolbarExportPrint: 'Imprimer',
  toolbarExportExcel: 'Télécharger pour Excel',

  // Columns management text
  columnsManagementSearchTitle: 'Recherche',
  columnsManagementNoColumns: 'Aucune colonne',
  columnsManagementShowHideAllText: 'Afficher/Masquer tout',

  // Textes du panneau des colonnes
  columnsPanelTextFieldLabel: 'Chercher colonne',
  columnsPanelTextFieldPlaceholder: 'Titre de la colonne',
  columnsPanelDragIconLabel: 'Réorganiser la colonne',
  columnsPanelShowAllButton: 'Tout afficher',
  columnsPanelHideAllButton: 'Tout cacher',

  // Textes du panneau des filtres
  filterPanelAddFilter: 'Ajouter un filtre',
  filterPanelRemoveAll: 'Tout supprimer',
  filterPanelDeleteIconLabel: 'Supprimer',
  filterPanelLogicOperator: 'Opérateur logique',
  filterPanelOperator: 'Opérateur',
  filterPanelOperatorAnd: 'Et',
  filterPanelOperatorOr: 'Ou',
  filterPanelColumns: 'Colonnes',
  filterPanelInputLabel: 'Valeur',
  filterPanelInputPlaceholder: 'Filtrer la valeur',

  // Textes des opérateurs des filtres
  filterOperatorContains: 'contient',
  filterOperatorEquals: 'est égal à',
  filterOperatorStartsWith: 'commence par',
  filterOperatorEndsWith: 'se termine par',
  filterOperatorIs: 'est',
  filterOperatorNot: "n'est pas",
  filterOperatorAfter: 'postérieur',
  filterOperatorOnOrAfter: 'égal ou postérieur',
  filterOperatorBefore: 'antérieur',
  filterOperatorOnOrBefore: 'égal ou antérieur',
  filterOperatorIsEmpty: 'est vide',
  filterOperatorIsNotEmpty: "n'est pas vide",
  filterOperatorIsAnyOf: 'fait partie de',
  'filterOperator=': '=',
  'filterOperator!=': '!=',
  'filterOperator>': '>',
  'filterOperator>=': '>=',
  'filterOperator<': '<',
  'filterOperator<=': '<=',

  // Texte des opérateurs de filtre d'en-tête
  headerFilterOperatorContains: 'Contient',
  headerFilterOperatorEquals: 'Est égal à',
  headerFilterOperatorStartsWith: 'Commence par',
  headerFilterOperatorEndsWith: 'Se termine par',
  headerFilterOperatorIs: 'Est',
  headerFilterOperatorNot: "N'est pas",
  headerFilterOperatorAfter: 'Postérieur',
  headerFilterOperatorOnOrAfter: 'Égal ou postérieur',
  headerFilterOperatorBefore: 'Antérieur',
  headerFilterOperatorOnOrBefore: 'Égal ou antérieur',
  headerFilterOperatorIsEmpty: 'Est vide',
  headerFilterOperatorIsNotEmpty: "N'est pas vide",
  headerFilterOperatorIsAnyOf: 'Fait partie de',
  'headerFilterOperator=': 'Est égal à',
  'headerFilterOperator!=': "N'est pas égal à",
  'headerFilterOperator>': 'Est supérieur à',
  'headerFilterOperator>=': 'Est supérieur ou égal à',
  'headerFilterOperator<': 'Est inférieur à',
  'headerFilterOperator<=': 'Est inférieur ou égal à',

  // Textes des valeurs des filtres
  filterValueAny: 'tous',
  filterValueTrue: 'vrai',
  filterValueFalse: 'faux',

  // Textes du menu de colonne
  columnMenuLabel: 'Menu',
  columnMenuShowColumns: 'Afficher les colonnes',
  columnMenuManageColumns: 'Gérer les colonnes',
  columnMenuFilter: 'Filtrer',
  columnMenuHideColumn: 'Cacher',
  columnMenuUnsort: 'Annuler le tri',
  columnMenuSortAsc: 'Tri ascendant',
  columnMenuSortDesc: 'Tri descendant',

  // Textes d'en-tête de colonne
  columnHeaderFiltersTooltipActive: (count) =>
    count > 1 ? `${count} filtres actifs` : `${count} filtre actif`,
  columnHeaderFiltersLabel: 'Afficher les filtres',
  columnHeaderSortIconLabel: 'Trier',

  // Textes de pied de page des lignes sélectionnées 
  footerRowSelected: (count) =>
    count > 1
      ? `${count.toLocaleString()} lignes sélectionnées`
      : `${count.toLocaleString()} ligne sélectionnée`,

  // Texte de pied de page du total des lignes
  footerTotalRows: 'Total des Lignes :',

  // Texte de pied de page du total des lignes visibles
  footerTotalVisibleRows: (visibleCount, totalCount) =>
    `${visibleCount.toLocaleString()} sur ${totalCount.toLocaleString()}`,

  // Textes de sélection de la case à cocher
  checkboxSelectionHeaderName: 'Sélection',
  checkboxSelectionSelectAllRows: 'Sélectionner toutes les lignes',
  checkboxSelectionUnselectAllRows: 'Désélectionner toutes les lignes',
  checkboxSelectionSelectRow: 'Sélectionner la ligne',
  checkboxSelectionUnselectRow: 'Désélectionner la ligne',

  // Textes de cellule booléenne
  booleanCellTrueLabel: 'vrai',
  booleanCellFalseLabel: 'faux',

  // Texte de la cellule d'action "Plus"
  actionsCellMore: 'Plus',

  // Textes d'épinglage de colonne
  pinToLeft: 'Épingler à gauche',
  pinToRight: 'Épingler à droite',
  unpin: 'Désépingler',

  // Données d'arborescence
  treeDataGroupingHeaderName: 'Groupe',
  treeDataExpand: 'afficher les sous-groupes',
  treeDataCollapse: 'masquer les sous-groupes',

  // Groupement des colonnes
  groupingColumnHeaderName: 'Groupe',
  groupColumn: (name) => `Grouper par ${name}`,
  unGroupColumn: (name) => `Arrêter de grouper par ${name}`,

  // Masquer/détail
  detailPanelToggle: 'Afficher/masquer les détails',
  expandDetailPanel: 'Afficher',
  collapseDetailPanel: 'Masquer',

  // Texte de positionnement des lignes
  rowReorderingHeaderName: 'Positionnement des lignes',

  // Agrégation
  aggregationMenuItemHeader: 'Agrégation',
  aggregationFunctionLabelSum: 'Somme',
  aggregationFunctionLabelAvg: 'Moyenne',
  aggregationFunctionLabelMin: 'Minimum',
  aggregationFunctionLabelMax: 'Maximum',
  aggregationFunctionLabelSize: "Nombre d'éléments",
};

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
  },
}));

export function CustomNoRowsOverlay() {
  const [controller, dispatch] = useMaterialUIController();
  const { lang } = controller;
  return (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>
        {lang === "eng" && "No results"}
        {lang === "fr" && "Pas de résultats"}
      </Box>
    </StyledGridOverlay>
  );
}

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
};

export function formatDate(date) {
  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
  ].join('/');
};

export function formatHeure(date) {
  return [
    padTo2Digits(date.getHours()),
    padTo2Digits(date.getMinutes())
  ].join(':');
};

export const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 600,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    textAlign: "left",
    whiteSpace: "pre-line"
  },
}));

export function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  return (
    <Pagination
      color="info"
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
};

export const styleModalFull = {
  overlay: {
    zIndex: 1200,
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    overflowY: "auto",
    WebkitOverflowScrolling: "touch"
  },
  content: {
    position: "absolute",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    background: "transparent",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "95%",
    width: "95%",
    border: 0
  }
};

export const styleModal430 = {
  overlay: {
    zIndex: 1200,
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    position: "absolute",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    background: "transparent",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: 430,
    overflow: "auto",
    WebkitOverflowScrolling: "touch",
    border: 0
  }
};

export const styleModal820 = {
  overlay: {
    zIndex: 1200,
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    position: "absolute",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    background: "transparent",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 820,
    width: "100%",
    overflow: "auto",
    WebkitOverflowScrolling: "touch",
    border: 0
  }
};

export const styleSelect = {
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  WebkitFontSmoothing: "auto",
  letterSpacing: "normal",
  fontSize: "13px"
}

export const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  "& .MuiInputBase-input": {
    height: "13px"
  }
}));

export const COLORS = [
  `CadetBlue`,
  `Coral`,
  `CornflowerBlue`,
  `DarkGoldenRod`,
  `Darkorange`,
  `ForestGreen`,
  `GoldenRod`,
  `HotPink`,
  `IndianRed`,
  `LightCoral`,
  `LightSalmon`,
  `LightSeaGreen`,
  `LightSlateGrey`,
  `LimeGreen`,
  `MediumAquaMarine`,
  `MediumPurple`,
  `MediumSeaGreen`,
  `MediumSlateBlue`,
  `OliveDrab`,
  `Orchid`,
  `PaleVioletRed`,
  `Peru`,
  `RosyBrown`,
  `Salmon`,
  `SandyBrown`,
  `SeaGreen`,
  `SteelBlue`,
  `Tomato`,
  `Violet`,
  `YellowGreen`
];