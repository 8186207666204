import {
    chargement,
    failliteAjoutCompteBancaire,
    ajoutCompteBancaireFait,
    failliteModifCompteBancaire,
    modifCompteBancaireFaite,
    failliteSupprCompteBancaire,
    supprCompteBancaireFaite,
} from "Simplificateurs/SimplificateursCompteBancaire";
import { obtentionListeComptesBancaires } from "Simplificateurs/SimplificateursTable";
import { serviceListeComptesBancaires, serviceAjoutCompteBancaire, serviceModifCompteBancaire, serviceSupprCompteBancaire } from "Services/CompteBancaire";
import { disjonctionAsync } from "ActionsAsync/ActionsAuthAsync";

export const listeComptesBancairesAsync = (dispatch, socket) => async envoi => {
    const resultat = await serviceListeComptesBancaires();
    if (resultat.error) {
        return envoi(disjonctionAsync(dispatch, socket));
    }
    envoi(obtentionListeComptesBancaires(resultat.data));
}

export const ajoutCompteBancaireAsync = (nom, num, banque, lieu, dispatch, socket) => async envoi => {
    envoi(chargement());
    const resultat = await serviceAjoutCompteBancaire(nom, num, banque, lieu);
    if (resultat.error) {
        return envoi(failliteAjoutCompteBancaire(resultat.response.data));
    }
    envoi(ajoutCompteBancaireFait());
    envoi(listeComptesBancairesAsync(dispatch, socket));
}

export const modifCompteBancaireAsync = (id, nom, num, banque, lieu, statut, dispatch, socket) => async envoi => {
    envoi(chargement());
    const resultat = await serviceModifCompteBancaire(id, nom, num, banque, lieu, statut);
    if (resultat.error) {
        console.log(resultat)
        return envoi(failliteModifCompteBancaire(resultat.response.data));
    }
    envoi(modifCompteBancaireFaite());
    envoi(listeComptesBancairesAsync(dispatch, socket));
}

export const supprCompteBancaireAsync = (id, dispatch, socket) => async envoi => {
    envoi(chargement());
    const resultat = await serviceSupprCompteBancaire(id);
    if (resultat.error) {
        return envoi(failliteSupprCompteBancaire(resultat.response.data));
    }
    envoi(supprCompteBancaireFaite());
    envoi(listeComptesBancairesAsync(dispatch, socket));
}