import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    disabled: false,
    vertical: "top",
    horizontal: "center",
    ouvrir0: false,
    erreurNouveauProfil: null,
    notifAjoutProfil: false,
    ouvrir1: false,
    erreurDemande: false,
    notifDemande: false,
    ouvrir2: false,
    erreurModificationPasse: null,
    notifModifPasse: false,
    ouvrir3: false,
    erreurValidationProfil: null,
    activeStep: 0,
    erreurAjoutUtilisateur: null,
    notifAjoutUtilisateur: false,
    data: {},
    ouvrir4: false,
    erreurModificationUtilisateur: null,
    notifModifUtilisateur: false,
    ouvrir5: false,
    erreurSuppressionUtilisateur: false,
    notifSupprUtilisateur: false,
    obtentionProfil: {},
    confirmationProfil: null,
    erreurModificationProfil: null,
    notifModifProfil: false,
    ouvrir6: false,
    notifAutoDisjonction: false,
    modifAutoDisjonction: null,
    modifAutoDEsactivation: null,
    initial: true,
    ouvrir06: false,
}
   
export const partieUtilisateur = createSlice({
    name: "utilisateur",
    initialState,
    reducers: {
        chargement: (state) => {
            return {
                ...state,
                disabled: true,
                erreurNouveauProfil: null
            }
        },
        ouvrirFDialogueInscription: (state) => {
            return {
                ...state,
                ouvrir0: true,
                disabled: false
            }
        },
        fermerFDialogueInscription: (state) => {
            return {
                ...state,
                ouvrir0: false,
                erreurNouveauProfil: null,
            }
        },
        failliteNouveauProfil: (state, action) => {
            const { error } = action.payload;
            return {
                ...state,
                erreurNouveauProfil: error,
                disabled: false
            }
        },
        nouveauProfilFait: (state) => {
            return {
                ...state,
                erreurNouveauProfil: null,
                disabled: false,
                ouvrir0: false,
                notifAjoutProfil: true
            }
        },
        fermerNotifAjoutP: (state) => {
            return {
                ...state,
                notifAjoutProfil: false
            }
        },
        ouvrirFDialogueDemande: (state) => {
            return {
                ...state,
                ouvrir1: true,
                disabled: false
            }
        },
        fermerFDialogueDemande: (state) => {
            return {
                ...state,
                ouvrir1: false,
                erreurDemande: false
            }
        },
        failliteDemandeUtilisateur: (state) => {
            return {
                ...state,
                erreurDemande: true,
                disabled: false
            }
        },
        demandeUtilisateur: (state) => {
            return {
                ...state,
                erreurDemande: false,
                disabled: false,
                ouvrir1: false,
                notifDemande: true
            }
        },
        fermerNotifDemande: (state) => {
            return {
                ...state,
                notifDemande: false
            }
        },
        ouvrirFDialogueModifPasse: (state) => {
            return {
                ...state,
                ouvrir2: true,
                disabled: false
            }
        },
        fermerFDialogueModifPasse: (state) => {
            return {
                ...state,
                ouvrir2: false,
                erreurModificationPasse: null
            }
        },
        failliteModificationPasse: (state, action) => {
            const { error } = action.payload;
            return {
                ...state,
                erreurModificationPasse: error,
                disabled: false
            }
        },
        modificationPasseFaite: (state) => {
            return {
                ...state,
                erreurModificationPasse: null,
                disabled: false,
                ouvrir2: false,
                notifModifPasse: true
            }
        },
        fermerNotifModifPasse: (state) => {
            return {
                ...state,
                notifModifPasse: false
            }
        },
        ouvrirFDialogueAjoutU: (state) => {
            return {
                ...state,
                ouvrir3: true,
                disabled: false
            }
        },
        fermerFDialogueAjoutU: (state) => {
            return {
                ...state,
                ouvrir3: false,
                erreurAjoutUtilisateur: null
            }
        },
        failliteValidationProfil1: (state, action) => {
            const { error } = action.payload;
            return {
                ...state,
                activeStep: 0,
                erreurValidationProfil: error,
                disabled: false
            }
        },
        validationProfilFaite1: (state) => {
            return {
                ...state,
                erreurValidationProfil: null,
                activeStep: 1,
                disabled: false
            }
        },
        avant1: (state) => {
            return {
                ...state,
                activeStep: 0
            }
        },
        failliteValidationProfil2: (state, action) => {
            const { error } = action.payload;
            return {
                ...state,
                activeStep: 1,
                erreurValidationProfil: error,
                disabled: false
            }
        },
        validationProfilFaite2: (state) => {
            return {
                ...state,
                erreurValidationProfil: null,
                activeStep: 2,
                disabled: false
            }
        },
        avant2: (state) => {
            return {
                ...state,
                activeStep: 1
            }
        },
        failliteAjoutUtilisateur: (state, action) => {
            const { error } = action.payload;
            return {
                ...state,
                erreurAjoutUtilisateur: error,
                disabled: false
            }
        },
        ajoutUtilisateurFait: (state) => {
            return {
                ...state,
                erreurAjoutUtilisateur: null,
                disabled: false,
                ouvrir3: false,
                ouvrir6: false,
                activeStep: 0,
                notifAjoutUtilisateur: true
            }
        },
        fermerNotifAjoutU: (state) => {
            return {
                ...state,
                notifAjoutUtilisateur: false
            }
        },
        ouvrirFDialogueModifU: (state, action) => {
            return {
                ...state,
                data: action.payload,
                ouvrir4: true,
                disabled: false
            }
        },
        fermerFDialogueModifU: (state) => {
            return {
                ...state,
                ouvrir4: false,
                data: {},
                erreurModificationUtilisateur: null
            }
        },
        failliteModificationUtilisateur: (state, action) => {
            const { error } = action.payload;
            return {
                ...state,
                erreurModificationUtilisateur: error,
                disabled: false
            }
        },
        modificationUtilisateurFaite: (state) => {
            return {
                ...state,
                erreurModificationUtilisateur: null,
                disabled: false,
                ouvrir4: false,
                notifModifUtilisateur: true
            }
        },
        fermerNotifModifU: (state) => {
            return {
                ...state,
                notifModifUtilisateur: false
            }
        },
        failliteModifAutoDisjonction: (state) => {
            return {
                ...state,
                modifAutoDisjonction: null
            }
        },
        modifAutoDisjonctionFaite: (state, action) => {
            const { msg } = action.payload;
            return {
                ...state,
                modifAutoDisjonction: msg
            }
        },
        failliteModifAutoDEsactivation: (state) => {
            return {
                ...state,
                modifAutoDEsactivation: null
            }
        },
        modifAutoDEsactivationFaite: (state, action) => {
            const { msg } = action.payload;
            return {
                ...state,
                modifAutoDEsactivation: msg
            }
        },
        ouvrirFDialogueSupprU: (state, action) => {
            return {
                ...state,
                data: action.payload,
                ouvrir5: true,
                disabled: false
            }
        },
        fermerFDialogueSupprU: (state) => {
            return {
                ...state,
                ouvrir5: false,
                data: {},
                erreurSuppressionUtilisateur: false
            }
        },
        failliteSuppressionUtilisateur: (state) => {
            return {
                ...state,
                erreurSuppressionUtilisateur: true,
                disabled: false
            }
        },
        suppressionUtilisateurFaite: (state) => {
            return {
                ...state,
                erreurSuppressionUtilisateur: false,
                disabled: false,
                ouvrir5: false,
                notifSupprUtilisateur: true
            }
        },
        fermerNotifSupprU: (state) => {
            return {
                ...state,
                notifSupprUtilisateur: false
            }
        },
        failliteConfirmationProfil: (state, action) => {
            const { error } = action.payload;
            return {
                ...state,
                disabled: false,
                confirmationProfil: error
            }
        },
        confirmationProfilFaite: (state, action) => {
            const { msg } = action.payload;
            return {
                ...state,
                disabled: false,
                confirmationProfil: msg
            }
        },
        obtentionProfilFaite: (state, action) => {
            return {
                ...state,
                obtentionProfil: action.payload
            }
        },
        failliteModificationProfil: (state, action) => {
            const { error } = action.payload;
            return {
                ...state,
                erreurModificationProfil: error,
                disabled: false
            }
        },
        modificationProfilFaite: (state) => {
            return {
                ...state,
                erreurModificationProfil: null,
                disabled: false,
                ouvrir0: false,
                notifModifProfil: true
            }
        },
        fermerNotifModifP: (state) => {
            return {
                ...state,
                notifModifProfil: false
            }
        },
        ouvrirFDialogueValidationP: (state, action) => {
            return {
                ...state,
                data: action.payload,
                ouvrir6: true,
                disabled: false
            }
        },
        fermerFDialogueValidationP: (state) => {
            return {
                ...state,
                ouvrir6: false,
                data: {},
                erreurAjoutUtilisateur: null
            }
        },
        ouvrirFDialogueInfoP: (state, action) => {
            return {
                ...state,
                data: action.payload,
                ouvrir06: true
            }
        },
        fermerFDialogueInfoP: (state) => {
            return {
                ...state,
                ouvrir06: false,
                data: {},
            }
        },
        effacerErreurProfil: (state) => {
            return {
                ...state,
                erreurNouveauProfil: null,
                erreurModificationProfil: null
            }
        },
        effacerErreurValidationP: (state) => {
            return {
                ...state,
                erreurValidationProfil: null
            }
        },
        effacerConfirmationProfil: (state) => {
            return {
                ...state,
                confirmationProfil: null
            }
        },
        effacerObtentionProfil: (state) => {
            return {
                ...state,
                obtentionProfil: {}
            }
        },
        effacerErreurModifU: (state) => {
            return {
                ...state,
                erreurModificationUtilisateur: null
            }
        },
        ouvrirNotifAutoDisjonction: (state) => {
            return {
                ...state,
                notifAutoDisjonction: true
            }
        },
        fermerNotifAutoDisjonction: (state) => {
            return {
                ...state,
                notifAutoDisjonction: false
            }
        },
        fermerTousDialoguesUtilisateur: (state) => {
            return {
                ...state,
                ouvrir2: false,
                ouvrir3: false,
                ouvrir4: false,
                ouvrir5: false,
                ouvrir6: false,
                ouvrir06: false,
            }
        }, 
        initialOui: (state) => {
            return {
                ...state,
                initial: true
            }
        },
        initialNon: (state) => {
            return {
                ...state,
                initial: false
            }
        }
    }
});

export const {
    chargement,
    ouvrirFDialogueInscription,
    fermerFDialogueInscription,
    failliteNouveauProfil,
    nouveauProfilFait,
    fermerNotifAjoutP,
    ouvrirFDialogueDemande,
    fermerFDialogueDemande,
    failliteDemandeUtilisateur,
    demandeUtilisateur,
    fermerNotifDemande,
    ouvrirFDialogueModifPasse,
    fermerFDialogueModifPasse,
    failliteModificationPasse,
    modificationPasseFaite,
    fermerNotifModifPasse,
    ouvrirFDialogueAjoutU,
    fermerFDialogueAjoutU,
    failliteValidationProfil1,
    validationProfilFaite1,
    avant1,
    failliteValidationProfil2,
    validationProfilFaite2,
    avant2,
    failliteAjoutUtilisateur,
    ajoutUtilisateurFait,
    fermerNotifAjoutU,
    ouvrirFDialogueModifU,
    fermerFDialogueModifU,
    failliteModificationUtilisateur,
    modificationUtilisateurFaite,
    fermerNotifModifU,
    ouvrirFDialogueSupprU,
    fermerFDialogueSupprU,
    failliteSuppressionUtilisateur,
    suppressionUtilisateurFaite,
    fermerNotifSupprU,
    failliteModifAutoDisjonction,
    modifAutoDisjonctionFaite,
    failliteModifAutoDEsactivation,
    modifAutoDEsactivationFaite,
    failliteConfirmationProfil,
    confirmationProfilFaite,
    obtentionProfilFaite,
    ouvrirFDialogueModifP,
    fermerFDialogueModifP,
    failliteModificationProfil,
    modificationProfilFaite,
    fermerNotifModifP,
    ouvrirFDialogueValidationP,
    fermerFDialogueValidationP,
    ouvrirFDialogueInfoP,
    fermerFDialogueInfoP,
    effacerErreurProfil,
    effacerErreurValidationP,
    effacerConfirmationProfil,
    effacerObtentionProfil,
    effacerErreurModifU,
    ouvrirNotifAutoDisjonction,
    fermerNotifAutoDisjonction,
    fermerTousDialoguesUtilisateur, 
    initialOui,
    initialNon
} = partieUtilisateur.actions;
   
export default partieUtilisateur.reducer;