/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { restaurationAsync } from "ActionsAsync/ActionsUtilisateurAsync";

// Composants du @mui material
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

// Composants du Material Dashboard 2 React
import MDBox from "Composants/MDBox";
import MDTypography from "Composants/MDTypography";
import MDButton from "Composants/MDButton";

// Contexte du Material Dashboard 2 React
import { useMaterialUIController, setLang } from "Contexte";

// Composants de mise en page d'authentification
import CoverLayout from "Vues/Authentication/Composants/Couverture";

// Images
import bgImage from "Outils/images/bg-profile.jpeg";
import brand from "Outils/images/logo.png";

import { styleSelect } from "Vues/Utilitaires";

function Restauration() {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, lang } = controller;

  const envoi = useDispatch();

  const utilisateurObj = useSelector((state) => state.utilisateur);
  const {
    disabled, 
    erreurModificationPasse,
    notifModifPasse
  } = utilisateurObj;

  const [montrerPasse1, assignerMontrerPasse1] = useState(false);
  const traiterClickMontrerPasse1 = () => assignerMontrerPasse1((show) => !show);
  const traiterMouseDownPasse1 = (e) => {
    e.preventDefault();
  };
  const [montrerPasse2, assignerMontrerPasse2] = useState(false);
  const traiterClickMontrerPasse2 = () => assignerMontrerPasse2((show) => !show);
  const traiterMouseDownPasse2 = (e) => {
    e.preventDefault();
  };

  const [passe, assignerPasse] = useState("");
  const traiterPasse = e => {
    assignerPasse(e.target.value);
  }
  const [confPasse, assignerConfPasse] = useState("");
  const traiterConfPasse = e => {
    assignerConfPasse(e.target.value);
  }

  const traiterRestaurationPasse = async (e) => {
    e.preventDefault();
    const tokenRest = window.location.href.split("/").pop();
    envoi(restaurationAsync(tokenRest, passe, confPasse));
  }

  const traiterModLang = e => {
    localStorage.setItem("lang", JSON.stringify(e.target.value));
    setLang(dispatch, e.target.value);
  };
  
  return (
    <CoverLayout coverHeight="45vh" image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="white"
          borderRadius="lg"
          coloredShadow="light"
          mx={2}
          mt={-3}
          p={0}
          mb={1}
          textAlign="center"
        >
          <MDBox component="img"  src={brand}  alt="logo" width={100} height={80} sx={{marginTop: "15px"}} />
        </MDBox>
        <MDBox pt={4} pb={3} px={3} style={{height: 430}}>
          <MDBox component="form" role="form" onSubmit={traiterRestaurationPasse} autoComplete="off">
            <MDBox mb={2} style={{height: 70, lineHeight: 0.5}}>
              <FormControl sx={{mt: 1, width: "100%"}} variant="outlined">
                <InputLabel htmlFor="passe1">{lang === "fr" ? "Nouveau mot de passe" : "New password"}</InputLabel>
                <OutlinedInput 
                  id="passe1"
                  type={montrerPasse1 ? "text" : "password"} 
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={traiterClickMontrerPasse1}
                        onMouseDown={traiterMouseDownPasse1}
                        edge="end"
                        style={{color: darkMode ? "#7b809a" : "#808080"}}
                      >
                        {montrerPasse1 ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  required 
                  label={lang === "fr" ? "Nouveau mot de passe" : "New password"} 
                  value={notifModifPasse ? "" : passe} 
                  onChange={traiterPasse}
                />
              </FormControl>
              {erreurModificationPasse === "Minimum 8 caractères" && 
              <MDTypography variant="button" color="error" mt={1}>
                {lang === "fr" && "Au moins 8 caractères"}
                {lang === "eng" && "At least 8 characters"}
              </MDTypography>
              }
              {erreurModificationPasse === "Maximum 20 caractères" && 
                <MDTypography variant="button" color="error" mt={1}>
                  {lang === "fr" && "Au plus 20 caractères"}
                  {lang === "eng" && "At most 20 characters"}
                </MDTypography>
              }
              {erreurModificationPasse === "Minimum 1 majuscule" && 
                <MDTypography variant="button" color="error" mt={1}>
                  {lang === "fr" && "Au moins 1 caractère en majuscule"}
                  {lang === "eng" && "At least 1 character in upper case"}
                </MDTypography>
              }
              {erreurModificationPasse === "Minimum 1 chiffre" && 
                <MDTypography variant="button" color="error" mt={1}>
                  {lang === "fr" && "Au moins 1 chiffre"}
                  {lang === "eng" && "At least 1 digit"}
                </MDTypography>
              }
              {erreurModificationPasse === "Minimum 1 caractère spécial" && 
                <MDTypography variant="button" color="error" mt={1}>
                  {lang === "fr" && "Au moins 1 caractère spécial"}
                  {lang === "eng" && "At least 1 special character"}
                </MDTypography>
              }
            </MDBox>
            <MDBox mb={2} style={{height: 70, lineHeight: 0.5}}>
              <FormControl sx={{mt: 1, width: "100%"}} variant="outlined">
                <InputLabel htmlFor="passe2">{lang === "fr" ? "Confirmer nouveau mot de passe" : "Confirm password"}</InputLabel>
                <OutlinedInput 
                  id="passe2"
                  type={montrerPasse2 ? "text" : "password"} 
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={traiterClickMontrerPasse2}
                        onMouseDown={traiterMouseDownPasse2}
                        edge="end"
                        style={{color: darkMode ? "#7b809a" : "#808080"}}
                      >
                        {montrerPasse2 ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  required 
                  label={lang === "fr" ? "Confirmer nouveau mot de passe" : "Confirm password"} 
                  value={notifModifPasse ? "" : confPasse} 
                  onChange={traiterConfPasse}
                />
              </FormControl>
              {erreurModificationPasse === "Veuillez corriger la confirmation" && 
                <MDTypography variant="button" color="error" mt={1}>
                  {lang === "fr" && "Veuillez confirmer avec le bon mot de passe"}
                  {lang === "eng" && "Please confirm with the correct password"}
                </MDTypography>
              }
            </MDBox>
            <MDBox mt={4} mb={1} sx={{height: 60}}>
              {notifModifPasse ?
                <MDButton component="a" href="/authentication/connexion" variant="gradient" color="success" fullWidth>
                  <Icon>key</Icon>&nbsp;
                  {lang === "fr" && "Connexion"}
                  {lang === "eng" && "Sign In"}
                </MDButton> :
                <MDButton disabled={disabled} type="submit" variant="gradient" color="info" fullWidth>
                  {lang === "fr" && "Modifier"}
                  {lang === "eng" && "Edit"}
                </MDButton>
              }
              <MDBox mt={1}>
                {erreurModificationPasse === "Token de réinitialisation non valide/expiré" && 
                  <MDTypography variant="button" color="error">
                    {lang === "fr" && "Token de réinitialisation non valide/expiré"}
                    {lang === "eng" && "Invalid/expired reset token"}
                  </MDTypography>
                }
                {erreurModificationPasse === "Champ(s) vide(s) dans la requête" && 
                  <MDTypography variant="button" color="error">
                    {lang === "fr" && "Champ(s) vide(s) dans la requête"}
                    {lang === "eng" && "Empty field(s) in the query"}
                  </MDTypography>
                }
              </MDBox>
            </MDBox>
          </MDBox>
          <MDBox mt={7} sx={{height: 45, width: 105}}>
            <FormControl sx={{height: 45, width: "100%", pr: 5.5}}>
              <InputLabel id="select-insert" htmlFor="select-insert2">
                LANG
              </InputLabel>
              <Select 
                sx={{height: 46}} 
                inputProps={{id: "select-insert2"}}
                required
                label="LANG"
                value={lang}
                onChange={traiterModLang}
              >
                <MenuItem key={1} value="fr" sx={{ display: "flex", gap: 1 }}>
                  <img src={require(`../../../../drapeaux/128x128/fr.png`)} alt="fr" width={18} />
                  <span style={styleSelect}>
                    FR
                  </span>        
                </MenuItem>    
                <MenuItem key={2} value="eng" style={{marginTop: "20px"}} sx={{ display: "flex", gap: 1 }}>
                  <img src={require(`../../../../drapeaux/128x128/gb.png`)} alt="gb" width={18} />
                  <span style={styleSelect}>
                    {lang === "fr" && "ANG"}
                    {lang === "eng" && "ENG"}
                  </span>        
                </MenuItem>  
              </Select>
            </FormControl>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Restauration;
