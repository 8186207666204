import { obtentionListeLieux, obtentionListePays } from "Simplificateurs/SimplificateursUtilitaire";
import { serviceListeLieux, serviceListePays } from "Services/Utilitaires";
import { disjonctionAsync } from "ActionsAsync/ActionsAuthAsync";

export const listeLieuxAsync = (dispatch, socket) => async envoi => {
    const resultat = await serviceListeLieux();
    if (resultat.error) {
        return envoi(disjonctionAsync(dispatch, socket));
    }
    envoi(obtentionListeLieux(resultat.data));
}

export const listePaysAsync = (dispatch, socket) => async envoi => {
    const resultat = await serviceListePays();
    if (resultat.error) {
        return envoi(disjonctionAsync(dispatch, socket));
    }
    envoi(obtentionListePays(resultat.data));
}