/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import DOMPurify from 'dompurify';
import { useSelector, useDispatch } from "react-redux";
import { ajoutCompteBancaireAsync, modifCompteBancaireAsync, supprCompteBancaireAsync } from "ActionsAsync/ActionsCompteBancaireAsync";
import { listeLieuxAsync } from "ActionsAsync/ActionsUtilitaireAsync";
import { 
  ouvrirFDialogueAjoutCB, 
  fermerFDialogueAjoutCB,
  fermerNotifAjoutCB,
  fermerFDialogueModifCB,
  fermerNotifModifCB,
  fermerFDialogueSupprCB,
  fermerNotifSupprCB,
  effacerErreurCompteBancaire
} from "Simplificateurs/SimplificateursCompteBancaire";
import Modal from 'react-modal';

// Composants du @mui material
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';
import Switch from "@mui/material/Switch";

// Composants du Material Dashboard 2 React
import MDBox from "Composants/MDBox";
import MDTypography from "Composants/MDTypography";
import MDInput from "Composants/MDInput";
import MDButton from "Composants/MDButton";

// Contexte Material Dashboard 2 PRO React
import { useMaterialUIController } from "Contexte";

import { Notification, StyledAutocomplete, styleModal430 } from "Vues/Utilitaires";

function NotificationsEtDialoguesUtilisateur() {
    const [controller, dispatch] = useMaterialUIController();
    const { darkMode, lang, socket } = controller;
  
    const envoi = useDispatch();
  
    const compteBancaireObj = useSelector((state) => state.compteBancaire);
    const {
      disabled,
      vertical,
      horizontal,
      ouvrir8,
      erreurAjoutCompteBancaire,
      notifAjoutCompteBancaire,
      data,
      ouvrir08,
      erreurModifCompteBancaire,
      notifModifCompteBancaire,
      ouvrir9,
      erreurSupprCompteBancaire,
      notifSupprCompteBancaire,
    } = compteBancaireObj;

    const utilitaireObj = useSelector((state) => state.utilitaire);
    const {chargementLieux, listeLieux} = utilitaireObj;

    useEffect(() => {
      const minuteurObtentionListeLieux = setTimeout(() => {
        envoi(listeLieuxAsync(dispatch, socket));
      }, 1000);
      return () => {
        clearTimeout(minuteurObtentionListeLieux);
      };
    }, []);
      
    const traiterOuvrir1 = () => envoi(ouvrirFDialogueAjoutCB());
    const traiterFermer1 = () => {
      envoi(fermerFDialogueAjoutCB());
      envoi(effacerErreurCompteBancaire());
      assignerNom("");
      assignerNum("");
      assignerBanque("");
      assignerLieu(null);
    };
    const traiterFermerNotification1 = () => {
      envoi(fermerNotifAjoutCB());
      envoi(effacerErreurCompteBancaire());
      assignerNom("");
      assignerNum("");
      assignerBanque("");
      assignerLieu(null);
    };

    const traiterOuvrir2 = (id, nom, num, banque, lieu, statutData) => () => {
      assignerId(id);
      assignerNouveauNom(nom);
      assignerNouveauNum(num);
      assignerNouvelleBanque(banque);
      assignerNouveauLieu(lieu);
      assignerStatut(statutData ? "1" : "0");
    };
    const traiterFermer2 = () => {
      envoi(fermerFDialogueModifCB());
      envoi(effacerErreurCompteBancaire());
      assignerId("");
      assignerNouveauNom("");
      assignerNouveauNum("");
      assignerNouvelleBanque("");
      assignerNouveauLieu(null);
      assignerStatut("");
    };
    const traiterFermerNotification2 = () => {
      envoi(fermerNotifModifCB());
      envoi(effacerErreurCompteBancaire());
      assignerId("");
      assignerNouveauNom("");
      assignerNouveauNum("");
      assignerNouvelleBanque("");
      assignerNouveauLieu(null);
      assignerStatut("");
    };
  
    const traiterOuvrir3 = (id) => () => {
      assignerId(id);
    };
    const traiterFermer3 = () => {
      envoi(fermerFDialogueSupprCB());
      assignerId("");
    };
    const traiterFermerNotification3 = () => {
      envoi(fermerNotifSupprCB());
      assignerId("");
    };
  
    const [id, assignerId] = useState("");

    const patternNom = new RegExp(/^[A-Z.-\s]{3,100}$/);
    const [nom, assignerNom] = useState("");
    const traiterNom = e => {
      let target = e.target;
      const cursor = e.target.selectionStart;
      assignerNom((e.target.value).replace(/[^a-zA-Z\.\-\s]/gi, "").toUpperCase());
      setTimeout(() => {
        target.setSelectionRange(cursor, cursor);
      }, 10);
    };
    const [nouveauNom, assignerNouveauNom] = useState("");
    const traiterNouveauNom = e => {
      let target = e.target;
      const cursor = e.target.selectionStart;
      assignerNouveauNom((e.target.value).replace(/[^a-zA-Z\.\-\s]/gi, "").toUpperCase());
      setTimeout(() => {
        target.setSelectionRange(cursor, cursor);
      }, 10);
    };

    const patternNum = new RegExp(/^[0-9-]{12,30}$/)
    const [num, assignerNum] = useState("");
    const traiterNum = e => {
      envoi(effacerErreurCompteBancaire());
      let target = e.target;
      const cursor = e.target.selectionStart;
      assignerNum((e.target.value).replace(/[^0-9\-\s]/gi, ""));
      setTimeout(() => {
        target.setSelectionRange(cursor, cursor);
      }, 10);
    };
    const [nouveauNum, assignerNouveauNum] = useState("");
    const traiterNouveauNum = e => {
      envoi(effacerErreurCompteBancaire());
      let target = e.target;
      const cursor = e.target.selectionStart;
      assignerNouveauNum((e.target.value).replace(/[^0-9\-\s]/gi, ""));
      setTimeout(() => {
        target.setSelectionRange(cursor, cursor);
      }, 10);
    };

    const patternBanque = new RegExp(/^[A-Z.-\s]{3,50}$/);
    const [banque, assignerBanque] = useState("");
    const traiterBanque = e => {
      let target = e.target;
      const cursor = e.target.selectionStart;
      assignerBanque((e.target.value).replace(/[^a-zA-Z\.\-\s]/gi, "").toUpperCase());
      setTimeout(() => {
        target.setSelectionRange(cursor, cursor);
      }, 10);
    };
    const [nouvelleBanque, assignerNouvelleBanque] = useState("");
    const traiterNouvelleBanque = e => {
      let target = e.target;
      const cursor = e.target.selectionStart;
      assignerNouvelleBanque((e.target.value).replace(/[^a-zA-Z\.\-\s]/gi, "").toUpperCase());
      setTimeout(() => {
        target.setSelectionRange(cursor, cursor);
      }, 10);
    };

    function obtenirCombinations(provinces) {
      const x = [];
      provinces.forEach(province => {
        province.VilleTerritoires.forEach(territoire => {
          x.push(`${territoire.Nom}, ${province.Nom}`);
        });
      });
      return x;
    }
    const [lieu, assignerLieu] = useState(null);
    const traiterLieu = (event, nouvelleValeur) => {
      assignerLieu(nouvelleValeur);
    };
    const [nouveauLieu, assignerNouveauLieu] = useState(null);
    const traiterNouveauLieu = (event, nouvelleValeur) => {
      assignerNouveauLieu(nouvelleValeur);
    };
    const [statut, assignerStatut] = useState("");
    const traiterStatut = e => {
      envoi(effacerErreurCompteBancaire());
      assignerStatut(e.target.checked ? "1" : "0");
    };

    const cleanNom = DOMPurify.sanitize(nom, {
      ALLOWED_CHARS: /^[A-Z.-\s]{3,100}$/
    });
    const cleanNouveauNom = DOMPurify.sanitize(nouveauNom, {
      ALLOWED_CHARS: /^[A-Z.-\s]{3,100}$/
    });
    const cleanNum = DOMPurify.sanitize(num, {
      ALLOWED_CHARS: /^[0-9-\s]{12,30}$/
    });
    const cleanNouveauNum = DOMPurify.sanitize(nouveauNum, {
      ALLOWED_CHARS: /^[0-9-\s]{12,30}$/
    });
    const cleanBanque = DOMPurify.sanitize(banque, {
      ALLOWED_CHARS: /^[A-Z.-\s]{3,50}$/
    });
    const cleanNouvelleBanque = DOMPurify.sanitize(nouvelleBanque, {
      ALLOWED_CHARS: /^[A-Z.-\s]{3,50}$/
    });
    const cleanLieu = DOMPurify.sanitize(lieu, {
      ALLOWED_CHARS: /^[A-Z0-9,'.-\s\/]{3,100}$/
    });
    const cleanNouveauLieu = DOMPurify.sanitize(nouveauLieu, {
      ALLOWED_CHARS: /^[A-Z0-9,'.-\s\/]{3,100}$/
    });
    const cleanId = DOMPurify.sanitize(id, {
      ALLOWED_CHARS: /[^a-z0-9-]$/
    });
   
    const traiterAjoutCompteBancaire = async (e) => {
      e.preventDefault();
      envoi(ajoutCompteBancaireAsync(cleanNom, cleanNum.trim(), cleanBanque, cleanLieu, dispatch, socket));
    }

    const traiterModifCompteBancaire = async (e) => {
      e.preventDefault();
      envoi(modifCompteBancaireAsync(cleanId.trim(), cleanNouveauNom, cleanNouveauNum.trim(), cleanNouvelleBanque, cleanNouveauLieu, statut, dispatch, socket));
    }

    const traiterSupprCompteBancaire = async (e) => {
      e.preventDefault();
      envoi(supprCompteBancaireAsync(cleanId.trim(), dispatch, socket));
    }
  
    return (
      <>
        <Snackbar 
          anchorOrigin={{ vertical, horizontal }} 
          open={notifAjoutCompteBancaire} 
          autoHideDuration={6000} 
          onClose={traiterFermerNotification1}
          key={vertical + horizontal + "1"}
        >
          <Notification onClose={traiterFermerNotification1} severity="success" sx={{ width: "100%", bgcolor: "background.paper"}}>
            {lang === "fr" && "Coordonnées bancaires ajoutées avec succès"}
            {lang === "eng" && "Bank details successfully added"}
          </Notification>
        </Snackbar>
        <Snackbar 
          anchorOrigin={{ vertical, horizontal }} 
          open={notifModifCompteBancaire} 
          autoHideDuration={6000} 
          onClose={traiterFermerNotification2}
          key={vertical + horizontal + "2"}
        >
          <Notification onClose={traiterFermerNotification2} severity="warning" sx={{ width: "100%", bgcolor: "background.paper"}}>
            {lang === "fr" && "Coordonnée(s) bancaire(s) modifiée(s) avec succès"}
            {lang === "eng" && "Bank detail successfully edited"}
          </Notification>
        </Snackbar>
        <Snackbar 
          anchorOrigin={{ vertical, horizontal }} 
          open={notifSupprCompteBancaire} 
          autoHideDuration={6000} 
          onClose={traiterFermerNotification3}
          key={vertical + horizontal + "3"}
        >
          <Notification onClose={traiterFermerNotification3} severity="error" sx={{ width: "100%", bgcolor: "background.paper"}}>
            {lang === "fr" && "Coordonnées bancaires supprimée avec succès"}
            {lang === "eng" && "Bank details successfully deleted"}
          </Notification>
        </Snackbar>
        <Tooltip title={lang === "eng" ? "Add" : "Ajouter"}>
          <MDButton variant="gradient" color="success" iconOnly sx={{float: "right"}} onClick={traiterOuvrir1}>
            <Icon>add</Icon>
          </MDButton>
        </Tooltip>
        <Modal isOpen={ouvrir8} onRequestClose={traiterFermer1} ariaHideApp={false} style={styleModal430}>
          <MDBox 
            sx={{padding: 3, height: 525}}
            variant="gradient" 
            bgColor={darkMode ? "dark": "#fff"}
            borderRadius="lg"
            coloredShadow={darkMode ? "dark": "light"}
          >
            <Grid container spacing={3} alignItems="center">
              <Grid item>
                <MDBox height="100%" lineHeight={1}>
                  <MDTypography variant="h6" fontWeight="medium">
                    {lang === "fr" && "AJOUT"}
                    {lang === "eng" && "ADD"}
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>
            <br />
            <MDBox component="form" role="form" onSubmit={traiterAjoutCompteBancaire} autoComplete="off">
              <MDBox mb={2} sx={{height: 70, lineHeight: 0.5}}>
                <MDInput 
                  type="text" 
                  required 
                  label=  {lang === "fr" ? "Intitulé du compte" : "Account name"}
                  value={nom}
                  onChange={traiterNom} 
                  sx={{width: "97%"}} 
                  error={nom === "" ? false : patternNom.test(nom) ? false : true}
                  success={nom === "" ? false : patternNom.test(nom) ? true : false} 
                />
                {nom !== "" && !(patternNom.test(nom)) && 
                  <MDTypography variant="button" color="error">
                    <br />
                    {lang === "fr" && "3 caractères min et 100 caractères max"}
                    {lang === "eng" && "3 characters min and 100 Max characters"}
                  </MDTypography>
                }
              </MDBox>
              <MDBox mb={2} sx={{height: 70, lineHeight: 0.5}}>
                <MDInput 
                  type="text" 
                  required 
                  label=  {lang === "fr" ? "Numéro de compte" : "Account number"}
                  value={num}
                  onChange={traiterNum} 
                  sx={{width: "97%"}} 
                  error={num === "" ? false : patternNum.test(num) ? false : true}
                  success={num === "" ? false : patternNum.test(num) ? true : false} 
                />
                {num !== "" && !(patternNum.test(num)) && 
                  <MDTypography variant="button" color="error">
                    <br />
                    {lang === "fr" && "12 caractères min et 30 caractères max"}
                    {lang === "eng" && "12 characters min and 30 Max characters"}
                  </MDTypography>
                }
                {erreurAjoutCompteBancaire === "Numéro de compte existant" && 
                  <MDTypography variant="button" color="error">
                    <br />
                    {lang === "fr" && "Numéro de compte existant"}
                    {lang === "eng" && "Existing bank account number"}
                  </MDTypography>
                }
              </MDBox>
              <MDBox mb={2} sx={{height: 70, lineHeight: 0.5}}>
                <MDInput 
                  type="text" 
                  required 
                  label=  {lang === "fr" ? "Nom de la banque" : "Bank name"}
                  value={banque}
                  onChange={traiterBanque} 
                  sx={{width: "97%"}} 
                  error={banque === "" ? false : patternBanque.test(banque) ? false : true}
                  success={banque === "" ? false : patternBanque.test(banque) ? true : false} 
                />
                {banque !== "" && !(patternBanque.test(banque)) && 
                  <MDTypography variant="button" color="error">
                    <br />
                    {lang === "fr" && "3 caractères min et 100 caractères max"}
                    {lang === "eng" && "3 characters min and 100 Max characters"}
                  </MDTypography>
                }
              </MDBox>
              <MDBox mb={3.5} sx={{height: 65}}>
                {chargementLieux ? 
                  <MDTypography variant="button" color="dark" mt={1}>
                    {lang === "fr" && "Chargement..."}
                    {lang === "eng" && "Loading..."}
                  </MDTypography> :
                  <StyledAutocomplete
                    disableClearable
                    openOnFocus
                    clearOnEscape
                    options={obtenirCombinations(listeLieux)}
                    getOptionLabel={(option) => option ? option : ""}
                    noOptionsText={lang === "fr" ? "Non trouvé" : "Not found"}
                    sx={{width: "97%"}} 
                    value={lieu} 
                    onChange={traiterLieu}
                    renderInput={(params) => (
                      <MDInput required type="text" label={lang === "fr" ? "Lieu" : "Place"} {...params} />
                    )}
                  />
                }
              </MDBox>
              <MDBox mt={4} mb={1} sx={{textAlign: "center"}}>
                <MDBox mb={1}>
                  <MDButton type="button" variant="gradient" color="error" onClick={traiterFermer1}>
                    {lang === "fr" && "Annuler"}
                    {lang === "eng" && "Cancel"}
                  </MDButton>
                  &nbsp;&nbsp;
                  <MDButton disabled={disabled} type="submit" variant="gradient" color="info">
                    {lang === "fr" && "Ajouter"}
                    {lang === "eng" && "Add"}
                  </MDButton>
                </MDBox>
                <MDBox>
                  {erreurAjoutCompteBancaire === "Champ(s) vide(s) dans la requête" && 
                    <MDTypography variant="button" color="error" mt={1}>
                      {lang === "fr" && "Champ(s) vide(s) dans la requête"}
                      {lang === "eng" && "Empty field (s) in the request"}
                    </MDTypography>
                  }
                </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
        </Modal>
        <Modal 
          isOpen={ouvrir08}
          onAfterOpen={traiterOuvrir2(
            data.id, 
            data.nom, 
            data.num, 
            data.banque, 
            data.lieu, 
            data.statut
          )}  
          onRequestClose={traiterFermer2} 
          ariaHideApp={false} 
          style={styleModal430}
        >
          <MDBox 
            sx={{padding: 3, height: 610}}
            variant="gradient" 
            bgColor={darkMode ? "dark": "#fff"}
            borderRadius="lg"
            coloredShadow={darkMode ? "dark": "light"}
          >
            <Grid container spacing={3} alignItems="center">
              <Grid item>
                <MDBox height="100%" lineHeight={1}>
                  <MDTypography variant="h6" fontWeight="medium">
                    {lang === "fr" && "MODIFICATION"}
                    {lang === "eng" && "EDIT"}
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>
            <br />
            <MDBox component="form" role="form" onSubmit={traiterModifCompteBancaire} autoComplete="off">
              <MDBox mb={2} sx={{height: 70, lineHeight: 0.5}}>
                <MDInput 
                  type="text" 
                  required 
                  label=  {lang === "fr" ? "Intitulé du compte" : "Account name"}
                  value={nouveauNom}
                  onChange={traiterNouveauNom} 
                  sx={{width: "97%"}} 
                  error={nouveauNom === "" ? false : patternNom.test(nouveauNom) ? false : true}
                  success={nouveauNom === "" ? false : patternNom.test(nouveauNom) ? true : false} 
                />
                {nouveauNom !== "" && !(patternNom.test(nouveauNom)) && 
                  <MDTypography variant="button" color="error">
                    <br />
                    {lang === "fr" && "3 caractères min et 100 caractères max"}
                    {lang === "eng" && "3 characters min and 100 Max characters"}
                  </MDTypography>
                }
              </MDBox>
              <MDBox mb={2} sx={{height: 70, lineHeight: 0.5}}>
                <MDInput 
                  type="text" 
                  required 
                  label=  {lang === "fr" ? "Numéro de compte" : "Account number"}
                  value={nouveauNum}
                  onChange={traiterNouveauNum} 
                  sx={{width: "97%"}} 
                  error={nouveauNum === "" ? false : patternNum.test(nouveauNum) ? false : true}
                  success={nouveauNum === "" ? false : patternNum.test(nouveauNum) ? true : false} 
                />
                {nouveauNum !== "" && !(patternNum.test(nouveauNum)) && 
                  <MDTypography variant="button" color="error">
                    <br />
                    {lang === "fr" && "12 caractères min et 30 caractères max"}
                    {lang === "eng" && "12 characters min and 30 Max characters"}
                  </MDTypography>
                }
                {erreurModifCompteBancaire === "Numéro de compte existant" && 
                  <MDTypography variant="button" color="error">
                    <br />
                    {lang === "fr" && "Numéro de compte existant"}
                    {lang === "eng" && "Existing bank account number"}
                  </MDTypography>
                }
              </MDBox>
              <MDBox mb={2} sx={{height: 70, lineHeight: 0.5}}>
                <MDInput 
                  type="text" 
                  required 
                  label=  {lang === "fr" ? "Nom de la banque" : "Bank name"}
                  value={nouvelleBanque}
                  onChange={traiterNouvelleBanque} 
                  sx={{width: "97%"}} 
                  error={nouvelleBanque === "" ? false : patternBanque.test(nouvelleBanque) ? false : true}
                  success={nouvelleBanque === "" ? false : patternBanque.test(nouvelleBanque) ? true : false} 
                />
                {nouvelleBanque !== "" && !(patternBanque.test(nouvelleBanque)) && 
                  <MDTypography variant="button" color="error">
                    <br />
                    {lang === "fr" && "3 caractères min et 100 caractères max"}
                    {lang === "eng" && "3 characters min and 100 Max characters"}
                  </MDTypography>
                }
              </MDBox>
              <MDBox mb={3.5} sx={{height: 65}}>
                {chargementLieux ? 
                  <MDTypography variant="button" color="dark" mt={1}>
                    {lang === "fr" && "Chargement..."}
                    {lang === "eng" && "Loading..."}
                  </MDTypography> :
                  <StyledAutocomplete
                    disableClearable
                    openOnFocus
                    clearOnEscape
                    options={obtenirCombinations(listeLieux)}
                    getOptionLabel={(option) => option ? option : ""}
                    noOptionsText={lang === "fr" ? "Non trouvé" : "Not found"}
                    sx={{width: "97%"}} 
                    value={nouveauLieu} 
                    onChange={traiterNouveauLieu}
                    renderInput={(params) => (
                      <MDInput required type="text" label={lang === "fr" ? "Lieu" : "Place"} {...params} />
                    )}
                  />
                }
              </MDBox>
              <MDBox>
                <MDBox mb={2}>
                  <MDTypography display="block" variant="caption" color="text" fontWeight="medium" mt={1}>
                    {lang === "fr" && "Statut (Actif/Inactif) *"}
                    {lang === "eng" && "Statut (Active/Inactive) *"}
                  </MDTypography>
                  <Switch checked={statut === "1" ? true : false} onChange={traiterStatut} />
                </MDBox>
              </MDBox>
              <MDBox mt={4} mb={1} sx={{textAlign: "center"}}>
                <MDBox mb={1}>
                  <MDButton type="button" variant="gradient" color="error" onClick={traiterFermer2}>
                    {lang === "fr" && "Annuler"}
                    {lang === "eng" && "Cancel"}
                  </MDButton>
                  &nbsp;&nbsp;
                  <MDButton disabled={disabled} type="submit" variant="gradient" color="info">
                    {lang === "fr" && "Modifier"}
                    {lang === "eng" && "Edit"}
                  </MDButton>
                </MDBox>
                <MDBox>
                  {erreurModifCompteBancaire === "Champ(s) vide(s) dans la requête" && 
                    <MDTypography variant="button" color="error" mt={1}>
                      {lang === "fr" && "Champ(s) vide(s) dans la requête"}
                      {lang === "eng" && "Empty field (s) in the request"}
                    </MDTypography>
                  }
                  {erreurModifCompteBancaire === "Aucune modification" && 
                    <MDTypography variant="button" color="error" mt={1}>
                      {lang === "fr" && "Aucune modification"}
                      {lang === "eng" && "No modification"}
                    </MDTypography>
                  }
                </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
        </Modal>
        <Modal isOpen={ouvrir9} onAfterOpen={traiterOuvrir3(data.id)} onRequestClose={traiterFermer3} ariaHideApp={false} style={styleModal430}>
          <MDBox 
            sx={{padding: 3, height: 190, textAlign: "center"}}
            variant="gradient" 
            bgColor={darkMode ? "dark": "#fff"}
            borderRadius="lg"
            coloredShadow={darkMode ? "dark": "light"}
          >
            <MDBox component="form" role="form" onSubmit={traiterSupprCompteBancaire} autoComplete="off">
              <MDTypography display="block" variant="button" color="text" fontWeight="medium">
                {lang === "fr" && "Êtes-vous sûre de vouloir supprimer ?"}
                {lang === "eng" && "Are you sure you want to delete?"}
              </MDTypography>
              <MDBox mt={4} mb={1}>
                <MDButton disabled={disabled} type="submit" variant="gradient" color="info">
                  {lang === "fr" && "Oui"}
                  {lang === "eng" && "Yes"}
                </MDButton>
                &nbsp;&nbsp;
                <MDButton variant="gradient" color="info" onClick={traiterFermer3}>
                  {lang === "fr" && "Non"}
                  {lang === "eng" && "No"}
                </MDButton>
              </MDBox>
              {erreurSupprCompteBancaire && 
                <MDTypography variant="button" color="error" mt={1}>
                  {lang === "fr" && "Impossible de supprimer"}
                  {lang === "eng" && "Unable to delete"}
                </MDTypography>
              }
            </MDBox>
          </MDBox>
        </Modal>
      </>
    );
  };

  export default NotificationsEtDialoguesUtilisateur;