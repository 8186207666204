import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    chargementListeProfils: true,
    listeProfils: [],
    pageP: !JSON.parse(localStorage.getItem("pageP")) ? {page: 0, pageSize: 50} : JSON.parse(localStorage.getItem("pageP")),
    selectedRowP: [],
    chargementListeUtilisateurs: true,
    listeUtilisateurs: [],
    pageU: !JSON.parse(localStorage.getItem("pageU")) ? {page: 0, pageSize: 50} : JSON.parse(localStorage.getItem("pageU")),
    selectedRowU: [],
    chargementListeNatureProduits: true,
    listeNatureProduits: [],
    pageNP: !JSON.parse(localStorage.getItem("pageNP")) ? {page: 0, pageSize: 50} : JSON.parse(localStorage.getItem("pageNP")),
    selectedRowNP: [],
    chargementREpartitionRedev: true,
    rEpartitionRedevances: [],
    chargementListeComptesBancaires: true,
    listeComptesBancaires: [],
    pageCB: !JSON.parse(localStorage.getItem("pageCB")) ? {page: 0, pageSize: 50} : JSON.parse(localStorage.getItem("pageCB")),
    selectedRowCB: [],
    chargementListeDEclarations: true,
    listeDEclarations: [],
    changeColD: !JSON.parse(localStorage.getItem("changeColD")) ? false : JSON.parse(localStorage.getItem("changeColD")),
    pageD: !JSON.parse(localStorage.getItem("pageD")) ? {page: 0, pageSize: 50} : JSON.parse(localStorage.getItem("pageD")),
    selectedRowD: [],
    chargementListeDP: true,
    listeDP: [],
    chargementListeNotesDEbits: true,
    listeNotesDEbits: [],
    changeColND: !JSON.parse(localStorage.getItem("changeColND")) ? false : JSON.parse(localStorage.getItem("changeColND")),
    pageND: !JSON.parse(localStorage.getItem("pageND")) ? {page: 0, pageSize: 50} : JSON.parse(localStorage.getItem("pageND")),
    selectedRowND: [],
}
   
export const partieTable = createSlice({
    name: "table",
    initialState,
    reducers: {
        obtentionListeProfils: (state, action) => {
            return {
                ...state,
                chargementListeProfils: false,
                listeProfils: action.payload
            }
        },
        setPageP: (state) => {
            return {
                ...state,
                pageP: JSON.parse(localStorage.getItem("pageP"))
            }
        },
        setSelectedRowP: (state, action) => {
            return {
                ...state,
                selectedRowP: action.payload
            }
        },
        obtentionListeUtilisateurs: (state, action) => {
            return {
                ...state,
                chargementListeUtilisateurs: false,
                listeUtilisateurs: action.payload
            }
        },
        setPageU: (state) => {
            return {
                ...state,
                pageU: JSON.parse(localStorage.getItem("pageU"))
            }
        },
        setSelectedRowU: (state, action) => {
            return {
                ...state,
                selectedRowU: action.payload
            }
        },
        obtentionListeNatureProduits: (state, action) => {
            return {
                ...state,
                chargementListeNatureProduits: false,
                listeNatureProduits: action.payload
            }
        },
        setPageNP: (state) => {
            return {
                ...state,
                pageNP: JSON.parse(localStorage.getItem("pageNP"))
            }
        },
        setSelectedRowNP: (state, action) => {
            return {
                ...state,
                selectedRowNP: action.payload
            }
        },
        obtentionREpartitionRedev: (state, action) => {
            return {
                ...state,
                chargementREpartitionRedev: false,
                rEpartitionRedevances: action.payload
            }
        },
        obtentionListeComptesBancaires: (state, action) => {
            return {
                ...state,
                chargementListeComptesBancaires: false,
                listeComptesBancaires: action.payload
            }
        },
        setPageCB: (state) => {
            return {
                ...state,
                pageCB: JSON.parse(localStorage.getItem("pageCB"))
            }
        },
        setSelectedRowCB: (state, action) => {
            return {
                ...state,
                selectedRowCB: action.payload
            }
        },
        obtentionListeDEclarations: (state, action) => {
            return {
                ...state,
                chargementListeDEclarations: false,
                listeDEclarations: action.payload
            }
        },
        setChangeColD: (state) => {
            return {
                ...state,
                changeColD: JSON.parse(localStorage.getItem("changeColD"))
            }
        },
        setPageD: (state) => {
            return {
                ...state,
                pageD: JSON.parse(localStorage.getItem("pageD"))
            }
        },
        setSelectedRowD: (state, action) => {
            return {
                ...state,
                selectedRowD: action.payload
            }
        },
        obtentionListeDP: (state, action) => {
            return {
                ...state,
                chargementListeDP: false,
                listeDP: action.payload
            }
        }, 
        obtentionListeNotesDEbits: (state, action) => {
            return {
                ...state,
                chargementListeNotesDEbits: false,
                listeNotesDEbits: action.payload
            }
        },
        setChangeColND: (state) => {
            return {
                ...state,
                changeColND: JSON.parse(localStorage.getItem("changeColND"))
            }
        },
        setPageND: (state) => {
            return {
                ...state,
                pageND: JSON.parse(localStorage.getItem("pageND"))
            }
        },
        setSelectedRowND: (state, action) => {
            return {
                ...state,
                selectedRowND: action.payload
            }
        },
    }
});

export const {
    obtentionListeProfils,
    setPageP,
    setSelectedRowP,
    obtentionListeNatureProduits,
    setPageNP,
    setSelectedRowNP,
    obtentionREpartitionRedev,
    obtentionListeComptesBancaires,
    setPageCB,
    setSelectedRowCB,
    obtentionListeUtilisateurs,
    setPageU,
    setSelectedRowU,
    obtentionListeDEclarations,
    setChangeColD,
    setPageD,
    setSelectedRowD,
    obtentionListeDP,
    obtentionListeNotesDEbits,
    setChangeColND,
    setPageND,
    setSelectedRowND,
} = partieTable.actions;
   
export default partieTable.reducer;