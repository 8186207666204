import io from 'socket.io-client';
// Contexte du Material Dashboard 2 React
import { setSocket } from "Contexte";
import {
    chargementConnexionUtilisateur, 
    failliteConnexionUtilisateur,
    confirmationUtilisateurFaite, 
    chargementConfirmationToken, 
    finConfirmationToken, 
    chargementDisjonctionUtilisateur, 
    disjonctionUtilisateur
} from "Simplificateurs/SimplificateursAuthentification";
import { 
    fermerTousDialoguesUtilisateur, 
    fermerNotifAutoDisjonction, 
    modifAutoDisjonctionFaite, 
    modifAutoDEsactivationFaite 
} from "Simplificateurs/SimplificateursUtilisateur";
import { fermerTousDialoguesNP } from "Simplificateurs/SimplificateursNatureProduit";
import { fermerTousDialoguesCB } from "Simplificateurs/SimplificateursCompteBancaire";
import { fermerTousDialoguesDEclaration } from "Simplificateurs/SimplificateursDEclaration";
import { serviceConnexion, serviceConfirmationToken, serviceDisjonction } from 'Services/Authentification';

const middleware = (socket) => {
    socket.on('auth', (success) => {
        if (!success) {
            return socket.disconnect();
        }
    });
};

const API_URL = window.location.protocol + "//" + window.location.host;

export const connexionAsync = (identifiant, passe, dispatch) => async envoi => {
    envoi(chargementConnexionUtilisateur());
    const resultat = await serviceConnexion(identifiant, passe);
    if (resultat.error) {
        return envoi(failliteConnexionUtilisateur(resultat.response.data));
    }
    const API_URL = window.location.protocol + "//" + window.location.host;
    const token = resultat.data.token;
    const socket = await io.connect(`${API_URL}`, {
        withCredentials: true,
        auth: {
            token,
        },
        middleware: [middleware]
    });
    setSocket(dispatch, socket);
    envoi(confirmationUtilisateurFaite(resultat.data));
    envoi(modifAutoDisjonctionFaite({msg: resultat.data.info.AutoDisjonction}));
    envoi(modifAutoDEsactivationFaite({msg:resultat.data.info.AutoDEsactivation}));
}

export const confirmationTokenAsync = (dispatch, socket, authSilencieuse = false) => async envoi => {
    envoi(chargementConfirmationToken(authSilencieuse));
    const resultat = await serviceConfirmationToken();
    envoi(finConfirmationToken());
    if (resultat.error) {
        if (resultat.response && [401, 403].includes(resultat.response.status)) return disjonctionAsync(dispatch, socket);
    }
    else if (resultat.status === 204) {
        envoi(finConfirmationToken());
    } else {
        envoi(confirmationUtilisateurFaite(resultat.data));
        envoi(modifAutoDisjonctionFaite({msg: resultat.data.info.AutoDisjonction}));
        envoi(modifAutoDEsactivationFaite({msg:resultat.data.info.AutoDEsactivation}));
        if (!socket) {
            const token = resultat.data.token;
            const nouveauSocket = await io.connect(`${API_URL}`, {
                withCredentials: true,
                auth: {
                    token,
                },
                middleware: [middleware]
            });
            setSocket(dispatch, nouveauSocket);
        }
    }
}

export const disjonctionAsync = (dispatch, socket) => async envoi => {
    envoi(fermerTousDialoguesUtilisateur());
    envoi(fermerNotifAutoDisjonction());
    envoi(fermerTousDialoguesNP());
    envoi(fermerTousDialoguesCB());
    envoi(fermerTousDialoguesDEclaration());

    envoi(chargementDisjonctionUtilisateur());
    serviceDisjonction();
    envoi(disjonctionUtilisateur());
    
    if(socket !== null) {
        socket.disconnect();
        setSocket(dispatch, null);
    }
}