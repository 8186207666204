import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    disabled: false,
    vertical: "top",
    horizontal: "center",
    ouvrir10: false,
    erreurValidationProduit1: null,
    erreurValidationProduit2: null,
    activeStep: 0,
    erreurAjoutDEclaration: null,
    notifAjoutDEclaration: false,
    data: {},
    erreurModifDEclaration: null,
    notifModifDEclaration: false,
    ouvrir11: false,
    erreurValidDEclaration: null,
    notifValidDEclaration: false,
    ouvrir12: false
}
   
export const partieDEclaration = createSlice({
    name: "dEclaration",
    initialState,
    reducers: {
        chargement: (state) => {
            return {
                ...state,
                disabled: true,
                erreurAjoutDEclaration: null
            }
        },
        ouvrirFDialogueAjoutDcl: (state) => {
            return {
                ...state,
                ouvrir10: true,
                disabled: false
            }
        },
        fermerFDialogueAjoutDcl: (state) => {
            return {
                ...state,
                ouvrir10: false,
                erreurValidationProduit1: null,
                erreurValidationProduit2: null,
                erreurAjoutDEclaration: null
            }
        },
        failliteValidationProduit1: (state, action) => {
            const { error } = action.payload;
            return {
                ...state,
                activeStep: 0,
                erreurValidationProduit1: error,
                disabled: false
            }
        },
        validationProduit1Faite: (state) => {
            return {
                ...state,
                erreurValidationProduit1: null,
                activeStep: 1,
                disabled: false
            }
        },
        failliteValidationProduit2: (state, action) => {
            const { error } = action.payload;
            return {
                ...state,
                activeStep: 1,
                erreurValidationProduit2: error,
                disabled: false
            }
        },
        validationProduit2Faite: (state) => {
            return {
                ...state,
                erreurValidationProduit2: null,
                activeStep: 2,
                disabled: false
            }
        },
        avant0: (state) => {
            return {
                ...state,
                activeStep: 0
            }
        },
        avant1: (state) => {
            return {
                ...state,
                activeStep: 1
            }
        },
        failliteAjoutDEclaration: (state, action) => {
            const { error } = action.payload;
            return {
                ...state,
                erreurAjoutDEclaration: error,
                disabled: false
            }
        },
        ajoutDEclarationFait: (state) => {
            return {
                ...state,
                erreurAjoutDEclaration: null,
                disabled: false,
                ouvrir10: false,
                activeStep: 0,
                notifAjoutDEclaration: true
            }
        },
        fermerNotifAjoutD: (state) => {
            return {
                ...state,
                notifAjoutDEclaration: false
            }
        },


        
        ouvrirFDialogueModifD: (state, action) => {
            return {
                ...state,
                data: action.payload,
                ouvrir10: true,
                disabled: false
            }
        },
        fermerFDialogueModifD: (state) => {
            return {
                ...state,
                ouvrir10: false,
                data: {},
                erreurModifDEclaration: null
            }
        },
        failliteModificationDEclaration: (state, action) => {
            const { error } = action.payload;
            return {
                ...state,
                erreurModifDEclaration: error,
                disabled: false
            }
        },
        modificationDEclarationFaite: (state) => {
            return {
                ...state,
                erreurModifDEclaration: null,
                disabled: false,
                ouvrir10: false,
                notifModifDEclaration: true
            }
        },
        fermerNotifModifD: (state) => {
            return {
                ...state,
                notifModifDEclaration: false
            }
        },







        ouvrirFDialogueValidDcl: (state, action) => {
            return {
                ...state,
                ouvrir11: true,
                data: action.payload,
                disabled: false
            }
        },
        fermerFDialogueValidDcl: (state) => {
            return {
                ...state,
                ouvrir11: false,
                erreurValidDEclaration: null,
                data: {}
            }
        },
        failliteValidDEclaration: (state, action) => {
            const { error } = action.payload;
            return {
                ...state,
                erreurValidDEclaration: error,
                disabled: false
            }
        },
        validDEclarationFaite: (state) => {
            return {
                ...state,
                erreurValidDEclaration: null,
                disabled: false,
                ouvrir11: false,
                notifValidDEclaration: true
            }
        },
        fermerNotifValidDcl: (state) => {
            return {
                ...state,
                notifValidDEclaration: false
            }
        },
        ouvrirFSurvolCamions: (state, action) => {
            return {
                ...state,
                data: action.payload,
                ouvrir12: true
            }
        },
        fermerFSurvolCamions: (state) => {
            return {
                ...state,
                ouvrir12: false,
                data: {}
            }
        },
        effacerErreurValidation1: (state) => {
            return {
                ...state,
                erreurValidationProduit1: null
            }
        },
        effacerErreurValidation2: (state) => {
            return {
                ...state,
                erreurValidationProduit2: null
            }
        },
        fermerTousDialoguesDEclaration: (state) => {
            return {
                ...state,
                ouvrir10: false,
                ouvrir11: false,
                ouvrir12: false
            }
        }
    }
});

export const {
    chargement,
    ouvrirFDialogueAjoutDcl,
    fermerFDialogueAjoutDcl,
    failliteValidationProduit1,
    validationProduit1Faite,
    failliteValidationProduit2,
    validationProduit2Faite,
    avant0,
    avant1,
    failliteAjoutDEclaration,
    ajoutDEclarationFait,
    fermerNotifAjoutD,



    ouvrirFDialogueModifD,
    fermerFDialogueModifD,
    failliteModificationDEclaration,
    modificationDEclarationFaite,
    fermerNotifModifD,

    ouvrirFDialogueValidDcl,
    fermerFDialogueValidDcl,
    failliteValidDEclaration,
    validDEclarationFaite,
    fermerNotifValidDcl,
    ouvrirFSurvolCamions,
    fermerFSurvolCamions,
    effacerErreurValidation1,
    effacerErreurValidation2,
    fermerTousDialoguesDEclaration
} = partieDEclaration.actions;
   
export default partieDEclaration.reducer;