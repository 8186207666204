import axios from "axios";
axios.defaults.withCredentials = true;
 
const API_URL = window.location.protocol + "//" + window.location.host;

export const serviceREpartitionRedev = async () => {
    try {
        return await axios.get(`${API_URL}/api/rEpartition/redevance/liste`);
    } catch (err) {
        return {
            error: true,
            response: err.response
        };
    }
}

export const serviceModifREpartitionRedev = async (rEpartitionRedevances) => {
    try {
        return await axios.post(`${API_URL}/api/rEpartition/redevance/modifier`, {rEpartitionRedevances});
    } catch (err) {
        return {
            error: true,
            response: err.response
        };
    }
};