/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  La fonction rgba() vous aide à créer un code couleur rgba, elle utilise la fonction hexToRgb()
  pour convertir le code hexadécimal en rgb pour l'utiliser dans le format de couleur rgba.
 */

// Fonctions d'assistance du Material Dashboard 2 PRO React
import hexToRgb from "Outils/theme/functions/hexToRgb";

function rgba(color, opacity) {
  return `rgba(${hexToRgb(color)}, ${opacity})`;
}

export default rgba;
