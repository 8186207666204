import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    disabled: false,
    estAuthentique: false,
    erreurConnexion: null,
    chargementAuth: true,
    chargementDijonction: false,
    token: null,
    expiration: null, 
    info: null
}
   
export const partieAuthentification = createSlice({
    name: "authentification",
    initialState,
    reducers: {
        chargementConnexionUtilisateur: (state) => {
            return {
                ...state,
                disabled: true
            }
        },
        failliteConnexionUtilisateur: (state, action) => {
            const { error } = action.payload;
            return {
                ...state,
                erreurConnexion: error,
                disabled: false
            }
        },
        confirmationUtilisateurFaite: (state, action) => {
            const { token, expiration, info } = action.payload;
            return {
                ...state,
                erreurConnexion: null,
                token,
                expiration,
                info,
                estAuthentique: true,
                disabled: false,
                chargementAuth: false
            }
        },
        chargementConfirmationToken: (state, action) => {
            const authSilencieuse = action.payload;
            if (authSilencieuse)
            return {
                ...state
            }
        },
        finConfirmationToken: (state) => {
            return {
                ...state,
                chargementAuth: false
            };
        },
        chargementDisjonctionUtilisateur: (state) => {
            return {
                ...state,
                chargementDijonction: true
            };
        },
        disjonctionUtilisateur: (state) => {
            localStorage.removeItem("page");
            localStorage.removeItem("columnVisibilityModel");
            localStorage.removeItem("sortModel");
            localStorage.removeItem("filterModel");
            return {
                ...state,
                estAuthentique: false,
                chargementDijonction: false,
                chargementAuth: false
            };
        }
    }
});

export const {
    chargementConnexionUtilisateur,
    failliteConnexionUtilisateur,
    confirmationUtilisateurFaite,
    chargementConfirmationToken,
    finConfirmationToken,
    chargementDisjonctionUtilisateur,
    disjonctionUtilisateur
} = partieAuthentification.actions;
   
export default partieAuthentification.reducer;