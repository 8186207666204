/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import DOMPurify from 'dompurify';
import { modifREpartitionRedevAsync } from "ActionsAsync/ActionsREpartitionRedevAsync";
import { fermerNotifModifREpRedev, effacerErreurModifREpRedev } from "Simplificateurs/SimplificateursREpartitionRedevance";

// Composants du @mui material
import LinearProgress from '@mui/material/LinearProgress';
import Icon from "@mui/material/Icon";
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';

// Composants du Material Dashboard 2 React
import MDBox from "Composants/MDBox";
import MDTypography from "Composants/MDTypography";
import MDInput from "Composants/MDInput";
import MDButton from "Composants/MDButton";
import { styled } from "@mui/material/styles";

// Table
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import { enUS } from '@mui/material/locale';

// Contexte Material Dashboard 2 PRO React
import { useMaterialUIController } from "Contexte";

import { 
  Notification,
  frFR, 
  globalStyles,
  CustomNoRowsOverlay, 
  CustomPagination
} from "Vues/Utilitaires";

var h = window.innerHeight - 340;

function NotificationsREpartitionRedev({ x }) {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, lang, socket } = controller;

  const envoi = useDispatch();

  const rEpartitionRedevanceObj = useSelector((state) => state.rEpartitionRedevance);
  const {
    disabled,    
    vertical,
    horizontal,
    erreurModifRepartitionRedev, 
    notifModifRepartitionRedev
  } = rEpartitionRedevanceObj;

  useEffect(() => {
    assignerListe(x.rEpartitionRedevances);
  }, [x.rEpartitionRedevances]);

  const traiterFermerNotification = () => {
    assignerErreurValidation(0);
    assignerNom("");
    assignerRedevancePourc(Number.parseFloat("0.00").toFixed(2));
    envoi(effacerErreurModifREpRedev());
    envoi(fermerNotifModifREpRedev());
  };

  const pattern = new RegExp(/^[A-Z\-.\s\(\)]{3,50}$/);
  const [nom, assignerNom] = useState("");
  const traiterNom = e => {
    assignerErreurValidation(0);
    envoi(effacerErreurModifREpRedev());
    let target = e.target;
    const cursor = e.target.selectionStart;
    assignerNom((e.target.value).replace(/[^A-Za-z\-.\s\(\)]/gi, "").toUpperCase());
    setTimeout(() => {
      target.setSelectionRange(cursor, cursor);
    }, 10);
  };

  const [redevancePourc, assignerRedevancePourc] = useState(Number.parseFloat("0.00").toFixed(2));
  const traiterRedevancePourc = e => {
    assignerErreurValidation(0);
    envoi(effacerErreurModifREpRedev());
    var dec = Number.parseFloat((e.target.value).replace(/[^0-9]/gi, "").substr(0,5) * 0.01).toFixed(2);
    if (dec > 100.00) {
      assignerRedevancePourc(Number.parseFloat("100.00").toFixed(2));
    }
    else {
      assignerRedevancePourc(Number.parseFloat((e.target.value).replace(/[^0-9]/gi, "").substr(0,5) * 0.01).toFixed(2));
    }
  };

  const [erreurValidation, assignerErreurValidation] = useState(0);
  const [liste, assignerListe] = useState([]);
  const traiterListe1 = () => {
    assignerErreurValidation(0);
    envoi(effacerErreurModifREpRedev());
    if(nom === "") {
      return assignerErreurValidation(1);
    }
    const cleanNom = DOMPurify.sanitize(nom, {
      ALLOWED_CHARS: /^[A-Z\-.\s\(\)]{3,50}$/
    });
    if (!pattern.test(cleanNom)) {
      return;
    }
    const invalidationDoublon = liste.find(x => {
      if (x.Nom === cleanNom) {
          return [assignerErreurValidation(2), true];
      }
      return false;
    });
    if (invalidationDoublon) {
      return;
    }
    if(redevancePourc === Number.parseFloat("0.00").toFixed(2)) {
      return assignerErreurValidation(3);
    }
    if (Math.floor((cumulRedevancePourc * 100) + (redevancePourc * 100)) > Math.floor(Number.parseFloat(100).toFixed(2) * 100)) {
      return assignerErreurValidation(4);
    }
    const obj = {
      Nom: cleanNom, 
      RedevancePourc: redevancePourc
    };
    assignerListe([...liste, obj]);
    assignerNom("");
    assignerRedevancePourc(Number.parseFloat("0.00").toFixed(2));
  }

  const traiterListe2 = () => {
    assignerErreurValidation(0);
    assignerNom("");
    assignerRedevancePourc(Number.parseFloat("0.00").toFixed(2));
    assignerListe(x.rEpartitionRedevances);
  }

  const cumulRedevancePourc = liste.reduce((acc, x) => acc + parseFloat(Math.floor(Number.parseFloat(x.RedevancePourc).toFixed(2) * 100) / 100), 0).toFixed(2);
  const memoCumulPourc = useMemo(() => cumulRedevancePourc, [liste, redevancePourc]);

  const supprimer = (index) => {
    assignerErreurValidation(0);
    const copieListe = [...liste];
    copieListe.splice(index, 1);
    assignerListe(copieListe);
  }

  const traiterREpartitionRedev = async (e) => {
    e.preventDefault();
    assignerErreurValidation(0);
    envoi(effacerErreurModifREpRedev());
    if (!liste.length) {
      return assignerErreurValidation(5);
    }
    if (Math.floor((cumulRedevancePourc * 100) + (redevancePourc * 100)) < Math.floor(Number.parseFloat(100).toFixed(2) * 100)) {
      return assignerErreurValidation(6);
    }
    await envoi(modifREpartitionRedevAsync(liste, dispatch, socket));
    assignerNom("");
    assignerRedevancePourc(Number.parseFloat("0.00").toFixed(2));
  }

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    border: 0,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    WebkitFontSmoothing: "auto",
    letterSpacing: "normal",
    fontSize: "13px",
    backgroundColor: darkMode ? "#202940" : "#fff",
    color: darkMode ? "#7b809a" : "rgba(0,0,0,.85)",
    "& .MuiDataGrid-main": {
      color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
    },
    "& .MuiDataGrid-columnSeparator": {
      color: "#7b809a"
    },
    "& .MuiDataGrid-columnHeaders, & .MuiDataGrid-cell": {
      borderBottom: darkMode ? "1px solid #344767" : "1px solid #f0f2f5"
    },
    "& .MuiDataGrid-footerContainer": {
      borderTop: darkMode ? "1px solid #344767" : "1px solid #f0f2f5"
    },
    "& .MuiIconButton-root": {
      color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
    },
    "& .MuiPaginationItem-previousNext": {
      color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
    },
    "& .MuiPaginationItem-textInfo": {
      color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
    },
    "& .Mui-selected": {
      color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)"
    },
    "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
      outline: "none"
    }
  })); 

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        {null}
      </GridToolbarContainer>
    );
  }

  const colonnes = [  
    {
      field: "Option",
      width: 65,
      renderCell: (params) => {
        const rowIndex = params.api.getRowIndexRelativeToVisibleRows(params.row.id);
        const globalIndex = paginationModel.page * paginationModel.pageSize + rowIndex;
        return (
          <MDBox>
              <Tooltip title="Supprimer">
                <MDButton variant="gradient" color="error" size="small" iconOnly onClick={() => supprimer(globalIndex)}>
                  <Icon>delete_forever</Icon>
                </MDButton>
              </Tooltip>
          </MDBox>
        );
      }, 
      sortable: false, 
      filterable: false,
      disableColumnMenu: true,
      disableExport: true
    },
    {
      field: "id",
      headerName: lang === "eng" ? "No." : "N°", 
      width: 50,
      align:"right",
      sortable: false, 
      filterable: false,
      disableColumnMenu: true,
      disableExport: true
    },
    { 
      field: "Nom",
      headerName: lang === "eng" ? "Name" : "Nom", 
      width: 100,
      disableColumnMenu: true
    },
    { 
      field: "RedevancePourc", 
      headerName: lang === "eng" ? "Royalty" : "Redevance", 
      width: 90, 
      renderCell: (params) => (
        <div>
          {lang === "fr" && `${Number.parseFloat(params.row.RedevancePourc).toFixed(2).replace(".", ",")} %`}
          {lang === "eng" && `${Number.parseFloat(params.row.RedevancePourc).toFixed(2)} %`}
        </div>
      ), 
      disableColumnMenu: true 
    }
  ]; 

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5
  });

  return (
    <MDBox>
      <Snackbar 
        anchorOrigin={{ vertical, horizontal }} 
        open={notifModifRepartitionRedev} 
        autoHideDuration={6000} 
        onClose={traiterFermerNotification}
        key={vertical + horizontal}
        >
          <Notification onClose={traiterFermerNotification} severity="success" sx={{ width: "100%", bgcolor: "background.paper"}}>
            {lang === "fr" && "Répartition des redevances minières modifiée avec succès"}
            {lang === "eng" && "Distribution of mining royalties successfully edited"}
          </Notification>
        </Snackbar>
      <MDBox component="form" role="form" onSubmit={traiterREpartitionRedev} autoComplete="off">
        <MDBox mb={2} sx={{height: 70, lineHeight: 0.5}}>
          <MDInput 
            type="text" 
            label={lang === "fr" ? "Nom de l'entité" : "Name of the entity"} 
            value={nom}
            onChange={traiterNom} 
            sx={{width: "75%"}} 
            error={nom === "" ? false : pattern.test(nom) ? false : true}
            success={nom === "" ? false : pattern.test(nom) ? true : false} 
          />
          {nom !== "" && !(pattern.test(nom)) && 
            <MDTypography variant="button" color="error">
              <br />
              {lang === "fr" && "3 caractères min et 50 caractères max"}
              {lang === "eng" && "3 characters min and 50 characters max"}
            </MDTypography>
          }
          {erreurValidation === 1 && 
            <MDTypography variant="button" color="error">
              <br />
              {lang === "fr" && "Case obligatoire"}
              {lang === "eng" && "Required field"}
            </MDTypography>
          }
          {erreurValidation === 2 && 
            <MDTypography variant="button" color="error">
              <br />
              {lang === "fr" && "Déjà présent dans la liste"}
              {lang === "eng" && "Already present in the list"}
            </MDTypography>
          }
        </MDBox>
        <MDBox mb={2} sx={{height: 70, lineHeight: 0.5}}>
          <FormControl variant="outlined" sx={{width: "75%"}}>
            <InputLabel htmlFor="outlined-adornment-perc2">
              {lang === "fr" && "Redevance *"}
              {lang === "eng" && "Royalty *"}
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-perc2"
              endAdornment={
                <span style={{color: darkMode ? "#fff": "black"}}>
                  %
                </span>  
              }
              type="text" 
              label= {lang === "fr" ? "Redevance *" : "Royalty *"}
              value={redevancePourc} 
              onClick={(e) => e.target.setSelectionRange((e.target.value).length, (e.target.value).length)}
              onKeyDown={(e) => e.target.setSelectionRange((e.target.value).length, (e.target.value).length)}
              onChange={traiterRedevancePourc}  
            />
          </FormControl>
          {erreurValidation === 3 && 
            <MDTypography variant="button" color="error">
                <br />
                {lang === "fr" && "La valeur doit être > à 0"}
                {lang === "eng" && "The value must be > 0"}
            </MDTypography>
          }
        </MDBox>
        <MDBox sx={{textAlign: "center", height: 60, width: "75%"}}>
          <MDBox>
            <MDButton type="button" variant="gradient" color="info" onClick={traiterListe1}>
              {lang === "fr" && "Ajouter"}
              {lang === "eng" && "Add"}
            </MDButton>
            &nbsp;&nbsp;
            <MDButton type="button" variant="gradient" color="error" onClick={traiterListe2}>
              {lang === "fr" && "Annuler"}
              {lang === "eng" && "Cancel"}
            </MDButton>
            &nbsp;&nbsp;
            <MDButton disabled={disabled} type="submit" variant="gradient" color="success">
              {lang === "fr" && "Soumettre"}
              {lang === "eng" && "Submit"}
            </MDButton>
          </MDBox>
          <MDBox>
            {erreurValidation === 4 && 
              <MDTypography variant="button" color="error">
                {lang === "fr" && "Le cumul sera > 100 %"}
                {lang === "eng" && "The accumulation will be > 100%"}
              </MDTypography>
            }
            {erreurValidation === 5 && 
              <MDTypography variant="button" color="error" mt={1}>
                {lang === "fr" && "Champ(s) vide(s) dans la requête"}
                {lang === "eng" && "Empty field(s) in the query"}
              </MDTypography>
            }
            {erreurValidation === 6 && 
              <MDTypography variant="button" color="error" mt={1}>
                {lang === "fr" && "Le cumul != 100 %"}
                {lang === "eng" && "The accumulation != 100%"}
              </MDTypography>
            }
            {erreurModifRepartitionRedev === "Le cumul (de la répartition de la redevance) != 100 %" && 
              <MDTypography variant="button" color="error" mt={1}>
                {lang === "fr" && "Le cumul != 100 %"}
                {lang === "eng" && "The accumulation != 100%"}
              </MDTypography>
            }
            {erreurModifRepartitionRedev === "30 éléments max" && 
              <MDTypography variant="button" color="error" mt={1}>
                {lang === "fr" && "30 éléments max"}
                {lang === "eng" && "30 elements max"}
              </MDTypography>
            }
            {erreurModifRepartitionRedev === "Aucune modification" && 
              <MDTypography variant="button" color="error" mt={1}>
                {lang === "fr" && "Aucune modification"}
                {lang === "eng" && "No modification"}
              </MDTypography>
            }
          </MDBox> 
        </MDBox>
      </MDBox>
      <MDBox mb={2} sx={{textAlign: "center", width: "75%"}}>
        <MDTypography variant="button" color="text" fontWeight="regular" key="cumulative-quantity">
          {lang === "fr" && `Pourcentage du Cumul : ${memoCumulPourc.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} % sur 100,00 %`}
          {lang === "eng" && `Cumulative Percentage : ${memoCumulPourc.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} % out of 100.00 %`}
        </MDTypography>
      </MDBox>
      <div style={{height: 270, width: "75%"}}>
        {globalStyles()}
        <StyledDataGrid
          checkboxSelection
          disableRowSelectionOnClick
          density="compact"
          rows={liste.map((x, index) => ({ id: index + 1, ...x }))}
          columns={colonnes} 
          slots= {{
            loadingOverlay: LinearProgress, 
            noRowsOverlay: CustomNoRowsOverlay, 
            noResultsOverlay: CustomNoRowsOverlay,
            toolbar: CustomToolbar, 
            pagination: CustomPagination
          }}
          loading={x.chargementREpartitionRedev}
          componentsProps={{
            panel: {
              sx: {
                "& .MuiDataGrid-panelWrapper": {
                  backgroundColor: darkMode ? "#202940" : "#fff",
                },
                "& .MuiNativeSelect-select": {
                  color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
                },
                "& .MuiSvgIcon-root": {
                  color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
                },
                "& .MuiFormControl-root": {
                  color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
                }
              }
            }
          }}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 5},
            }
          }}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          localeText={lang === "fr" ? frFR : enUS}
        />
      </div>
    </MDBox>
  );
};

export default NotificationsREpartitionRedev;
