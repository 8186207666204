/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  Vous pouvez ajouter une nouvelle route, personnaliser les routes et les supprimer ici.

  Une fois que vous avez ajouté un nouvelle route sur ce fichier, il sera automatiquement visible sur
  le Sidenav.

  Pour ajouter une nouvelle route, vous pouvez suivre les routes existantes dans le tableau des routes.
  1. La clé `type` avec la valeur `collapse` est utilisée pour une route.
  2. La clé `type` avec la valeur `title` est utilisée pour un titre à l'intérieur du Sidenav.
  3. La clé `type` avec la valeur `divider` est utilisée pour un diviseur entre les éléments Sidenav.
  4. La clé `name` est utilisée pour le nom de la route sur le Sidenav.
  5. La clé `key` est utilisée pour la clé de la route (elle vous aidera avec l'accessoire de clé à l'intérieur d'une boucle).
  6. La touche `icon` est utilisée pour l'icône de la route sur le Sidenav, vous devez ajouter un nœud.
  7. La touche `collapse` est utilisée pour créer un élément pliable sur le Sidenav qui a d'autres routes imbriquées, vous devez passer les routes imbriquées à l'intérieur d'un tableau en tant que valeur pour la clé « collapse ».
  8. La clé `route`est utilisée pour stocker l'emplacement de la route qui est utilisé pour le React router.
  9. La clé `href` est utilisée pour stocker l'emplacement des liens externes.
  10. La clé `title` est uniquement pour l'élément avec le type `title` et est utilisée pour le texte du titre sur le Sidenav.
  10. La clé `component` est utilisée pour stocker le composant de sa route.
*/

// Mises en page du Material Dashboard 2 React
import CP from "Vues/CP";
import Profils from "Vues/Profils";
import DEclarations from "Vues/DEclarations";
import Banques from "Vues/Banques";

// Îcones du @mui
import Icon from "@mui/material/Icon";

const routesPersonnellesDP = [
  {
    type: "collapse",
    nom: "Tableau de bord",
    name: "Dashboard",
    key: "tableau_de_bord",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/Session/TableauDeBord",
    component: <CP />
  },
  {
    type: "collapse",
    nom: "Profiles des assujettis",
    name: "Subject profils",
    key: "profils",
    icon: <Icon fontSize="small">people</Icon>,
    route: "/Session/Profils",
    component: <Profils />
  },
  {
    type: "collapse",
    nom: "Liquidation",
    name: "Liquidation",
    key: "liquidation",
    icon: <Icon fontSize="small">ballot</Icon>,
    route: "/Session/Liquidation",
    component: <DEclarations />
  },
  {
    type: "collapse",
    nom: "Comptes bancaires",
    name: "Bank accounts",
    key: "banque",
    icon: <Icon fontSize="small">account_balance</Icon>,
    route: "/Session/Option/Banques",
    component: <Banques />,
  },
];

export default routesPersonnellesDP;