/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


import { useEffect, useState } from "react";

import { PieChart, Pie, Legend, Cell/*, ResponsiveContainer*/ } from "recharts";

// Contexte Material Dashboard 2 PRO React
import { useMaterialUIController } from "Contexte";

import { styleSelect, COLORS } from "Vues/Utilitaires";

var h = window.innerHeight - 340;

function GraphREpartitionRedevance({ x }) {
  const [controller, dispatch] = useMaterialUIController();
  const {lang} = controller;

  const obtenirObjetAvecCouleur = (objets, couleurs) => {
    if (!objets || !objets.length) {
      return [];
    }
    const taille = Math.min(objets.length, couleurs.length);
    return objets.map((objet, index) => {
      return {
        name: objet.Nom,
        value: +objet.RedevancePourc,
        color: couleurs[index % taille]
      };
    });
  };

  const [objetAvecCouleur, assignerObjetAvecCouleur] = useState([]);

  useEffect(() => {
      if (x.rEpartitionRedevances && x.rEpartitionRedevances.length) {
        assignerObjetAvecCouleur(obtenirObjetAvecCouleur(x.rEpartitionRedevances, COLORS));
      }
  }, [x.rEpartitionRedevances]);

  const renderCustomizedLabel = ({
    innerRadius, outerRadius, cx, cy, midAngle, name, value
  }) => {
    const RADIAN = Math.PI / 180;
    // eslint-disable-next-line
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    //const radius = 25 + innerRadius + (outerRadius - innerRadius);
    // eslint-disable-next-line
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    // eslint-disable-next-line
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
 
    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central" style={styleSelect}>
        {lang === "eng" && `${Number.parseFloat(value).toFixed(2)} %`}
        {lang === "fr" && `${Number.parseFloat(value).toFixed(2).replace(".", ",")} %`}
      </text>
    );
  };

  return (
    <div style={{height: h, width: "100%", marginTop: "15px", overflow: "auto"}}>
      <div style={{width: "80%"}}>
        <PieChart width={600} height={300}>
          <Legend layout="vertical" verticalAlign="bottom" align="right" wrapperStyle={{fontSize: "13px"}} />
          <Pie
            data={objetAvecCouleur}
            outerRadius="100%"
            label={renderCustomizedLabel}
            labelLine={false}
            fill="#8884D8"
            dataKey="value"
          >
            {objetAvecCouleur.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </div>
    </div>
  );
};

export default GraphREpartitionRedevance;
