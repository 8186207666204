/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { listeNatureProduitsAsync } from "ActionsAsync/ActionsNatureProduitAsync";
import { setPageNP, setSelectedRowNP } from "Simplificateurs/SimplificateursTable";
import { ouvrirFDialogueModifNP } from "Simplificateurs/SimplificateursNatureProduit";

// Composants du @mui material
import LinearProgress from '@mui/material/LinearProgress';
import Icon from "@mui/material/Icon";
import Tooltip from '@mui/material/Tooltip';
import { styled } from "@mui/material/styles";

// Table
import { 
  DataGrid,
  GridToolbarContainer,
  //GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport, 
} from "@mui/x-data-grid";
import { enUS } from '@mui/material/locale';

// Composants du Material Dashboard 2 React
import MDBox from "Composants/MDBox";
import MDButton from "Composants/MDButton";

// Contexte Material Dashboard 2 PRO React
import { useMaterialUIController } from "Contexte";

import { 
  globalStyles,
  CustomNoRowsOverlay, 
  CustomPagination,
  frFR
} from "Vues/Utilitaires";

var h = window.innerHeight - 340;

function TableNatureProduits() {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, lang, socket } = controller;

  const envoi = useDispatch();

  const tableObj = useSelector((state) => state.table);
  const {
    chargementListeNatureProduits, 
    listeNatureProduits,
    pageNP,
    selectedRowNP
  } = tableObj;

  useEffect(() => {
    const minuteurObtenirListeNP = setTimeout(() => {
      envoi(listeNatureProduitsAsync(dispatch, socket));
    }, 1000);
    return () => {
      clearTimeout(minuteurObtenirListeNP);
    };
  }, []);


  const traiterOuvrir = (id, nom, redevancePourc) => () => {
    envoi(ouvrirFDialogueModifNP({id, nom, redevancePourc}));
  };

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    border: 0,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    WebkitFontSmoothing: "auto",
    letterSpacing: "normal",
    fontSize: "13px",
    backgroundColor: darkMode ? "#202940" : "#fff",
    color: darkMode ? "#7b809a" : "rgba(0,0,0,.85)",
    "& .MuiDataGrid-main": {
      color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
    },
    "& .MuiDataGrid-columnSeparator": {
      color: "#7b809a"
    },
    "& .MuiDataGrid-columnHeaders, & .MuiDataGrid-cell": {
      borderBottom: darkMode ? "1px solid #344767" : "1px solid #f0f2f5"
    },
    "& .MuiDataGrid-footerContainer": {
      borderTop: darkMode ? "1px solid #344767" : "1px solid #f0f2f5"
    },
    "& .MuiIconButton-root": {
      color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
    },
    "& .MuiPaginationItem-previousNext": {
      color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
    },
    "& .MuiPaginationItem-textInfo": {
      color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
    },
    "& .Mui-selected": {
      color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)"
    },
    "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
      outline: "none"
    }
  }));
  
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        {/*<GridToolbarColumnsButton />*/}
        <GridToolbarFilterButton />
        <GridToolbarExport 
          printOptions={{ disableToolbarButton: true }}
          csvOptions={{
            fileName: lang === "fr" ? `${"Liste de nature des produits (" + moment(new Date()).format('DD-MM-YYYY HHmmss') + ")"}` : `${"Product types list (" + moment(new Date()).format('DD-MM-YYYY HHmmss') + ")"}`,
            delimiter: ',',
            utf8WithBom: true,
          }}
        />
      </GridToolbarContainer>
    );
  }

  const colonnes = [
    {
      field: "Option",
      width: 70,
      renderCell: (params) => {
        return (
          <MDBox>
            <Tooltip title={lang === "fr" ? "Modifier" : "Edit"}>
              <MDButton variant="gradient" color="warning" size="small" iconOnly onClick={traiterOuvrir(
                params.row.Id,
                lang === "fr" ? params.row.NomFr : params.row.NomAng,
                params.row.RedevancePourc,
              )}>
                <Icon>edit</Icon>
              </MDButton>
            </Tooltip>
          </MDBox>
        );
      }, 
      sortable: false, 
      filterable: false,
      disableColumnMenu: true,
      disableExport: true
    },
    { 
      field: lang === "fr" ? "NomFr" : "NomAng",
      headerName: lang === "fr" ? "Nom" : "Name", 
      width: 300,
      disableColumnMenu: true 
    },
    { 
      field: "RedevancePourc", 
      headerName: lang === "fr" ? "Redevance" : "Royalty", 
      width: 250, 
      renderCell: (params) => (
        <div>
          {lang === "fr" && `${Number.parseFloat(params.row.RedevancePourc).toFixed(2).replace(".", ",")} %`}
          {lang === "eng" && `${Number.parseFloat(params.row.RedevancePourc).toFixed(2)} %`}
        </div>
      ), 
      valueGetter: (value) => lang === "fr" ? `${Number.parseFloat(value).toFixed(2).replace(".", ",")}` : `${Number.parseFloat(value).toFixed(2)}`,
      disableColumnMenu: true 
    }
  ]; 

  const [paginationModel, setPaginationModel] = useState(pageNP);
  useEffect(() => {
    localStorage.setItem("pageNP", JSON.stringify(paginationModel));
    envoi(setPageNP());
  },[paginationModel])

  return (
    <div style={{ height: h, width: "100%" }}>
      {globalStyles()}
      <StyledDataGrid
        checkboxSelection
        disableRowSelectionOnClick
        density="compact"
        rows={listeNatureProduits}
        getRowId={(row) => row.Id}
        columns={colonnes} 
        slots= {{
          loadingOverlay: LinearProgress, 
          noRowsOverlay: CustomNoRowsOverlay, 
          noResultsOverlay: CustomNoRowsOverlay,
          toolbar: CustomToolbar, 
          pagination: CustomPagination
        }}
        loading={chargementListeNatureProduits}
        componentsProps={{
          panel: {
            sx: {
              "& .MuiDataGrid-panelWrapper": {
                backgroundColor: darkMode ? "#202940" : "#fff",
              },
              "& .MuiNativeSelect-select": {
                color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
              },
              "& .MuiSvgIcon-root": {
                color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
              },
              "& .MuiFormControl-root": {
                color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
              }
            }
          }
        }}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 50},
          },
          sorting: {
            sortModel: !JSON.parse(localStorage.getItem("sortModelNP")) ? lang === "fr" ? [{ field: "NomFr", sort: "asc" }] : [{ field: "NomAng", sort: "asc" }] : JSON.parse(localStorage.getItem("sortModelNP"))
          },
          filter: {
            filterModel: !JSON.parse(localStorage.getItem("filterModelNP")) ? {items: []} : JSON.parse(localStorage.getItem("filterModelNP"))
          }
        }}
        paginationModel={pageNP}
        onPaginationModelChange={setPaginationModel}
        rowSelectionModel={selectedRowNP}
        onRowSelectionModelChange={
          (model) => {
            envoi(setSelectedRowNP(model));
          }
        }
        onSortModelChange={(model) => localStorage.setItem("sortModelNP", JSON.stringify(model))}
        onFilterModelChange={(model)=> localStorage.setItem("filterModelNP", JSON.stringify(model))}
        localeText={lang === "fr" ? frFR : enUS}
      />
    </div>
  );
};

export default TableNatureProduits;
