import axios from "axios";
 
const API_URL = window.location.protocol + "//" + window.location.host;

export const serviceListeLieux = async () => {
    try {
        return await axios.get(`${API_URL}/api/utilitaire/lieu/liste`);
    } catch (err) {
        return {
            error: true,
            response: err.response
        };
    }
}

export const serviceListePays = async () => {
    try {
        return await axios.get(`${API_URL}/api/utilitaire/pays/liste`);
    } catch (err) {
        return {
            error: true,
            response: err.response
        };
    }
}