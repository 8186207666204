import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    disabled: false,
    vertical: "top",
    horizontal: "center",
    data: {},
    ouvrir7: false,
    erreurModifNatureProduit: null,
    notifModifNatureProduit: false
}
   
export const partieNatureProduit = createSlice({
    name: "natureProduit",
    initialState,
    reducers: {
        chargement: (state) => {
            return {
                ...state,
                disabled: true,
            }
        },
        ouvrirFDialogueModifNP: (state, action) => {
            return {
                ...state,
                data: action.payload,
                ouvrir7: true,
                disabled: false
            }
        },
        fermerFDialogueModifNP: (state) => {
            return {
                ...state,
                ouvrir7: false,
                data: {},
                erreurModifNatureProduit: null
            }
        },
        failliteModifNatureProduit: (state, action) => {
            const { error } = action.payload;
            return {
                ...state,
                erreurModifNatureProduit: error,
                disabled: false
            }
        },
        modifNatureProduitFaite: (state) => {
            return {
                ...state,
                erreurModifNatureProduit: null,
                disabled: false,
                ouvrir7: false,
                notifModifNatureProduit: true
            }
        },
        fermerNotifModifNP: (state) => {
            return {
                ...state,
                notifModifNatureProduit: false
            }
        },
        fermerTousDialoguesNP: (state) => {
            return {
                ...state,
                ouvrir7: false
            }
        }, 
        effacerErreurModifNP: (state) => {
            return {
                ...state,
                erreurModifNatureProduit: null
            }
        }
    }
});

export const {
    chargement,
    ouvrirFDialogueModifNP,
    fermerFDialogueModifNP,
    failliteModifNatureProduit,
    modifNatureProduitFaite,
    fermerNotifModifNP,
    fermerTousDialoguesNP,
    effacerErreurModifNP
} = partieNatureProduit.actions;
   
export default partieNatureProduit.reducer;