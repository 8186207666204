import { useEffect, useState } from "react";
// Contexte du Material Dashboard 2 React
import { useMaterialUIController } from "Contexte";

const Rebours2 = ({ temps }) => {
  const [controller, dispatch] = useMaterialUIController();
  const {lang} = controller;

  const [heures, assignerHeures] = useState(0)
  const [minutes, assignerMinutes] = useState(0)
  const [secondes, assignerSecondes] = useState(0);
  useEffect(() => {
    const intervalSec = setInterval(() => {
      const tempsActuel = Date.now();
      if (Number.isInteger(parseInt(temps))) {
        const diff = (parseInt(temps)) - tempsActuel;
        assignerHeures(Math.floor((diff / 1000 / 60 / 60) % 60));
        assignerMinutes(Math.floor((diff / 1000 / 60) % 60));
        assignerSecondes(Math.floor((diff / 1000) % 60));
      }
    }, 1000);
    return () => clearInterval(intervalSec);
  }, []);
  return (
    heures + minutes + secondes === 0 ? " " : heures < 0 && minutes < 0 && secondes < 0 ? 
    <span>
      {lang === "fr" && "Impossible de modifier au delà de 24 heures"}
      {lang === "eng" && "Impossible to edit after 24 hours"}
    </span>
    : 
      <span>
        {lang === "fr" && `${String(heures).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secondes).padStart(2, '0')} pour toute modification`}
        {lang === "eng" && `${String(heures).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secondes).padStart(2, '0')} for any change`}
      </span>
  );
};

export default Rebours2;