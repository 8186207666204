/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types est une bibliothèque pour la vérification de type d'accessoires
import PropTypes from "prop-types";

// Composants du Material Dashboard 2 React
import MDBox from "Composants/MDBox";

// Contexte du Material Dashboard 2 PRO React
import { useMaterialUIController } from "Contexte";

// Styles de base du Material Dashboard 2 PRO React
import typography from "Outils/theme/base/typography";

function Footer() {
  const [controller, dispatch] = useMaterialUIController();
  const { lang } = controller;
  
  const { size } = typography;

  return (
    <MDBox
      width="100%"
      bottom="0"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      px={1.5}
    >
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="text"
        fontSize={size.sm}
        px={1.5}
      >
        &copy; {new Date().getFullYear()} FOMIN. {lang === "eng" ? "All right reserved." : "Tous les droits sont réservés."}
        &nbsp;<img src={require(`../../drapeaux/rdc.png`)} alt="gb" width={25} />
      </MDBox>
    </MDBox>
  );
}

// Vérification de type d'accessoires pour le pied de page
Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
