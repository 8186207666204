/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import DOMPurify from 'dompurify';
import { 
  effacerNotificationsAsync,
  modificationPasseAsync, 
  modificationAutoDisjonctionAsync, 
  modificationAutoDEsactivationAsync 
} from "ActionsAsync/ActionsUtilisateurAsync";
import { disjonctionAsync } from "ActionsAsync/ActionsAuthAsync";
import { ouvrirFDialogueModifPasse, fermerFDialogueModifPasse, fermerNotifModifPasse } from "Simplificateurs/SimplificateursUtilisateur";
import { setChangeColD, setChangeColND } from "Simplificateurs/SimplificateursTable";
import Modal from 'react-modal';
import moment from "moment";

// prop-types est une bibliothèque pour la vérification de type d'accessoires
import PropTypes from "prop-types";

// Composants du @material-ui
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import Badge from '@mui/material/Badge';
import Divider from "@mui/material/Divider";
import Snackbar from '@mui/material/Snackbar';
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// Composants du Material Dashboard 2 React
import MDBox from "Composants/MDBox";
import MDTypography from "Composants/MDTypography";
import MDButton from "Composants/MDButton";
import NotificationItem from "Composants/Items/NotificationItem";

// Styles personnalisés pour DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu
} from "Composants/Navbars/DashboardNavbar/styles";

// Contexte du Material Dashboard 2 PRO React
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "Contexte";

import { Notification, HtmlTooltip, styleModal430, styleSelect } from "Vues/Utilitaires";

let listeAutoDisjonction = require('../../../listeAutoDisjonction.json');

let listeAutoDEsactivation = require('../../../listeAutoDEsactivation.json');

function DashboardNavbar({ absolute = false, light = false, isMini = false }) {
  const navigate = useNavigate();
  const envoi = useDispatch();

  const authObj = useSelector((state) => state.authentification);
  const {info} = authObj;

  const utilisateurObj = useSelector((state) => state.utilisateur);
  const {
    disabled, 
    vertical,
    horizontal,
    ouvrir2,
    modifAutoDisjonction,
    modifAutoDEsactivation,
    erreurModificationPasse,
    notifModifPasse
  } = utilisateurObj;

  const [montrerPasse1, assignerMontrerPasse1] = useState(false);
  const traiterClickMontrerPasse1 = () => assignerMontrerPasse1((show) => !show);
  const traiterMouseDownPasse1 = (e) => {
    e.preventDefault();
  };
  const [montrerPasse2, assignerMontrerPasse2] = useState(false);
  const traiterClickMontrerPasse2 = () => assignerMontrerPasse2((show) => !show);
  const traiterMouseDownPasse2 = (e) => {
    e.preventDefault();
  };
  const [montrerPasse3, assignerMontrerPasse3] = useState(false);
  const traiterClickMontrerPasse3 = () => assignerMontrerPasse3((show) => !show);
  const traiterMouseDownPasse3 = (e) => {
    e.preventDefault();
  };

  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { 
    miniSidenav, 
    transparentNavbar, 
    fixedNavbar, 
    openConfigurator, 
    darkMode,
    lang, 
    socket
  } = controller;

  const gesDisjonction = () => {
    envoi(disjonctionAsync(dispatch, socket));
  }

  const [openMenu, setOpenMenu] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [openGeneralSettings, setOpenGeneralSettings] = useState(false);

  const [notificationProfil, assignerNotificationProfil] = useState(0);
  const [notificationUtilisateur, assignerNotificationUtilisateur] = useState(0);
  const [notificationDEclaration, assignerNotificationDEclaration] = useState(0);
  const [notificationNoteDEbit, assignerNotificationNoteDEbit] = useState(0);
  const [notificationNotePerception, assignerNotificationNotePerception] = useState(0);
  const [notificationPaiement, assignerNotificationPaiement] = useState(0);

  socket.on("ping-failure", () => {
    alert(lang === "fr" ? "Les notifications en temps réel sont suspendues. Le système tente de se reconnecter." : "Real-time notifications are paused. The system is trying to reconnect.");
  });

  socket.on("notifications", (arg1, arg2, arg3, arg4, arg5, arg6) => {
    assignerNotificationProfil(arg1);
    assignerNotificationUtilisateur(arg2);
    assignerNotificationDEclaration(arg3);
    assignerNotificationNoteDEbit(arg4);
    assignerNotificationNotePerception(arg5);
    assignerNotificationPaiement(arg6);
  });

  socket.on("dateMinNotificationProfil", (arg) => {
    async function dateMinNotificationProfil(arg) {
      try {
        localStorage.setItem("filterModelP", JSON.stringify({
          items: [
            { field: "createdAt", operator: "onOrAfter", value: new Date(arg) }
          ]
        }));
        setOpenNotifications(false);
      } catch (error) {
        console.error(error);
      }
    }
    dateMinNotificationProfil(arg).then(() => {
      navigate("/Session/Profils");
    }).catch((error) => {
      console.error(error);
    });
  });

  socket.on("dateMinNotificationUtilisateur", (arg) => {
    async function dateMinNotificationUtilisateur(arg) {
      try {
        localStorage.setItem("filterModelU", JSON.stringify({
          items: [
            { field: "createdAt", operator: "onOrAfter", value: new Date(arg) }
          ]
        }));
        setOpenNotifications(false);
      } catch (error) {
        console.error(error);
      }
    }
    dateMinNotificationUtilisateur(arg).then(() => {
      navigate("/Session/Option/Utilisateurs");
    }).catch((error) => {
      console.error(error);
    });
  });

  socket.on("dateMinNotificationDEclaration", (arg) => {
    async function dateMinNotificationDEclaration(arg) {
      try {
        localStorage.setItem("changeColD", false);
        envoi(setChangeColD());
        localStorage.setItem("filterModelD", JSON.stringify({
          items: [
            { field: "createdAt", operator: "onOrAfter", value: new Date(arg) }
          ]
        }));
        setOpenNotifications(false);
      } catch (error) {
        console.error(error);
      }
    }
    dateMinNotificationDEclaration(arg).then(() => {
      navigate("/Session/Liquidation");
    }).catch((error) => {
      console.error(error);
    });
  });

  socket.on("dateMinNotificationNoteDEbit", (arg) => {
    async function dateMinNotificationNoteDEbit(arg) {
      try {
        localStorage.setItem("changeColD", true);
        envoi(setChangeColD());
        localStorage.setItem("filterModelND", JSON.stringify({
          items: [
            { field: "DateEmissionND", operator: "onOrAfter", value: new Date(arg) }
          ]
        }));
        setOpenNotifications(false);
      } catch (error) {
        console.error(error);
      }
    }
    dateMinNotificationNoteDEbit(arg).then(() => {
      navigate("/Session/Liquidation");
    }).catch((error) => {
      console.error(error);
    });
  });

  socket.on("dateMinNotificationNotePerception", (arg) => {
    async function dateMinNotificationNotePerception(arg) {
      try {
        localStorage.setItem("changeColND", true);
        envoi(setChangeColND());
        localStorage.setItem("filterModelNPe", JSON.stringify({
          items:[
            { field: "DateTaxation", operator: "onOrAfter", value: new Date(arg) }
          ]
        }));
        setOpenNotifications(false);
      } catch (error) {
        console.error(error);
      }
    }
    dateMinNotificationNotePerception(arg).then(() => {
      navigate("/Session/Ordonnancement");
    }).catch((error) => {
      console.error(error);
    });
  });

  socket.on("dateMinNotificationPaiement", (arg) => {
    async function dateMinNotificationPaiement(arg) {
      try {
        localStorage.setItem("filterModelPa", JSON.stringify({
          items:[
            { field: "createdAt", operator: "onOrAfter", value: new Date(arg) }
          ]
        }));
        setOpenNotifications(false);
      } catch (error) {
        console.error(error);
      }
    }
    dateMinNotificationPaiement(arg).then(() => {
      navigate("/Session/Recouvrement");
    }).catch((error) => {
      console.error(error);
    });
  });

  useEffect(() => {
    if(info.PremiEreSession) {
      envoi(ouvrirFDialogueModifPasse());
    }

    const minuteur = setInterval(() => {
      if (socket.connected === true) {
        socket.emit("idProfil", `${info.Id}`);
      }
    }, 1000);

    // Définir le type de barre de navigation
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // Une fonction qui définit l'état transparent de la barre de navigation.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     L'écouteur d'événement qui appelle la fonction handleTransparentNavbar lorsque
     l'on fait défiler la fenêtre.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Appel de la fonction handleTransparentNavbar pour définir l'état avec la valeur initiale.
    handleTransparentNavbar();

    // Supprimer l'écouteur d'événement lors du nettoyage
    return () => {
      window.removeEventListener("scroll", handleTransparentNavbar);
      clearInterval(minuteur);
    }
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const handleOpenNotifications = (event) => setOpenNotifications(event.currentTarget);
  const handleCloseNotifications = () => setOpenNotifications(false);
  const handleOpenGeneralSettings = (event) => setOpenGeneralSettings(event.currentTarget);
  const handleCloseGeneralSettings = () => setOpenGeneralSettings(false);

  const traiterOuvrir = () => {
    handleCloseMenu();
    envoi(ouvrirFDialogueModifPasse());
    assignerAutoDisjonction(modifAutoDisjonction);
    assignerAutoDEsactivation(modifAutoDEsactivation);
  }
  const traiterFermer = () => {
    if(!info.PremiEreSession) {
      envoi(fermerFDialogueModifPasse());
      assignerPasseActuel("");
      assignerPasse("");
      assignerConfPasse("");
    }
  };

  const traiterFermerNotification = () => {
    envoi(fermerNotifModifPasse(false));
    assignerPasseActuel("");
    assignerPasse("");
    assignerConfPasse("");
    envoi(disjonctionAsync(dispatch, socket));
  };

  const [autoDisjonction, assignerAutoDisjonction] = useState("");
  const traiterAutoDisjonction = e => {
    assignerAutoDisjonction(e.target.value);
  }

  const [autoDEsactivation, assignerAutoDEsactivation] = useState("");
  const traiterAutoDEsactivation = e => {
    assignerAutoDEsactivation(e.target.value);
  }

  const [passeActuel, assignerPasseActuel] = useState("");
  const traiterPasseActuel = e => {
    assignerPasseActuel(e.target.value);
  }
  const [passe, assignerPasse] = useState("");
  const traiterPasse = e => {
    assignerPasse(e.target.value);
  }
  const [confPasse, assignerConfPasse] = useState("");
  const traiterConfPasse = e => {
    assignerConfPasse(e.target.value);
  }

  const traiterModificationAuto = async () => {
    const cleanId = DOMPurify.sanitize(info.Id, {
      ALLOWED_CHARS: /[^a-z0-9-]$/
    });
    const cleanAutoDisjonction = DOMPurify.sanitize(autoDisjonction, {
      ALLOWED_CHARS: /^\d+$/
    });
    const cleanAutoDEsactivation = DOMPurify.sanitize(autoDEsactivation, {
      ALLOWED_CHARS: /^\d+$/
    });
    if(!info.PremiEreSession) {
      Number(cleanAutoDisjonction) != Number(modifAutoDisjonction) && await envoi(modificationAutoDisjonctionAsync(cleanId.trim(), cleanAutoDisjonction.trim()));
      Number(cleanAutoDEsactivation) != Number(modifAutoDEsactivation) && await envoi(modificationAutoDEsactivationAsync(cleanId.trim(), cleanAutoDEsactivation.trim()));
    }
  } 

  const traiterModificationPasse = async (e) => {
    e.preventDefault();
    const cleanId = DOMPurify.sanitize(info.Id, {
      ALLOWED_CHARS: /[^a-z0-9-]$/
    })
    await envoi(modificationPasseAsync(cleanId.trim(), passeActuel, passe, confPasse));
  }

  const traiterNotificationProfil =() => {
    socket.emit("filtreNotificationProfil", notificationProfil);
  }
  const traiterNotificationUtilisateur =() => {
    socket.emit("filtreNotificationUtilisateur", notificationUtilisateur);
  }
  const traiterNotificationDEclaration =() => {
    socket.emit("filtreNotificationDEclaration", notificationDEclaration);
  }
  const traiterNotificationNoteDEbit =() => {
    socket.emit("filtreNotificationNoteDEbit", notificationNoteDEbit);
  }
  const traiterNotificationNotePerception =() => {
    socket.emit("filtreNotificationNotePerception", notificationNotePerception);
  }
  const traiterNotificationPaiement =() => {
    socket.emit("filtreNotificationPaiement", notificationPaiement);
  }
  const traiterEffacerNotifications = async (e) => {
    const cleanId = DOMPurify.sanitize(info.Id, {
      ALLOWED_CHARS: /[^a-z0-9-]$/
    })
    await envoi(effacerNotificationsAsync(cleanId.trim(), dispatch, socket));
  }


  // Afficher le menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>badge</Icon>} onClick={traiterOuvrir} title={info.Email} />
      <NotificationItem icon={<Icon>exit_to_app</Icon>} onClick={gesDisjonction} title={lang === "fr" ? "Déconnexion" : "Logout"} />
    </Menu>
  );

  // Afficher le menu des notifications
  const renderNotifications = () => (
    <Menu
      anchorEl={openNotifications}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openNotifications)}
      onClose={handleCloseNotifications}
      sx={{ mt: 2 }}
    >
      {notificationProfil + notificationUtilisateur + notificationDEclaration + notificationNoteDEbit + notificationNotePerception + notificationPaiement === 0 ?
        <MDTypography variant="inherit" color="text" fontWeight="regular" style={{textAlign: "center"}}>
          {lang === "fr" && "Aucune notification"}
          {lang === "eng" && "No notification"}
        </MDTypography>
      :
        <div>
          {notificationProfil > 0 && <NotificationItem icon={<Icon>person</Icon>} title={lang === "fr" ? notificationProfil + " Nouveau(x) Profil(s)" : notificationProfil + " New Profile(s)"} onClick={traiterNotificationProfil} />}
          {notificationUtilisateur > 0 && <NotificationItem icon={<Icon>account_box</Icon>} title={lang === "fr" ? notificationUtilisateur + " Nouveau(x) utilisateur(s)" : notificationUtilisateur + " New user(s)"} onClick={traiterNotificationUtilisateur} />}
          {notificationDEclaration > 0 && <NotificationItem icon={<Icon>ballot</Icon>} title={lang === "fr" ? notificationDEclaration + " Nouvelle(s) déclaration(s)" : notificationDEclaration + " New statement(s)"} onClick={traiterNotificationDEclaration} />}
          {notificationNoteDEbit > 0 && <NotificationItem icon={<Icon>description</Icon>} title={lang === "fr" ? notificationNoteDEbit + " Nouvelle(s) note(s) de débit" : notificationNoteDEbit + " New debit note(s)"} onClick={traiterNotificationNoteDEbit} />}
          {notificationNotePerception > 0 && <NotificationItem icon={<Icon>receipt</Icon>} title={lang === "fr" ? notificationNotePerception + " Nouvelle(s) note(s) de perception" : notificationNotePerception + " New collection note(s)"} onClick={traiterNotificationNotePerception} />}
          {notificationPaiement > 0 && <NotificationItem icon={<Icon>payments</Icon>} title={lang === "fr" ? notificationPaiement + " Paiements(s)" : notificationPaiement + " Payment(s)"} onClick={traiterNotificationPaiement} />}
          {notificationProfil + notificationUtilisateur + notificationDEclaration + notificationNoteDEbit + notificationNotePerception + notificationPaiement > 0 && <Divider />}
          {notificationProfil + notificationUtilisateur + notificationDEclaration + notificationNoteDEbit + notificationNotePerception + notificationPaiement > 0 &&
            <NotificationItem icon={<Icon>remove_circle</Icon>} title={lang === "fr" ? "Effacer tout" : "Clear all"} onClick={traiterEffacerNotifications} />
          }
        </div>
      }
    </Menu>
  );

  // Afficher les paramètres généraux
  const renderGeneralSettings = () => (
    <Menu
      anchorEl={openGeneralSettings}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openGeneralSettings)}
      onClose={handleCloseGeneralSettings}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>manage_accounts</Icon>} onClick={route1} title={lang === "fr" ? "Gestion des utilisateurs" : "User management"} />
      <NotificationItem icon={<Icon>label</Icon>} onClick={route2} title={lang === "fr" ? "Natures des produits" : "Types of products"} />
      <NotificationItem icon={<Icon>donut_small</Icon>} onClick={route3} title={lang === "fr" ? "Répartition de la redevance" : "Royalty distribution"} />
      <NotificationItem icon={<Icon>attribution</Icon>} onClick={route4} title={lang === "fr" ? "Autorités" : "Authority"} />
      <NotificationItem icon={<Icon>history</Icon>} onClick={route5} title={lang === "fr" ? "Journaux" : "Logs"} />
    </Menu>
  );

  const route1 = () => {
    navigate("/Session/Option/Utilisateurs");
    setOpenGeneralSettings(false);
  };
  const route2 = () => {
    navigate("/Session/Option/Nature/Produits");
    setOpenGeneralSettings(false);
  };
  const route3 = () => {
    navigate("/Session/Option/REpartition/Redevance");
    setOpenGeneralSettings(false);
  };
  const route4 = () => {
    navigate("/Session/Option/AutoritE");
    setOpenGeneralSettings(false);
  };
  const route5 = () => {
    navigate("/Session/Option/Logs");
    setOpenGeneralSettings(false);
  };

  // Styles pour les icônes de la barre de navigation
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  var PNF = require("google-libphonenumber").PhoneNumberFormat;
  var utilPhone = require("google-libphonenumber").PhoneNumberUtil.getInstance();

  return (
    <>
      <Snackbar 
        anchorOrigin={{ vertical, horizontal }} 
        open={notifModifPasse} 
        autoHideDuration={6000} 
        onClose={traiterFermerNotification}
        key={vertical + horizontal}
      >
        <Notification onClose={traiterFermerNotification} severity="warning" sx={{ width: "100%", bgcolor: "background.paper"}}>
          {lang === "fr" && "Mot de passe modifié avec succès"}
          {lang === "eng" && "Password successfully edited"}
        </Notification>
      </Snackbar>
      <Modal isOpen={ouvrir2} onRequestClose={traiterFermer} onAfterClose={traiterModificationAuto} ariaHideApp={false} style={styleModal430}>
        <MDBox 
          sx={{padding: 3, height: info.PremiEreSession ? 530 : 725}}
          variant="gradient" 
          bgColor={darkMode ? "dark": "white"}
          borderRadius="lg"
          coloredShadow={darkMode ? "dark": "light"}
        >
          {info.PremiEreSession ?
            <>
              <Grid container spacing={3} alignItems="center">
                <Grid item>
                  <MDBox height="100%" lineHeight={1}>
                    <MDTypography variant="h6" fontWeight="medium">
                      MODIFICATION
                    </MDTypography>
                    <MDTypography variant="button" color="text" fontWeight="regular">
                      {lang === "fr" && "Pour des raisons de sécurité, il est nécessaire de changer le mot de passe à défaut!"}
                      {lang === "eng" && "For security reasons, it is necessary to change the default password!"}
                    </MDTypography>
                    <br /><br />
                    <MDTypography variant="button" color="text">
                      {lang === "fr" && "Ou déconnectez-vous en cliquant"}
                      {lang === "eng" && "Or logout by clicking"} 
                      <MDTypography
                        component="a"
                        href="#"
                        variant="button"
                        color="error"
                        fontWeight="medium"
                        textGradient
                        onClick={gesDisjonction}
                      >
                        &nbsp;
                        {lang === "fr" && "ici"}
                        {lang === "eng" && "here"} 
                      </MDTypography>
                    </MDTypography>
                  </MDBox>
                </Grid>
              </Grid>
              <br />
            </> :
            <>
              {
                info.TypeId !== null &&
                info.IdNat !== null &&
                info.NoAgr !== null &&
                info.DateD !== null &&
                info.DateF !== null ?
                <HtmlTooltip title={
                  <Fragment>
                    {lang === "fr" ? "Exportateur : " + info.TypeId : "Exporter: " + info.TypeId }
                    {`${info.IdNat === null ? "" : lang === "fr" ? "\nIdentité Nationale : " + info.IdNat : "\nNational Identity: " + info.IdNat}`}
                    {lang === "fr" ? "\nAdresse : " + info.No : "\nAddress: " + info.No }
                    {", Av. " + info.Avenue + ", Q/" + info.Quartier + ", C/" + info.Commune + ", V/" + info.Ville + ", P/" + info.Province}
                    {lang === "fr" ? "\nNuméro d'arrêté de l'agrément : " + info.NoAgr : "\nApproval order number:" + info.NoAgr}
                    {lang === "fr" ? "\nDate de début : " + moment(info.DateD).format("DD/MM/YYYY") : "\nStart date:" + moment(info.DateD).format("DD/MM/YYYY")}
                    {lang === "fr" ? "\nDate d'expiration : " + moment(info.DateF).format("DD/MM/YYYY") : "\nEnd date:" + moment(info.DateF).format("DD/MM/YYYY")}
                  </Fragment>
                }>
                  <MDTypography display="block" variant="button" color="text" fontWeight="medium" mt={1}>
                    {lang === "fr" && "Nom complet : " + info.Nom}
                    {lang === "eng" && "Full Name: " + info.Nom}&nbsp;
                    <Icon color="success">more</Icon>
                  </MDTypography>
                </HtmlTooltip> : 
                info.TypeService !== null &&
                info.ChefLieu !== null ?
                <HtmlTooltip title={
                  <Fragment>
                    {lang === "fr" ? "Adresse : " + info.No : "Address: " + info.No}
                    {", Av. " + info.Avenue + ", Q/" + info.Quartier + ", C/" + info.Commune + ", V/" + info.Ville + ", P/" + info.Province}
                    {lang === "fr" ? "\nChef-lieu : " + info.ChefLieu : "\nChief town: " + info.ChefLieu}
                    {info.Fonction !== null && lang === "fr" ? "\nFonction : " + info.Fonction : "\nRole: " + info.Fonction}
                  </Fragment>
                }>
                  <MDTypography display="block" variant="button" color="text" fontWeight="medium" mt={1}>
                    {lang === "fr" && "Nom complet : " + info.Nom}
                    {lang === "eng" && "Full Name: " + info.Nom}&nbsp;
                    <Icon color="success">more</Icon>
                  </MDTypography>
                </HtmlTooltip> :
                <HtmlTooltip title={
                  <Fragment>
                    {lang === "fr" ? "Adresse : " + info.No : "Address: " + info.No}
                    {", Av. " + info.Avenue + ", Q/" + info.Quartier + ", C/" + info.Commune + ", V/" + info.Ville + ", P/" + info.Province}
                  </Fragment>
                }>
                  <MDTypography display="block" variant="button" color="text" fontWeight="medium" mt={1}>
                    {lang === "fr" && "Nom complet : " + info.Nom}
                    {lang === "eng" && "Full Name: " + info.Nom}&nbsp;
                    <Icon color="success">more</Icon>
                  </MDTypography>
                </HtmlTooltip>
              }
              <MDTypography display="block" variant="button" color="text" fontWeight="medium" mt={1}>
                {lang === "fr" && "Téléphone : " + utilPhone.format(utilPhone.parse("+" + info.Phone, ""), PNF.INTERNATIONAL)}
                {lang === "eng" && "Telephone: " + utilPhone.format(utilPhone.parse("+" + info.Phone, ""), PNF.INTERNATIONAL)}
              </MDTypography>
              <MDTypography display="block" variant="button" color="text" fontWeight="medium" mt={1}>
                {info.Administrateur ? lang === "fr" ? "Niveau : Administrateur" : "Level: Administrator" : lang === "fr" ? "Niveau : Non Administrateur" : "Level: Not Administrator"}
              </MDTypography>
              <MDTypography display="block" variant="button" color="text" fontWeight="medium" mt={1}>
                {lang === "fr" && "Dernière Session : " + info.DerniEreSession}
                {lang === "eng" && "Last Session: " + info.DerniEreSession}
              </MDTypography>
              <MDTypography display="block" variant="button" color="text" fontWeight="medium" mt={1}>
                {lang === "fr" && "Dernier mot de passe : " + info.DernierChangementDeMotDePasse}
                {lang === "eng" && "Last password: " + info.DernierChangementDeMotDePasse}
              </MDTypography>
              <MDBox mt={2} mb={3.5} sx={{height: 45, width: 385}}>
                {
                  <FormControl sx={{height: 45, width: "100%", pr: 5.5}}>
                    <InputLabel id="select-insert" htmlFor="select-insert1">
                      {lang === "fr" && "Déconnexion planifiée"}
                      {lang === "eng" && "Scheduled logout"}
                    </InputLabel>
                    <Select 
                      sx={{height: 46}} 
                      inputProps={{id: "select-insert1"}}
                      required
                      label={lang === "fr" ? "Déconnexion planifiée" : "Scheduled logout"}
                      value={autoDisjonction}
                      onChange={traiterAutoDisjonction}
                    >
                      {
                        listeAutoDisjonction.map((autoDisjonction) => (
                          <MenuItem key={autoDisjonction.Id} value={autoDisjonction.Valeur}>
                            <span style={styleSelect}>
                              {autoDisjonction.Nom}
                            </span>        
                          </MenuItem>
                        ))
                      }      
                    </Select>
                  </FormControl>
                }
              </MDBox>
              <MDBox mt={2} mb={3.5} sx={{height: 45, width: 385}}>
                {
                  <FormControl sx={{height: 45, width: "100%", pr: 5.5}}>
                    <InputLabel id="select-insert" htmlFor="select-insert2">
                      {lang === "fr" && "Désactivation de compte planifiée"}
                      {lang === "eng" && "Scheduled account deactivation"}
                    </InputLabel>
                    <Select 
                      sx={{height: 46}} 
                      inputProps={{id: "select-insert2"}}
                      required
                      label={lang === "fr" ? "Désactivation de compte planifiée" : "Scheduled account deactivation"}
                      value={autoDEsactivation}
                      onChange={traiterAutoDEsactivation}
                    >
                      {
                        listeAutoDEsactivation.map((autoDEsactivation) => (
                          <MenuItem key={autoDEsactivation.Id} value={autoDEsactivation.Valeur}>
                            <span style={styleSelect}>
                              {lang === "fr" && `${autoDEsactivation.Valeur} mois`}
                              {lang === "eng" && `${autoDEsactivation.Valeur} months`}
                            </span>        
                          </MenuItem>
                        ))
                      }      
                    </Select>
                  </FormControl>
                }
              </MDBox>
              <br />
            </>
          }
          <MDBox component="form" role="form" onSubmit={traiterModificationPasse} autoComplete="off">
            <MDBox mb={2} sx={{height: 70, lineHeight: 0.5}}>
              <FormControl sx={{mt: 1, width: "100%"}} variant="outlined">
                <InputLabel htmlFor="passe1">{lang === "fr" ? "Mot de passe actuel" : "Actual password"}</InputLabel>
                <OutlinedInput 
                  id="passe1"
                  type={montrerPasse1 ? "text" : "password"} 
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={traiterClickMontrerPasse1}
                        onMouseDown={traiterMouseDownPasse1}
                        edge="end"
                        style={{color: darkMode ? "#7b809a" : "#808080"}}
                      >
                        {montrerPasse1 ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  required 
                  label={lang === "fr" ? "Mot de passe actuel" : "Actual password"} 
                  value={passeActuel} 
                  onChange={traiterPasseActuel}
                />
              </FormControl>
              {erreurModificationPasse === "Veuillez entrer le bon mot de passe actuel" && 
                <MDTypography variant="button" color="error" mt={1}>
                  {lang === "fr" && "Veuillez entrer le bon mot de passe actuel"}
                  {lang === "eng" && "Please enter the correct current password"}
                </MDTypography>
              }
            </MDBox>
            <MDBox mb={2} sx={{height: 70, lineHeight: 0.5}}>
              <FormControl sx={{mt: 1, width: "100%"}} variant="outlined">
                <InputLabel htmlFor="passe2">{lang === "fr" ?"Nouveau mot de passe" : "New password"}</InputLabel>
                <OutlinedInput 
                  id="passe2"
                  type={montrerPasse2 ? "text" : "password"} 
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={traiterClickMontrerPasse2}
                        onMouseDown={traiterMouseDownPasse2}
                        edge="end"
                        style={{color: darkMode ? "#7b809a" : "#808080"}}
                      >
                        {montrerPasse2 ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  required 
                  label={lang === "eng" ? "New password" : "Nouveau mot de passe"} 
                  value={passe} 
                  onChange={traiterPasse}
                />
              </FormControl>
              {erreurModificationPasse === "Minimum 8 caractères" && 
              <MDTypography variant="button" color="error" mt={1}>
                {lang === "fr" && "Au moins 8 caractères"}
                {lang === "eng" && "At least 8 characters"}
              </MDTypography>
              }
              {erreurModificationPasse === "Maximum 20 caractères" && 
                <MDTypography variant="button" color="error" mt={1}>
                  {lang === "fr" && "Au plus 20 caractères"}
                  {lang === "eng" && "At most 20 characters"}
                </MDTypography>
              }
              {erreurModificationPasse === "Minimum 1 majuscule" && 
                <MDTypography variant="button" color="error" mt={1}>
                  {lang === "fr" && "Au moins 1 caractère en majuscule"}
                  {lang === "eng" && "At least 1 character in upper case"}
                </MDTypography>
              }
              {erreurModificationPasse === "Minimum 1 chiffre" && 
                <MDTypography variant="button" color="error" mt={1}>
                  {lang === "fr" && "Au moins 1 chiffre"}
                  {lang === "eng" && "At least 1 digit"}
                </MDTypography>
              }
              {erreurModificationPasse === "Minimum 1 caractère spécial" && 
                <MDTypography variant="button" color="error" mt={1}>
                  {lang === "fr" && "Au moins 1 caractère spécial"}
                  {lang === "eng" && "At least 1 special character"}
                </MDTypography>
              }
            </MDBox>
            <MDBox mb={2} sx={{height: 70, lineHeight: 0.5}}>
              <FormControl sx={{mt: 1, width: "100%"}} variant="outlined">
                <InputLabel htmlFor="passe3">{lang === "fr" ? "Confirmer nouveau mot de passe" : "Confirm password"}</InputLabel>
                <OutlinedInput 
                  id="passe3"
                  type={montrerPasse3 ? "text" : "password"} 
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={traiterClickMontrerPasse3}
                        onMouseDown={traiterMouseDownPasse3}
                        edge="end"
                        style={{color: darkMode ? "#7b809a" : "#808080"}}
                      >
                        {montrerPasse3 ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  required 
                  label={lang === "eng" ? "Confirm password" : "Confirmer nouveau mot de passe"} 
                  value={confPasse} 
                  onChange={traiterConfPasse}
                />
              </FormControl>
              {erreurModificationPasse === "Veuillez corriger la confirmation" && 
                <MDTypography variant="button" color="error" mt={1}>
                  {lang === "fr" && "Veuillez confirmer avec le bon mot de passe"}
                  {lang === "eng" && "Please confirm with the correct password"}
                </MDTypography>
              }
            </MDBox>
            <MDBox mt={4} mb={1} sx={{textAlign: "center"}}>
              {info.PremiEreSession ?
                <MDButton disabled={disabled} type="submit" variant="gradient" color="info">
                  {lang === "fr" && "Modifier"}
                  {lang === "eng" && "Edit"}
                </MDButton> :
                <MDBox mb={1}>
                  <MDButton type="button" variant="gradient" color="error" onClick={traiterFermer}>
                    {lang === "fr" && "Fermer"}
                    {lang === "eng" && "Close"}
                  </MDButton>
                  &nbsp;&nbsp;
                  <MDButton disabled={disabled} type="submit" variant="gradient" color="info">
                    {lang === "fr" && "Modifier"}
                    {lang === "eng" && "Edit"}
                  </MDButton>
                </MDBox> 
              }
              <MDBox>
                {erreurModificationPasse === "Champ(s) vide(s) dans la requête" && 
                  <MDTypography variant="button" color="error" mt={1}>
                    {lang === "fr" && "Champ(s) vide(s) dans la requête"}
                    {lang === "eng" && "Empty field(s) in the query"}
                  </MDTypography>
                }
                {erreurModificationPasse === "Aucune modification" && 
                  <MDTypography variant="button" color="error" mt={1}>
                    {lang === "fr" && "Aucune modification"}
                    {lang === "eng" && "No modification"}
                  </MDTypography>
                }
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
      </Modal>
      <AppBar
        position={absolute ? "absolute" : navbarType}
        color="inherit"
        sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
      >
        <Toolbar sx={(theme) => navbarContainer(theme)}>
          <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>

          </MDBox>
          {isMini ? null : (
            <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
              {/*<MDBox pr={1}>
                <MDInput label="Search here" />
              </MDBox>*/}
              <MDBox color={light ? "white" : "inherit"}>
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleOpenMenu}
                >
                  <Icon sx={iconsStyle}>account_circle</Icon>
                </IconButton>
                {renderMenu()}
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleOpenNotifications}
                >
                  {
                    notificationProfil + notificationUtilisateur + notificationDEclaration + notificationNoteDEbit + notificationNotePerception + notificationPaiement === 0 ?
                    <Icon sx={iconsStyle}>notifications</Icon> :
                    <Badge badgeContent={`${notificationProfil + notificationUtilisateur + notificationDEclaration + notificationNoteDEbit + notificationNotePerception + notificationPaiement}`} color="error">
                      <Icon sx={iconsStyle}>notifications</Icon>
                    </Badge>
                  }
                </IconButton>
                {renderNotifications()}
                {info.Administrateur && <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleOpenGeneralSettings}
                >
                  <Icon sx={iconsStyle}>settings</Icon>
                </IconButton>}
                {info.Administrateur && renderGeneralSettings()}
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarMobileMenu}
                  onClick={handleMiniSidenav}
                >
                  <Icon sx={iconsStyle} fontSize="medium">
                    {miniSidenav ? "menu_open" : "menu"}
                  </Icon>
                </IconButton>
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  onClick={handleConfiguratorOpen}
                >
                  <Icon sx={iconsStyle}>display_settings</Icon>
                </IconButton>
              </MDBox>
            </MDBox>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
}

// Vérification de type d'accessoires pour DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
