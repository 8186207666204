/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { listeProfilsAsync } from "ActionsAsync/ActionsUtilisateurAsync";
import { ouvrirFDialogueValidationP, ouvrirFDialogueInfoP } from "Simplificateurs/SimplificateursUtilisateur";
import { setPageP, setSelectedRowP } from "Simplificateurs/SimplificateursTable";

// Composants du @mui material
import LinearProgress from '@mui/material/LinearProgress';
import Icon from "@mui/material/Icon";
import Tooltip from '@mui/material/Tooltip';
import { styled } from "@mui/material/styles";

// Table
import { 
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport, 
} from "@mui/x-data-grid";
import { enUS } from '@mui/material/locale';

// Composants du Material Dashboard 2 React
import MDBox from "Composants/MDBox";
import MDButton from "Composants/MDButton";

// Contexte Material Dashboard 2 PRO React
import { useMaterialUIController } from "Contexte";

import { 
  globalStyles,
  CustomNoRowsOverlay, 
  formatDate,
  formatHeure,
  CustomPagination,
  frFR
} from "Vues/Utilitaires";

var h = window.innerHeight - 340;

function TableProfils() {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, lang, socket } = controller;

  const envoi = useDispatch();

  const authObj = useSelector((state) => state.authentification);

  const tableObj = useSelector((state) => state.table);
  const {
    chargementListeProfils, 
    listeProfils,
    pageP,
    selectedRowP
  } = tableObj;

  useEffect(() => {
    const minuteurObtenirListeProfils = setTimeout(() => {
      envoi(listeProfilsAsync(authObj.info.Id, dispatch, socket));
    }, 1000);
    return () => {
      clearTimeout(minuteurObtenirListeProfils);
    };
  }, []);

  const traiterOuvrir1 = (idProfil, email) => () => {
    envoi(ouvrirFDialogueValidationP({idProfil, email}));
  };

  const traiterOuvrir2 = (
    typeId,
    dEnomination,
    idNat,
    nom,
    postNom,
    prEnom,
    province,
    ville,
    commune,
    quartier,
    avenue,
    no,
    noAgr,
    dateD,
    dateF,
    email,
    phone
  ) => () => {
    envoi(ouvrirFDialogueInfoP({
      typeId,
      dEnomination,
      idNat,
      nom,
      postNom,
      prEnom,
      province,
      ville,
      commune,
      quartier,
      avenue,
      no,
      noAgr,
      dateD,
      dateF,
      email,
      phone
    }));
  };

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    border: 0,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    WebkitFontSmoothing: "auto",
    letterSpacing: "normal",
    fontSize: "13px",
    backgroundColor: darkMode ? "#202940" : "#fff",
    color: darkMode ? "#7b809a" : "rgba(0,0,0,.85)",
    "& .MuiDataGrid-main": {
      color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
    },
    "& .MuiDataGrid-columnSeparator": {
      color: "#7b809a"
    },
    "& .MuiDataGrid-columnHeaders, & .MuiDataGrid-cell": {
      borderBottom: darkMode ? "1px solid #344767" : "1px solid #f0f2f5"
    },
    "& .MuiDataGrid-footerContainer": {
      borderTop: darkMode ? "1px solid #344767" : "1px solid #f0f2f5"
    },
    "& .MuiIconButton-root": {
      color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
    },
    "& .MuiPaginationItem-previousNext": {
      color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
    },
    "& .MuiPaginationItem-textInfo": {
      color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
    },
    "& .Mui-selected": {
      color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)"
    },
    "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
      outline: "none"
    }
  }));

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport 
          printOptions={{ disableToolbarButton: true }} 
          csvOptions={{
            fileName: lang === "fr" ? `${"Liste des Profils (" + moment(new Date()).format('DD-MM-YYYY HHmmss') + ")"}` : `${"Profiles list (" + moment(new Date()).format('DD-MM-YYYY HHmmss') + ")"}`,
            delimiter: ',',
            utf8WithBom: true,
          }}
        />
      </GridToolbarContainer>
    );
  }

  var PNF = require("google-libphonenumber").PhoneNumberFormat;
  var utilPhone = require("google-libphonenumber").PhoneNumberUtil.getInstance();

  const colonnes = [
    {
      field: "Option",
      width: authObj.info.TypeService === 1 ? 120 : 90,
      renderCell: (params) => {
        return (
          <>
            {
              authObj.info.TypeService === 1 && params.row.EstValidE === "Non" ? 
                (new Date(params.row.createdAt).getTime() + 24 * (60 * 60 * 1000)) < Date.now() ?
                <>
                  <Tooltip title={lang === "fr" ? "Valider" : "Validate"}>
                    <MDButton variant="gradient" color="primary" size="small" iconOnly onClick={traiterOuvrir1(params.row.Id, params.row.Email)}>
                      <Icon>thumb_up_alt</Icon>
                    </MDButton>
                  </Tooltip>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </> : 
                <>
                  <MDButton variant="gradient" color="secondary" size="small" disabled iconOnly>
                    <Icon>thumb_up_alt</Icon>
                  </MDButton>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </> : 
              authObj.info.TypeService === null ? null :
              <>
                <MDButton variant="gradient" color="secondary" size="small" disabled iconOnly>
                  <Icon>thumb_up_alt</Icon>
                </MDButton>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </>
            }
            <Tooltip title={lang === "fr" ? "Afficher" : "View"}>
              <MDButton variant="gradient" color="success" size="small" iconOnly onClick={traiterOuvrir2(
                params.row.TypeId,
                params.row.DEnomination,
                params.row.IdNat,
                params.row.Nom,
                params.row.PostNom,
                params.row.PrEnom,
                params.row.Province,
                params.row.Ville,
                params.row.Commune,
                params.row.Quartier,
                params.row.Avenue,
                params.row.No,
                params.row.NoAgr,
                params.row.DateD,
                params.row.DateF,
                params.row.Email,
                params.row.Phone
              )}>
                <Icon>summarize</Icon>
              </MDButton>
            </Tooltip>
          </>
        );
      }, 
      sortable: false, 
      filterable: false,
      disableColumnMenu: true,
      disableExport: true
    },
    { 
      field: "EstEnElaboration", 
      headerName: "Elaboration", 
      type: "singleSelect", 
      valueOptions: [
        { value: "Oui", label: lang === "fr" ? "Oui" : "Yes"},
        { value: "Non", label: lang === "fr" ? "Non" : "No" }
      ],
      width: 85, 
      renderCell: (params) => (
        <MDBox ml={-1}>
          {params.value === "Oui" && <MDButton variant="outlined" color="success" size="small" iconOnly disabled><Icon>check</Icon></MDButton>}
          {params.value === "Non" && <MDButton variant="outlined" color="error" size="small" iconOnly disabled><Icon>close</Icon></MDButton>}
        </MDBox>
      ),
      disableColumnMenu: true
    },
    { 
      field: "EstEnAttenteDeValidation", 
      headerName: lang === "fr" ? "Attente de validation" : "Waiting for validation", 
      type: "singleSelect", 
      valueOptions: [
        { value: "Oui", label: lang === "fr" ? "Oui" : "Yes"},
        { value: "Non", label: lang === "fr" ? "Non" : "No" }
      ],
      width: 90, 
      renderCell: (params) => (
        <MDBox ml={-1}>
          {params.value === "Oui" && <MDButton variant="outlined" color="success" size="small" iconOnly disabled><Icon>check</Icon></MDButton>}
          {params.value === "Non" && <MDButton variant="outlined" color="error" size="small" iconOnly disabled><Icon>close</Icon></MDButton>}
        </MDBox>
      ),
      disableColumnMenu: true
    },
    { 
      field: "RetardTraitement", 
      headerName: lang === "fr" ? "Retard de traitement" : "Delay in processing", 
      type: "singleSelect", 
      valueOptions: [
        { value: "Oui", label: lang === "fr" ? "Oui" : "Yes"},
        { value: "Non", label: lang === "fr" ? "Non" : "No" }
      ],
      width: 85, 
      renderCell: (params) => (
        <MDBox ml={-1}>
          {params.value === "Oui" && <MDButton variant="outlined" color="success" size="small" iconOnly disabled><Icon>check</Icon></MDButton>}
          {params.value === "Non" && <MDButton variant="outlined" color="error" size="small" iconOnly disabled><Icon>close</Icon></MDButton>}
        </MDBox>
      ),
      disableColumnMenu: true
    },
    { 
      field: "EstValidE", 
      headerName: "Validation", 
      type: "singleSelect", 
      valueOptions: [
        { value: "Oui", label: lang === "fr" ? "Oui" : "Yes"},
        { value: "Non", label: lang === "fr" ? "Non" : "No" }
      ],
      width: 85, 
      renderCell: (params) => (
        <MDBox ml={-1}>
          {params.value === "Oui" && <MDButton variant="outlined" color="success" size="small" iconOnly disabled><Icon>check</Icon></MDButton>}
          {params.value === "Non" && <MDButton variant="outlined" color="error" size="small" iconOnly disabled><Icon>close</Icon></MDButton>}
        </MDBox>
      ),
      disableColumnMenu: true
    },
    { 
      field: "Email", 
      width: 230,
      disableColumnMenu: true
    },
    {
      field: "TypeId",
      headerName: lang === "fr" ? "Exportateur" : "Exporter", 
      width: 350,
      disableColumnMenu: true
    },
    { 
      field: "DEnomination", 
      headerName: lang === "fr" ? "Dénomination" : "Company name", 
      width: 195, 
      disableColumnMenu: true 
    },
    { 
      field: "createdAt", 
      headerName: lang === "fr" ? "Inscription" : "Registration", 
      type: "dateTime", 
      width: 155, 
      renderCell: (params) => `${formatDate(new Date(params.value))}, ${formatHeure(new Date(params.value))}`,
      valueGetter: (value) => new Date(value),
      disableColumnMenu: true
    },
    { 
      field: "Nom", 
      headerName: lang === "fr" ? "Nom" : "Last name",
      width: 160, 
      disableColumnMenu: true 
    },
    { 
      field: "PostNom", 
      headerName: lang === "fr" ? "Post-nom" : "Middle name", 
      width: 160, 
      disableColumnMenu: true 
    },
    { 
      field: "PrEnom", 
      headerName: lang === "fr" ? "Prénom" : "First name", 
      width: 160, 
      disableColumnMenu: true 
    },
    { 
      field: "IdNat", 
      headerName: lang === "fr" ? "Identité Nationale" : "National Identification Number",
      width: 150,
      disableColumnMenu: true 
    },
    { 
      field: "NoAgr", 
      headerName: lang === "fr" ? "No. d'arrêté de l'agrément" : "Approval Order Number",
      width: 200,
      disableColumnMenu: true 
    },
    { 
      field: "DateD", 
      headerName: lang === "fr" ? "Début" : "Start", 
      type: "date", 
      width: 160, 
      renderCell: (params) => formatDate(new Date(params.value)),
      valueGetter: (value) => new Date(value),
      disableColumnMenu: true
    },
    { 
      field: "DateF", 
      headerName: "Expiration", 
      type: "date", 
      width: 160, 
      renderCell: (params) => formatDate(new Date(params.value)), 
      valueGetter: (value) => new Date(value),
      disableColumnMenu: true
    },
    { 
      field: "Phone", 
      headerName: lang === "fr" ? "Téléphone" : "Phone No.", 
      width: 150,
      renderCell: (params) => utilPhone.format(utilPhone.parse("+" + params.value, ""), PNF.INTERNATIONAL),  
      valueGetter: (value) => `+${value}`, 
      disableColumnMenu: true 
    },
    { 
      field: "No", 
      headerName: lang === "fr" ? "N°" : "No.", 
      width: 150,
      disableColumnMenu: true 
    },
    { 
      field: "Avenue", 
      headerName: lang === "fr" ? "Avenue" : "Street", 
      width: 150,
      disableColumnMenu: true 
    },
    { 
      field: "Quartier", 
      headerName: lang === "fr" ? "Quartier" : "Area",
      width: 150,
      disableColumnMenu: true 
    },
    { 
      field: "Commune", 
      headerName: lang === "fr" ? "Commune/Secteur" : "Municipality/Sector",
      width: 150,
      disableColumnMenu: true 
    },
    { 
      field: "Ville", 
      headerName: lang === "fr" ? "Ville/Territoire" : "City/Territory",
      width: 150,
      disableColumnMenu: true 
    },
    { 
      field: "Province", 
      width: 150,
      disableColumnMenu: true 
    }
  ]; 

  const [paginationModel, setPaginationModel] = useState(pageP);
  useEffect(() => {
    localStorage.setItem("pageP", JSON.stringify(paginationModel));
    envoi(setPageP());
  },[paginationModel]);

  return (
    <div style={{ height: h, width: "100%" }}>
      {globalStyles()}
      <StyledDataGrid
        checkboxSelection
        disableRowSelectionOnClick
        density="compact"
        rows={listeProfils} 
        getRowId={(row) => row.Id}
        getRowClassName={
          (params) => {
            if (params.row.RetardTraitement === "Oui") {
              return "super-app-theme"
            }
          }
        }
        columns={colonnes} 
        slots= {{
          loadingOverlay: LinearProgress, 
          noRowsOverlay: CustomNoRowsOverlay, 
          noResultsOverlay: CustomNoRowsOverlay,
          toolbar: CustomToolbar, 
          pagination: CustomPagination
        }}
        loading={chargementListeProfils}
        componentsProps={{
          panel: {
            sx: {
              "& .MuiDataGrid-panelWrapper": {
                backgroundColor: darkMode ? "#202940" : "#fff",
              },
              "& .MuiNativeSelect-select": {
                color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
              },
              "& .MuiSvgIcon-root": {
                color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
              },
              "& .MuiFormControl-root": {
                color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
              }
            }
          }
        }}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 50},
          },
          columns: {
            columnVisibilityModel: !JSON.parse(localStorage.getItem("columnVisibilityModelP")) ? {} : JSON.parse(localStorage.getItem("columnVisibilityModelP"))
          },
          sorting: {
            sortModel: !JSON.parse(localStorage.getItem("sortModelP")) ? [] : JSON.parse(localStorage.getItem("sortModelP"))
          },
          filter: {
            filterModel: !JSON.parse(localStorage.getItem("filterModelP")) ? {items: []} : JSON.parse(localStorage.getItem("filterModelP"))
          }
        }}
        paginationModel={pageP}
        onPaginationModelChange={setPaginationModel}
        onColumnVisibilityModelChange={(model) => localStorage.setItem("columnVisibilityModelP", JSON.stringify(model))}
        rowSelectionModel={selectedRowP}
        onRowSelectionModelChange={
          (model) => {
            envoi(setSelectedRowP(model));
          }
        }
        onSortModelChange={(model) => localStorage.setItem("sortModelP", JSON.stringify(model))}
        onFilterModelChange={(model)=> localStorage.setItem("filterModelP", JSON.stringify(model))}
        localeText={lang === "fr" ? frFR : enUS}
      />
    </div>
  )
};

export default TableProfils;
