/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DOMPurify from 'dompurify';
import { modifNatureProduitAsync } from "ActionsAsync/ActionsNatureProduitAsync";
import { fermerFDialogueModifNP, fermerNotifModifNP, effacerErreurModifNP } from "Simplificateurs/SimplificateursNatureProduit";
import Modal from 'react-modal';

// Composants du @mui material
import Grid from "@mui/material/Grid";
import Snackbar from '@mui/material/Snackbar';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';

// Composants du Material Dashboard 2 React
import MDBox from "Composants/MDBox";
import MDTypography from "Composants/MDTypography";
import MDButton from "Composants/MDButton";

// Contexte Material Dashboard 2 PRO React
import { useMaterialUIController } from "Contexte";

import { Notification, styleModal430 } from "Vues/Utilitaires";

function NotificationsEtDialoguesUtilisateur() {
    const [controller, dispatch] = useMaterialUIController();
    const { darkMode, lang, socket } = controller;
  
    const envoi = useDispatch();
  
    const natureProduitObj = useSelector((state) => state.natureProduit);
    const {
      disabled,
      vertical,
      horizontal,
      data,
      ouvrir7,
      erreurModifNatureProduit,
      notifModifNatureProduit
    } = natureProduitObj;
  
    const traiterOuvrir = (id, redevancePourc) => () => {
      assignerId(id);
      assignerRedevancePourc(Number.parseFloat(redevancePourc).toFixed(2));
    };
    const traiterFermer = () => {
      envoi(fermerFDialogueModifNP());
      assignerId("");
      assignerRedevancePourc(Number.parseFloat("0.00").toFixed(2));
    };
    const traiterFermerNotification = () => {
      envoi(fermerNotifModifNP());
      assignerId("");
      assignerRedevancePourc(Number.parseFloat("0.00").toFixed(2));
    };
  
    const [id, assignerId] = useState("");

    const [redevancePourc, assignerRedevancePourc] = useState(Number.parseFloat("0.00").toFixed(2));
    const traiterRedevancePourc = e => {
      envoi(effacerErreurModifNP());
      var dec = Number.parseFloat((e.target.value).replace(/[^0-9]/gi, "").substr(0,5) * 0.01).toFixed(2);
      if (dec > 100.00) {
        assignerRedevancePourc(Number.parseFloat("100.00").toFixed(2));
      }
      else {
        assignerRedevancePourc(Number.parseFloat((e.target.value).replace(/[^0-9]/gi, "").substr(0,5) * 0.01).toFixed(2));
      }
    };

    const traiterModifNatureProduit = async (e) => {
      e.preventDefault();
      const cleanId = DOMPurify.sanitize(id, {
        ALLOWED_CHARS: /[^a-z0-9-]$/
      });
      await envoi(modifNatureProduitAsync(cleanId.trim(), redevancePourc, dispatch, socket));
    }
  
    return (
      <>
        <Snackbar 
          anchorOrigin={{ vertical, horizontal }} 
          open={notifModifNatureProduit} 
          autoHideDuration={6000} 
          onClose={traiterFermerNotification}
          key={vertical + horizontal + "1"}
        >
          <Notification onClose={traiterFermerNotification} severity="warning" sx={{ width: "100%", bgcolor: "background.paper"}}>
            {lang === "fr" && "Redevance produit modifiée avec succès"}
            {lang === "eng" && "Product royalty successfully edited"}
          </Notification>
        </Snackbar>
        <Modal isOpen={ouvrir7}  onAfterOpen={traiterOuvrir(data.id, data.redevancePourc)} onRequestClose={traiterFermer} ariaHideApp={false} style={styleModal430}>
          <MDBox 
            sx={{padding: 3, height: 315}}
            variant="gradient" 
            bgColor={darkMode ? "dark": "#fff"}
            borderRadius="lg"
            coloredShadow={darkMode ? "dark": "light"}
          >
            <Grid container spacing={3} alignItems="center">
              <Grid item>
                <MDBox height="100%" lineHeight={1}>
                  <MDTypography variant="h6" fontWeight="medium">
                    {lang === "fr" && "MODIFICATION"}
                    {lang === "eng" && "EDIT"}
                  </MDTypography>
                  <MDTypography variant="button" color="text" fontWeight="regular">
                    {lang === "fr" && "Vous pouvez modifier la redevance de"}
                    {lang === "eng" && "You can edit the royalty of"}
                    <br />"{data.nom}".
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>
            <br />
            <MDBox component="form" role="form" onSubmit={traiterModifNatureProduit} autoComplete="off">
              <MDBox mb={2} sx={{height: 70}}>
                <FormControl variant="outlined" sx={{width: 330}}>
                  <InputLabel htmlFor="outlined-adornment-perc2">
                    {lang === "fr" && "Redevance *"}
                    {lang === "eng" && "Royalty *"}
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-perc2"
                    endAdornment={
                      <span style={{color: darkMode ? "#fff": "black"}}>
                        %
                      </span>  
                    }
                    type="text" 
                    required 
                    label= {lang === "fr" ? "Redevance *" : "Royalty *"}
                    value={redevancePourc} 
                    onClick={(e) => e.target.setSelectionRange((e.target.value).length, (e.target.value).length)}
                    onKeyDown={(e) => e.target.setSelectionRange((e.target.value).length, (e.target.value).length)}
                    onChange={traiterRedevancePourc}  
                  />
                </FormControl>
              </MDBox>
              <MDBox mt={4} mb={1} sx={{textAlign: "center"}}>
                <MDBox mb={1}>
                  <MDButton type="button" variant="gradient" color="error" onClick={traiterFermer}>
                    {lang === "fr" && "Annuler"}
                    {lang === "eng" && "Cancel"}
                  </MDButton>
                  &nbsp;&nbsp;
                  <MDButton disabled={disabled} type="submit" variant="gradient" color="info">
                    {lang === "fr" && "Modifier"}
                    {lang === "eng" && "Edit"}
                  </MDButton>
                </MDBox>
                <MDBox>
                  {erreurModifNatureProduit === "Aucune modification" && 
                    <MDTypography variant="button" color="error" mt={1}>
                      {lang === "fr" && "Aucune modification"}
                      {lang === "eng" && "No modification"}
                    </MDTypography>
                  }
                  {erreurModifNatureProduit === "Champ(s) vide(s) dans la requête" && 
                    <MDTypography variant="button" color="error" mt={1}>
                      {lang === "fr" && "Champ(s) vide(s) dans la requête"}
                      {lang === "eng" && "Empty field(s) in the query"}
                    </MDTypography>
                  }
                </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
        </Modal>
      </>
    );
  };

  export default NotificationsEtDialoguesUtilisateur;