/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import DOMPurify from 'dompurify';
import { 
  validationProduit1Async, 
  validationProduit2Async,
  ajoutDEclarationAsync, 
  modifDEclarationAsync,
  validDEclarationAsync,
} from "ActionsAsync/ActionsDEclarationAsync";
import { listeLieuxAsync, listePaysAsync } from "ActionsAsync/ActionsUtilitaireAsync";
import { listeNatureProduitsAsync } from "ActionsAsync/ActionsNatureProduitAsync";
import { listeDPAsync } from "ActionsAsync/ActionsUtilisateurAsync";
import { 
    ouvrirFDialogueAjoutDcl,
    effacerErreurValidation1,
    effacerErreurValidation2,
    fermerFDialogueAjoutDcl,
    avant0,
    avant1,
    fermerNotifAjoutD,
    fermerFSurvolCamions,
} from "Simplificateurs/SimplificateursDEclaration";
import Modal from "react-modal";

// Composants du @mui material
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import Snackbar from "@mui/material/Snackbar";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from '@mui/material/StepContent';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import OutlinedInput from '@mui/material/OutlinedInput';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { styled } from "@mui/material/styles";
import { enUS } from '@mui/material/locale';

// Table
import { 
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport, 
} from "@mui/x-data-grid";

// Composants du Material Dashboard 2 React
import MDBox from "Composants/MDBox";
import MDTypography from "Composants/MDTypography";
import MDInput from "Composants/MDInput";
import MDButton from "Composants/MDButton";
import LinearProgress from '@mui/material/LinearProgress';

// Contexte Material Dashboard 2 PRO React
import { useMaterialUIController } from "Contexte";

import { 
  frFR, 
  globalStyles,
  CustomNoRowsOverlay, 
  CustomPagination
} from "Vues/Utilitaires";

import { Notification, styleSelect, StyledAutocomplete, styleModal430, styleModal820, styleModalFull } from "Vues/Utilitaires";
import { fermerFDialogueValidDcl } from "Simplificateurs/SimplificateursDEclaration";
import { fermerNotifValidDcl } from "Simplificateurs/SimplificateursDEclaration";

function NotificationsEtDialoguesDEclaration() {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, lang, socket } = controller;

  const envoi = useDispatch();

  const authObj = useSelector((state) => state.authentification);
  const idProfil = authObj.info.Id;

  const tableObj = useSelector((state) => state.table);
  const {
    chargementListeNatureProduits, 
    listeNatureProduits,
    chargementListeDP,
    listeDP
  } = tableObj;

  const dEclarationObj = useSelector((state) => state.dEclaration);
  const {
    disabled,
    vertical,
    horizontal,
    ouvrir10,
    activeStep,
    erreurValidationProduit1,
    erreurValidationProduit2,
    erreurAjoutDEclaration,
    notifAjoutDEclaration,
    ouvrir11,
    data,
    erreurModifDEclaration,
    notifModifDEclaration,
    ouvrir12,
    erreurValidDEclaration,
    notifValidDEclaration,
  } = dEclarationObj;

  const utilitaireObj = useSelector((state) => state.utilitaire);
  const { 
    chargementLieux, 
    listeLieux, 
    chargementPays,
    listePays
  } = utilitaireObj;

  useEffect(() => {
    const minuteurObtenirListes = setTimeout(() => {
      envoi(listeNatureProduitsAsync(dispatch, socket));
      envoi(listeLieuxAsync(dispatch, socket));
      envoi(listePaysAsync(dispatch, socket));
      if (authObj.info.TypeService === 1) envoi(listeDPAsync(idProfil, dispatch, socket));
    }, 1000);
    return () => {
      clearTimeout(minuteurObtenirListes);
    };
  }, []);

  const traiterOuvrir1 = () => envoi(ouvrirFDialogueAjoutDcl());
  const traiterFermer1 = () => {
    envoi(fermerFDialogueAjoutDcl());
    traiterAvant0();
    assignerNatureProduit(null); 
    assignerChantierExtraction(""); 
    assignerProvinceExtraction(null); 
    assignerEmplacementSite("");
    assignerZoneExploitation("");
    assignerLocalitEGroupement("");
    assignerSecteur(null);
    assignerTerritoire(null);
    assignerQtENet(Number.parseFloat("0.00").toFixed(2));
    assignerQtEHumide(Number.parseFloat("0.00").toFixed(2));
    assignerQtESec(Number.parseFloat("0.00").toFixed(2));
    assignerQualitESecPourc(Number.parseFloat("0.00").toFixed(2));
    assignerQualitEHumidePourc(Number.parseFloat("0.00").toFixed(2));
    assignerQtENetNature("");
    assignerQtEHumideNature("");
    assignerQtESecNature("");
    assignerPrixUnitEUSD(Number.parseFloat("0.00").toFixed(2));
    assignerPrixTotalUSD(Number.parseFloat("0.00").toFixed(2));
    assignerIdentitEAcheteurs("");
    assignerRaisonSocial("");
    assignerRueAcheteur("");
    assignerPaysAcheteur("");
    assignerEmailAcheteur("");
    assignerREglement("");
    assignerDateExportation(`${aujourd8}`);
    assignerFraisTransport(Number.parseFloat("0.00").toFixed(2));
    assignerFraisAnalyse(Number.parseFloat("0.00").toFixed(2));
    assignerFraisAssurance(Number.parseFloat("0.00").toFixed(2));
    assignerFraisCommercialisation(Number.parseFloat("0.00").toFixed(2));
    assignerVenteEntitETrans("");
    assignerListeCamions([])
  };
  const traiterFermerNotification1 = () => {
    envoi(fermerNotifAjoutD());
    traiterAvant0();
    assignerNatureProduit(null); 
    assignerChantierExtraction(""); 
    assignerProvinceExtraction(null); 
    assignerEmplacementSite("");
    assignerZoneExploitation("");
    assignerLocalitEGroupement("");
    assignerSecteur(null);
    assignerTerritoire(null);
    assignerQtENet(Number.parseFloat("0.00").toFixed(2));
    assignerQtEHumide(Number.parseFloat("0.00").toFixed(2));
    assignerQtESec(Number.parseFloat("0.00").toFixed(2));
    assignerQualitESecPourc(Number.parseFloat("0.00").toFixed(2));
    assignerQualitEHumidePourc(Number.parseFloat("0.00").toFixed(2));
    assignerQtENetNature("");
    assignerQtEHumideNature("");
    assignerQtESecNature("");
    assignerPrixUnitEUSD(Number.parseFloat("0.00").toFixed(2));
    assignerPrixTotalUSD(Number.parseFloat("0.00").toFixed(2));
    assignerIdentitEAcheteurs("");
    assignerRaisonSocial("");
    assignerRueAcheteur("");
    assignerPaysAcheteur("");
    assignerEmailAcheteur("");
    assignerREglement("");
    assignerDateExportation(`${aujourd8}`);
    assignerFraisTransport(Number.parseFloat("0.00").toFixed(2));
    assignerFraisAnalyse(Number.parseFloat("0.00").toFixed(2));
    assignerFraisAssurance(Number.parseFloat("0.00").toFixed(2));
    assignerFraisCommercialisation(Number.parseFloat("0.00").toFixed(2));
    assignerVenteEntitETrans("");
    assignerListeCamions([])
  }

  /*const traiterOuvrir2 = (idProfil, typeId, dEnomination, idNat, nom, postNom, prEnom, no, avenue,  quartier, commune, ville, province, noAgr, dateD, dateF, email, phone) => () => {
    assignerIdProfil(idProfil);
    assignerTypeId(typeId);
    assignerDEnomination(dEnomination);
    assignerIdNat(idNat);
    assignerNom(nom);
    assignerPostNom(postNom);
    assignerPrEnom(prEnom);
    assignerNo(no);
    assignerAvenue(avenue);
    assignerQuartier(quartier);
    assignerListeCommunes([{Id: 1, Nom: commune}]);
    assignerCommune(commune);
    assignerListeVilles([{Id: 1, Nom: ville}]);
    assignerVille(ville);
    assignerProvince(province);
    assignerNoAgr(noAgr);
    assignerDateD(moment(dateD).format('YYYY-MM-DD'));
    assignerDateF(moment(dateF).format('YYYY-MM-DD'));
    assignerEmail(email);
    assignerPhone(phone);
  }
  const traiterFermer2 = () => {
    envoi(fermerFDialogueModifP());
    assignerBlocknModification(true);
    assignerIdProfil("");
    assignerTypeId("");
    assignerDEnomination("");
    assignerIdNat("");
    assignerNom("");
    assignerPostNom("");
    assignerPrEnom("");
    assignerNo("");
    assignerAvenue("");
    assignerQuartier("");
    assignerListeCommunes([]);
    assignerCommune("");
    assignerListeVilles([]);
    assignerVille("");
    assignerProvince("");
    assignerNoAgr("");
    assignerDateD(`${aujourd8}`);
    assignerDateF(`${aujourd8}`);
    assignerEmail("");
    assignerPhone("");

  }
  const traiterFermerNotification2 = () => {
    envoi(notifModifDEclaration);
    assignerBlocknModification(true);
  }*/

  const traiterOuvrir3 = (idDEclaration) => () => {
    assignerIdDEclaration(idDEclaration);
  };
  const traiterFermer3 = () => {
    envoi(fermerFDialogueValidDcl());
    assignerIdDEclaration("");
    assignerNomVisaId("");
    assignerNomVisa("");
    assignerFonctionVisa("");
    assignerGouverneur("");
    assignerMaireAdm("");
  };
  const traiterFermerNotification3 = () => {
    envoi(fermerNotifValidDcl());
    assignerIdDEclaration("");
    assignerNomVisaId("");
    assignerNomVisa("");
    assignerFonctionVisa("");
    assignerGouverneur("");
    assignerMaireAdm("");
  };

  const traiterOuvrir4 = (listeCamionsParD, no) => () => {
    assignerListeCamionsParD(listeCamionsParD);
    assignerNo(no);
    assignerChargementLCParD(false);
  };
  const traiterFermer4 = () => {
    envoi(fermerFSurvolCamions());
    assignerListeCamionsParD([]);
    assignerNo("");
    assignerChargementLCParD(true);
  };

  const [no, assignerNo] = useState("");

  const [natureProduit, assignerNatureProduit] = useState(null);
  const traiterNatureProduit = (event, nouvelleValeur) => {
    assignerNatureProduit(nouvelleValeur);
  };

  const patternLieuxEtAutre = new RegExp(/^[A-Z0-9'.-\s\/]{3,20}$/);
  const [chantierExtraction, assignerChantierExtraction] = useState("");
  const traiterChantierExtraction = e => {
    let target = e.target;
    const cursor = e.target.selectionStart;
    assignerChantierExtraction((e.target.value).replace(/[^a-zA-Z0-9'\.\-\s\/]/gi, "").toUpperCase());
    setTimeout(() => {
      target.setSelectionRange(cursor, cursor);
    }, 10);
  };
  const [emplacementSite, assignerEmplacementSite] = useState("");
  const traiterEmplacementSite = e => {
    let target = e.target;
    const cursor = e.target.selectionStart;
    assignerEmplacementSite((e.target.value).replace(/[^a-zA-Z0-9'\.\-\s\/]/gi, "").toUpperCase());
    setTimeout(() => {
      target.setSelectionRange(cursor, cursor);
    }, 10);
  };
  const [zoneExploitation, assignerZoneExploitation] = useState("");
  const traiterZoneExploitation = e => {
    let target = e.target;
    const cursor = e.target.selectionStart;
    assignerZoneExploitation((e.target.value).replace(/[^a-zA-Z0-9'\.\-\s\/]/gi, "").toUpperCase());
    setTimeout(() => {
      target.setSelectionRange(cursor, cursor);
    }, 10);
  };
  const [localitEGroupement, assignerLocalitEGroupement] = useState("");
  const traiterLocalitEGroupement = e => {
    let target = e.target;
    const cursor = e.target.selectionStart;
    assignerLocalitEGroupement((e.target.value).replace(/[^a-zA-Z0-9'\.\-\s\/]/gi, "").toUpperCase());
    setTimeout(() => {
      target.setSelectionRange(cursor, cursor);
    }, 10);
  };
  const [rueAcheteur, assignerRueAcheteur] = useState("");
  const traiterRueAcheteur = e => {
    let target = e.target;
    const cursor = e.target.selectionStart;
    assignerRueAcheteur((e.target.value).replace(/[^a-zA-Z0-9'\.\-\s\/]/gi, "").toUpperCase());
    setTimeout(() => {
      target.setSelectionRange(cursor, cursor);
    }, 10);
  };
  const [rEglement, assignerREglement] = useState("");
  const traiterREglement = e => {
    let target = e.target;
    const cursor = e.target.selectionStart;
    assignerREglement((e.target.value).replace(/[^a-zA-Z0-9'\.\-\s\/]/gi, "").toUpperCase());
    setTimeout(() => {
      target.setSelectionRange(cursor, cursor);
    }, 10);
  };

  const [listeTerritoires, assignerListeTerritoires] = useState([]);
  const [provinceExtraction, assignerProvinceExtraction] = useState(null);
  const traiterProvinceExtraction = (event, nouvelleValeur) => {
    assignerTerritoire(null);
    assignerProvinceExtraction(nouvelleValeur);
    const  territoiresArray = listeLieux.filter(province => province.Nom === nouvelleValeur.Nom)[0].VilleTerritoires;
    assignerListeTerritoires([...territoiresArray].filter(territoire => !territoire.EstVille && territoire.EstTerritoire).sort((a, b) => a.Nom.localeCompare(b.Nom)));
  };
  const [listeSecteurs, assignerListeSecteurs] = useState([]);
  const [territoire, assignerTerritoire] = useState(null);
  const traiterTerritoire = (event, nouvelleValeur) => {
    assignerSecteur(null);
    assignerTerritoire(nouvelleValeur);
    let secteursArray = (listeTerritoires.filter(territoire => territoire.Nom == nouvelleValeur.Nom))[0].CommuneSecteurs;
    assignerListeSecteurs([...secteursArray].filter(secteur => secteur.EstSecteur).sort((a, b) => a.Nom.localeCompare(b.Nom)));
  };
  const [secteur, assignerSecteur] = useState(null);
  const traiterSecteur = (event, nouvelleValeur) => {
    assignerSecteur(nouvelleValeur);
  };

  const patternNoms = new RegExp(/^[A-Z.-\s]{3,50}$/);
  const [identitEAcheteurs, assignerIdentitEAcheteurs] = useState("");
  const traiterIdentitEAcheteurs = e => {
    let target = e.target;
    const cursor = e.target.selectionStart;
    assignerIdentitEAcheteurs((e.target.value).replace(/[^a-zA-Z\.\-\s]/gi, "").toUpperCase());
    setTimeout(() => {
      target.setSelectionRange(cursor, cursor);
    }, 10);
  };
  const [raisonSocial, assignerRaisonSocial] = useState("");
  const traiterRaisonSocial = e => {
    let target = e.target;
    const cursor = e.target.selectionStart;
    assignerRaisonSocial((e.target.value).replace(/[^a-zA-Z\.\-\s]/gi, "").toUpperCase());
    setTimeout(() => {
      target.setSelectionRange(cursor, cursor);
    }, 10);
  };

  const patternNomComplet = new RegExp(/^[A-Z.-\s]{3,100}$/);
  const [nomVisaId, assignerNomVisaId] = useState("");
  const [nomVisa, assignerNomVisa] = useState("");
  const traiterNomVisaId = e => {
    assignerNomVisaId(e.target.value);
    let nomArray = (listeDP.filter(obj => {return obj.Id === e.target.value}))[0].Nom;
    let postNomArray = (listeDP.filter(obj => {return obj.Id === e.target.value}))[0].PostNom;
    let prEnomArray = (listeDP.filter(obj => {return obj.Id === e.target.value}))[0].PrEnom;
    assignerNomVisa(nomArray + " " + postNomArray + " " + prEnomArray);
    let fonctionArray = (listeDP.filter(obj => {return obj.Id === e.target.value}))[0].Service.Fonction;
    assignerFonctionVisa(fonctionArray);
  };
  const [gouverneur, assignerGouverneur] = useState("");
  const traiterGouverneur = e => {
    let target = e.target;
    const cursor = e.target.selectionStart;
    assignerGouverneur((e.target.value).replace(/[^a-zA-Z\.\-\s]/gi, "").toUpperCase());
    setTimeout(() => {
      target.setSelectionRange(cursor, cursor);
    }, 10);
  };
  const [maireAdm, assignerMaireAdm] = useState("");
  const traiterMaireAdm = e => {
    let target = e.target;
    const cursor = e.target.selectionStart;
    assignerMaireAdm((e.target.value).replace(/[^a-zA-Z\.\-\s]/gi, "").toUpperCase());
    setTimeout(() => {
      target.setSelectionRange(cursor, cursor);
    }, 10);
  };

  const [paysAcheteur, assignerPaysAcheteur] = useState("");
  const traiterPaysAcheteur = e => {
    assignerPaysAcheteur(e.target.value);
  };

  const patternEmail = new RegExp(/^[A-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Z0-9](?:[A-Z0-9-]*[A-Z0-9])?\.)+[A-Z]{2,}$/i);
  const [emailAcheteur, assignerEmailAcheteur] = useState("");
  const traiterEmailAcheteur= e => {
    envoi(effacerErreurValidation2());
    assignerEmailAcheteur(e.target.value);
  };

  const patternVenteEntitETrans = new RegExp(/^[A-Z0-9.-\s]{3,50}$/);
  const [venteEntitETransformation, assignerVenteEntitETrans] = useState("");
  const traiterVenteEntitETrans= e => {
    let target = e.target;
    const cursor = e.target.selectionStart;
    assignerVenteEntitETrans((e.target.value).replace(/[^a-zA-Z0-9\.\-\s]/gi, "").toUpperCase());
    setTimeout(() => {
      target.setSelectionRange(cursor, cursor);
    }, 10);
  };

  const [qtENetNature, assignerQtENetNature] = useState("");
  const traiterQtENetNature = e => {
    assignerQtENetNature(e.target.value);
    assignerQtEHumideNature(e.target.value);
    assignerQtESecNature(e.target.value);
  }
  const [qtEHumideNature, assignerQtEHumideNature] = useState("");
  const traiterQtEHumideNature = e => {
    assignerQtEHumideNature(e.target.value);
    assignerQtENetNature(e.target.value);
    assignerQtESecNature(e.target.value);
  }
  const [qtESecNature, assignerQtESecNature] = useState("");
  const traiterQtESecNature = e => {
    assignerQtESecNature(e.target.value);
    assignerQtENetNature(e.target.value);
    assignerQtEHumideNature(e.target.value);
  }

  const [qtENet, assignerQtENet] = useState(Number.parseFloat("0.00").toFixed(2));
  const traiterQtENet = e => {
    envoi(effacerErreurValidation1());
    assignerQtESec(Number.parseFloat("0.00").toFixed(2));
    assignerQtEHumide(Number.parseFloat("0.00").toFixed(2));
    assignerQualitESecPourc(Number.parseFloat("0.00").toFixed(2));
    assignerQualitEHumidePourc(Number.parseFloat("0.00").toFixed(2));
    assignerQtENet(Number.parseFloat((e.target.value).replace(/[^0-9]/gi, "").substr(0,12) * 0.01).toFixed(2));
  };
  const [qualitESecPourc, assignerQualitESecPourc] = useState(Number.parseFloat("0.00").toFixed(2));
  const [qualitEHumidePourc, assignerQualitEHumidePourc] = useState(Number.parseFloat("0.00").toFixed(2));
  const [qtEHumide, assignerQtEHumide] = useState(Number.parseFloat("0.00").toFixed(2));
  const traiterQtEHumide = e => {
    envoi(effacerErreurValidation1());
    const dec = Number.parseFloat((e.target.value).replace(/[^0-9]/gi, "").substr(0, 12) * 0.01).toFixed(2);
    if (qtENet > 0.00) {
      if (Math.round(dec * 100) > Math.round(qtENet * 100)) {
        assignerQtEHumide(qtENet);
        assignerQtESec(Number.parseFloat("0.00").toFixed(2));
  
        assignerQualitEHumidePourc(Number.parseFloat("100.00").toFixed(2));
        assignerQualitESecPourc(Number.parseFloat("0.00").toFixed(2));
      }
      else {
        assignerQtEHumide(dec);
        assignerQtESec((qtENet - dec).toFixed(2));
  
        assignerQualitEHumidePourc((dec / qtENet * 100).toFixed(2));
        assignerQualitESecPourc(((qtENet - dec) / qtENet * 100).toFixed(2));
      }
    }
  };
  const [qtESec, assignerQtESec] = useState(Number.parseFloat("0.00").toFixed(2));
  const traiterQtESec = e => {
    envoi(effacerErreurValidation1());
    var dec = Number.parseFloat((e.target.value).replace(/[^0-9]/gi, "").substr(0,12) * 0.01).toFixed(2);
    if(qtENet > 0.00) {
      if (Math.floor(dec * 100) > Math.floor(qtENet * 100)) {
        assignerQtESec(qtENet);
        assignerQtEHumide(Number.parseFloat("0.00").toFixed(2));
  
        assignerQualitESecPourc(Number.parseFloat("100.00").toFixed(2));
        assignerQualitEHumidePourc(Number.parseFloat("0.00").toFixed(2));
      }
      else {
        assignerQtESec(dec);
        assignerQtEHumide((qtENet - dec).toFixed(2));
  
        assignerQualitESecPourc((dec / qtENet * 100).toFixed(2));
        assignerQualitEHumidePourc(((qtENet - dec) / qtENet * 100).toFixed(2));
      }
    }
  };
 
  const [prixUnitEUSD, assignerPrixUnitEUSD] = useState(Number.parseFloat("0.00").toFixed(2));
  const traiterPrixUnitEUSD = e => {
    envoi(effacerErreurValidation2());
    assignerPrixUnitEUSD(Number.parseFloat((e.target.value).replace(/[^0-9]/gi, "").substr(0,12) * 0.01).toFixed(2));
  };
  const [prixTotalUSD, assignerPrixTotalUSD] = useState(Number.parseFloat("0.00").toFixed(2));
  const traiterPrixTotalUSD = e => {
    envoi(effacerErreurValidation2());
    assignerPrixTotalUSD(Number.parseFloat((e.target.value).replace(/[^0-9]/gi, "").substr(0,12) * 0.01).toFixed(2));
  };
  const [fraisTransport, assignerFraisTransport] = useState(Number.parseFloat("0.00").toFixed(2));
  const traiterFraisTransport = e => {
    assignerFraisTransport(Number.parseFloat((e.target.value).replace(/[^0-9]/gi, "").substr(0,12) * 0.01).toFixed(2));
  };
  const [fraisAnalyse, assignerFraisAnalyse] = useState(Number.parseFloat("0.00").toFixed(2));
  const traiterFraisAnalyse = e => {
    assignerFraisAnalyse(Number.parseFloat((e.target.value).replace(/[^0-9]/gi, "").substr(0,12) * 0.01).toFixed(2));
  };
  const [fraisAssurance, assignerFraisAssurance] = useState(Number.parseFloat("0.00").toFixed(2));
  const traiterFraisAssurance = e => {
    assignerFraisAssurance(Number.parseFloat((e.target.value).replace(/[^0-9]/gi, "").substr(0,12) * 0.01).toFixed(2));
  };
  const [fraisCommercialisation, assignerFraisCommercialisation] = useState(Number.parseFloat("0.00").toFixed(2));
  const traiterFraisCommercialisation = e => {
    assignerFraisCommercialisation(Number.parseFloat((e.target.value).replace(/[^0-9]/gi, "").substr(0,12) * 0.01).toFixed(2));
  };

  var aujourd8 = new Date();
  var jj = String(aujourd8.getDate()).padStart(2, '0');
  var mm = String(aujourd8.getMonth() + 1).padStart(2, '0');
  var yyyy = aujourd8.getFullYear();
  aujourd8 = yyyy + '-' + mm + '-' + jj;
  const [dateExportation, assignerDateExportation] = useState(`${aujourd8}`);
  const traiterDateExportation = e => {
    assignerDateExportation(e.target.value);
  };

  const patternPlaque = new RegExp(/^[A-Z0-9-\s]{6,20}$/);
  const [plaque, assignerPlaque] = useState("");
  const traiterPlaque= e => {
    assignerErreurValidProduit3(0);
    let target = e.target;
    const cursor = e.target.selectionStart;
    assignerPlaque((e.target.value).replace(/[^a-zA-Z0-9\-\s]/gi, "").toUpperCase());
    setTimeout(() => {
      target.setSelectionRange(cursor, cursor);
    }, 10);
  };

  const [qtENetC, assignerQtENetC] = useState(Number.parseFloat("0.00").toFixed(2));
  const traiterQtENetC = e => {
    assignerErreurValidProduit3(0);
    assignerQtESecC(Number.parseFloat("0.00").toFixed(2));
    assignerQtEHumideC(Number.parseFloat("0.00").toFixed(2));
    assignerQualitEHumidePourcC(Number.parseFloat("0.00").toFixed(2));
    assignerQtENetC(Number.parseFloat((e.target.value).replace(/[^0-9]/gi, "").substr(0,12) * 0.01).toFixed(2));
  };
  const [qualitEHumidePourcC, assignerQualitEHumidePourcC] = useState(Number.parseFloat("0.00").toFixed(2));
  const [qtEHumideC, assignerQtEHumideC] = useState(Number.parseFloat("0.00").toFixed(2));
  const traiterQtEHumideC = e => {
    assignerErreurValidProduit3(0);
    var dec = Number.parseFloat((e.target.value).replace(/[^0-9]/gi, "").substr(0,12) * 0.01).toFixed(2);
    if(qtENetC > 0.00) {
      if (Math.floor(dec * 100) > Math.floor(qtENetC * 100)) {
        assignerQtEHumideC(qtENetC);
        assignerQtESecC(Number.parseFloat("0.00").toFixed(2));
  
        assignerQualitEHumidePourcC(Number.parseFloat("100.00").toFixed(2));
      }
      else {
        assignerQtEHumideC(dec);
        assignerQtESecC((qtENetC - dec).toFixed(2));
  
        assignerQualitEHumidePourcC((dec / qtENetC * 100).toFixed(2));
      }
    }
  };
  const [qtESecC, assignerQtESecC] = useState(Number.parseFloat("0.00").toFixed(2));
  const traiterQtESecC = e => {
    assignerErreurValidProduit3(0);
    var dec = Number.parseFloat((e.target.value).replace(/[^0-9]/gi, "").substr(0,12) * 0.01).toFixed(2);
    if(qtENetC > 0.00) {
      if (Math.floor(dec * 100) > Math.floor(qtENetC * 100)) {
        assignerQtESecC(qtENetC);
        assignerQtEHumideC(Number.parseFloat("0.00").toFixed(2));
  
        assignerQualitEHumidePourcC(Number.parseFloat("0.00").toFixed(2));
      }
      else {
        assignerQtESecC(dec);
        assignerQtEHumideC((qtENetC - dec).toFixed(2));
  
        assignerQualitEHumidePourcC(((qtENetC - dec) / qtENetC * 100).toFixed(2));
      }
    }
  };
  const [prixUnitEUSDC, assignerPrixUnitEUSDC] = useState(Number.parseFloat("0.00").toFixed(2));
  const traiterPrixUnitEUSDC = e => {
     assignerErreurValidProduit3(0);
    assignerPrixUnitEUSDC(Number.parseFloat((e.target.value).replace(/[^0-9]/gi, "").substr(0,12) * 0.01).toFixed(2));
  };
  const [prixTotalUSDC, assignerPrixTotalUSDC] = useState(Number.parseFloat("0.00").toFixed(2));
  const traiterPrixTotalUSDC = e => {
     assignerErreurValidProduit3(0);
    assignerPrixTotalUSDC(Number.parseFloat((e.target.value).replace(/[^0-9]/gi, "").substr(0,12) * 0.01).toFixed(2));
  };

  const [erreurValidationProduit3, assignerErreurValidProduit3] = useState(0);
  const [listeCamions, assignerListeCamions] = useState([]);
  const traiterListeCamions = () => {
    assignerErreurValidProduit3(0)
    if(plaque === "") {
      return assignerErreurValidProduit3(1);
    }
    const cleanPlaque = DOMPurify.sanitize(plaque, {
      ALLOWED_CHARS: /^[A-Z0-9-\s]{6,20}$/
    });
    if (!patternPlaque.test(cleanPlaque)) {
      return;
    }
    const invalidationDoublon = listeCamions.find(x => {
      if (x.Plaque === cleanPlaque) {
          return [assignerErreurValidProduit3(2), true];
      }
      return false;
    });
    if (invalidationDoublon) {
      return;
    }
    if(qtENetC === Number.parseFloat("0.00").toFixed(2)) {
      return assignerErreurValidProduit3(3);
    }
    if(qtENetC > Number.parseFloat("45000.00").toFixed(2)) {
      return assignerErreurValidProduit3(30);
    }
    if(qtESecC === Number.parseFloat("0.00").toFixed(2)) {
      return assignerErreurValidProduit3(4);
    }
    if(qtEHumideC === Number.parseFloat("0.00").toFixed(2)) {
      return assignerErreurValidProduit3(5);
    }
    if(prixUnitEUSDC === Number.parseFloat("0.00").toFixed(2)) {
      return assignerErreurValidProduit3(6);
    }
    if(prixTotalUSDC === Number.parseFloat("0.00").toFixed(2)) {
      return assignerErreurValidProduit3(7);
    }
    if (Math.floor((cumulQtENet * 100) + (qtENetC * 100)) > Math.floor(Number.parseFloat(qtENetNature === "1" ? qtENet : qtENet * 1000).toFixed(2) * 100)) {
      return assignerErreurValidProduit3(8);
    }
    if (Math.floor((cumulPrixTotalUSD * 100) + (prixTotalUSDC * 100)) > Math.floor(prixTotalUSD * 100)) {
      return assignerErreurValidProduit3(9);
    }
    const obj = {
      Plaque: cleanPlaque, 
      QtENet: qtENetC,
      QualitEHumidePourc: qualitEHumidePourcC,
      QtEHumide: qtEHumideC,
      QtESec: qtESecC,
      PrixUnitEUSD: prixUnitEUSDC,
      PrixTotalUSD: prixTotalUSDC
    };
    assignerListeCamions([...listeCamions, obj]);
    assignerPlaque("");
    assignerQtENetC(Number.parseFloat("0.00").toFixed(2));
    assignerQualitEHumidePourcC(Number.parseFloat("0.00").toFixed(2));
    assignerQtEHumideC(Number.parseFloat("0.00").toFixed(2));
    assignerQtESecC(Number.parseFloat("0.00").toFixed(2));
    assignerPrixUnitEUSDC(Number.parseFloat("0.00").toFixed(2));
    assignerPrixTotalUSDC(Number.parseFloat("0.00").toFixed(2));
  }

  const cumulQtENet = listeCamions.reduce((acc, x) => acc + parseFloat(Math.floor(Number.parseFloat(x.QtENet).toFixed(2) * 100) / 100), 0).toFixed(2);
  const cumulPrixTotalUSD = listeCamions.reduce((acc, x) => acc + parseFloat(Math.floor(Number.parseFloat(x.PrixTotalUSD).toFixed(2) * 100) / 100), 0).toFixed(2);
  const memoCumulQtENet = useMemo(() => cumulQtENet, [listeCamions, qtENet]);
  const memoCumulPrixTotalUSD = useMemo(() => cumulPrixTotalUSD, [listeCamions, prixTotalUSD]);

  const [fonctionVisa, assignerFonctionVisa] = useState("");
  const [idDEclaration, assignerIdDEclaration] = useState("");
  const [chargementLCParD, assignerChargementLCParD] = useState(true);
  const [listeCamionsParD, assignerListeCamionsParD] = useState([]);

  const submitHandler = (e) => {
    const { id } = e.nativeEvent.submitter;
    switch (id) {
      case "submit1":
        return traiterSuivant1(e);

      case "submit2":
        return traiterSuivant2(e);

      case "submit3":
        return traiterAjoutDEclaration(e);

      default:
      // ignore
    }
  };

  const supprimer = (index) => {
    assignerErreurValidProduit3(0);
    const copieListeCamions = [...listeCamions];
    copieListeCamions.splice(index, 1);
    assignerListeCamions(copieListeCamions);
  }

  const cleanNatureProduit = DOMPurify.sanitize(natureProduit?.NomFr, {
    ALLOWED_CHARS: /^[A-ZéÉè]{2,20}$/
  });
  const cleanChantierExtraction = DOMPurify.sanitize(chantierExtraction, {
    ALLOWED_CHARS: /^[A-Z0-9'.-\s\/]{3,20}$/
  });
  const cleanProvinceExtraction = DOMPurify.sanitize(provinceExtraction?.Nom, {
    ALLOWED_CHARS: /^[A-Z0-9'.-\s\/]{3,20}$/
  });
  const cleanEmplacementSite = DOMPurify.sanitize(emplacementSite, {
    ALLOWED_CHARS: /^[A-Z0-9'.-\s\/]{3,20}$/
  });
  const cleanZoneExploitation = DOMPurify.sanitize(zoneExploitation, {
    ALLOWED_CHARS: /^[A-Z0-9'.-\s\/]{3,20}$/
  });
  const cleanLocalitEGroupement = DOMPurify.sanitize(localitEGroupement, {
    ALLOWED_CHARS: /^[A-Z0-9'.-\s\/]{3,20}$/
  });
  const cleanSecteur = DOMPurify.sanitize(secteur?.Nom, {
    ALLOWED_CHARS: /^[A-Z0-9'.-\s\/]{3,20}$/
  });
  const cleanTerritoire = DOMPurify.sanitize(territoire?.Nom, {
    ALLOWED_CHARS: /^[A-Z0-9'.-\s\/]{3,20}$/
  });

  const traiterSuivant1 = async (e) => {
    e.preventDefault();
    envoi(validationProduit1Async(
      cleanNatureProduit, 
      cleanChantierExtraction, 
      cleanProvinceExtraction, 
      cleanEmplacementSite,
      cleanZoneExploitation,
      cleanLocalitEGroupement,
      cleanSecteur,
      cleanTerritoire,
      qtENet,
      qtEHumide,
      qtESec,
      qualitESecPourc,
      qualitEHumidePourc
    ));

  }

  const cleanIdentitEAcheteurs = DOMPurify.sanitize(identitEAcheteurs, {
    ALLOWED_CHARS: /^[A-Z.-\s]{3,50}$/
  });
  const cleanRaisonSocial = DOMPurify.sanitize(raisonSocial, {
    ALLOWED_CHARS: /^[A-Z.-\s]{3,50}$/
  });
  const cleanRueAcheteur = DOMPurify.sanitize(rueAcheteur, {
    ALLOWED_CHARS: /^[A-Z.-\s]{3,50}$/
  });
  const cleanPaysAcheteur = DOMPurify.sanitize(paysAcheteur, {
    ALLOWED_CHARS: /^[A-Z.-\s]{3,50}$/
  });
  const cleanEmailAcheteur = DOMPurify.sanitize(emailAcheteur, {
    ALLOWED_CHARS: /^[A-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Z0-9](?:[A-Z0-9-]*[A-Z0-9])?\.)+[A-Z]{2,}$/i
  });
  const cleanREglement = DOMPurify.sanitize(rEglement, {
    ALLOWED_CHARS: /^[A-Z.-\s]{3,50}$/
  });
  const cleanVenteEntitETransformation = DOMPurify.sanitize(venteEntitETransformation, {
    ALLOWED_CHARS: /^[A-Z0-9.-\s]{3,50}$/
  });
  
  const traiterSuivant2 = async (e) => {
    e.preventDefault();
    envoi(validationProduit2Async(
      prixUnitEUSD,
      prixTotalUSD,
      cleanIdentitEAcheteurs,
      cleanRaisonSocial,
      cleanRueAcheteur,
      cleanPaysAcheteur,
      cleanEmailAcheteur,
      cleanREglement,
      dateExportation,
      fraisTransport,
      fraisAnalyse,
      fraisAssurance,
      fraisCommercialisation,
      cleanVenteEntitETransformation
    ));
  }
  const traiterAvant0 = () => envoi(avant0());
  const traiterAvant1 = () => envoi(avant1());

  const cleanIdProfil = DOMPurify.sanitize(idProfil, {
    ALLOWED_CHARS: /[^a-z0-9-]$/
  });

  const traiterAjoutDEclaration = async (e) => {
    e.preventDefault();
    assignerErreurValidProduit3(0)
    if (!listeCamions.length) {
      return assignerErreurValidProduit3(10);
    }
    if (Math.floor((cumulQtENet * 100) + (qtENetC * 100)) < Math.floor(Number.parseFloat(qtENetNature === "1" ? qtENet : qtENet * 1000).toFixed(2) * 100)) {
      return assignerErreurValidProduit3(11);
    }
    if (Math.floor((cumulPrixTotalUSD * 100) + (prixTotalUSDC * 100)) < Math.floor(prixTotalUSD * 100)) {
      return assignerErreurValidProduit3(12);
    }
    envoi(ajoutDEclarationAsync(
      cleanIdProfil.trim(),
      cleanNatureProduit, 
      cleanChantierExtraction, 
      cleanProvinceExtraction, 
      cleanEmplacementSite,
      cleanZoneExploitation,
      cleanLocalitEGroupement,
      cleanSecteur,
      cleanTerritoire,
      qtENetNature,
      qtENet,
      qtEHumide,
      qtESec,
      qualitESecPourc,
      qualitEHumidePourc,
      prixUnitEUSD,
      prixTotalUSD,
      cleanIdentitEAcheteurs,
      cleanRaisonSocial,
      cleanRueAcheteur,
      cleanPaysAcheteur,
      cleanEmailAcheteur,
      cleanREglement,
      dateExportation,
      fraisTransport,
      fraisAnalyse,
      fraisAssurance,
      fraisCommercialisation,
      cleanVenteEntitETransformation, 
      listeCamions,
      dispatch, 
      socket
    ));
  }
  const traiterModifDEclaration = async (e) => {
    e.preventDefault();
    envoi(modifDEclarationAsync(dispatch, socket));
  }

  const cleanIdDEclaration = DOMPurify.sanitize(idDEclaration, {
    ALLOWED_CHARS: /[^a-z0-9-]$/
  });
  const cleanNomVisa = DOMPurify.sanitize(nomVisa, {
    ALLOWED_CHARS: /^[A-Z.-\s]{3,100}$/
  });
  const cleanFonctionVisa = DOMPurify.sanitize(fonctionVisa, {
    ALLOWED_CHARS: /^[A-Z0-9'.-\s\/]{3,20}$/
  });

  const traiterValidDEclaration = async (e) => {
    e.preventDefault();
    envoi(validDEclarationAsync(cleanIdDEclaration.trim(), cleanNomVisa, cleanFonctionVisa, gouverneur, maireAdm, dispatch, socket));
  }

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    border: 0,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    WebkitFontSmoothing: "auto",
    letterSpacing: "normal",
    fontSize: "13px",
    backgroundColor: darkMode ? "#202940" : "#fff",
    color: darkMode ? "#7b809a" : "rgba(0,0,0,.85)",
    "& .MuiDataGrid-main": {
      color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
    },
    "& .MuiDataGrid-columnSeparator": {
      color: "#7b809a"
    },
    "& .MuiDataGrid-columnHeaders, & .MuiDataGrid-cell": {
      borderBottom: darkMode ? "1px solid #344767" : "1px solid #f0f2f5"
    },
    "& .MuiDataGrid-footerContainer": {
      borderTop: darkMode ? "1px solid #344767" : "1px solid #f0f2f5"
    },
    "& .MuiIconButton-root": {
      color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
    },
    "& .MuiPaginationItem-previousNext": {
      color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
    },
    "& .MuiPaginationItem-textInfo": {
      color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
    },
    "& .Mui-selected": {
      color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)"
    },
    "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
      outline: "none"
    }
  }));

  function CustomToolbar1() {
    return (
      <GridToolbarContainer>
        {null}
      </GridToolbarContainer>
    );
  }

  function CustomToolbar2() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport 
          printOptions={{ disableToolbarButton: true }} 
          csvOptions={{
            fileName: lang === "fr" ? `${"Liste des camions - " + no + " (" + moment(new Date()).format('DD-MM-YYYY HHmmss') + ")"}` : `${"Trucks list - " + no + " (" + moment(new Date()).format('DD-MM-YYYY HHmmss') + ")"}`,
            delimiter: ',',
            utf8WithBom: true,
          }}
        />
      </GridToolbarContainer>
    );
  }

  const colonnesCamions = [
    {
      field: "id",
      headerName: lang === "fr" ? "N°" : "No.",  
      width: 50,
      align:"right",
      sortable: false, 
      filterable: false,
      disableColumnMenu: true,
      disableExport: true
    },
    { 
      field: "Plaque", 
      headerName: lang === "fr" ? "Plaque" : "Plate No.", 
      width: 100,
      sortable: false, 
      filterable: false,
      disableColumnMenu: true,
      disableExport: true
    },
    { 
      field: "QtENet", 
      headerName: lang === "fr" ? "Ps Net/Kg" : "Net Wt/Kg", 
      width: 85,
      align:"right",
      renderCell: (params) => (
        <div>
          {lang === "fr" && `${Number.parseFloat(params.row.QtENet).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")}`}
          {lang === "eng" && `${Number.parseFloat(params.row.QtENet).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")}`}
        </div>
      ), 
      sortable: false, 
      filterable: false,
      disableColumnMenu: true,
      disableExport: true
    },
    { 
      field: "QualitEHumidePourc", 
      headerName: lang === "fr" ? "Humidité %" : "Humidity %", 
      width: 95,
      align:"right",
      renderCell: (params) => (
        <div>
          {lang === "fr" && `${Number.parseFloat(params.row.QualitEHumidePourc).toFixed(2).replace(".", ",")}`}
          {lang === "eng" && `${Number.parseFloat(params.row.QualitEHumidePourc).toFixed(2)}`}
        </div>
      ), 
      sortable: false, 
      filterable: false,
      disableColumnMenu: true,
      disableExport: true
    },
    { 
      field: "QtEHumide", 
      headerName: lang === "fr" ? "Ps Humidité/Kg" : "Humidity Wt/Kg", 
      width: 117,
      align:"right",
      renderCell: (params) => (
        <div>
          {lang === "fr" && `${Number.parseFloat(params.row.QtEHumide).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")}`}
          {lang === "eng" && `${Number.parseFloat(params.row.QtEHumide).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")}`}
        </div>
      ), 
      sortable: false, 
      filterable: false,
      disableColumnMenu: true,
      disableExport: true
    },
    { 
      field: "QtESec", 
      headerName: lang === "fr" ? "Ps Sec/Kg" : "Dry Wt/Kg", 
      width: 85,
      align:"right",
      renderCell: (params) => (
        <div>
          {lang === "fr" && `${Number.parseFloat(params.row.QtESec).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")}`}
          {lang === "eng" && `${Number.parseFloat(params.row.QtESec).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")}`}
        </div>
      ),
      sortable: false, 
      filterable: false,
      disableColumnMenu: true,
      disableExport: true
    },
    { 
      field: "PrixUnitEUSD", 
      headerName: lang === "fr" ? "Prix Unit/$" : "Unit Price/$", 
      width: 85,
      align:"right",
      renderCell: (params) => (
        <div>
          {lang === "fr" && `${Number.parseFloat(params.row.PrixUnitEUSD).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")}`}
          {lang === "eng" && `${Number.parseFloat(params.row.PrixUnitEUSD).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")}`}
        </div>
      ),
      sortable: false, 
      filterable: false,
      disableColumnMenu: true,
      disableExport: true
    },
    { 
      field: "PrixTotalUSD", 
      headerName: lang === "fr" ? "Prix Total/$" : "Total Price/$", 
      width: 95,
      align:"right",
      renderCell: (params) => (
        <div>
          {lang === "fr" && `${Number.parseFloat(params.row.PrixTotalUSD).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")}`}
          {lang === "eng" && `${Number.parseFloat(params.row.PrixTotalUSD).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")}`}
        </div>
      ),
      sortable: false, 
      filterable: false,
      disableColumnMenu: true,
      disableExport: true
    },
    {
      field: "Option",
      width: 65,
      renderCell: (params) => {
        return (
          <MDBox>
              <Tooltip title={lang === "fr" ? "Supprimer" : "Delete"}>
                <MDButton variant="gradient" color="error" size="small" iconOnly onClick={() => supprimer(params.api.getRowIndexRelativeToVisibleRows(params.row.Id))}>
                  <Icon>delete_forever</Icon>
                </MDButton>
              </Tooltip>
          </MDBox>
        );
      }, 
      sortable: false, 
      filterable: false,
      disableColumnMenu: true,
      disableExport: true
    }
  ]; 

  const colonnesCamionsParD = [
    {
      field: "id",
      headerName: lang === "fr" ? "N°" : "No.", 
      width: 50,
      align:"right",
      disableColumnMenu: true
    },
    { 
      field: "Plaque", 
      headerName: lang === "fr" ? "Plaque" : "Plate No.",  
      width: 100,
      disableColumnMenu: true
    },
    { 
      field: "QtENet", 
      headerName: lang === "fr" ? "Ps Net/Kg" : "Net Wt/Kg", 
      width: 85,
      align:"right",
      renderCell: (params) => (
        <div>
          {lang === "fr" && `${Number.parseFloat(params.row.QtENet).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")}`}
          {lang === "eng" && `${Number.parseFloat(params.row.QtENet).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")}`}
        </div>
      ), 
      valueGetter: (value) => lang === "fr" ? `${Number.parseFloat(value).toFixed(2).replace(".", ",")}` : `${Number.parseFloat(value).toFixed(2)}`,
      disableColumnMenu: true
    },
    { 
      field: "QualitEHumidePourc", 
      headerName: lang === "fr" ? "Humidité %" : "Humidity %", 
      width: 95,
      align:"right",
      renderCell: (params) => (
        <div>
          {lang === "fr" && `${Number.parseFloat(params.row.QualitEHumidePourc).toFixed(2).replace(".", ",")}`}
          {lang === "eng" && `${Number.parseFloat(params.row.QualitEHumidePourc).toFixed(2)}`}
        </div>
      ), 
      valueGetter: (value) => lang === "fr" ? `${Number.parseFloat(value).toFixed(2).replace(".", ",")}` : `${Number.parseFloat(value).toFixed(2)}`,
      disableColumnMenu: true
    },
    { 
      field: "QtEHumide", 
      headerName: lang === "fr" ? "Ps Humidité/Kg" : "Humidity Wt/Kg", 
      width: 117,
      align:"right",
      renderCell: (params) => (
        <div>
          {lang === "fr" && `${Number.parseFloat(params.row.QtEHumide).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")}`}
          {lang === "eng" && `${Number.parseFloat(params.row.QtEHumide).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")}`}
        </div>
      ), 
      valueGetter: (value) => lang === "fr" ? `${Number.parseFloat(value).toFixed(2).replace(".", ",")}` : `${Number.parseFloat(value).toFixed(2)}`,
      disableColumnMenu: true
    },
    { 
      field: "QtESec", 
      headerName: lang === "fr" ? "Ps Sec/Kg" : "Dry Wt/Kg", 
      width: 85,
      align:"right",
      renderCell: (params) => (
        <div>
          {lang === "fr" && `${Number.parseFloat(params.row.QtESec).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")}`}
          {lang === "eng" && `${Number.parseFloat(params.row.QtESec).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")}`}
        </div>
      ),
      valueGetter: (value) => lang === "fr" ? `${Number.parseFloat(value).toFixed(2).replace(".", ",")}` : `${Number.parseFloat(value).toFixed(2)}`,
      disableColumnMenu: true
    },
    { 
      field: "PrixUnitEUSD", 
      headerName: lang === "fr" ? "Prix Unit/$" : "Unit Price/$", 
      width: 85,
      align:"right",
      renderCell: (params) => (
        <div>
          {lang === "fr" && `${Number.parseFloat(params.row.PrixUnitEUSD).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")}`}
          {lang === "eng" && `${Number.parseFloat(params.row.PrixUnitEUSD).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")}`}
        </div>
      ),
      valueGetter: (value) => lang === "fr" ? `${Number.parseFloat(value).toFixed(2).replace(".", ",")}` : `${Number.parseFloat(value).toFixed(2)}`,
      disableColumnMenu: true
    },
    { 
      field: "PrixTotalUSD", 
      headerName: lang === "fr" ? "Prix Total/$" : "Total Price/$", 
      width: 95,
      align:"right",
      renderCell: (params) => (
        <div>
          {lang === "fr" && `${Number.parseFloat(params.row.PrixTotalUSD).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")}`}
          {lang === "eng" && `${Number.parseFloat(params.row.PrixTotalUSD).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")}`}
        </div>
      ),
      valueGetter: (value) => lang === "fr" ? `${Number.parseFloat(value).toFixed(2).replace(".", ",")}` : `${Number.parseFloat(value).toFixed(2)}`,
      disableColumnMenu: true
    }
  ]; 

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 9
  });

  return (
    <>
      <Snackbar 
        anchorOrigin={{ vertical, horizontal }} 
        open={notifAjoutDEclaration} 
        autoHideDuration={6000} 
        onClose={traiterFermerNotification1}
        key={vertical + horizontal + "1"}
      >
        <Notification onClose={traiterFermerNotification1} severity="success" sx={{ width: "100%", bgcolor: "background.paper"}}>
          {lang === "fr" && "Déclaration ajouée avec succès"}
          {lang === "eng" && "Declaration successfully added"}
        </Notification>
      </Snackbar>
      <Snackbar 
        anchorOrigin={{ vertical, horizontal }} 
        open={notifModifDEclaration} 
        autoHideDuration={6000} 
        onClose={/*traiterFermerNotification2*/""}
        key={vertical + horizontal + "2"}
      >
        <Notification onClose={/*traiterFermerNotification2*/""} severity="warning" sx={{ width: "100%", bgcolor: "background.paper"}}>
          {lang === "fr" && "Déclaration modifiée avec succès"}
          {lang === "eng" && "Declaration successfully edited"}
        </Notification>
      </Snackbar>
      <Snackbar 
        anchorOrigin={{ vertical, horizontal }} 
        open={notifValidDEclaration} 
        autoHideDuration={6000} 
        onClose={traiterFermerNotification3}
        key={vertical + horizontal + "3"}
      >
        <Notification onClose={traiterFermerNotification3} severity="success" sx={{ width: "100%", bgcolor: "background.paper"}}>
          {lang === "fr" && "Déclaration validée avec succès"}
          {lang === "eng" && "Declaration successfully validated"}
        </Notification>
      </Snackbar>
      {authObj.info.AssujettiId !== null &&
        <Tooltip title={lang === "fr" ? "Ajouter" : "Add"}>
          <MDButton variant="gradient" color="success" iconOnly sx={{float: "right"}} onClick={traiterOuvrir1}>
            <Icon>add</Icon>
          </MDButton>
        </Tooltip>
      }
      <Modal isOpen={ouvrir10} onRequestClose={traiterFermer1} ariaHideApp={false} style={styleModalFull}>
        <MDBox 
          sx={{padding: 3, width: "100%"}}
          variant="gradient" 
          bgColor={darkMode ? "dark": "white"}
          borderRadius="lg"
          coloredShadow={darkMode ? "dark": "light"}
        >
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <MDBox lineHeight={1}>
                <MDTypography variant="h6" fontWeight="medium">
                  {lang === "fr" && "FORMULAIRE DE DECLARATION"}
                  {lang === "eng" && "DECLARATION FORM"}
                </MDTypography>
                <MDTypography variant="button" color="text" fontWeight="regular">
                  {lang === "fr" && "Notez bien que les caractères invalides n'apparaîtront pas dans les champs d'entrée."}
                  {lang === "eng" && "Please note that invalid characters will not appear in input fields."}
                  &nbsp;
                  <Tooltip title={lang === "fr" ? "Pas d'accents, de tréma ou de cédille et autres caractères spéciaux en dehors de certaines ponctuations tels que le point (.), le trait d'union (-) ainsi que la barre oblique(/) ou l'espace ( ) dans certains cas." : "No accents, diaeresis or cedilla and other special characters apart from certain punctuation such as the period (.), the hyphen (-) as well as the slash (/) or the space ( ) in some cases."}>
                    <Icon color="success">info</Icon>
                  </Tooltip>
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
          <MDBox mt={-2} component="form" role="form" onSubmit={submitHandler} autoComplete="off">
            <Stepper activeStep={activeStep} orientation="vertical" sx={{background: "transparent", border: 0, boxShadow: "none"}} connector={null}>
              <Step key="nature">
                <StepLabel icon={<Icon>looks_one</Icon>} sx={{color: darkMode ? "#fff" : "rgba(0,0,0,.85)"}}>
                  <MDTypography display="block" variant="button" color="text" fontWeight="medium">
                    {lang === "fr" && "NATURE, ORIGINE, QUANTITE ET QUALITE"}
                    {lang === "eng" && "NATURE, ORIGIN, QUANTITY AND QUALITY"}
                  </MDTypography>
                  <MDTypography display="block" variant="button" color="text" fontWeight="light">
                    {lang === "fr" && "(des produits marchands concernés)"}
                    {lang === "eng" && "(of the commercial products concerned)"}
                  </MDTypography>
                </StepLabel>
                <StepContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6} xl={4}>
                      <MDBox mb={2}>
                        <MDTypography variant="button" color="text" fontWeight="regular">
                          {lang === "fr" && "NATURE ET ORIGINE"}
                          {lang === "eng" && "NATURE AND ORIGIN"}
                        </MDTypography>
                        <MDBox mt={2} mb={3.5} sx={{height: 65}}>
                          {chargementListeNatureProduits ? 
                            <MDTypography variant="button" color="dark" mt={1}>
                              {lang === "fr" && "Chargement..."}
                              {lang === "eng" && "Loading..."}
                            </MDTypography> :
                            <StyledAutocomplete
                              disableClearable
                              openOnFocus
                              clearOnEscape
                              options={[...listeNatureProduits].sort((a, b) =>
                                lang === "fr"
                                  ? a.NomFr.localeCompare(b.NomAng)
                                  : a.NomAng.localeCompare(b.NomFr)
                              )}
                              getOptionLabel={(option) =>
                                option ? (lang === "fr" ? option.NomFr : option.NomAng) : ""
                              }
                              isOptionEqualToValue={(option, value) =>
                                lang === "fr"
                                  ? option.NomFr === value.NomFr
                                  : option.NomAng === value.NomAng
                              }
                              noOptionsText={lang === "fr" ? "Non trouvé" : "Not found"}
                              sx={{width: "97%"}} 
                              value={natureProduit} 
                              onChange={traiterNatureProduit}
                              renderInput={(params) => (
                                <MDInput required type="text" label={lang === "fr" ? "Nature des produits" : "Type of products"} {...params} />
                              )}
                            />
                          }
                        </MDBox>
                        <MDBox mb={3.5} sx={{height: 65, lineHeight: 0.5}}>
                          <MDInput 
                            type="text" 
                            required 
                            label={lang === "fr" ? "Chantier d'extraction" : "Extraction site"} 
                            value={chantierExtraction} 
                            onChange={traiterChantierExtraction} 
                            sx={{width: "97%"}} 
                            error={chantierExtraction === "" ? false : patternLieuxEtAutre.test(chantierExtraction) ? false : true}
                            success={chantierExtraction === "" ? false : patternLieuxEtAutre.test(chantierExtraction) ? true : false} 
                          />
                          {chantierExtraction !== "" && !(patternLieuxEtAutre.test(chantierExtraction)) &&  
                            <MDTypography variant="button" color="error">
                              <br />
                              {lang === "fr" && "3 caractères min et 20 caractères max"}
                              {lang === "eng" && "3 characters min and 20 characters max"}
                            </MDTypography>
                          }
                        </MDBox>
                        <MDBox mt={2} mb={3.5} sx={{height: 65}}>
                          {chargementLieux ? 
                            <MDTypography variant="button" color="dark" mt={1}>
                              {lang === "fr" && "Chargement..."}
                              {lang === "eng" && "Loading..."}
                            </MDTypography> :
                            <StyledAutocomplete
                              disableClearable
                              openOnFocus
                              clearOnEscape
                              options={listeLieux}
                              getOptionLabel={(option) => option ? option.Nom : ""}
                              isOptionEqualToValue={(option, value) => option.Nom === value.Nom}
                              noOptionsText={lang === "fr" ? "Non trouvé" : "Not found"}
                              sx={{width: "97%"}} 
                              value={provinceExtraction} 
                              onChange={traiterProvinceExtraction}
                              renderInput={(params) => (
                                <MDInput required type="text" label={lang === "fr" ? "Province d'extraction" : "Province of extraction"} {...params} />
                              )}
                            />
                          }
                        </MDBox>
                        <MDBox mb={3.5} sx={{height: 65, lineHeight: 0.5}}>
                          <MDInput 
                            type="text" 
                            required 
                            label={lang === "fr" ? "Emplacement du site" : "Site location"} 
                            value={emplacementSite} 
                            onChange={traiterEmplacementSite} 
                            sx={{width: "97%"}} 
                            error={emplacementSite === "" ? false : patternLieuxEtAutre.test(emplacementSite) ? false : true}
                            success={emplacementSite === "" ? false : patternLieuxEtAutre.test(emplacementSite) ? true : false} 
                          />
                          {emplacementSite !== "" && !(patternLieuxEtAutre.test(emplacementSite)) &&  
                            <MDTypography variant="button" color="error">
                              <br />
                              {lang === "fr" && "3 caractères min et 20 caractères max"}
                              {lang === "eng" && "3 characters min and 20 characters max"}
                            </MDTypography>
                          }
                        </MDBox>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} xl={4}>
                      <MDBox mt={6}  mb={2}>
                        <MDBox mb={3.5} sx={{height: 65, lineHeight: 0.5}}>
                          <MDInput 
                            type="text" 
                            required 
                            label={lang === "fr" ? "Nom de la zone d'exploitation industrielle/artisanale" : "Name of the industrial/artisanal exploitation area"}
                            value={zoneExploitation}
                            onChange={traiterZoneExploitation} 
                            sx={{width: "97%"}} 
                            error={zoneExploitation === "" ? false : patternLieuxEtAutre.test(zoneExploitation) ? false : true}
                            success={zoneExploitation === "" ? false : patternLieuxEtAutre.test(zoneExploitation) ? true : false} 
                          />
                          {zoneExploitation !== "" && !(patternLieuxEtAutre.test(zoneExploitation)) &&  
                            <MDTypography variant="button" color="error">
                              <br />
                              {lang === "fr" && "3 caractères min et 20 caractères max"}
                              {lang === "eng" && "3 characters min and 20 characters max"}
                            </MDTypography>
                          }
                        </MDBox>
                        <MDBox mb={3.5} sx={{height: 65, lineHeight: 0.5}}>
                          <MDInput 
                            type="text"
                            required
                            label={lang === "fr" ? "Localité/Groupement" : "Locality/Grouping"}
                            value={localitEGroupement} 
                            onChange={traiterLocalitEGroupement} 
                            sx={{width: "97%"}} 
                            error={localitEGroupement === "" ? false : patternLieuxEtAutre.test(localitEGroupement) ? false : true}
                            success={localitEGroupement === "" ? false : patternLieuxEtAutre.test(localitEGroupement) ? true : false} 
                          />
                          {localitEGroupement !== "" && !(patternLieuxEtAutre.test(localitEGroupement)) && 
                            <MDTypography variant="button" color="error">
                              <br />
                              {lang === "fr" && "3 caractères min et 20 caractères max"}
                              {lang === "eng" && "3 characters min and 20 characters max"}
                            </MDTypography>
                          }
                        </MDBox>
                        <MDBox mb={3.5} sx={{height: 65}}>
                          {chargementLieux ? 
                            <MDTypography variant="button" color="dark" mt={1}>
                              {lang === "fr" && "Chargement..."}
                              {lang === "eng" && "Loading..."}
                            </MDTypography> :
                            provinceExtraction === null ? 
                            <MDTypography variant="button" color="dark" mt={1}>
                              {lang === "fr" && "Sélectionner la province d'extraction..."}
                              {lang === "eng" && "Select the province of extraction..."}
                            </MDTypography> :
                            <StyledAutocomplete
                              disableClearable
                              openOnFocus
                              clearOnEscape
                              options={listeTerritoires}
                              getOptionLabel={(option) => option ? option.Nom : ""}
                              isOptionEqualToValue={(option, value) => option.Nom === value.Nom}
                              noOptionsText={lang === "fr" ? "Non trouvé" : "Not found"}
                              sx={{width: "97%"}} 
                              value={territoire} 
                              onChange={traiterTerritoire}
                              renderInput={(params) => (
                                <MDInput required type="text" label={lang === "fr" ? "Territoire" : "Territory"} {...params} />
                              )}
                            />
                          }
                        </MDBox>
                        <MDBox mb={3.5} sx={{height: 65}}>
                          {chargementLieux ? 
                            <MDTypography variant="button" color="dark" mt={1}>
                              {lang === "fr" && "Chargement..."}
                              {lang === "eng" && "Loading..."}
                            </MDTypography> :
                            territoire === null ? 
                            <MDTypography variant="button" color="dark" mt={1}>
                              {lang === "fr" && "Sélectionner le territoire..."}
                              {lang === "eng" && "Select the territory..."}
                            </MDTypography> :
                            <StyledAutocomplete
                              disableClearable
                              openOnFocus
                              clearOnEscape
                              options={listeSecteurs}
                              getOptionLabel={(option) => option ? option.Nom : ""}
                              isOptionEqualToValue={(option, value) => option.Nom === value.Nom}
                              noOptionsText={lang === "fr" ? "Non trouvé" : "Not found"}
                              sx={{width: "97%"}} 
                              value={secteur} 
                              onChange={traiterSecteur}
                              renderInput={(params) => (
                                <MDInput required type="text" label={lang === "fr" ? "Secteur" : "Sector"} {...params} />
                              )}
                            />
                          }
                        </MDBox>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} xl={4}>
                      <MDBox mb={2}>
                        <MDTypography variant="button" color="text" fontWeight="regular">
                          {lang === "fr" && "QUANTITE ET QUALITE"}
                          {lang === "eng" && "QUANTITY AND QUALITY"}
                        </MDTypography>
                        <Grid container spacing={2} sx={{width: "102%"}}>
                          <Grid item xs={6}>
                            <MDBox mt={2} mb={3.5} sx={{height: 65, lineHeight: 0.5}}>
                              <FormControl variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-weight1">{lang === "fr" ? "Quantité net *" : "Net quantity *"}</InputLabel>
                                <OutlinedInput
                                  id="outlined-adornment-weight1"
                                  type="text" 
                                  label={lang === "fr" ? "Quantité net *" : "Net quantity *"}
                                  value={qtENet} 
                                  onClick={(e) => e.target.setSelectionRange((e.target.value).length, (e.target.value).length)}
                                  onKeyDown={(e) => e.target.setSelectionRange((e.target.value).length, (e.target.value).length)}
                                  onChange={traiterQtENet}  
                                />
                                {erreurValidationProduit1 === "Quantité net : valeur <= 0.00 non valide" && 
                                  <MDTypography variant="button" color="error">
                                    {lang === "fr" && "La valeur doit être > 0"}
                                    {lang === "eng" && "The value must be > 0"}
                                  </MDTypography>
                                }
                              </FormControl>
                            </MDBox>
                          </Grid>
                          <Grid item xs={6} mt={1}>
                            <MDBox sx={{lineHeight: 1}}>
                              <RadioGroup 
                                sx={{color: darkMode ? "#fff": "black"}}
                                row
                                aria-labelledby="activation-radio-buttons-group-label" 
                                value={qtENetNature}
                                onChange={traiterQtENetNature}
                              >
                                <FormControlLabel value="1" control={<Radio required  />} label="Kg(s)" />
                                <FormControlLabel value="0" control={<Radio required  />} label="T(s)" />
                              </RadioGroup>
                            </MDBox>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{width: "102%"}}>
                          <Grid item xs={6}>
                            <MDBox mb={3.5} sx={{height: 65, lineHeight: 0.5}}>
                              <FormControl variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-weight2">{lang === "fr" ? "Quantité humide *" : "Wet quantity *"}</InputLabel>
                                <OutlinedInput
                                  id="outlined-adornment-weight2"
                                  type="text" 
                                  label={lang === "fr" ? "Quantité humide *" : "Wet quantity *"}
                                  value={qtEHumide} 
                                  onClick={(e) => e.target.setSelectionRange((e.target.value).length, (e.target.value).length)}
                                  onKeyDown={(e) => e.target.setSelectionRange((e.target.value).length, (e.target.value).length)}
                                  onChange={traiterQtEHumide}  
                                />
                                {erreurValidationProduit1 === "Quantité humide : valeur <= 0.00 non valide" && 
                                  <MDTypography variant="button" color="error">
                                    {lang === "fr" && "La valeur doit être > 0"}
                                    {lang === "eng" && "The value must be > 0"}
                                  </MDTypography>
                                }
                              </FormControl>
                            </MDBox>
                          </Grid>
                          <Grid item xs={6} mt={-1}>
                            <MDBox sx={{lineHeight: 1}}>
                              <RadioGroup 
                                ml={5}
                                sx={{color: darkMode ? "#fff": "black"}}
                                row
                                aria-labelledby="activation-radio-buttons-group-label" 
                                value={qtEHumideNature}
                                onChange={traiterQtEHumideNature}
                              >
                                <FormControlLabel value="1" control={<Radio required  />} label="Kg(s)" />
                                <FormControlLabel value="0" control={<Radio required  />} label="T(s)" />
                              </RadioGroup>
                            </MDBox>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{width: "102%"}}>
                          <Grid item xs={6}>
                            <MDBox mb={3.5} sx={{height: 65, lineHeight: 0.5}}>
                              <FormControl variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-weight3">{lang === "fr" ? "Quantité sec *" : "Dry quantity *"}</InputLabel>
                                <OutlinedInput
                                  id="outlined-adornment-weight3"
                                  type="text" 
                                  label={lang === "fr" ? "Quantité sec *" : "Dry quantity *"}
                                  value={qtESec} 
                                  onClick={(e) => e.target.setSelectionRange((e.target.value).length, (e.target.value).length)}
                                  onKeyDown={(e) => e.target.setSelectionRange((e.target.value).length, (e.target.value).length)}
                                  onChange={traiterQtESec}  
                                />
                                {erreurValidationProduit1 === "Quantité sec : valeur <= 0.00 non valide" && 
                                  <MDTypography variant="button" color="error">
                                    {lang === "fr" && "La valeur doit être > 0"}
                                    {lang === "eng" && "The value must be > 0"}
                                  </MDTypography>
                                }
                              </FormControl>
                            </MDBox>
                          </Grid>
                          <Grid item xs={6} mt={-1}>
                            <MDBox sx={{lineHeight: 1}}>
                              <RadioGroup 
                                sx={{color: darkMode ? "#fff": "black"}}
                                row
                                aria-labelledby="activation-radio-buttons-group-label" 
                                value={qtESecNature}
                                onChange={traiterQtESecNature}
                              >
                                <FormControlLabel value="1" control={<Radio required  />} label="Kg(s)" />
                                <FormControlLabel value="0" control={<Radio required  />} label="T(s)" />
                              </RadioGroup>              
                            </MDBox>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{width: "102%"}}>
                          <Grid item xs={6}>
                            <FormControl variant="outlined">
                              <InputLabel htmlFor="outlined-adornment-perc1">{lang === "fr" ? "Qualité *" : "Quality *"}</InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-perc1"
                                endAdornment={
                                  <span style={{color: darkMode ? "#fff": "black"}}>
                                    %
                                  </span>  
                                }
                                type="text" 
                                readOnly 
                                label={lang === "fr" ? "Qualité *" : "Quality *"}
                                value={qualitESecPourc} 
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl variant="outlined">
                              <InputLabel htmlFor="outlined-adornment-perc2">H2O *</InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-perc2"
                                endAdornment={
                                  <span style={{color: darkMode ? "#fff": "black"}}>
                                    %
                                  </span>  
                                }
                                type="text" 
                                readOnly 
                                label="H2O *"
                                value={qualitEHumidePourc} 
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </MDBox>
                    </Grid>
                  </Grid>
                  <MDBox mt={1.5} sx={{textAlign: "center", height: 60}}>
                    <MDBox mb={1}>
                      <MDButton variant="gradient" color="error" onClick={traiterFermer1}>
                        {lang === "fr" && "Annuler"}
                        {lang === "eng" && "Cancel"}
                      </MDButton>
                      &nbsp;&nbsp;
                      <MDButton disabled={disabled} id="submit1" type="submit" variant="gradient" color="info">
                        {lang === "fr" && "Suivant"}
                        {lang === "eng" && "Next"}
                      </MDButton>
                    </MDBox>
                    <MDBox>
                      {erreurValidationProduit1 === "Champ(s) vide(s) dans la requête" && 
                        <MDTypography variant="button" color="error" mt={1}>
                          {lang === "fr" && "Champ(s) vide(s) dans la requête"}
                          {lang === "eng" && "Empty field(s) in the query"}
                        </MDTypography>
                      }
                    </MDBox>
                  </MDBox>
                </StepContent>
              </Step>
              <Step key="dtls">
                <StepLabel icon={<Icon>looks_two</Icon>} sx={{color: darkMode ? "#fff" : "rgba(0,0,0,.85)"}}>
                  <MDTypography display="block" variant="button" color="text" fontWeight="medium">
                    DETAILS
                  </MDTypography>
                </StepLabel>
                <StepContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6} xl={4}>
                      <MDBox mt={1} mb={3.5} sx={{flexGrow: 1, height: 85, lineHeight: 0.5}}>
                        <Grid container spacing={2} sx={{width: "102%"}}>
                          <Grid item xs={6}>
                            <FormControl variant="outlined">
                              <InputLabel htmlFor="outlined-adornment-price1">{lang === "fr" ? "Prix/Unité *" : "Unit price *"}</InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-price1"
                                endAdornment={
                                  <span style={{color: darkMode ? "#fff": "black"}}>
                                    USD
                                  </span>  
                                }
                                type="text" 
                                required 
                                label={lang === "fr" ? "Prix/Unité *" : "Unit price *"}
                                value={prixUnitEUSD} 
                                onClick={(e) => e.target.setSelectionRange((e.target.value).length, (e.target.value).length)}
                                onKeyDown={(e) => e.target.setSelectionRange((e.target.value).length, (e.target.value).length)}
                                onChange={traiterPrixUnitEUSD}  
                              />
                              {erreurValidationProduit2 === "Prix/Unité (USD) : valeur <= 0.00 non valide" && 
                                <MDTypography variant="button" color="error">
                                  {lang === "fr" && "La valeur doit être > 0"}
                                  {lang === "eng" && "The value must be > 0"}
                                </MDTypography>
                              }
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl variant="outlined">
                              <InputLabel htmlFor="outlined-adornment-price2">{lang === "fr" ? "Prix Total *" : "Total price *"}</InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-price2"
                                endAdornment={
                                  <span style={{color: darkMode ? "#fff": "black"}}>
                                    USD
                                  </span>  
                                }
                                type="text" 
                                required 
                                label={lang === "fr" ? "Prix Total *" : "Total price *"}
                                value={prixTotalUSD} 
                                onClick={(e) => e.target.setSelectionRange((e.target.value).length, (e.target.value).length)}
                                onKeyDown={(e) => e.target.setSelectionRange((e.target.value).length, (e.target.value).length)}
                                onChange={traiterPrixTotalUSD}  
                              />
                              {erreurValidationProduit2 === "Prix total (USD) : valeur <= 0.00 non valide" && 
                                <MDTypography variant="button" color="error">
                                  {lang === "fr" && "La valeur doit être > 0"}
                                  {lang === "eng" && "The value must be > 0"}
                                </MDTypography>
                              }
                            </FormControl>
                          </Grid>
                        </Grid>
                      </MDBox>
                      <MDBox mb={3.5} sx={{height: 65, lineHeight: 0.5}}>
                        <MDInput 
                          type="text" 
                          required 
                          label={lang === "fr" ? "Identité des acheteurs" : "Identity of buyers"}
                          value={identitEAcheteurs} 
                          onChange={traiterIdentitEAcheteurs} 
                          sx={{width: "97%"}} 
                          error={identitEAcheteurs === "" ? false : patternNoms.test(identitEAcheteurs) ? false : true}
                          success={identitEAcheteurs === "" ? false : patternNoms.test(identitEAcheteurs) ? true : false} 
                        />
                        {identitEAcheteurs !== "" && !(patternNoms.test(identitEAcheteurs)) &&  
                          <MDTypography variant="button" color="error">
                            <br />
                            {lang === "fr" && "3 caractères min et 50 caractères max"}
                            {lang === "eng" && "3 characters min and 50 characters max"}
                          </MDTypography>
                        }
                      </MDBox>
                      <MDBox mb={3.5} sx={{height: 65, lineHeight: 0.5}}>
                        <MDInput 
                          type="text" 
                          required 
                          label={lang === "fr" ? "Nom ou raison social" : "Company name"} 
                          value={raisonSocial} 
                          onChange={traiterRaisonSocial} 
                          sx={{width: "97%"}} 
                          error={raisonSocial === "" ? false : patternNoms.test(raisonSocial) ? false : true}
                          success={raisonSocial === "" ? false : patternNoms.test(raisonSocial) ? true : false} 
                        />
                        {raisonSocial !== "" && !(patternNoms.test(raisonSocial)) &&  
                          <MDTypography variant="button" color="error">
                            <br />
                            {lang === "fr" && "3 caractères min et 50 caractères max"}
                            {lang === "eng" && "3 characters min and 50 characters max"}
                          </MDTypography>
                        }
                      </MDBox>
                      <MDBox mb={3.5} sx={{height: 65, lineHeight: 0.5}}>
                        <MDInput 
                          type="text" 
                          required 
                          label={lang === "fr" ? "Rue" : "Street"} 
                          value={rueAcheteur} 
                          onChange={traiterRueAcheteur} 
                          sx={{width: "97%"}} 
                          error={rueAcheteur === "" ? false : patternLieuxEtAutre.test(rueAcheteur) ? false : true}
                          success={rueAcheteur === "" ? false : patternLieuxEtAutre.test(rueAcheteur) ? true : false} 
                        />
                        {rueAcheteur !== "" && !(patternLieuxEtAutre.test(rueAcheteur)) &&  
                          <MDTypography variant="button" color="error">
                            <br />
                            {lang === "fr" && "3 caractères min et 20 caractères max"}
                            {lang === "eng" && "3 characters min and 20 characters max"}
                          </MDTypography>
                        }
                      </MDBox> 
                    </Grid>
                    <Grid item xs={12} md={6} xl={4}>
                      <MDBox mt={1} mb={3.5} sx={{flexGrow: 1, height: 85, lineHeight: 0.5}}>
                        <Grid container spacing={2} sx={{width: "102%"}}>
                          <Grid item xs={6}>
                            {chargementPays ? 
                              <MDTypography variant="button" color="dark" mt={1}>
                                {lang === "fr" && "Chargement..."}
                                {lang === "eng" && "Loading..."}
                              </MDTypography> :
                              <FormControl sx={{height: 45, width: "100%"}}>
                                <InputLabel id="select-insert5-label" htmlFor="select-insert5">{lang === "fr" ? "Pays *" : "Country *"}</InputLabel>
                                <Select 
                                  sx={{height: 46}} 
                                  required
                                  labelId="select-insert5-label"
                                  inputProps={{id: "select-insert5"}}
                                  label={lang === "fr" ? "Pays *" : "Country *"}
                                  value={paysAcheteur}
                                  onChange={traiterPaysAcheteur}
                                >
                                  {
                                    listePays.map((pays) => (
                                      <MenuItem key={pays.Id} value={pays.NomFr} sx={{ display: "flex", gap: 1 }}>
                                        <img src={require(`../../../drapeaux/128x128/${pays.Iso2.toLowerCase()}.png`)} alt={pays.NomFr} width={18} />
                                        <span style={styleSelect}>
                                          {pays.NomFr}
                                        </span>        
                                      </MenuItem>
                                    ))
                                  }      
                                </Select>
                              </FormControl>
                            }
                          </Grid>
                          <Grid item xs={6}>
                            <MDInput sx={{width: "97%"}} type="date" required label={lang === "fr" ? "Date de l'exportation" : "Date of export"} value={dateExportation} onChange={traiterDateExportation} />
                          </Grid>
                        </Grid>
                      </MDBox>
                      <MDBox mb={3.5} sx={{height: 65, lineHeight: 0.5}}>
                        <MDInput 
                          type="text" 
                          required 
                          label="Email" 
                          value={emailAcheteur} 
                          onChange={traiterEmailAcheteur} 
                          sx={{width: "97%"}} 
                          error={emailAcheteur === "" ? false : patternEmail.test(emailAcheteur) ? false : true}
                          success={emailAcheteur === "" ? false : patternEmail.test(emailAcheteur) ? true : false} 
                        />
                        {emailAcheteur !== "" && !(patternEmail.test(emailAcheteur)) && 
                          <MDTypography variant="button" color="error">
                            <br />
                            {lang === "fr" && "Veuillez entrer une adresse e-mail valide"}
                            {lang === "eng" && "Please enter a valid email address"}
                          </MDTypography>
                        }
                      </MDBox>
                      <MDBox mb={3.5} sx={{height: 65, lineHeight: 0.5}}>
                        <MDInput 
                          type="text" 
                          required 
                          label={lang === "fr" ? "Modalité du Règlement du prix de vente" : "Payment terms"} 
                          value={rEglement} 
                          onChange={traiterREglement} 
                          sx={{width: "97%"}} 
                          error={rEglement === "" ? false : patternLieuxEtAutre.test(rEglement) ? false : true}
                          success={rEglement === "" ? false : patternLieuxEtAutre.test(rEglement) ? true : false} 
                        />
                        {rEglement !== "" && !(patternLieuxEtAutre.test(rEglement)) &&  
                          <MDTypography variant="button" color="error">
                            <br />
                            {lang === "fr" && "3 caractères min et 20 caractères max"}
                            {lang === "eng" && "3 characters min and 20 characters max"}
                          </MDTypography>
                        }
                      </MDBox>
                      <FormControl sx={{width: "97%", mb: 6}} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-fee1">{lang === "fr" ? "Frais de transport *" : "Transportation fees *"}</InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-fee1"
                          endAdornment={
                            <span style={{color: darkMode ? "#fff": "black"}}>
                              USD
                            </span>  
                          }
                          type="text" 
                          required 
                          label={lang === "fr" ? "Frais de transport *" : "Transportation fees *"}
                          value={fraisTransport} 
                          onClick={(e) => e.target.setSelectionRange((e.target.value).length, (e.target.value).length)}
                          onKeyDown={(e) => e.target.setSelectionRange((e.target.value).length, (e.target.value).length)}
                          onChange={traiterFraisTransport}  
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} xl={4}>
                      <FormControl sx={{width: "97%", height: 65, mt: 1, mb: 4.2}} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-fee2">{lang === "fr" ? "Frais d'analyse *" : "Analysis fees *"}</InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-fee2"
                          endAdornment={
                            <span style={{color: darkMode ? "#fff": "black"}}>
                              USD
                            </span>  
                          }
                          type="text" 
                          required 
                          label={lang === "fr" ? "Frais d'analyse *" : "Analysis fees *"}
                          value={fraisAnalyse} 
                          onClick={(e) => e.target.setSelectionRange((e.target.value).length, (e.target.value).length)}
                          onKeyDown={(e) => e.target.setSelectionRange((e.target.value).length, (e.target.value).length)}
                          onChange={traiterFraisAnalyse}  
                        />
                        <FormHelperText id="component-helper-text2" sx={{color: darkMode ? "#fff": "black"}}>
                          {lang === "fr" && "Se rapportant au contrôle de qualité des produits marchands"}
                          {lang === "eng" && "Relating to quality control of commercial products"}
                        </FormHelperText>
                      </FormControl>
                      <FormControl sx={{width: "97%", mb: 6}} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-fee3">{lang === "fr" ? "Frais d'assurance *" : "Insurance fees *"}</InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-fee3"
                          endAdornment={
                            <span style={{color: darkMode ? "#fff": "black"}}>
                              USD
                            </span>  
                          }
                          type="text" 
                          required 
                          label={lang === "fr" ? "Frais d'assurance *" : "Insurance fees *"}
                          value={fraisAssurance} 
                          onClick={(e) => e.target.setSelectionRange((e.target.value).length, (e.target.value).length)}
                          onKeyDown={(e) => e.target.setSelectionRange((e.target.value).length, (e.target.value).length)}
                          onChange={traiterFraisAssurance}  
                        />
                      </FormControl>
                      <FormControl sx={{width: "97%", mb: 6}} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-fee4">{lang === "fr" ? "Frais de commercialisation *" : "Marketing fees *"}</InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-fee4"
                          endAdornment={
                            <span style={{color: darkMode ? "#fff": "black"}}>
                              USD
                            </span>  
                          }
                          type="text" 
                          required 
                          label={lang === "fr" ? "Frais de commercialisation *" : "Marketing fees *"}
                          value={fraisCommercialisation} 
                          onClick={(e) => e.target.setSelectionRange((e.target.value).length, (e.target.value).length)}
                          onKeyDown={(e) => e.target.setSelectionRange((e.target.value).length, (e.target.value).length)}
                          onChange={traiterFraisCommercialisation}  
                        />
                      </FormControl>
                      <MDBox mb={1} sx={{height: 65, lineHeight: 0.5}}>
                          <MDInput 
                            type="text" 
                            label={lang === "fr" ? "Vente à une Entité de transformation" : "Sale to a Transformation Entity"} 
                            value={venteEntitETransformation} 
                            onChange={traiterVenteEntitETrans} 
                            sx={{width: "97%"}} 
                            error={venteEntitETransformation === "" ? false : patternVenteEntitETrans.test(venteEntitETransformation) ? false : true}
                            success={venteEntitETransformation === "" ? false : patternVenteEntitETrans.test(venteEntitETransformation) ? true : false} 
                          />
                          {venteEntitETransformation !== "" && !(patternVenteEntitETrans.test(venteEntitETransformation)) &&  
                            <MDTypography variant="button" color="error">
                              <br />
                              {lang === "fr" && "3 caractères min et 50 caractères max"}
                              {lang === "eng" && "3 characters min and 50 characters max"}
                            </MDTypography>
                          }
                        </MDBox>
                    </Grid>
                  </Grid>  
                  <MDBox mb={1.5} sx={{textAlign: "center", height: 60}}>
                    <MDBox mb={1}>
                      <MDButton type="button" variant="gradient" color="info" onClick={traiterAvant0}>
                        {lang === "fr" && "Précédant"}
                        {lang === "eng" && "Previous"}
                      </MDButton>
                      &nbsp;&nbsp;
                      <MDButton disabled={disabled} id="submit2" type="submit" variant="gradient" color="info">
                        {lang === "fr" && "Suivant"}
                        {lang === "eng" && "Next"}
                      </MDButton>
                    </MDBox>
                    <MDBox>
                      {erreurValidationProduit2 === "Champ(s) vide(s) dans la requête" && 
                        <MDTypography variant="button" color="error" mt={1}>
                          {lang === "fr" && "Champ(s) vide(s) dans la requête"}
                          {lang === "eng" && "Empty field(s) in the query"}
                        </MDTypography>
                      }
                    </MDBox>
                  </MDBox>
                  <MDBox sx={{textAlign: "center"}}>
                    <MDButton variant="gradient" color="error" onClick={traiterFermer1}>
                      {lang === "fr" && "Annuler"}
                      {lang === "eng" && "Cancel"}
                    </MDButton>
                  </MDBox> 
                </StepContent>
              </Step>
              <Step key="camions">
                <StepLabel icon={<Icon>looks_3</Icon>} sx={{color: darkMode ? "#fff" : "rgba(0,0,0,.85)"}}>
                  <MDTypography display="block" variant="button" color="text" fontWeight="medium">
                    {lang === "fr" && "LISTE DES CAMIONS"}
                    {lang === "eng" && "LIST OF TRUCKS"}
                  </MDTypography>
                </StepLabel>
                <StepContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6} xl={4}>
                      <MDBox mb={3.5} sx={{height: 65, lineHeight: 0.5}}>
                        <MDInput 
                          type="text" 
                          label={lang === "fr" ? "Plaque"  : "Plate No."}
                          value={plaque} 
                          onChange={traiterPlaque} 
                          sx={{width: "97%"}}  
                          error={plaque === "" ? false : patternPlaque.test(plaque) ? false : true}
                          success={plaque === "" ? false : patternPlaque.test(plaque) ? true : false} 
                        />
                        {plaque !== "" && !(patternPlaque.test(plaque)) &&  
                          <MDTypography variant="button" color="error">
                            <br />
                            {lang === "fr" && "6 caractères min et 20 caractères max"}
                            {lang === "eng" && "6 characters min and 20 characters max"}
                          </MDTypography>
                        }
                        {erreurValidationProduit3 === 1 && 
                          <MDTypography variant="button" color="error">
                            <br />
                            {lang === "fr" && "Veuillez Entrer le numéro de plaque"}
                            {lang === "eng" && "Please enter plate number"}
                          </MDTypography>
                        }
                        {erreurValidationProduit3 === 2 && 
                          <MDTypography variant="button" color="error">
                            <br />
                            {lang === "fr" && "Déjà présente dans la liste"}
                            {lang === "eng" && "Already present in the list"}
                          </MDTypography>
                        }
                      </MDBox>
                      <MDBox mb={3.5} sx={{flexGrow: 1, height: 85, lineHeight: 0.5}}>
                        <Grid container spacing={2} sx={{width: "102%"}}>
                          <Grid item xs={6}>
                            <FormControl variant="outlined">
                              <InputLabel htmlFor="outlined-adornment-weight4">{lang === "fr" ? "Quantité net *" : "Net quantity *"}</InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-weight4"
                                endAdornment={
                                  <span style={{color: darkMode ? "#fff": "black"}}>
                                    Kg(s)
                                  </span>  
                                }
                                type="text" 
                                label={lang === "fr" ? "Quantité net *" : "Net quantity *"}
                                value={qtENetC} 
                                onClick={(e) => e.target.setSelectionRange((e.target.value).length, (e.target.value).length)}
                                onKeyDown={(e) => e.target.setSelectionRange((e.target.value).length, (e.target.value).length)}
                                onChange={traiterQtENetC}  
                              />
                              {erreurValidationProduit3 === 3 && 
                                <MDTypography variant="button" color="error">
                                  {lang === "fr" && "La valeur doit être > 0"}
                                  {lang === "eng" && "The value must be > 0"}
                                </MDTypography>
                              }
                               {erreurValidationProduit3 === 30 && 
                                <MDTypography variant="button" color="error">
                                  {lang === "fr" && "La valeur doit être < 45,00 Ts (45 000,00 Kgs)"}
                                  {lang === "eng" && "The value must be < 45.00 Ts (45 000.00 Kgs)"}
                                </MDTypography>
                              }
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl variant="outlined">
                              <InputLabel htmlFor="outlined-adornment-weight5">{lang === "fr" ? "Quantité sec *" : "Dry quantity *"}</InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-weight5"
                                endAdornment={
                                  <span style={{color: darkMode ? "#fff": "black"}}>
                                    Kg(s)
                                  </span>  
                                }
                                type="text" 
                                label={lang === "fr" ? "Quantité sec *" : "Dry quantity *"}
                                value={qtESecC} 
                                onClick={(e) => e.target.setSelectionRange((e.target.value).length, (e.target.value).length)}
                                onKeyDown={(e) => e.target.setSelectionRange((e.target.value).length, (e.target.value).length)}
                                onChange={traiterQtESecC}  
                              />
                              {erreurValidationProduit3 === 4 && 
                                <MDTypography variant="button" color="error">
                                  {lang === "fr" && "La valeur doit être > 0"}
                                  {lang === "eng" && "The value must be > 0"}
                                </MDTypography>
                              }
                            </FormControl>
                          </Grid>
                        </Grid>
                      </MDBox>
                      <MDBox mb={3.5} sx={{flexGrow: 1, height: 85, lineHeight: 0.5}}>
                        <Grid container spacing={2} sx={{width: "102%"}}>
                          <Grid item xs={6}>
                            <FormControl variant="outlined">
                              <InputLabel htmlFor="outlined-adornment-perc3">H2O *</InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-perc3"
                                endAdornment={
                                  <span style={{color: darkMode ? "#fff": "black"}}>
                                    %
                                  </span>  
                                }
                                type="text" 
                                readOnly 
                                label="H2O *"
                                value={qualitEHumidePourcC} 
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl variant="outlined">
                              <InputLabel htmlFor="outlined-adornment-weight6">{lang === "fr" ? "Quantité humide *" : "Wet quantity *"}</InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-weight6"
                                endAdornment={
                                  <span style={{color: darkMode ? "#fff": "black"}}>
                                    Kg(s)
                                  </span>  
                                }
                                type="text" 
                                label={lang === "fr" ? "Quantité humide *" : "Wet quantity *"}
                                value={qtEHumideC} 
                                onClick={(e) => e.target.setSelectionRange((e.target.value).length, (e.target.value).length)}
                                onKeyDown={(e) => e.target.setSelectionRange((e.target.value).length, (e.target.value).length)}
                                onChange={traiterQtEHumideC}  
                              />
                              {erreurValidationProduit3 === 5 && 
                                <MDTypography variant="button" color="error">
                                  {lang === "fr" && "La valeur doit être > 0"}
                                  {lang === "eng" && "The value must be > 0"}
                                </MDTypography>
                              }
                            </FormControl>
                          </Grid>
                        </Grid>
                      </MDBox>
                      <MDBox mb={0.5} sx={{flexGrow: 1, height: 85, lineHeight: 0.5}}>
                        <Grid container spacing={2} sx={{width: "102%"}}>
                          <Grid item xs={6}>
                            <FormControl variant="outlined">
                              <InputLabel htmlFor="outlined-adornment-price3">{lang === "fr" ? "Prix/Unité *" : "Unit price *"}</InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-price3"
                                endAdornment={
                                  <span style={{color: darkMode ? "#fff": "black"}}>
                                    USD
                                  </span>  
                                }
                                type="text" 
                                label={lang === "fr" ? "Prix/Unité *" : "Unit price *"}
                                value={prixUnitEUSDC} 
                                onClick={(e) => e.target.setSelectionRange((e.target.value).length, (e.target.value).length)}
                                onKeyDown={(e) => e.target.setSelectionRange((e.target.value).length, (e.target.value).length)}
                                onChange={traiterPrixUnitEUSDC}  
                              />
                              {erreurValidationProduit3 === 6 && 
                                <MDTypography variant="button" color="error">
                                  {lang === "fr" && "La valeur doit être > 0"}
                                  {lang === "eng" && "The value must be > 0"}
                                </MDTypography>
                              }
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl variant="outlined">
                              <InputLabel htmlFor="outlined-adornment-price4">{lang === "fr" ? "Prix Total *" : "Total price *"}</InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-price4"
                                endAdornment={
                                  <span style={{color: darkMode ? "#fff": "black"}}>
                                    USD
                                  </span>  
                                }
                                type="text" 
                                label={lang === "fr" ? "Prix Total *" : "Total price *"}
                                value={prixTotalUSDC} 
                                onClick={(e) => e.target.setSelectionRange((e.target.value).length, (e.target.value).length)}
                                onKeyDown={(e) => e.target.setSelectionRange((e.target.value).length, (e.target.value).length)}
                                onChange={traiterPrixTotalUSDC}  
                              />
                              {erreurValidationProduit3 === 7 && 
                                <MDTypography variant="button" color="error">
                                  {lang === "fr" && "La valeur doit être > 0"}
                                  {lang === "eng" && "The value must be > 0"}
                                </MDTypography>
                              }
                            </FormControl>
                          </Grid>
                        </Grid>
                      </MDBox>
                      <MDBox sx={{textAlign: "center", height: 60}}>
                        <MDButton type="button" variant="gradient" color="info" onClick={traiterListeCamions}>
                          {lang === "fr" && "Ajouter"}
                          {lang === "eng" && "Add"}
                        </MDButton>
                        <MDBox>
                          {erreurValidationProduit3 === 8 && 
                            <MDTypography variant="button" color="error">
                              {lang === "fr" && "La quantité net sera > à son cumul par camion"}
                              {lang === "eng" && "The net quantity will be > its accumulation per truck"}
                            </MDTypography>
                          }
                        </MDBox>
                        <MDBox>
                          {erreurValidationProduit3 === 9 && 
                            <MDTypography variant="button" color="error">
                              {lang === "fr" && "Le prix total sera > à son cumul par camion"}
                              {lang === "eng" && "The total price will be > its accumulation per truck"}
                            </MDTypography>
                          }
                        </MDBox>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} xl={8}>
                      <MDBox sx={{ textAlign: "center" }}>
                        <MDTypography variant="button" color="text" fontWeight="regular" key="cumulative-quantity">
                          {lang === "fr" && `Cumul Quantité Net : ${memoCumulQtENet.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} Kgs sur ${Number.parseFloat(qtENetNature === "1" ? qtENet : qtENet * 1000).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} Kgs`}
                          {lang === "eng" && `Cumulative Net Quantity: ${memoCumulQtENet.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} Kgs on ${Number.parseFloat(qtENetNature === "1" ? qtENet : qtENet * 1000).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} Kgs`}
                        </MDTypography>
                      </MDBox>
                      <MDBox sx={{ textAlign: "center" }} mb={1.5}>
                        <MDTypography variant="button" color="text" fontWeight="regular" key="cumulative-price">
                          {lang === "fr" && `Cumul Prix Total : ${memoCumulPrixTotalUSD.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD sur ${Number.parseFloat(prixTotalUSD).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD`}
                          {lang === "eng" && `Cumulative Total Price: ${memoCumulPrixTotalUSD.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD on ${Number.parseFloat(prixTotalUSD).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD`}
                         
                        </MDTypography>
                      </MDBox>
                      <div style={{height: "280px", width: "92%", float: "right", marginBottom: "20px"}}>
                        {globalStyles()}
                        <StyledDataGrid
                          disableRowSelectionOnClick
                          density="compact"
                          rows={listeCamions.map((x, index) => ({ id: index + 1, ...x }))}
                          columns={colonnesCamions} 
                          slots= {{
                            noRowsOverlay: CustomNoRowsOverlay, 
                            noResultsOverlay: CustomNoRowsOverlay,
                            toolbar: CustomToolbar1, 
                            pagination: CustomPagination
                          }}
                          initialState={{
                            pagination: {
                              paginationModel: { pageSize: 9},
                            },
                          }}
                          paginationModel={paginationModel}
                          onPaginationModelChange={setPaginationModel}
                          localeText={lang === "fr" ? frFR : enUS}
                        />
                      </div>
                      <MDBox sx={{textAlign: "center"}}>
                        <MDBox mb={1}>
                          <MDButton type="button" variant="gradient" color="info" onClick={traiterAvant1}>
                            {lang === "fr" && "Précédant"}
                            {lang === "eng" && "Previous"}
                          </MDButton>
                          &nbsp;&nbsp;
                          <MDButton disabled={disabled} id="submit3" type="submit" variant="gradient" color="info">
                            {lang === "fr" && "Enregistrer"}
                            {lang === "eng" && "Save"}
                          </MDButton>
                        </MDBox>
                        <MDBox sx={{height: 40}}>
                          {erreurValidationProduit3 === 10 && 
                            <MDTypography variant="button" color="error">
                              {lang === "fr" && "Veuillez ajouter au moins un camion"}
                              {lang === "eng" && "Please add at least one truck"}
                            </MDTypography>
                          }
                          {erreurValidationProduit3 === 11 && 
                            <MDTypography variant="button" color="error">
                              {lang === "fr" && "La quantité net < cumul par camion"}
                              {lang === "eng" && "The net quantity < accumulation per truck"}
                            </MDTypography>
                          }
                          {erreurValidationProduit3 === 12 && 
                            <MDTypography variant="button" color="error">
                              {lang === "fr" && "Le prix total < cumul par camion"}
                              {lang === "eng" && "The total price < accumulation per truck"}
                            </MDTypography>
                          }
                          {erreurAjoutDEclaration === "Champ(s) vide(s) dans la requête" && 
                            <MDTypography variant="button" color="error">
                              {lang === "fr" && "Champ(s) vide(s) dans la requête"}
                              {lang === "eng" && "Empty field(s) in the query"}
                            </MDTypography>
                          }
                          {/*erreurModifDEclaration === "Aucune modification" && 
                            <MDTypography variant="button" color="error">
                              {lang === "fr" && "Aucune modification"}
                              {lang === "eng" && "No modification"}
                            </MDTypography>
                          */}
                          {erreurModifDEclaration === "Champ(s) vide(s) dans la requête" && 
                            <MDTypography variant="button" color="error">
                              {lang === "fr" && "Champ(s) vide(s) dans la requête"}
                              {lang === "eng" && "Empty field(s) in the query"}
                            </MDTypography>
                          }
                        </MDBox>
                        <MDBox>
                          <MDButton variant="gradient" color="error" onClick={traiterFermer1}>
                            {lang === "fr" && "Annuler"}
                            {lang === "eng" && "Cancel"}
                          </MDButton>
                        </MDBox> 
                      </MDBox>
                    </Grid>
                  </Grid>
                </StepContent>
              </Step>
              <Step key="fin">
  
              </Step>
            </Stepper>
          </MDBox>
        </MDBox>
      </Modal>
      <Modal isOpen={ouvrir11} onAfterOpen={traiterOuvrir3(data.idDEclaration)} onRequestClose={traiterFermer3} ariaHideApp={false} style={styleModal430}>
        <MDBox 
          sx={{padding: 3, height: 575}}
          variant="gradient" 
          bgColor={darkMode ? "dark": "#fff"}
          borderRadius="lg"
          coloredShadow={darkMode ? "dark": "light"}
        >
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <MDBox height="100%" lineHeight={1}>
                <MDTypography variant="h6" fontWeight="medium">
                  VALIDATION
                </MDTypography>
                <MDTypography variant="button" color="text" fontWeight="regular">
                  {lang === "fr" && "Visa, ampliateurs et validation de la déclaration."}
                  {lang === "eng" && "Visa and validation of the declaration."}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
          <br />
          <MDBox component="form" role="form" onSubmit={traiterValidDEclaration} autoComplete="off">
            <MDBox mt={2} mb={3.5} sx={{height: 65, width: 342}}>
              {chargementListeDP ? 
                <MDTypography variant="button" color="dark" mt={1}>
                  {lang === "fr" && "Chargement..."}
                  {lang === "eng" && "Loading..."}
                </MDTypography> :
                <FormControl sx={{height: 45, width: "100%"}}>
                  <InputLabel id="select-insert6-label" htmlFor="select-insert6">
                    {lang === "fr" && "Nom (Visa) *"}
                    {lang === "eng" && "Name (Visa) *"}
                  </InputLabel>
                  <Select 
                    sx={{height: 46}} 
                    required
                    labelId="select-insert6-label"
                    label={lang === "fr" ? "Nom (Visa) *" : "Name (Visa) *"}
                    inputProps={{id: "select-insert6"}}
                    value={nomVisaId}
                    onChange={traiterNomVisaId}
                  >
                    {
                      listeDP.map((x) => (
                        <MenuItem key={x.Id} value={x.Id}>   
                          <span style={styleSelect}>
                            {x.Nom + " " + x.PostNom + " " + x.PrEnom}  
                          </span>
                        </MenuItem>
                      ))
                    }      
                  </Select>
                </FormControl>
              }
            </MDBox>
            <MDBox mb={5.5}>
              <MDInput type="text" readOnly label={lang === "fr" ? "Fonction" : "Role"} value={fonctionVisa} fullWidth />   
            </MDBox>
            <MDBox mb={3.5} sx={{height: 65, lineHeight: 0.5}}>
              <MDInput 
                type="text" 
                label={lang === "fr" ? "Gouverneur" : "Governor"}
                value={gouverneur} 
                onChange={traiterGouverneur} 
                sx={{width: 342}} 
                error={gouverneur === "" ? false : patternNomComplet.test(gouverneur) ? false : true}
                success={gouverneur === "" ? false : patternNomComplet.test(gouverneur) ? true : false} 
              />
              {gouverneur !== "" && !(patternNomComplet.test(gouverneur)) &&  
                <MDTypography variant="button" color="error">
                  <br />
                  {lang === "fr" && "3 caractères min et 100 caractères max"}
                  {lang === "eng" && "3 characters min and 100 characters max"}
                </MDTypography>
              }
            </MDBox>
            <MDBox mb={3.5} sx={{height: 65, lineHeight: 0.5}}>
              <MDInput 
                type="text" 
                label={lang === "fr" ? "Maire/Administrateur" : "Mayor/Administrator"}
                value={maireAdm} 
                onChange={traiterMaireAdm} 
                sx={{width: 342}} 
                error={maireAdm === "" ? false : patternNomComplet.test(maireAdm) ? false : true}
                success={maireAdm ==- "" ? false : patternNomComplet.test(maireAdm) ? true : false} 
              />
              {maireAdm !== "" && !(patternNomComplet.test(maireAdm)) &&  
                <MDTypography variant="button" color="error">
                  <br />
                  {lang === "fr" && "3 caractères min et 100 caractères max"}
                  {lang === "eng" && "3 characters min and 100 characters max"}
                </MDTypography>
              }
            </MDBox>
            <MDBox mt={4} mb={1} sx={{textAlign: "center"}}>
              <MDBox mb={1}>
                <MDButton type="button" variant="gradient" color="error" onClick={traiterFermer3}>
                  {lang === "fr" && "Annuler"}
                  {lang === "eng" && "Cancel"}
                </MDButton>
                &nbsp;&nbsp;
                <MDButton disabled={disabled} type="submit" variant="gradient" color="info">
                  {lang === "fr" && "Valider"}
                  {lang === "eng" && "Validate"}
                </MDButton>
              </MDBox>
              <MDBox>
                {erreurValidDEclaration === "Déclaration déjà validée" && 
                  <MDTypography variant="button" color="error" mt={1}>
                    {lang === "fr" && "Déclaration déjà validée"}
                    {lang === "eng" && "Declaration already validated"}
                  </MDTypography>
                }
                {erreurValidDEclaration === "Champ(s) vide(s) dans la requête" && 
                  <MDTypography variant="button" color="error" mt={1}>
                    {lang === "fr" && "Champ(s) vide(s) dans la requête"}
                    {lang === "eng" && "Empty field(s) in the query"}
                  </MDTypography>
                }
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
      </Modal>
      <Modal isOpen={ouvrir12} onAfterOpen={traiterOuvrir4(data.listeCamionsParD, data.no)} onRequestClose={traiterFermer4} ariaHideApp={false} style={styleModal820}>
        <MDBox 
          sx={{padding: 3}}
          variant="gradient" 
          bgColor={darkMode ? "dark": "#fff"}
          borderRadius="lg"
          coloredShadow={darkMode ? "dark": "light"}
        >
          <MDTypography variant="h6" fontWeight="medium">
            {lang === "fr" && "Liste des camions"}
            {lang === "eng" && "List of trucks"}
            &nbsp;({data.no})
          </MDTypography>
          <br />
          <div style={{ height: "550px", width: "100%" }}>
            {globalStyles()}
            <StyledDataGrid
              disableRowSelectionOnClick
              density="compact"
              rows={listeCamionsParD.map((x, index) => ({ id: index + 1, ...x }))}
              columns={colonnesCamionsParD} 
              slots= {{
                loadingOverlay: LinearProgress,
                noRowsOverlay: CustomNoRowsOverlay, 
                noResultsOverlay: CustomNoRowsOverlay,
                toolbar: CustomToolbar2, 
                pagination: CustomPagination
              }}
              loading={chargementLCParD}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 9},
                }
              }}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              localeText={lang === "fr" ? frFR : enUS}
            />
          </div>
          <br />
          <MDBox mb={1} sx={{textAlign: "center"}}>
            <MDButton variant="gradient" color="error" onClick={traiterFermer4}>
              {lang === "fr" && "Fermer"}
              {lang === "eng" && "Close"}
            </MDButton>
          </MDBox> 
        </MDBox>
      </Modal>
    </>
  );
};

export default NotificationsEtDialoguesDEclaration;
