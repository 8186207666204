/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DOMPurify from 'dompurify';
import Modal from 'react-modal';
import { ajoutUtilisateurAsync } from "ActionsAsync/ActionsUtilisateurAsync";
import { listeLieuxAsync } from "ActionsAsync/ActionsUtilitaireAsync";
import { fermerFDialogueInfoP, fermerFDialogueValidationP, fermerNotifAjoutU } from "Simplificateurs/SimplificateursUtilisateur";

// Composants du @mui material
import Snackbar from '@mui/material/Snackbar';
import Grid from "@mui/material/Grid";

// Composants du Material Dashboard 2 React
import MDBox from "Composants/MDBox";
import MDTypography from "Composants/MDTypography";
import MDButton from "Composants/MDButton";

// Contexte Material Dashboard 2 PRO React
import { useMaterialUIController } from "Contexte";

import { Notification, formatDate, styleModal430, styleModalFull } from "Vues/Utilitaires";

function NotificationsEtDialoguesProfil() {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, lang, socket } = controller;

  const envoi = useDispatch();

  const utilisateurObj = useSelector((state) => state.utilisateur);
  const {
    disabled,
    vertical,
    horizontal,
    data,
    ouvrir6,
    erreurAjoutUtilisateur,
    notifAjoutUtilisateur,
    ouvrir06
  } = utilisateurObj;

  useEffect(() => {
    const minuteurObtenirListeLieux = setTimeout(() => {
      envoi(listeLieuxAsync(dispatch, socket));
    }, 1000);
    return () => {
      clearTimeout(minuteurObtenirListeLieux);
    };
  }, []);


  const traiterOuvrir1 = (
    typeId,
    dEnomination,
    idNat,
    nom,
    postNom,
    prEnom,
    province,
    ville,
    commune,
    quartier,
    avenue,
    no,
    noAgr,
    dateD,
    dateF,
    email,
    phone
  ) => () => {
    assignerTypeId(typeId);
    assignerDEnomination(dEnomination);
    assignerIdNat(idNat);
    assignerNom(nom);
    assignerPostNom(postNom);
    assignerPrEnom(prEnom);
    assignerProvince(province);
    assignerVille(ville);
    assignerCommune(commune);
    assignerQuartier(quartier);
    assignerAvenue(avenue);
    assignerNo(no);
    assignerNoAgr(noAgr);
    assignerDateD(dateD);
    assignerDateF(dateF);
    assignerEmail(email);
    assignerPhone(phone);
  }
  const traiterFermer1 = () => {
    envoi(fermerFDialogueInfoP());
    assignerTypeId("");
    assignerDEnomination("");
    assignerIdNat("");
    assignerNom("");
    assignerPostNom("");
    assignerPrEnom("");
    assignerProvince("");
    assignerVille("");
    assignerCommune("");
    assignerQuartier("");
    assignerAvenue("");
    assignerNo("");
    assignerNoAgr("");
    assignerDateD("");
    assignerDateF("");
    assignerEmail("");
    assignerPhone("");
  };

  const traiterOuvrir2 = (idProfil, email) => () => {
    assignerIdProfil(idProfil);
    assignerEmail(email);
  };
  const traiterFermer2 = () => {
    envoi(fermerFDialogueValidationP());
    assignerIdProfil("");
    assignerEmail("");
  };
  const traiterFermerNotification = () => {
    envoi(fermerNotifAjoutU());
    assignerIdProfil("");
    assignerEmail("");
  };

  const [typeId, assignerTypeId] = useState("");
  const [dEnomination, assignerDEnomination] = useState("");
  const [idNat, assignerIdNat] = useState("");
  const [nom, assignerNom] = useState("");
  const [postNom, assignerPostNom] = useState("");
  const [prEnom, assignerPrEnom] = useState("");
  const [province, assignerProvince] = useState("");
  const [ville, assignerVille] = useState("");
  const [commune, assignerCommune] = useState("");
  const [quartier, assignerQuartier] = useState("");
  const [avenue, assignerAvenue] = useState("");
  const [no, assignerNo] = useState("");
  const [noAgr, assignerNoAgr] = useState("");
  const [dateD, assignerDateD] = useState("");
  const [dateF, assignerDateF] = useState("");
  const [email, assignerEmail] = useState("");
  const [phone, assignerPhone] = useState("");

  const [idProfil, assignerIdProfil] = useState("");

  const traiterAjoutUtilisateur = async (e) => {
    e.preventDefault();
    const cleanIdProfil = DOMPurify.sanitize(idProfil, {
      ALLOWED_CHARS: /[^a-zA-Z0-9-]$/i
    });
    await envoi(ajoutUtilisateurAsync(cleanIdProfil, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, dispatch, socket));
  }

  return (
    <>  
      <Modal isOpen={ouvrir06} onAfterOpen={traiterOuvrir1(
        data.typeId,
        data.dEnomination,
        data.idNat,
        data.nom,
        data.postNom,
        data.prEnom,
        data.province,
        data.ville,
        data.commune,
        data.quartier,
        data.avenue,
        data.no,
        data.noAgr,
        data.dateD,
        data.dateF,
        data.email,
        data.phone
      )} onRequestClose={traiterFermer1} ariaHideApp={false} style={styleModalFull}>
        <MDBox 
          sx={{padding: 3}}
          variant="gradient" 
          bgColor={darkMode ? "dark": "white"}
          borderRadius="lg"
          coloredShadow={darkMode ? "dark": "light"}
        >
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <MDBox lineHeight={1}>
                <MDTypography variant="h6" fontWeight="medium">
                  {lang === "fr" && "INFORMATION DE PROFIL"}
                  {lang === "eng" && "PROFILE INFORMATION"}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} xl={4}>
              <MDBox mt={2} mb={2}>
                <MDTypography variant="button" color="text" fontWeight="regular">
                  <u>
                    {lang === "fr" && "INFORMATIONS DE BASE"}
                    {lang === "eng" && "BASIC INFORMATION"}
                  </u> 
                </MDTypography>
                <MDBox mt={6} mb={3.5} sx={{height: 65}}>
                  <MDTypography display="block" variant="button" color="text" fontWeight="medium">
                    {lang === "fr" && "EXPORTATEUR : "}
                    {lang === "eng" && "EXPORTER: "}
                    {typeId}
                  </MDTypography>
                </MDBox>
                <MDBox mb={3.5} sx={{height: 65, lineHeight: 0.5}}>
                  <MDTypography display="block" variant="button" color="text" fontWeight="medium">
                    {lang === "fr" && "DENOMINATION OU RAISON SOCIAL : "}
                    {lang === "eng" && "COMPANY NAME: "}
                    {dEnomination}
                  </MDTypography>
                </MDBox>
                {typeId === "TITULAIRE DES DROITS MINIERS" && 
                  <>
                    <MDBox mb={3.5} sx={{height: 65, lineHeight: 0.5}}>
                      <MDTypography display="block" variant="button" color="text" fontWeight="medium">
                        {lang === "fr" && "NUMERO D'IDENTIFICATION NATIONALE : "}
                        {lang === "eng" && "NATIONAL IDENTIFICATION NUMBER: "}
                        {idNat}
                      </MDTypography>
                    </MDBox>
                    <MDBox mb={3.5} sx={{height: 65, lineHeight: 0.5}}>
                      <MDTypography display="block" variant="button" color="text" fontWeight="medium">
                        {lang === "fr" && "NOM : "}
                        {lang === "eng" && "LAST NAME: "}
                        {nom}
                      </MDTypography>
                    </MDBox>
                    <MDBox mb={3.5} sx={{height: 65, lineHeight: 0.5}}>
                      <MDTypography display="block" variant="button" color="text" fontWeight="medium">
                        {lang === "fr" && "POST-NOM : "}
                        {lang === "eng" && "MIDDLE NAME: "}
                        {postNom}
                      </MDTypography>
                    </MDBox>
                    <MDBox mb={3.5} sx={{height: 65, lineHeight: 0.5}}>
                      <MDTypography display="block" variant="button" color="text" fontWeight="medium">
                        {lang === "fr" && "PRENOM : "}
                        {lang === "eng" && "FIRST NAME: "}
                        {prEnom}
                      </MDTypography>
                    </MDBox>
                  </> 
                }
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <MDBox mt={2}  mb={2}>
                <MDTypography variant="button" color="text" fontWeight="regular">
                  <u>
                    {lang === "fr" && "SIEGE SOCIAL - SIEGE D'EXPLOITATION"}
                    {lang === "eng" && "HEAD OFFICE - OPERATING HEADQUARTERS"}
                  </u>
                </MDTypography>
                <MDBox mt={6} mb={3.5} sx={{height: 65}}>
                  <MDTypography display="block" variant="button" color="text" fontWeight="medium">
                    {lang === "fr" && "PROVINCE : "}
                    {lang === "eng" && "PROVINCE: "}
                    {province}
                  </MDTypography>
                </MDBox>
                <MDBox mb={3.5} sx={{height: 65}}>
                  <MDTypography display="block" variant="button" color="text" fontWeight="medium">
                    {lang === "fr" && "VILLE/TERRITOIRE : "}
                    {lang === "eng" && "CITY/TERRITORY: "}
                    {ville}
                  </MDTypography>
                </MDBox>
                <MDBox mb={3.5} sx={{height: 65}}>
                  <MDTypography display="block" variant="button" color="text" fontWeight="medium">
                    {lang === "fr" && "COMMUNE/SECTEUR : "}
                    {lang === "eng" && "MUNICIPALITY/SECTOR: "}
                    {commune}
                  </MDTypography>
                </MDBox>
                <MDBox mb={3.5} sx={{height: 65, lineHeight: 0.5}}>
                  <MDTypography display="block" variant="button" color="text" fontWeight="medium">
                    {lang === "fr" && "QUARTIER : "}
                    {lang === "eng" && "AREA: "}
                    {quartier}
                  </MDTypography>
                </MDBox>
                <MDBox mb={3.5} sx={{height: 65, lineHeight: 0.5}}>
                  <MDTypography display="block" variant="button" color="text" fontWeight="medium">
                    {lang === "fr" && "AVENUE : "}
                    {lang === "eng" && "AVENUE: "}
                    {avenue}
                  </MDTypography>
                </MDBox>
                <MDBox mb={3.5} sx={{height: 65, lineHeight: 0.5}}>
                  <MDTypography display="block" variant="button" color="text" fontWeight="medium">
                    {lang === "fr" && "NUMERO : "}
                    {lang === "eng" && "NUMBER: "}
                    {no}
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <MDBox mt={2} mb={2}>
                <MDTypography variant="button" color="text" fontWeight="regular">
                  <u>
                    {lang === "fr" && "INFORMATIONS ADDITIONELLES"}
                    {lang === "eng" && "ADDITIONAL INFORMATION"}
                  </u>
                </MDTypography>
                <MDBox mt={6} mb={9} sx={{height: 65, lineHeight: 0.5}}>
                  <MDTypography display="block" variant="button" color="text" fontWeight="medium">
                    {lang === "fr" && "NUMERO DE L'ARRETE D'AGREMENT : "}
                    {lang === "eng" && "APPROVAL ORDER NUMBER: "}
                    {noAgr}
                  </MDTypography>
                </MDBox>
                <MDBox mb={7} sx={{flexGrow: 1, height: 85, lineHeight: 0.5}}>
                  <MDTypography variant="button" color="text">
                    {lang === "eng" && "* Approval validity period"}
                    {lang === "fr" && "* Période de validité de l'agrément"}
                  </MDTypography>
                  <Grid mt={1.5} container spacing={1} sx={{width: "99%"}}>
                    <Grid item xs={6}>
                      <MDTypography display="block" variant="button" color="text" fontWeight="medium">
                        {lang === "fr" && "DEBUT : "}
                        {lang === "eng" && "START: "}
                        {formatDate(new Date(dateD))}
                      </MDTypography>
                    </Grid>
                    <Grid item xs={6}>
                      <MDTypography display="block" variant="button" color="text" fontWeight="medium">
                        {lang === "fr" && "EXPIRATION : "}
                        {lang === "eng" && "EXPIRATION: "}
                        {formatDate(new Date(dateF))}
                      </MDTypography>
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox mt={2} mb={3.5} sx={{height: 65, lineHeight: 0.5}}>
                  <MDTypography display="block" variant="button" color="text" fontWeight="medium">
                    {lang === "fr" && "EMAIL : "}
                    {lang === "eng" && "EMAIL: "}
                    {email}
                  </MDTypography>
                </MDBox>
                <MDBox mb={3.5} sx={{height: 65, lineHeight: 1}}>
                  <MDTypography display="block" variant="button" color="text" fontWeight="medium">
                    {lang === "fr" && "TELEPHONE : "}
                    {lang === "eng" && "PHONE NUMBER: "}
                    {phone}
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
          <MDBox sx={{textAlign: "center", height: 40}} mb={1}>
            <MDButton type="button" variant="gradient" color="error" onClick={traiterFermer1}>
              {lang === "fr" && "Fermer"}
              {lang === "eng" && "Close"}
            </MDButton>
          </MDBox>
        </MDBox>
      </Modal>
      <Snackbar 
        anchorOrigin={{ vertical, horizontal }} 
        open={notifAjoutUtilisateur} 
        autoHideDuration={6000} 
        onClose={traiterFermerNotification}
        key={vertical + horizontal}
      >
        <Notification onClose={traiterFermerNotification} severity="success" sx={{ width: "100%", bgcolor: "background.paper"}}>
            {lang === "fr" && "Profil validé avec succès"}
            {lang === "eng" && "Profile successfully validated"}
        </Notification>
      </Snackbar>
      <Modal isOpen={ouvrir6} onAfterOpen={traiterOuvrir2(data.idProfil, data.email)} onRequestClose={traiterFermer2} ariaHideApp={false} style={styleModal430}>
        <MDBox 
          sx={{padding: 3, height: 170, textAlign: "center"}}
          variant="gradient" 
          bgColor={darkMode ? "dark": "#fff"}
          borderRadius="lg"
          coloredShadow={darkMode ? "dark": "light"}
        >
          <MDBox component="form" role="form" onSubmit={traiterAjoutUtilisateur} autoComplete="off">
            <MDTypography display="block" variant="button" color="text" fontWeight="medium" mt={1}>
              {lang === "fr" && "Êtes-vous sûre de vouloir valider"}
              {lang === "eng" && "Are you sure you want to validate"}
              <br />"{data.email}" ?
            </MDTypography>
            <MDBox mt={4} mb={1}>
              <MDButton disabled={disabled} type="submit" variant="gradient" color="info">
                {lang === "fr" && "Oui"}
                {lang === "eng" && "Yes"}
              </MDButton>
              &nbsp;&nbsp;
              <MDButton variant="gradient" color="info" onClick={traiterFermer2}>
                {lang === "fr" && "Non"}
                {lang === "eng" && "No"}
              </MDButton>
            </MDBox>
            {erreurAjoutUtilisateur == "Profil déjà validé" && 
              <MDTypography variant="button" color="error" mt={1}>
                {lang === "fr" && "Profil déjà validé"}
                {lang === "eng" && "Profile already validated"}
              </MDTypography>
            }
          </MDBox>
        </MDBox>
      </Modal>
    </>
  );
};

export default NotificationsEtDialoguesProfil;
