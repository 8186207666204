import store from "Store";
import { chargement, failliteValidNoteDEbit, validNoteDEbitFaite } from "Simplificateurs/SimplificateursNoteDEbit";
import { serviceListeNoteDEbit, serviceValidNoteDEbit } from "Services/NoteDEbit";
import { obtentionListeNotesDEbits } from "Simplificateurs/SimplificateursTable";
import { disjonctionAsync } from "ActionsAsync/ActionsAuthAsync";

export const listeNotesDEbitsAsync = (dispatch, socket) => async envoi => {
    if (store.getState().authentification.info.Id) {
        const idProfil = store.getState().authentification.info.Id;
        const resultat = await serviceListeNoteDEbit(idProfil);
        if (resultat.error) {
            return envoi(disjonctionAsync(dispatch, socket));
        }
        envoi(obtentionListeNotesDEbits(resultat.data));
    }
}

export const validNoteDEbitAsync = (idDEclaration, statut, commentaire, dispatch, socket) => async envoi => {
    envoi(chargement());
    const resultat = await serviceValidNoteDEbit(idDEclaration, statut, commentaire);
    if (resultat.error) {
        return envoi(failliteValidNoteDEbit(resultat.response.data));
    }
    envoi(validNoteDEbitFaite(statut));
    envoi(listeNotesDEbitsAsync(dispatch, socket));
}