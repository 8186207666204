import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    disabled: false,
    vertical: "top",
    horizontal: "center",
    ouvrir13: false,
    data: {},
    erreurValidNoteDEbit: null,
    notifValidNoteDEbit: false
}
   
export const partieNoteDEbit = createSlice({
    name: "noteDEbit",
    initialState,
    reducers: {
        chargement: (state) => {
            return {
                ...state,
                disabled: true,
            }
        },
        ouvrirFDialogueValidND: (state, action) => {
            return {
                ...state,
                ouvrir13: true,
                data: action.payload,
                disabled: false
            }
        },
        fermerFDialogueValidND: (state) => {
            return {
                ...state,
                ouvrir13: false,
                erreurValidNoteDEbit: null,
                data: {}
            }
        },
        failliteValidNoteDEbit: (state, action) => {
            const { error } = action.payload;
            return {
                ...state,
                erreurValidDNoteDEbit: error,
                disabled: false
            }
        },
        validNoteDEbitFaite: (state, action) => {
            return {
                ...state,
                erreurValidNoteDEbit: null,
                disabled: false,
                ouvrir13: false,
                notifValidNoteDEbit1: action.payload == "1" ? true : false,
                notifValidNoteDEbit2: action.payload == "0" ? true : false
            }
        },
        fermerNotifValidNoteDEbit1: (state) => {
            return {
                ...state,
                notifValidNoteDEbit1: false
            }
        },
        fermerNotifValidNoteDEbit2: (state) => {
            return {
                ...state,
                notifValidNoteDEbit2: false
            }
        },
        fermerTousDialoguesNoteDEbit: (state) => {
            return {
                ...state,
                ouvrir13: false,
            }
        }
    }
});

export const {
    chargement,
    ouvrirFDialogueValidND,
    fermerFDialogueValidND,
    failliteValidNoteDEbit,
    validNoteDEbitFaite,
    fermerNotifValidNoteDEbit1,
    fermerNotifValidNoteDEbit2,
    fermerTousDialoguesNoteDEbit,

} = partieNoteDEbit.actions;
   
export default partieNoteDEbit.reducer;