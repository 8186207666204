/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import QRCode from 'qrcode';
import { Document, Page, View, Text, StyleSheet, Image, BlobProvider } from "@react-pdf/renderer";
import FrenchNumbersToWords from "FrenchNumbersToWords";
import { listeNotesDEbitsAsync } from "ActionsAsync/ActionsNoteDEbitAsync";
import { ouvrirFDialogueValidND } from "Simplificateurs/SimplificateursNoteDEbit";
import { setPageND, setSelectedRowND } from "Simplificateurs/SimplificateursTable";

// Composants du @mui material
import LinearProgress from '@mui/material/LinearProgress';
import Icon from "@mui/material/Icon";
import Tooltip from '@mui/material/Tooltip';
import { styled } from "@mui/material/styles";

// Table
import { 
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport, 
} from "@mui/x-data-grid";
import { enUS } from '@mui/material/locale';

// Composants du Material Dashboard 2 React
import MDBox from "Composants/MDBox";
import MDButton from "Composants/MDButton";
import Menu from "@mui/material/Menu";
import NotificationItem from "Composants/Items/NotificationItem";

// Contexte Material Dashboard 2 PRO React
import { useMaterialUIController } from "Contexte";

import { 
  frFR, 
  globalStyles,
  CustomNoRowsOverlay, 
  formatDate,
  formatHeure,
  HtmlTooltip,
  CustomPagination
} from "Vues/Utilitaires";

const armoiries = require("../../../Outils/images/armoiries.png");
const logo = require("../../../Outils/images/logo.png");
const watermark1 = require("../../../Outils/images/watermark1.png");
const watermark2 = require("../../../Outils/images/watermark2.png");
const watermark4 = require("../../../Outils/images/watermark4.png");
const watermark5 = require("../../../Outils/images/watermark5.png");

var h = window.innerHeight - 340;

function TableNoteDEbits() {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, lang, socket } = controller;

  const envoi = useDispatch();

  const authObj = useSelector((state) => state.authentification);

  const tableObj = useSelector((state) => state.table);
  const {
    changeColND,
    chargementListeNotesDEbits, 
    listeNotesDEbits,
    pageND,
    selectedRowND,
  } = tableObj;

  useEffect(() => {
    const minuteurObtenirlistes = setTimeout(() => {
      envoi(listeNotesDEbitsAsync(dispatch, socket));
    }, 1000);
    return () => {
      clearTimeout(minuteurObtenirlistes);
    };
  }, []);

  const traiterOuvrir1 = (idDEclaration) => () => {
    envoi(ouvrirFDialogueValidND({idDEclaration}));
  };

  const pdfStyles = StyleSheet.create({
    watermark: {
      position: 'absolute',
      top: 30,
      left: 35,
      width: '100%',
      height: '100%',
    },
    body: {
      backgroundColor: '#fff',
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    image1: {
      width: "auto", 
      height: "65px",
      paddingLeft: "17%",
      marginRight: "68%",
      marginBottom: "5px"
    },
    image2: {
      width: "135%", 
      height: "75px",
      margin: 5,
      paddingLeft: "45%"
    },
    title1: {
      fontFamily: "Times-Bold",
      fontSize: 15,
      margin: 10,
      paddingTop: 5,
      paddingLeft: 3,
      textAlign: "center",
      border: "3px solid #B22222",
    },
    title2: {
      fontFamily: "Times-Bold",
      fontSize: 12,
      paddingRight: "50%",
      textAlign: "center"
    },
    title3: {
      fontFamily: "Times-Roman",
      fontSize: 12,
      paddingRight: "50%",
      textAlign: "center"
    },
    title4: {
      fontFamily: "Times-Bold",
      fontSize: 12,
      textDecoration: "underline",
      paddingRight: "50%",
      paddingBottom: 3,
      textAlign: "center"
    },
    title4a: {
      fontFamily: "Times-Bold",
      fontSize: 12,
      textDecoration: "underline",
      textAlign: "center"
    },
    title4b: {
      fontFamily: "Times-Bold",
      fontSize: 12,
      textAlign: "center"
    },
    title5: {
      fontFamily: "Times-Bold",
      fontSize: 15,
      margin: 10,
      paddingTop: 5,
      paddingLeft: 3,
      textAlign: "center",
      textDecoration: "underline"
    },
    subtitle: {
      fontFamily: "Times-Bold",
      fontSize: 12,
      margin: 3, 
      paddingLeft: 70,
      textAlign: "left"
    },
    text1: {
      fontFamily: "Times-Roman",
      lineHeight: 1.5,
      fontSize: 12,
      paddingLeft: 20,
      textAlign: "left"
    },
    text1a: {
      fontFamily: "Times-Bold",
      lineHeight: 1.5,
      fontSize: 12,
      paddingLeft: 20,
      textAlign: "left"
    },
    text2: {
      lineHeight: 1.5,
      fontSize: 12, 
      maxWidth: 350,
      textAlign: "left"
    },
    text2a: {
      lineHeight: 1.5,
      fontSize: 12, 
      maxWidth: 175,
      textAlign: "left"
    },
    text2b: {
      lineHeight: 1.5,
      fontSize: 12, 
      paddingLeft: 345,
      textAlign: "left"
    },
    text3: {
      fontFamily: "Times-Roman",
      lineHeight: 1.5,
      fontSize: 12,
      paddingLeft: 95,
      textAlign: "left"
    },
    text4: {
      fontFamily: "Times-Roman",
      lineHeight: 1.5,
      fontSize: 12, 
      maxWidth: 350,
      textAlign: "left"
    },
    text4a: {
      fontFamily: "Times-Roman",
      lineHeight: 1.5,
      fontSize: 12, 
      maxWidth: 350,
      paddingLeft: 110,
      textAlign: "left"
    },
    text4b: {
      fontFamily: "Times-Roman",
      lineHeight: 1.5,
      fontSize: 12, 
      maxWidth: 175,
      textAlign: "left"
    },
    text5: {
      fontFamily: "Times-Roman",
      lineHeight: 1.5,
      fontSize: 12,
      paddingLeft: 45,
      textAlign: "left"
    },
    text6: {
      fontFamily: "Times-Roman",
      lineHeight: 1.5,
      fontSize: 12, 
      maxWidth: 350,
      textAlign: "left"
    },
    text6a: {
      fontFamily: "Times-Roman",
      lineHeight: 1.5,
      fontSize: 12, 
      maxWidth: 450,
      textAlign: "left"
    },
    text7: {
      fontFamily: "Times-Roman",
      lineHeight: 1.5,
      fontSize: 12,
      paddingLeft: 65,
      textAlign: "left"
    },
    text7a: {
      fontFamily: "Times-Roman",
      lineHeight: 1.5,
      fontSize: 12,
      paddingTop: -5,
      paddingLeft: 75,
      textAlign: "left"
    },
    text7b: {
      fontFamily: "Times-Bold",
      lineHeight: 1.5,
      fontSize: 12,
      paddingLeft: 57,
      textAlign: "left"
    },
    text8: {
      fontFamily: "Times-Roman",
      lineHeight: 1.5,
      fontSize: 12, 
      maxWidth: 350,
      textAlign: "left"
    },
    text9: {
      fontFamily: "Times-Roman",
      lineHeight: 1.5,
      fontSize: 12, 
      margin: 3,
      paddingRight: 85,
      textAlign: "right"
    },
    text10: {
      fontFamily: "Times-Roman",
      lineHeight: 1.5,
      fontSize: 12, 
      margin: 3,
      paddingTop: 10,
      paddingLeft: 135,
      textAlign: "left"
    },
    table: { 
      display: "table", 
      width: "auto", 
      borderStyle: "solid", 
      borderWidth: 1, 
      borderRightWidth: 0, 
      borderBottomWidth: 0 
    }, 
    tableRow: { 
      margin: "auto", 
      flexDirection: "row" 
    }, 
    tableCol: { 
      borderStyle: "solid", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0 
    }, 
    tableCell1: { 
      margin: "auto", 
      fontFamily: "Times-Bold",
      fontSize: 11 
    },
    tableCell2: { 
      margin: "auto", 
      fontFamily: "Times-Roman",
      fontSize: 11
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
    qrcode1: {
      width: "60%", 
      height: "75px"
    },
    qrcode2: {
      width: "180%", 
      height: "75px"
    },
  });

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    border: 0,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    WebkitFontSmoothing: "auto",
    letterSpacing: "normal",
    fontSize: "13px",
    backgroundColor: darkMode ? "#202940" : "#fff",
    color: darkMode ? "#7b809a" : "rgba(0,0,0,.85)",
    "& .MuiDataGrid-main": {
      color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
    },
    "& .MuiDataGrid-columnSeparator": {
      color: "#7b809a"
    },
    "& .MuiDataGrid-columnHeaders, & .MuiDataGrid-cell": {
      borderBottom: darkMode ? "1px solid #344767" : "1px solid #f0f2f5"
    },
    "& .MuiDataGrid-footerContainer": {
      borderTop: darkMode ? "1px solid #344767" : "1px solid #f0f2f5"
    },
    "& .MuiIconButton-root": {
      color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
    },
    "& .MuiPaginationItem-previousNext": {
      color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
    },
    "& .MuiPaginationItem-textInfo": {
      color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
    },
    "& .Mui-selected": {
      color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)"
    },
    "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
      outline: "none"
    }
  }));

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport 
          printOptions={{ disableToolbarButton: true }} 
          csvOptions={{
            fileName: `${"Liste des Déclarations (" + moment(new Date()).format('DD-MM-YYYY HHmmss') + ")"}`,
            delimiter: ',',
            utf8WithBom: true,
          }}
        />
      </GridToolbarContainer>
    );
  }

  var PNF = require("google-libphonenumber").PhoneNumberFormat;
  var utilPhone = require("google-libphonenumber").PhoneNumberUtil.getInstance();

  const colonnesO = [
    {
      field: "Options",
      width: 115,
      renderCell: (params) => {
        const [ouvrirMenu, assignerOuvrirMenu] = useState(false);
        const traiterOuvrirMenu = (event) => assignerOuvrirMenu(event.currentTarget);
        const traiterFermerMenu = () => assignerOuvrirMenu(false);
        const redevancePourc = params.row.RedevancePourc;
        const prixTotalUSDRedevance = params.row.PrixTotalUSD * redevancePourc / 100;
        const redevanceUSD50 = prixTotalUSDRedevance * 50 / 100;
        const redevanceUSD25 = prixTotalUSDRedevance * 25 / 100;
        const redevanceUSD15 = prixTotalUSDRedevance * 15 / 100;
        const redevanceUSD10 = prixTotalUSDRedevance * 10 / 100;
        const decimal = Number.parseFloat(prixTotalUSDRedevance).toFixed(2);
        const [qrCodeData1, assignerQRCodeData1] = useState("");
        const [qrCodeData2, assignerQRCodeData2] = useState("");
        useEffect(() => {
          QRCode.toDataURL(`https://localhost:3000/authentication/nd/lecture/${params.row.Lecture}`, (error, url) => {
            if (params.row.Lecture && error) {
              console.error("Erreur lors de la génération du code QR (ND):", error);
              return;
            }
            assignerQRCodeData1(url);
          });
          QRCode.toDataURL(`https://localhost:3000/authentication/np/lecture/${params.row.LectureF}`, (error, url) => {
            if (params.row.LectureF && error) {
              console.error("Erreur lors de la génération du code QR (NP) :", error);
              return;
            }
            assignerQRCodeData2(url);
          });
        }, [params.row.Lecture, params.row.LectureF]);
        return (
          <>
            {authObj.info.AssujettiId === null && authObj.info.TypeService === null ?
              <>
                {params.row.EstEnAttenteDeValidationND === "Oui" ?
                  <Tooltip title={lang === "fr" ? "Valider" : "Validate"}>
                    <MDButton variant="gradient" color="primary" size="small" iconOnly onClick={traiterOuvrir1(params.row.Id)}>
                      <Icon>thumb_up_alt</Icon>
                    </MDButton>
                  </Tooltip> :
                  <MDButton variant="gradient" color="secondary" size="small" disabled iconOnly>
                    <Icon>thumb_up_alt</Icon>
                  </MDButton>
                }
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Tooltip title={lang === "fr" ? "Télécharger/Imprimer" : "Download/Print"}>
                  <MDButton variant="gradient" color="success" size="small" iconOnly onClick={traiterOuvrirMenu}>
                    <Icon>download</Icon>
                  </MDButton>
                </Tooltip>
              </> :
              <Tooltip title={lang === "fr" ? "Télécharger/Imprimer" : "Download/Print"}>
                <MDButton variant="gradient" color="success" size="small" iconOnly onClick={traiterOuvrirMenu}>
                  <Icon>download</Icon>
                </MDButton>
              </Tooltip>
            }
            <Menu
              anchorEl={ouvrirMenu}
              anchorReference={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              open={Boolean(ouvrirMenu)}
              onClose={traiterFermerMenu}
              sx={{ mt: 2 }}
            >
              <BlobProvider document={(
                <Document>
                  <Page size="A4" style={pdfStyles.body}>
                    <View style={pdfStyles.watermark} fixed>
                      <Image src={params.row.StatutND === null ? watermark4 : params.row.StatutND === true ? watermark1 : watermark5} style={{ width: '100%', height: '100%' }} />
                    </View>
                    <Text style={pdfStyles.title2}>REPUBLIQUE DEMOCRATIQUE DU CONGO</Text>
                    <Text style={pdfStyles.title2}>PROVINCE DU {params.row.ProvinceExtraction}</Text>
                    <Image style={pdfStyles.image1} src={armoiries}/>
                    <Text style={pdfStyles.title3}>DIVISION PROVINCIALE DES MINES</Text>
                    {params.row.EstValidEND === "Oui" && <Text style={pdfStyles.title4}>{"VILLE DE " + params.row.LieuEmissionND}</Text>}
                    <Text style={pdfStyles.title3}>LE CHEF DE DIVISION</Text>
                    <Text style={pdfStyles.title1}>NOTE DE DEBIT {params.row.EstValidEND === "Oui" && (<>N° DIV.MIN/354/8.3/ {(params.row.No).slice(-7)}&nbsp;&nbsp;&nbsp;&nbsp;{moment(params.row.createdAt).format("YYYY")}</>)}</Text>
                    <Text style={pdfStyles.text1}>Taxation de la redevance minière Cfr Art 240 à 242 Code Minieret Art 256 du Règlement Minier</Text>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text1}>Dénomination sociale du déclarant</Text>
                      <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman", paddingLeft: 151.5}]}>: </Text>
                      <Text style={[pdfStyles.text2a, {fontFamily: "Times-Roman"}]}>{params.row.RaisonSocial}</Text>
                    </View>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text1}>Siège social</Text>
                      <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman", paddingLeft: 262}]}>: </Text>
                      <Text style={[pdfStyles.text2a, {fontFamily: "Times-Roman"}]}>{params.row.NoA}, {params.row.Avenue},</Text>
                    </View> 
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={[pdfStyles.text2b, {fontFamily: "Times-Roman"}]}>Q/{params.row.Quartier}</Text>
                    </View> 
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={[pdfStyles.text2b, {fontFamily: "Times-Roman"}]}>C/{params.row.Commune}</Text>
                    </View> 
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={[pdfStyles.text2b, {fontFamily: "Times-Roman"}]}>V/{params.row.Ville},</Text>
                    </View> 
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={[pdfStyles.text2b, {fontFamily: "Times-Roman"}]}>P/{params.row.Province}</Text>
                    </View> 
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text1}>Lieu d'exploitation</Text>
                      <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman", paddingLeft: 228}]}>: </Text>
                      <Text style={[pdfStyles.text2a, {fontFamily: "Times-Roman"}]}>{params.row.EmplacementSite}</Text>
                    </View>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text1}>Suivant la déclaration d'origine et de vente N°</Text>
                      <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman", paddingLeft: 97.5}]}>: </Text>
                      <Text style={[pdfStyles.text2a, {fontFamily: "Times-Roman"}]}>{params.row.No}</Text>
                    </View>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text1}>Des produits miniers marchands, dont spécifications suivantes</Text>
                      <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman", paddingLeft: 20.5}]}>: </Text>
                    </View>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text5}>1. Type du produit minier marchand</Text>
                      <Text style={[pdfStyles.text6, {paddingLeft: 120.5}]}>: </Text>
                      <Text style={pdfStyles.text6}>{params.row.NatureProduit}</Text>
                    </View>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text5}>2. Cours (valeur de base mensuelle)</Text>
                      <Text style={[pdfStyles.text6, {paddingLeft: 122.5}]}>: </Text>
                      <Text style={pdfStyles.text6}>{params.row.Cours?.replace(".", ",")}</Text>
                    </View>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text5}>3. Quantité</Text>
                      <Text style={[pdfStyles.text6, {paddingLeft: 240}]}>: </Text>
                    </View>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text3}>Kilogramme</Text>
                      <Text style={[pdfStyles.text4, {paddingLeft: 183}]}>: </Text>
                      <Text style={pdfStyles.text4}>
                        {params.row.QtENetConvertTKg.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")}
                      </Text>
                    </View>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text3}>Tonnes</Text>
                      <Text style={[pdfStyles.text4, {paddingLeft: 209}]}>: </Text>
                      <Text style={pdfStyles.text4}>
                        {params.row.QtENetConvertKgT.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")}
                      </Text>
                    </View>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text3}>Carat</Text>
                      <Text style={[pdfStyles.text4, {paddingLeft: 217.5}]}>: </Text>
                      <Text style={pdfStyles.text4}>
                        {params.row.QtENetConvertKgC.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")}
                      </Text>
                    </View>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text5}>4. Teneur</Text>
                      <Text style={[pdfStyles.text6, {paddingLeft: 248.5}]}>: </Text>
                      <Text style={pdfStyles.text6}>{params.row.QualitESecPourc.replace(".", ",")} %</Text>
                    </View>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text5}>5. Prix total</Text>
                      <Text style={[pdfStyles.text6, {paddingLeft: 237}]}>: </Text>
                      <Text style={pdfStyles.text6}>{params.row.PrixTotalUSD.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD</Text>
                    </View>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text5}>6. Taux de la redevance minière à appliquer</Text>
                      <Text style={[pdfStyles.text6, {paddingLeft: 84.5}]}>: </Text>
                      <Text style={pdfStyles.text6}>{params.row.RedevancePourc.replace(".", ",")} %</Text>
                    </View>
                    <Text style={pdfStyles.text7a}>(Cfr Article 241 du Code Minier)</Text>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text7b}>Montant de la redevance minière</Text>
                      <Text style={[pdfStyles.text8, {paddingLeft: 112.5}]}>: </Text>
                      <Text style={pdfStyles.text8}>{params.row.Redevance} USD</Text>
                    </View>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text3}>(En lettres)</Text>
                      <Text style={[pdfStyles.text4, {paddingLeft: 189.5}]}>: </Text>
                      <Text style={pdfStyles.text4b}>{params.row.RedevanceLettre}</Text>
                    </View>
                    <Text style={pdfStyles.text1a}>Répartition de la redevance minière</Text>
                    <Text style={[pdfStyles.text5, {paddingTop: -3}]}>(Cfr Art 242 du Code Minier)</Text>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text1a}>1. Pouvoir Central (DGRAD) : 50%, soit</Text>
                      <Text style={[pdfStyles.text2, {fontFamily: "Times-Bold", paddingLeft: 110}]}>: </Text>
                      <Text style={[pdfStyles.text2a, {fontFamily: "Times-Bold"}]}>{Number.parseFloat(redevanceUSD50).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD</Text>
                    </View>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text1a}>2. Province : 25%, soit</Text>
                      <Text style={[pdfStyles.text2, {fontFamily: "Times-Bold", paddingLeft: 203}]}>: </Text>
                      <Text style={[pdfStyles.text2a, {fontFamily: "Times-Bold"}]}>{Number.parseFloat(redevanceUSD25).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD</Text>
                    </View>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text1a}>3. Entité Territoriale Décentralisée : 15%, soit</Text>
                      <Text style={[pdfStyles.text2, {fontFamily: "Times-Bold", paddingLeft: 82.5}]}>: </Text>
                      <Text style={[pdfStyles.text2a, {fontFamily: "Times-Bold"}]}>{Number.parseFloat(redevanceUSD15).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD</Text>
                    </View>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text1a}>4. Fonds Minier : 10%, soit</Text>
                      <Text style={[pdfStyles.text2, {fontFamily: "Times-Bold", paddingLeft: 178.5}]}>: </Text>
                      <Text style={[pdfStyles.text2a, {fontFamily: "Times-Bold"}]}>{Number.parseFloat(redevanceUSD10).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD</Text>
                    </View>
                    <Text style={[pdfStyles.text1, {paddingTop: 10}]}>N.B :</Text>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text5}>• </Text>
                      <Text style={pdfStyles.text6a}>Le débiteur est tenu de verser sous sa propre responsabilité le montant ainsi réparti de la redevance minière dans chaque compte des bénéficiaires ;</Text>
                    </View>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text5}>• </Text>
                      <Text style={pdfStyles.text6a}>Le débiteur est tenu de déposer les preuves de versements à la Division Provinciale des Mines ou au Service des Mines du ressort endéans huit (8) jours ;</Text>
                    </View>
                    <View style={{flexDirection: "row", margin: 3}}>
                      <Text style={pdfStyles.text5}>• </Text>
                      <Text style={pdfStyles.text6a}>En cas de paiement par chèque bancaire ou ordre de paiement, les frais y afférents sont à charge du débiteur.</Text>
                    </View>
                    {
                      params.row.EstValidEND === "Oui" &&
                      <>
                        <Text style={[pdfStyles.text9, {paddingTop: 15}]}>Fait à {params.row.LieuEmissionND}, le {formatDate(new Date(params.row.DateEmissionND))}</Text>
                        <Text style={[pdfStyles.text9, {paddingTop: 15}]}>Monsieur/Madame {params.row.NomVisaND}</Text>
                        <View style={{flexDirection: "row", marginLeft: 400, marginTop: 30}}>
                          <Image style={pdfStyles.qrcode1} src={qrCodeData1} alt="QR Code" />
                        </View>
                        <Text style={pdfStyles.pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} fixed />
                      </>
                    }
                  </Page>
                </Document>
              )}>
                {({ url }) => (
                  <NotificationItem icon={<Icon>download</Icon>} href={url} target="_blank" title={lang === "fr" ? "Télécharger/Imprimer la note de débit" : "Download/Print the debit note"} disabled={params.row.EstValidED === "Oui" ? false : true} />
                )}
              </BlobProvider>
              <BlobProvider document={(
                <Document>
                  <Page size="A4" style={pdfStyles.body}>
                    <View style={pdfStyles.watermark} fixed>
                      <Image src={watermark2} style={{ width: '100%', height: '100%' }} />
                    </View>
                    <View style={{flexDirection: "row"}}>
                      <View>
                        <Text style={[pdfStyles.title2, {color: "#4169E1", marginLeft: 15}]}>République Démocratique Du Congo</Text>
                        <Text style={[pdfStyles.title2, {color: "#CD5C5C"}]}>Fonds Minier pour les Génération Futures</Text>
                        <Image style={pdfStyles.image2} src={logo}/>
                      </View>
                      <View style={{flexDirection: "row", marginLeft: 350}}>
                        <Image style={pdfStyles.qrcode2} src={qrCodeData2} alt="QR Code" />
                      </View>
                    </View>
                    <Text style={pdfStyles.title5}>NOTE DE PERCEPTION N° ...</Text>
                    <View style={{ border: "0.3px solid black"}}> 
                      <View style={{flexDirection: "row", margin: 3}}>
                        <Text style={pdfStyles.title4a}>SERVICE TAXATEUR</Text>
                        <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman", paddingLeft: 5}]}>:</Text>
                        <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman"}]}>...</Text>
                      </View>
                      <View style={{flexDirection: "row", margin: 3}}>
                        <Text style={pdfStyles.title4b}>-</Text>
                        <Text style={pdfStyles.text1}>N° facture ou note de taxation (débit)</Text>
                        <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman", paddingLeft: 5}]}>: </Text>
                        <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman"}]}>DIV.MIN/354/8.3/ {(params.row.No).slice(-7)}&nbsp;&nbsp;&nbsp;&nbsp;{moment(params.row.createdAt).format("YYYY")}</Text>
                      </View>
                      <View style={{flexDirection: "row", margin: 3}}>
                        <Text style={pdfStyles.title4b}>-</Text>
                        <Text style={pdfStyles.text1}>Non ou raison social</Text>
                        <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman", paddingLeft: 5}]}>: </Text>
                        <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman"}]}>{params.row.RaisonSocial}</Text>
                      </View>
                      <View style={{flexDirection: "row", margin: 3}}>
                        <Text style={pdfStyles.title4b}>-</Text>
                        <Text style={pdfStyles.text1}>Article budgétaire</Text>
                        <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman", paddingLeft: 5}]}>: </Text>
                        <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman"}]}>{params.row.NatureProduit}</Text>
                      </View>
                      <View style={{flexDirection: "row", margin: 3}}>
                        <Text style={pdfStyles.title4b}>-</Text>
                        <Text style={pdfStyles.text1}>Avenue/Rue</Text>
                        <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman", paddingLeft: 5}]}>: </Text>
                        <Text style={[pdfStyles.text2a, {fontFamily: "Times-Roman"}]}>{params.row.Avenue}</Text>
                        <Text style={pdfStyles.text1}>N°</Text>
                        <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman", paddingLeft: 5}]}>: </Text>
                        <Text style={[pdfStyles.text2a, {fontFamily: "Times-Roman"}]}>{params.row.No}</Text>
                      </View>
                      <View style={{flexDirection: "row", margin: 3}}>
                        <Text style={pdfStyles.title4b}>-</Text>
                        <Text style={pdfStyles.text1}>Nombre d'actes</Text>
                        <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman", paddingLeft: 5}]}>: </Text>
                        <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman"}]}>{params.row.NombreActes}</Text>
                      </View>
                      <View style={{flexDirection: "row", margin: 3}}>
                        <Text style={pdfStyles.title4b}>-</Text>
                        <Text style={pdfStyles.text1}>Commune</Text>
                        <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman", paddingLeft: 5}]}>: </Text>
                        <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman"}]}>{params.row.Commune}</Text>
                      </View>
                      <View style={{flexDirection: "row", margin: 3}}>
                        <Text style={pdfStyles.title4b}>-</Text>
                        <Text style={pdfStyles.text1}>Arrêté N°</Text>
                        <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman", paddingLeft: 5}]}>: </Text>
                        <Text style={[pdfStyles.text2a, {fontFamily: "Times-Roman"}]}>{params.row.Arr}</Text>
                        <Text style={pdfStyles.text1}>du</Text>
                        <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman", paddingLeft: 5}]}>: </Text>
                        <Text style={[pdfStyles.text2a, {fontFamily: "Times-Roman"}]}>{formatDate(new Date(params.row.Date))}</Text>
                      </View>
                      <View style={{flexDirection: "row", margin: 3}}>
                        <Text style={pdfStyles.title4b}>-</Text>
                        <Text style={pdfStyles.text1}>Tél.</Text>
                        <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman", paddingLeft: 5}]}>: </Text>
                        <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman"}]}>{utilPhone.format(utilPhone.parse("+" + params.row.Phone, ""), PNF.INTERNATIONAL)}</Text>
                      </View>
                      <View style={{flexDirection: "row", margin: 3}}>
                        <Text style={pdfStyles.title4b}>-</Text>
                        <Text style={pdfStyles.text1}>Montant taxé (en chiffres)</Text>
                        <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman", paddingLeft: 5}]}>: </Text>
                        <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman"}]}>{Number.parseFloat(redevanceUSD10).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD</Text>
                      </View>
                      <View style={{flexDirection: "row", margin: 3}}>
                        <Text style={pdfStyles.title4b}>-</Text>
                        <Text style={pdfStyles.text1}>Montant taxé (en lettres)</Text>
                        <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman", paddingLeft: 5}]}>: </Text>
                        <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman"}]}>{new FrenchNumbersToWords(Number.parseFloat(redevanceUSD10).toFixed(2), 'fr').result.fullText} virgule {new FrenchNumbersToWords(Number.parseFloat(decimal).toFixed(2).substring(Number.parseFloat(decimal).toFixed(2).indexOf(".")+1, Number.parseFloat(decimal).toFixed(2).length), 'fr').result.fullText} dollars américains</Text>
                      </View>
                      <View style={{flexDirection: "row", margin: 3}}>
                        <Text style={pdfStyles.title4b}>-</Text>
                        <Text style={pdfStyles.text1}>Date de taxation</Text>
                        <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman", paddingLeft: 5}]}>: </Text>
                        <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman"}]}>{formatDate(new Date(params.row.DateTaxation))}</Text>
                      </View>
                      <View style={{flexDirection: "row", margin: 3}}>
                        <Text style={pdfStyles.title4b}>-</Text>
                        <Text style={pdfStyles.text1}>Nom de l'agent taxateur</Text>
                        <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman", paddingLeft: 5}]}>: </Text>
                        <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman"}]}>{params.row.NomVisaND}</Text>
                      </View>
                      <View style={{flexDirection: "row", margin: 3}}>
                        <Text style={pdfStyles.title4b}>-</Text>
                        <Text style={pdfStyles.text1}>Qualité</Text>
                        <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman", paddingLeft: 5}]}>: </Text>
                        <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman"}]}>{params.row.FonctionVisaND}</Text>
                      </View>
                    </View>
                    <View style={{ border: "0.3px solid black", marginTop: 10}}> 
                      <View style={{flexDirection: "row", margin: 3}}>
                        <Text style={pdfStyles.title4a}>AGENT DU FOMIN</Text>
                        <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman", paddingLeft: 5}]}>:</Text>
                      </View>
                      <View style={{flexDirection: "row", margin: 3}}>
                        <Text style={pdfStyles.title4b}>-</Text>
                        <Text style={pdfStyles.text1}>Avis de l'agent</Text>
                        <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman", paddingLeft: 5}]}>: </Text>
                        <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman"}]}>{params.row.Commentaire}</Text>
                      </View>
                      <View style={{flexDirection: "row", margin: 3}}>
                        <Text style={pdfStyles.title4b}>-</Text>
                        <Text style={pdfStyles.text1}>Montant à payer (en chiffres)</Text>
                        <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman", paddingLeft: 5}]}>: </Text>
                        <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman"}]}>{Number.parseFloat(redevanceUSD10).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD</Text>
                      </View>
                      <View style={{flexDirection: "row", margin: 3}}>
                        <Text style={pdfStyles.title4b}>-</Text>
                        <Text style={pdfStyles.text1}>Montant à payer (en lettres)</Text>
                        <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman", paddingLeft: 5}]}>: </Text>
                        <Text style={[pdfStyles.text2, {fontFamily: "Times-Roman"}]}>{new FrenchNumbersToWords(Number.parseFloat(redevanceUSD10).toFixed(2), 'fr').result.fullText} virgule {new FrenchNumbersToWords(Number.parseFloat(decimal).toFixed(2).substring(Number.parseFloat(decimal).toFixed(2).indexOf(".")+1, Number.parseFloat(decimal).toFixed(2).length), 'fr').result.fullText} dollars américains</Text>
                      </View>
                    </View>
                    <Text style={pdfStyles.pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} fixed />
                  </Page>
                </Document>
              )}>
                {({ url }) => (
                  <NotificationItem icon={<Icon>download</Icon>} href={url} target="_blank" title={lang === "fr" ? "Télécharger/Imprimer la note de perception" : "Download/Print the perception note"} disabled={params.row.EstValidEND === "Oui" ? false : true} />
                )}
              </BlobProvider>
            </Menu>
          </>
        );
      }, 
      sortable: false, 
      filterable: false,
      disableColumnMenu: true,
      disableExport: true
    }
  ]; 

  const colonnesND = [
    { 
      field: "EstEnElaborationND", 
      headerName: "Elaboration", 
      type: "singleSelect", 
      valueOptions: [
        { value: "Oui", label: lang === "fr" ? "Oui" : "Yes"},
        { value: "Non", label: lang === "fr" ? "Non" : "No" }
      ],
      width: 85, 
      renderCell: (params) => (
        <MDBox ml={-1}>
          {params.value === "Oui" && <MDButton variant="outlined" color="success" size="small" iconOnly disabled><Icon>check</Icon></MDButton>}
          {params.value === "Non" && <MDButton variant="outlined" color="error" size="small" iconOnly disabled><Icon>close</Icon></MDButton>}
        </MDBox>
      ),
      disableColumnMenu: true
    },
    { 
      field: "EstEnAttenteDeValidationND", 
      headerName: lang === "fr" ? "Attente de validation" : "Waiting for validation", 
      type: "singleSelect", 
      valueOptions: [
        { value: "Oui", label: lang === "fr" ? "Oui" : "Yes"},
        { value: "Non", label: lang === "fr" ? "Non" : "No" }
      ],
      width: 85, 
      renderCell: (params) => (
        <MDBox ml={-1}>
          {params.value === "Oui" && <MDButton variant="outlined" color="success" size="small" iconOnly disabled><Icon>check</Icon></MDButton>}
          {params.value === "Non" && <MDButton variant="outlined" color="error" size="small" iconOnly disabled><Icon>close</Icon></MDButton>}
        </MDBox>
      ),
      disableColumnMenu: true
    },
    { 
      field: "EstValidEND", 
      headerName: "Validation", 
      type: "singleSelect", 
      valueOptions: [
        { value: "Oui", label: lang === "fr" ? "Oui" : "Yes"},
        { value: "Non", label: lang === "fr" ? "Non" : "No" }
      ],
      width: 85, 
      renderCell: (params) => (
        <MDBox ml={-1}>
          {params.value === "Oui" && <MDButton variant="outlined" color="success" size="small" iconOnly disabled><Icon>check</Icon></MDButton>}
          {params.value === "Non" && <MDButton variant="outlined" color="error" size="small" iconOnly disabled><Icon>close</Icon></MDButton>}
        </MDBox>
      ),
      disableColumnMenu: true
    },
    { 
      field: "Identifiant", 
      headerName: lang === "fr" ? "Identifiant" : "Identifier",
      width: 220,
      renderCell: (params) => (
        <div>
          {
            <HtmlTooltip title={
              <Fragment>
                {lang === "fr" ? "Exportateur : " + params.row.TypeId : "Exporter: " + params.row.TypeId}
                {lang === "fr" ? "\nRaison social : " + params.row.RaisonSocial : "\nCompany name: " + params.row.RaisonSocial}
                {`${params.row.IdNat === null ? "" : lang === "fr" ? "\nIdentité Nationale : " + params.row.IdNat : "\nNational Identity: " + params.row.IdNat}`}
                {lang === "fr" ? "\nAdresse : " + params.row.NoA : "\nAddress: " + params.row.No }
                {", Av. " + params.row.Avenue + ", Q/" + params.row.Quartier + ", C/" + params.row.Commune + ", V/" + params.row.Ville + ", P/" + params.row.Province}
                {lang === "fr" ? "\nNuméro d'arrêté de l'agrément : " + params.row.Arr : "\nApproval order number: " + params.row.Arr}
                {lang === "fr" ? "\nDate de début : " + moment(params.row.Date).format("DD/MM/YYYY") : "\nStart date: " + moment(params.row.Date).format("DD/MM/YYYY")}
                {lang === "fr" ? "\nDate d'expiration : " + moment(params.row.DateF).format("DD/MM/YYYY") : "\nEnd date: " + moment(params.row.DateF).format("DD/MM/YYYY")}
              </Fragment>
            }>
              <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
                <Icon color="success">info</Icon> {params.row.Identifiant}
              </div>
            </HtmlTooltip>
          }
        </div>
      ), 
      disableColumnMenu: true
    },
    { 
      field: "Num", 
      headerName: lang === "fr" ? "N° de la note de débit" : "Débit note no.",  
      width: 160,
      renderCell: (params) => `DIV.MIN/354/8.3/ ${(params.row.No).slice(-7)}&nbsp;&nbsp;&nbsp;&nbsp;${moment(params.row.DateEmissionND).format("YYYY")}`,
      disableColumnMenu: true
    },
    { 
      field: "No", 
      headerName: lang === "fr" ? "N° de la déclaration" : "Declaration No.",  
      width: 160,
      disableColumnMenu: true
    },
    { 
      field: "NatureProduit", 
      headerName: lang === "fr" ? "Article budgétaire" : "Budget item",
      width: 170,
      disableColumnMenu: true
    },
    { 
      field: "Cours", 
      headerName: lang === "fr" ? "Cours (valeur de base mensuelle)" : "Price (monthly base value)", 
      width: 170,
      renderCell: (params) => params.row.Cours ? lang === "fr" ? `${Number.parseFloat(params.row.Cours).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD` : `${Number.parseFloat(params.row.Cours).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD` : "", 
      valueGetter: (value) => value ? lang === "fr" ? `${Number.parseFloat(value).toFixed(2).replace(".", ",")}` : `${Number.parseFloat(value).toFixed(2)}` : "",
      disableColumnMenu: true
    },
    { 
      field: "QtENetConvertTKg", 
      headerName: lang === "fr" ? "Quantité net 1" : "Net quantity 1", 
      width: 160,
      renderCell: (params) => lang === "fr" ? `${Number.parseFloat(params.row.QtENetConvertTKg).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} Kg(s)` : `${Number.parseFloat(params.row.QtENetConvertTKg).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} Kg(s)`, 
      valueGetter: (value) => lang === "fr" ? `${Number.parseFloat(value).toFixed(2).replace(".", ",")}` : `${Number.parseFloat(value).toFixed(2)}`,
      disableColumnMenu: true
    },
    { 
      field: "QtENetConvertKgT", 
      headerName: lang === "fr" ? "Quantité net 2" : "Net quantity 2", 
      width: 160,
      renderCell: (params) => lang === "fr" ? `${Number.parseFloat(params.row.QtENetConvertKgT).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")}  Tonne(s)` : `${Number.parseFloat(params.row.QtENetConvertKgT).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} Tonne(s)`, 
      valueGetter: (value) => lang === "fr" ? `${Number.parseFloat(value).toFixed(2).replace(".", ",")}` : `${Number.parseFloat(value).toFixed(2)}`,
      disableColumnMenu: true
    },
    { 
      field: "QtENetConvertKgc", 
      headerName: lang === "fr" ? "Quantité net 3" : "Net quantity 3", 
      width: 160,
      renderCell: (params) => lang === "fr" ? `${Number.parseFloat(params.row.QtENetConvertKgC).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} Carat(s)` : `${Number.parseFloat(params.row.QtENetConvertKgC).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} Carat(s)`, 
      valueGetter: (value) => lang === "fr" ? `${Number.parseFloat(value).toFixed(2).replace(".", ",")}` : `${Number.parseFloat(value).toFixed(2)}`,
      disableColumnMenu: true
    },
    { 
      field: "QualitESecPourc", 
      headerName: lang === "fr" ? "Qualité sec" : "Dry quality", 
      width: 160,
      renderCell: (params) => lang === "fr" ? `${Number.parseFloat(params.row.QualitESecPourc).toFixed(2).replace(".", ",")} %` : `${Number.parseFloat(params.row.QualitESecPourc).toFixed(2)} %`, 
      valueGetter: (value) => lang === "fr" ? `${Number.parseFloat(value).toFixed(2).replace(".", ",")}` : `${Number.parseFloat(value).toFixed(2)}`,
      disableColumnMenu: true
    },
    { 
      field: "PrixTotalUSD", 
      headerName: lang === "fr" ? "Prix Total" : "Total price", 
      width: 160,
      renderCell: (params) => lang === "fr" ? `${Number.parseFloat(params.row.PrixTotalUSD).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD` : `${Number.parseFloat(params.row.PrixTotalUSD).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD`, 
      valueGetter: (value) => lang === "fr" ? `${Number.parseFloat(value).toFixed(2).replace(".", ",")}` : `${Number.parseFloat(value).toFixed(2)}`,
      disableColumnMenu: true
    },
    { 
      field: "RedevancePourc", 
      headerName: lang === "fr" ? "Taux de la redevance" : "Royalty rate", 
      width: 160,
      renderCell: (params) => lang === "fr" ? `${Number.parseFloat(params.row.RedevancePourc).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} %` : `${Number.parseFloat(params.row.RedevancePourc).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} %`, 
      valueGetter: (value) => lang === "fr" ? `${Number.parseFloat(value).toFixed(2).replace(".", ",")}` : `${Number.parseFloat(value).toFixed(2)}`,
      disableColumnMenu: true
    },
    { 
      field: "Redevance", 
      headerName: lang === "fr" ? "Montant de la redevance" : "Royalty amount", 
      width: 180,
      renderCell: (params) => lang === "fr" ? `${Number.parseFloat(params.row.Redevance).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD` : `${Number.parseFloat(params.row.Redevance).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD`, 
      valueGetter: (value) => lang === "fr" ? `${Number.parseFloat(value).toFixed(2).replace(".", ",")}` : `${Number.parseFloat(value).toFixed(2)}`,
      disableColumnMenu: true
    },
    { 
      field: "RedevanceLettre", 
      headerName: lang === "fr" ? "Montant de la redevance (lettres)" : "Royalty amount (letters)",
      width: 230,
      filterable: false
    },
    { 
      field: "LieuEmissionND", 
      headerName: lang === "fr" ? "Lieu d'émission" : "Issuance place", 
      width: 160,
      disableColumnMenu: true
    },
    { 
      field: "DateEmissionND", 
      headerName: lang === "fr" ? "date d'émission" : "Issuance date", 
      type: "dateTime", 
      width: 150, 
      renderCell: (params) => params.value ? `${formatDate(new Date(params.value))}, ${formatHeure(new Date(params.value))}` : "",
      valueGetter: (value) => value ? new Date(value) : "",
      disableColumnMenu: true
    },
    { 
      field: "NomVisaND", 
      headerName: lang === "fr" ? "Nom (Visa)" : "Name (Visa)", 
      width: 160, 
      disableColumnMenu: true
    }
  ]; 

  const colonnesNP = [
    { 
      field: "Identifiant", 
      headerName: lang === "fr" ? "Identifiant" : "Identifier",
      width: 220,
      renderCell: (params) => (
        <div>
          {
            <HtmlTooltip title={
              <Fragment>
                {lang === "fr" ? "Exportateur : " + params.row.TypeId : "Exporter: " + params.row.TypeId}
                {lang === "fr" ? "\nRaison social : " + params.row.RaisonSocial : "\nCompany name: " + params.row.RaisonSocial}
                {`${params.row.IdNat === null ? "" : lang === "fr" ? "\nIdentité Nationale : " + params.row.IdNat : "\nNational Identity: " + params.row.IdNat}`}
                {lang === "fr" ? "\nAdresse : " + params.row.NoA : "\nAddress: " + params.row.No }
                {", Av. " + params.row.Avenue + ", Q/" + params.row.Quartier + ", C/" + params.row.Commune + ", V/" + params.row.Ville + ", P/" + params.row.Province}
                {lang === "fr" ? "\nNuméro d'arrêté de l'agrément : " + params.row.Arr : "\nApproval order number: " + params.row.Arr}
                {lang === "fr" ? "\nDate de début : " + moment(params.row.Date).format("DD/MM/YYYY") : "\nStart date: " + moment(params.row.Date).format("DD/MM/YYYY")}
                {lang === "fr" ? "\nDate d'expiration : " + moment(params.row.DateF).format("DD/MM/YYYY") : "\nEnd date: " + moment(params.row.DateF).format("DD/MM/YYYY")}
              </Fragment>
            }>
              <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
                <Icon color="success">info</Icon> {params.row.Identifiant}
              </div>
            </HtmlTooltip>
          }
        </div>
      ), 
      disableColumnMenu: true
    },
    { 
      field: "Num", 
      headerName: lang === "fr" ? "N° Réf." : "Ref. No.",  
      width: 160,
      renderCell: (params) => `DIV.MIN/354/8.3/ ${(params.row.No).slice(-7)}&nbsp;&nbsp;&nbsp;&nbsp;${moment(params.row.createdAt).format("YYYY")}`,
      disableColumnMenu: true
    },
    { 
      field: "RaisonSocial", 
      headerName: lang === "fr" ? "Nom ou raison social" : "Company name",
      width: 170,
      disableColumnMenu: true
    },
    { 
      field: "NatureProduit", 
      headerName: lang === "fr" ? "Article budgétaire" : "Budget item",
      width: 170,
      disableColumnMenu: true
    },
    { 
      field: "Avenue", 
      headerName: lang === "fr" ? "Avenue" : "Street", 
      width: 150,
      disableColumnMenu: true
    },
    { 
      field: "NoA", 
      headerName: lang === "fr" ? "N°" : "No.", 
      width: 150,
      disableColumnMenu: true 
    },
    { 
      field: "NombreActes", 
      headerName: lang === "fr" ? "Nombre d'actes" : "Number of acts", 
      width: 170,
      disableColumnMenu: true 
    },
    { 
      field: "Commune", 
      headerName: lang === "fr" ? "Commune/Secteur" : "Municipality/Sector",
      width: 150,
      disableColumnMenu: true 
    },
    { 
      field: "Arr", 
      headerName: lang === "fr" ? "Arrêté N°" : "Order No.",
      width: 150,
      disableColumnMenu: true 
    },
    { 
      field: "Date", 
      type: "date", 
      width: 150, 
      renderCell: (params) => `${formatDate(new Date(params.row.Date))}`,
      valueGetter: (value) => new Date(value),
      disableColumnMenu: true
    },
    { 
      field: "Phone", 
      headerName: lang === "fr" ? "Téléphone" : "Phone No.", 
      width: 150,
      renderCell: (params) => utilPhone.format(utilPhone.parse("+" + params.row.Phone, ""), PNF.INTERNATIONAL),  
      valueGetter: (value) => `+${value}`, 
      disableColumnMenu: true 
    },
    { 
      field: "Redevance", 
      headerName: lang === "fr" ? "Montant taxé/à payer (chiffres)" : "Amount taxed/payable (numbers)", 
      width: 160,
      renderCell: (params) => lang === "fr" ? `${Number.parseFloat(params.row.Redevance / 10).toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD` : `${Number.parseFloat(params.row.Redevance / 10).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} USD`, 
      //valueGetter: (value) => lang === "fr" ? `${Number.parseFloat(value).toFixed(2).replace(".", ",")}` : `${Number.parseFloat(value).toFixed(2)}`,
      disableColumnMenu: true
    },
    { 
      field: "MontantLettre", 
      headerName: lang === "fr" ? "Montant taxé/à payer (lettres)" : "Amount taxed/payable (letters)",
      width: 170,
      renderCell: (params) => `${new FrenchNumbersToWords(Number.parseFloat(params.row.Redevance / 10).toFixed(2), 'fr').result.fullText} virgule ${new FrenchNumbersToWords(Number.parseFloat(params.row.Redevance / 10).toFixed(2).substring(Number.parseFloat(params.row.Redevance / 10).toFixed(2).indexOf(".")+1, Number.parseFloat(params.row.Redevance / 10).toFixed(2).length), 'fr').result.fullText} dollars américains`,
      //valueGetter: (value) => lang === "fr" ? `${new FrenchNumbersToWords(Number.parseFloat(params.row.Redevance / 10).toFixed(2), 'fr').result.fullText} virgule ${new FrenchNumbersToWords(Number.parseFloat(params.row.Redevance / 10).toFixed(2).substring(Number.parseFloat(params.row.Redevance / 10).toFixed(2).indexOf(".")+1, Number.parseFloat(params.row.Redevance / 10).toFixed(2).length), 'fr').result.fullText} dollars américains`,
      disableColumnMenu: true 
    },
    { 
      field: "DateTaxation", 
      headerName: lang === "fr" ? "Date de taxation" : "Tax date", 
      type: "date", 
      width: 150, 
      renderCell: (params) => params.value ? `${formatDate(new Date(params.value))}` : "",
      valueGetter: (value) => value ? new Date(value) : "",
      disableColumnMenu: true

    },
    { 
      field: "NomVisaND", 
      headerName: lang === "fr" ? "Nom (Visa)" : "Name (Visa)", 
      width: 160, 
      disableColumnMenu: true
    },
    { 
      field: "FonctionVisaND", 
      headerName: lang === "fr" ? "Fonction (Visa)" : "Role (Visa)", 
      width: 160, 
      disableColumnMenu: true
    },
    { 
      field: "Commentaire", 
      headerName: lang === "fr" ? "Avis" : "Review", 
      width: 300, 
      disableColumnMenu: true
    },
  ]; 

  const colonnes = changeColND
  ? [...colonnesO, ...colonnesNP]
  : [...colonnesO, ...colonnesND];

  const [paginationModel, setPaginationModel] = useState(pageND);
  useEffect(() => {
    localStorage.setItem("pageND", JSON.stringify(paginationModel));
    envoi(setPageND());
  },[paginationModel])

  return (
    <div style={{ height: h, width: "100%" }}>
      {globalStyles()}
      <StyledDataGrid
        checkboxSelection
        disableRowSelectionOnClick
        density="compact"
        rows={listeNotesDEbits} 
        getRowId={(row) => row.Id}
        columns={colonnes} 
        slots= {{
          loadingOverlay: LinearProgress, 
          noRowsOverlay: CustomNoRowsOverlay, 
          noResultsOverlay: CustomNoRowsOverlay,
          toolbar: CustomToolbar, 
          pagination: CustomPagination
        }}
        loading={chargementListeNotesDEbits}
        componentsProps={{
          panel: {
            sx: {
              "& .MuiDataGrid-panelWrapper": {
                backgroundColor: darkMode ? "#202940" : "#fff",
              },
              "& .MuiNativeSelect-select": {
                color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
              },
              "& .MuiSvgIcon-root": {
                color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
              },
              "& .MuiFormControl-root": {
                color: darkMode ? "rgba(255,255,255,0.85)" : "rgba(0,0,0,.85)",
              }
            }
          }
        }}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 50},
          },
          columns: {
            columnVisibilityModel: !JSON.parse(localStorage.getItem("columnVisibilityModelND")) ? {} : JSON.parse(localStorage.getItem("columnVisibilityModelND"))
          },
          sorting: {
            sortModel: !JSON.parse(localStorage.getItem("sortModelND")) ? [] : JSON.parse(localStorage.getItem("sortModelND"))
          },
          filter: {
            filterModel: !JSON.parse(localStorage.getItem(changeColND === false ? "filterModelND" : "filterModelNPe")) ? {items: []} : JSON.parse(localStorage.getItem(changeColND === false ? "filterModelND" : "filterModelNPe"))
          }
        }}
        paginationModel={pageND}
        onPaginationModelChange={setPaginationModel}
        onColumnVisibilityModelChange={(model) => localStorage.setItem("columnVisibilityModelND", JSON.stringify(model))}
        rowSelectionModel={selectedRowND}
        onRowSelectionModelChange={
          (model) => {
            envoi(setSelectedRowND(model));
          }
        }
        onSortModelChange={(model) => localStorage.setItem("sortModelND", JSON.stringify(model))}
        onFilterModelChange={(model)=> localStorage.setItem(changeColND === false ? "filterModelND" : "filterModelNPe", JSON.stringify(model))}
        localeText={lang === "fr" ? frFR : enUS}
      />
    </div>
  );
};

export default TableNoteDEbits;
